import React, { Component } from "react";
import "./style.css";
import Card from 'react-bootstrap/Card';
import API from '../../utils/API';


// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'




class Licensing extends Component {

    state = {
        showEnroll: true,
        agentUID: "",
        alreadyLicensed: false,
        statesLicensed: ["Pennsylvania", "Rhode Island", "Vermont", "New York", "North"],
        expirationDate: "01/01/1999",
        currentyLicensed: "",
        licensedStates: "",
        residentState: "Pennsylvania",
        stepOneCompleted: "",
        stepTwoCompleted: "",
        stepThreeCompleted: "",
        stepFourCompleted: "",
        stepFiveCompleted: ""
    }

    componentDidMount = () => {
        console.log("Licensing Card Mounted")
        console.log(this.props);
        // setTimeout(() => {
            // this.setState({
            //     showEnroll: true
            // }, 5000)
        // })
        // setTimeout(() => { this.calculateTotals() }, 3000)
        API.findLicense({uid: this.props.uid})
            .then(res => {
                console.log("Found License Data");
                console.log(res)
                if(res.data[0].agentUID === this.props.uid) {
                    this.setState({
                        showEnroll: false,
                        agentUID: res.data[0].agentUID,
                        currentyLicensed: res.data[0].currentlyLicensed,
                        licensedStates: res.data[0].licensedStates,
                        residentState: res.data[0].residentState,
                        stepOneCompleted: res.data[0].stepOneCompleted,
                        stepTwoCompleted: res.data[0].stepTwoCompleted,
                        stepThreeCompleted: res.data[0].stepThreeCompleted,
                        stepFourCompleted: res.data[0].stepFourCompleted,
                        stepFiveCompleted: res.data[0].stepFiveCompleted
                    })
                }
            })
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    enroll = () => {
        let dataBody = {
            agentUID: this.props.agentData.uid,
            agentID: this.props.agentData._id,
            residentState: this.props.agentData.residentState,
            licensedStates: "PA|NJ|DE"
        }
        API.createLicense(dataBody)
            .then(res => {
                console.log("Created License info");
                console.log(res);
            })
    }

    render() {
        return (
            <div>

            <div style={{ backgroundColor: "rgba(255,255,255,0.6)", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", padding: '20px'}}>
                    
                    <h5 style={{color: "black"}}>P&C Licensing Info:</h5>
                    { !this.state.alreadyLicensed ? (
                
                    <button className="btn btn-primary btn-block" style={{width: '50%', marginLeft: '25%', backgroundColor: "#4CAF50", border: "none", color: "white", padding: "15px 32px", textAlign: "center", textDecoration: "none", fontSize: "16px", cursor: "pointer"}} onClick={(e) => {console.log("Opening licensing guide")}}>Enroll</button>
                    ) : (
                    this.state.statesLicensed.length !== 0 && (
                        <div style={{ color: "black", fontFamily: "Arial, sans-serif", padding: "5px" }}>
                        <p>States Licensed: {this.state.statesLicensed.join(', ')}</p>
                        <p>Expiration Date: {this.state.expirationDate}</p>

                        {/* <p>Step One Completed: {this.state.stepOneCompleted}</p>
                        <p>Step Two Completed: {this.state.stepTwoCompleted}</p>
                        <p>Step Three Completed: {this.state.stepThreeCompleted}</p>
                        <p>Step Four Completed: {this.state.stepFourCompleted}</p>
                        <p>Step Five Completed: {this.state.stepFiveCompleted}</p> */}
                        </div>
                    ))}
            </div>

                
            </div >
        )

    }

}

export default Licensing;