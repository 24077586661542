import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import { storage } from '../../config/Fire';
import cogoToast from "cogo-toast";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class PropertyPolicySummary extends Component {

    state = {
        editActivated: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        this.setState({
            carrier: this.props.propertyData.carrier,
            policyNumber: this.props.propertyData.policyNumber,
            effectiveDate: this.props.propertyData.effectiveDate,
            dwelling: this.props.propertyData.dwelling,
            structures: this.props.propertyData.structures,
            persprop: this.props.propertyData.persprop,
            lossofuse: this.props.propertyData.lossofuse,
            liability: this.props.propertyData.liability,
            medical: this.props.propertyData.medical,
            deductible: this.props.propertyData.deductible,
            premium: this.props.propertyData.premium,
            url: this.props.propertyData.declaration
        })
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    copyText = (event, data) => {
        console.log(this.state);
        event.preventDefault();

        if (data !== undefined){
            var copyText = data;
            navigator.clipboard.writeText(copyText);
            console.log(navigator.clipboard);
            cogoToast.success("Copied: " + data)
        } else {
            cogoToast.error("Couldn't copy data")
        }

    }


    fileSelectedHandler = event => {
        event.preventDefault();
        if (event.target.files[0]) {
            this.setState({
                declaration: event.target.files[0]
            })
        }
    }

    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        declaration: urlB,
                        uploaded: true
                    })
                })
            }
        )
    }


    activateEdit = (e) => {
        e.preventDefault();
        if (this.state.editActivated === true) {
            this.setState({
                editActivated: false
            })
        } else {
            this.setState({
                editActivated: true
            })
        }
    }


    saveEdits = (e) => {
        e.preventDefault();

        var editedData = {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            effectiveDate: this.state.effectiveDate,
            dwelling: this.state.dwelling,
            structures: this.state.structures,
            persprop: this.state.persprop,
            lossofuse: this.state.lossofuse,
            liability: this.state.liability,
            medical: this.state.medical,
            deductible: this.state.deductible,
            premium: parseInt(this.state.premium)
        }

        console.log(editedData)

        API.updatePropertyPolicy(this.props.propertyData._id, {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            dwelling: this.state.dwelling,
            structures: this.state.structures,
            persprop: this.state.persprop,
            lossofuse: this.state.lossofuse,
            liability: this.state.liability,
            medical: this.state.medical,
            deductible: this.state.deductible,
            premium: parseInt(this.state.premium),
            declaration: this.state.url
        }).then(res => {
            alert("You've updated this policy!")
        }).catch(err => alert("The edit did not go through!"))

    }

    softDeleteProperty = (id) => {
        console.log(id + " has been deleted")
        API.softDeleteProperty(id)
            .then(res => {
                alert("You've deleted this property policy")
            }).catch(err => alert("The deletion did not go through"))
    }

    showProperty = () => {
        if (this.state.showProperty === true) {
            this.setState({
                showProperty: false
            })
        } else {
            this.setState({
                showProperty: true
            })
        }
    }

    render() {
        return (
            <div className="row">
                <div className={this.props.colSize}>
                    {this.props.propertyData.policyActive ?
                        <div className="card" style={{ overflow: 'auto' }}>
                            <div className="card-header" style={{ color: 'black' }}>
                            <h5><strong>{this.props.propertyData.carrier} Property</strong></h5>
                            <h6><strong>Policy #: {this.props.propertyData.policyNumber}</strong></h6>    
                            <button className="btn btn-success" onClick={this.showProperty}>Show / Hide</button>
                            {this.props.admin ? <button className="btn btn-warning" onClick={this.activateEdit}>Edit</button> : null}
                            {this.props.admin ? <button className="btn-danger btn" onClick={() => this.softDeleteProperty(this.props.propertyData._id)}>Delete</button> : null}
                            </div>

                            {this.state.editActivated ?


                                <div className="card-body" style={{ backgroundColor: 'white' }}>
                                    <Table responsive striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <th><strong></strong></th>
                                                <th><strong></strong></th>
                                            </tr>
                                            <tr>
                                                <td><strong>Carrier</strong></td>
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.carrier} name="carrier" /></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Policy Number</strong></td>
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.policyNumber} name="policyNumber" /></td>
                                                {/* <td>{this.props.autoData.policyNumber}</td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Premium</strong></td>
                                                <td><input type="number" onChange={this.handleInputChange} value={this.state.premium} name="premium" /></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Effective Date</strong></td>
                                                {/* <td><input type="date" onChange={this.handleInputChange} value={this.state.effectiveDate} name="effectiveDate" /></td> */}
                                                <td>
                                                    <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                                        {this.props.propertyData.effectiveDate}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <Table responsive striped bordered hover>

                                        <tbody>
                                            <tr>
                                                <th><strong>Coverage</strong></th>
                                                <th><strong>Amount</strong></th>
                                            </tr>
                                            <tr>
                                                <td><strong>Dwelling Coverage</strong></td>
                                                {/* <td>{this.props.autoData.BI}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.dwelling} name="dwelling" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Private Structures</strong></td>
                                                {/* <td>{this.props.autoData.PD}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.structures} name="structures" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Personal Property</strong></td>
                                                {/* <td>{this.props.autoData.UM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.persprop} name="persprop" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Loss of Use</strong></td>
                                                {/* <td>{this.props.autoData.UIM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.lossofuse} name="lossofuse" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Liability</strong></td>
                                                {/* <td>{this.props.autoData.UIM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.liability} name="liability" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Medical Payments</strong>.</td>
                                                {/* <td>{this.props.autoData.UIM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.medical} name="medical" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Deductible</strong></td>
                                                {/* <td>{this.props.autoData.UIM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.deductible} name="deductible" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Declarations</strong></td>
                                                <td>
                                                    <input style={{width: '80%'}} type="file" onChange={this.fileSelectedHandler} />
                                                    <button onClick={this.fileUploadHandlerB}>Upload
                                                <span>
                                                            {this.state.uploaded === false ? <img alt="loading" src="/loading.gif" /> : null}
                                                            {this.state.uploaded === true ? <img alt="checkmark" src="/checkmark.png" style={{ height: '20px', width: '20px' }} /> : null}
                                                        </span>
                                                    </button>
                                                </td></tr>
                                        </tbody>
                                    </Table>
                                    <button style={{ textAlign: 'center' }} className="btn btn-success" onClick={this.saveEdits}>Save Edits</button>



                                </div>





                                :
                                <div className="card-body" style={{ backgroundColor: 'white' }}>
                                    {this.state.showProperty ?

                                        <>

                                            <Table striped bordered hover responsive>
                                                <tbody>
                                                    <tr>
                                                        <th><strong>Category</strong></th>
                                                        <th><strong></strong></th>
                                                    </tr>   
                                                    <tr>
                                                        <td><strong>Carrier</strong></td>
                                                        <td>{this.props.propertyData.carrier}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Policy Number</strong></td>
                                                        <td>{this.props.propertyData.policyNumber} <i class="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.props.propertyData.policyNumber)}}></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Premium</strong></td>
                                                        <td>${this.props.propertyData.premium}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Effective Date</strong></td>
                                                        <td>
                                                            <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                                                {this.props.propertyData.effectiveDate}
                                                            </Moment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            <Table striped bordered hover responsive>

                                                <tbody>
                                                    <tr>
                                                        <th><strong>Coverage</strong></th>
                                                        <th><strong>Amount</strong></th>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Dwelling</strong></td>
                                                        <td>${this.props.propertyData.dwelling} <i class="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.props.propertyData.dwelling)}}></i></td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Private Structures</strong></td>
                                                        <td>${this.props.propertyData.structures}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Personal Property</strong></td>
                                                        <td>${this.props.propertyData.persprop}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Personal Liability</strong></td>
                                                        <td>${this.props.propertyData.liability}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Deductible</strong></td>
                                                        <td>${this.props.propertyData.deductible}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Declarations Page</strong></td>
                                                        <td><a href={this.props.propertyData.declaration}>Download</a></td>
                                                    </tr>
                                                </tbody>

                                            </Table>
                                        </>
                                        : null}
                                </div>
                            }
                        </div>
                        : null}
                </div>
            </div>

        )

    }

}

export default PropertyPolicySummary;