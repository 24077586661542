import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
import cogoToast from "cogo-toast";

// const customStyles = {
//     content: {
//         top: '50%',
//         left: '50%',
//         right: 'auto',
//         bottom: 'auto',
//         marginRight: '-50%',
//         transform: 'translate(-50%, -50%)'
//     }
// }


class RequestItem extends Component {

    state = {
        editActivated: false,
        editModalIsOpen: false,
        showAddtlEdits: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        setTimeout(() => {
            this.loadState()
        }, 1000)

    }

    handleInputChange = event => {
        // const { name, value } = event.target;
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };

    loadState = () => {
        this.setState({
            active: this.props.request.active,
            completed: this.props.request.completed
        })
    }







    saveRequestData = (e) => {
        e.preventDefault();

        var requestData = {
            id: this.props.request._id,
            active: this.state.active,
            completed: this.state.completed
        }

        console.log(requestData)

        API.updateServiceRequest(this.props.request._id, {
            active: false,
            completed: true
        }).then(res => {
            alert("You've updated this request!")
            this.props.complete()
        }).catch(err => alert("The update did not go through!"))

    }

    downloadDocument = (e) => {
        e.preventDefault();
        console.log(this.props.request.fileData);
        window.location.href = 'data:application/octet-stream;base64,' + this.props.request.fleData;
    }

    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
    }

    render() {
        return (
            <>
                {this.props.request.active ?
                <>
                    <tr key={this.props.key}>
                        <td>
                            {this.props.request.name}
                        </td>
                        <td>

                            {this.props.request.email}
                        </td>
                        <td>
                            {this.props.request.type ?
<>
                                    {this.props.request.type === "policy-review" ? 'Policy Review' : null}
                                    {this.props.request.type === "req-mortgage-doc" ? 'Mortgagee Request' : null}
                                    {this.props.request.type === "renewal-quote" ? 'Renewal Q' : null}
                                    {this.props.request.type === "billing" ? 'Billing' : null}
                                    {this.props.request.type === "req-lienholder-doc" ? 'Lienholder' : null}
                                    {this.props.request.type === "general" ? 'General' : null}
                                    {this.props.request.type === "req-other" ? 'Other' : null}
                                </>
                            : null}
                        </td>
                        <td style={{minWidth: '300px'}}>
                            {this.props.request.message}
                        </td>
                        <td style={{textAlign: 'center'}}>{this.props.request.fileData ?
                                <button className="btn btn-info" onClick={(e) => this.downloadBase64File(e, this.props.request.fileData)}>Document</button>
                            : null }
                        </td>
                        <td>
                            {this.props.request ?
                                <button className="btn btn-primary btn-sm" onClick={this.saveRequestData}>Complete</button>
                                : null}
                        </td>
                    </tr>
                    <br />
                </>
                    : null}
               
            </>
        )
    }
}

export default RequestItem;