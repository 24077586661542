import React, { Component } from "react";
import "./style.css";
import './style.css'
import API from "../../utils/API";
import Modal from "react-modal";
import cogoToast from "cogo-toast";
import CreateAgentRow from "../CreateAgentRow";
import AgentUpdateForm from "../AgentUpdateForm";
import DocVault from "../DocVault";



const customStylesModal = {
    content: {
        padding: '5px 5px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        border: 'thick solid black',
        overflowX: 'auto'
    }
}

const docVaultModal = {
    content: {
        margin: '10%',
        padding: '5px 5px',
        backgroundImage: 'rgba(255,255,255,0.5)',
        // height: '100%',
        border: 'thick solid black',
        inset: '40px',
        overflowX: 'auto'
    } 
}



class AgentDatabase extends Component {

    state = {
        requests: [],
        agents: [],
        refreshAgents: [],
        searchedAgents: [],
        sortedAgents: [],
        openEditModal: false,
        editableAgent: '',
        searchTerm: '',
        searchedPolicies: [],
        sortedPolicies: [],
        policies: [],
        refreshPolicies: [],
        showCreateAgentRow: false,
        docIDArray: [],
        policyType: "",
        agentID: ""
    }

    componentDidMount = () => {
        console.log("Service Request Tracker Card Mounted")
        // setTimeout(() => { this.calculateTotals() }, 3000)
        // API.getServicerequests().then(res => {
        //     console.log("Retrieved Service Requests");
        //     console.log(res.data);
        //     this.setState({
        //         requests: res.data
        //     })
        // })
        // let policies = []

      API.getAgents()
            .then(res => { 

                this.setState({ 
                    agents: res.data,
                    refreshAgents: res.data
                })
                console.log(this.state);
             }).catch(err => console.log(err));

        
    }

    openEditModal = (data) => {
        console.log("Opening up Edit Modal for Data ID: " + data._id)
        console.log(data)
        if(this.state.openEditModal === false){
            this.setState({
                openEditModal: true,
                editableAgent: data
            })
        } else {
            this.setState({
                openEditModal: false
            })
        }
    }


    closeEditModal = (e) => {
        e.preventDefault();
        if (this.state.openEditModal === true){
            this.setState({
                openEditModal: false,
                editableAgent: ''
            })
        } else {
            this.setState({
                openEditModal: true
            })
        }
        
    }
  

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };



    sortTable = (e, type) => {

        if (type === 'effective'){
            console.log("Sorting Effective Dates");
        }

        e.preventDefault();
        console.log(e);
        console.log("Sorting table of type: " + type);
        // let sortedPolicies = this.state.policies.sort((a, b) => {

        //     if (a.clientLastName )

        // })
        // console.log(sortedPolicies)
    }

    searchTable = (e) => {
        e.preventDefault();
        console.log("Search Table for: " + this.state.searchTerm)
        let rowsWithTerm = [];
        for (var i = 0; i < this.state.refreshAgents.length; i++){

            let object = this.state.refreshAgents[i]

            for (const agent in object){
                console.log(`${agent}: ${object[agent]}`)
                console.log(typeof(agent))
                console.log(typeof(object[agent]))
                if (typeof(object[agent]) === 'string'){

                    if (object[agent].toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1){

                        if (rowsWithTerm.length === 0){
                            rowsWithTerm.push(object);
                        } else {
                            let rowFoundAlready = false;
                            for (var j = 0; j < rowsWithTerm.length; j++){
                                if (rowsWithTerm[j]._id === object._id){
                                    rowFoundAlready = true;
                                }
                                if (j === rowsWithTerm.length - 1){
                                    if (rowFoundAlready === false){
                                        rowsWithTerm.push(object);
                                    }
                                }
                            }
                            this.setState({
                                agents: rowsWithTerm
                            })
                        }
                       
                    }

                }

            }

            if (i === this.state.refreshAgents.length - 1){
                console.log("Completed search");
                console.log("Rows with Term: " + this.state.searchTerm);
                console.log(rowsWithTerm)
                console.table(rowsWithTerm);
            }

        }
        // this.setState({

        // })
    }

    resetTable = (e) => {
        e.preventDefault();
        console.log("Resetting table");
        console.log("Transforming agents table: ")
        console.table(this.state.agents)
        console.log("Into original table: ");
        console.table(this.state.refreshAgents)
        this.setState({
            agents: this.state.refreshAgents
        })
    }

    addNewRowToPolicies = (rowEntry) => {
        console.log("Adding New Row to Agents");
        console.log(rowEntry);
        this.state.agents.push(rowEntry);
        this.state.refreshAgents.push(rowEntry);

        this.setState({
            showCreateAgentRow: false
        })
    }

    readNewRowData = (rowEntry) => {
      
        console.log("Data ready to  be inserted");
        console.log(rowEntry);
        console.log("Adding New Row to Agents");
        console.log(rowEntry);
        this.state.agents.push(rowEntry);
        this.state.refreshAgents.push(rowEntry);
        // this.resetTable2()

        this.setState({
            showCreateAgentRow: false
        })
    }


    showCreateAgentRow = (e) => {
        console.log("Showing Create Agent Row");
        e.preventDefault();

        if (this.state.showCreateAgentRow === true){
            this.setState({
                showCreateAgentRow: false
            })
        } else {
            this.setState({
                showCreateAgentRow: true
            })
        }
    }

    uploadDocument = (event, id) => {
        event.preventDefault();
        console.log("Uploading document for ID: " + id);
    }

    openServiceRequestModal = (event, id) => {
        event.preventDefault();
        if (this.state.openServiceRequestModal !== true){
            this.setState({
                openServiceRequestModal: true,
                serviceID: id
            })
        } else {
            this.setState({
                openServiceRequestModal: false,
                serviceID: id
            })
        }
    }

    openAgentUpdateModal = (event, id) => {
        event.preventDefault();
        if (this.state.openAgentUpdateModal !== true){
            this.setState({
                openAgentUpdateModal: true,
                agentID: id
            })
        } else {
            this.setState({
                openAgentUpdateModal: false,
                agentID: id
            })
        }
    }

    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
        
    }

    gatherVaultDocs(e, docIDArray, policyID, policyType){
        e.preventDefault();
        console.log("Gathering Documents from Vault for: ");
        console.log(docIDArray)
        this.setState({
            docIDArray: docIDArray,
            openDocVaultModal: true,
            agentID: policyID,
            policyType: policyType
        })

    }

    openDocVaultModal = (e) => {
        e.preventDefault();
        if (this.state.openDocVaultModal === false){
            this.setState({
                openDocVaultModal: true
            }) 
        } else {
            this.setState({
                openDocVaultModal: false,
                docIDArray: [],
                policyID: ""
            })    
        }
    }



    render() {
        return (
            <div>
  

                 <Modal
                    isOpen={this.state.openEditModal}
                    onRequestClose={(e) => this.closeEditModal(e)}
                    style={customStylesModal}
                 >  
                    <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={(e) => this.closeEditModal(e)}>X</button>                            
                    {/* <p>Edit Row</p> */}
                    {/* <p>{this.state.editablePolicy._id}</p> */}

                    <AgentUpdateForm
                        agents={this.props.agents}
                        agentData={this.state.editableAgent}
                    />


                    
                 </Modal>

                 <Modal
                    isOpen={this.state.openDocVaultModal}
                    onRequestClose={(e) => this.openDocVaultModal(e)}
                    style={docVaultModal}
                 >
                    <DocVault
                        docIDArray={this.state.docIDArray}
                        rootPolicyID={this.state.agentID}
                        rootPolicyType={this.state.policyType}
                        closeModal={(e) => this.openDocVaultModal(e)}
                    />
                 </Modal>
                 <div className="card" style={{overflowX: 'auto'}}>
                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.props.closeComponent()}>X</button>
                                                
                                                {/* <div className="card-header">Service Request Controller</div>
                                                <div className="card-body"> */}
                    <h3 style={{textAlign: 'center'}}>Agent Database </h3>
                    <div className="row">
                        <div className="col-sm-2"><button style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.resetTable(e)}>Refresh</button></div>
                        <div className="col-sm-2"><button style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateAgentRow(e)}>New Agent</button></div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-2">
                            <form>
                                <input style={{width: '70%'}} value={this.state.searchTerm} onChange={this.handleInputChange} type="text" name="searchTerm" />
                                <button style={{width: '30%', backgroundColor: 'green', color: 'white'}} type="submit" onClick={(e) => this.searchTable(e)}> Go </button>
                            </form>
                        </div>
                    </div>
                    
                    

                    <table
                        id="database"
                    >
                                                    <thead>

                                                     

                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>Edit</th>
                                                            <th>Document</th>
                                                            <th>Authority</th>
                                                            <th>Email</th>
                                                            <th>Phone</th>
                                                            <th>State</th>
                                                            <th># Quotes</th>               
                                                            <th># Autos</th>
                                                            <th># Properties</th>
                                                            <th># Policies</th> 

                                                            {/* <th>Column</th>                                                             */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                       
                                                    
                                                                {this.state.showCreateAgentRow ?
                                                                    
                                                                    <CreateAgentRow
                                                                        agents={this.state.agents}
                                                                        sendRowToDatabase={(data) => this.addNewRowToPolicies(data)}
                                                                        generateNewRow={(data) => this.readNewRowData(data)}
                                                                    />
                                                            
                                                                : null }

                                                    
                                                                {this.state.agents.map(agent => (
                                                                    <tr key={agent._id}>
                                                                        <td><strong>{agent.firstName}</strong></td>
                                                                        <td><strong>{agent.lastName}</strong></td>
                                                                        {/* <td>{policy.clientFirstName}</td> */}
                                                                        <td><button class="btn btn-secondary" onClick={(e) => this.openEditModal(agent)}>Edit</button></td>
                                                                        <td>
                                                                            {/* <button className="btn-primary btn" onClick={(e) => this.openServiceRequestModal(e, policy._id)}>Request</button> */}
                                                                            {agent.documents.length > 0 ? 
                                                                      
                                                                                <i onClick={(e) => this.gatherVaultDocs(e, agent.documents, agent._id, 'agent')} class="fa fa-folder-open fa-2x" aria-hidden="true"></i>
                                                             
                                                                            : null}   
                                                                        </td>

                                                                        <td>{agent.authority}</td>
                                                                        <td>{agent.email}</td>
                                                                        <td>{agent.telephone}</td>
                                                                        <td>{agent.residentState}</td>
                                                                        <td>{agent.quotes.length}</td>
                                                                        <td>{agent.autos.length}</td>
                                                                        <td>{agent.properties.length}</td>
                                                                        <td>{agent.autos.length + agent.properties.length + agent.umbrellas.length}</td>
                                                                     
                                                                        {/* <td>   */}
                                                                            {/* {policy.declaration ? 
                                                                            
                                                                                <button className="btn btn-info" onClick={(e) => this.downloadBase64File(e, policy.declaration)}>Document</button>

                                                                            : null}    */}
                                                                        {/* </td> */}
                                                                    </tr>
                                                                ))}


                                                                

                                                    </tbody>
                        </table>
                                                    {/* <ServiceRequestController
                                                        requests={this.state.serviceRequests}
                                                    />
                                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showServiceRequestController()}>Close</button> */}
                                                {/* </div> */}
                    </div>
            </div >
        )

    }

}

export default AgentDatabase;