import React, { Component } from "react";
import "./style.css";
// import Parralax from "../../components/Parralax"
// import SmallParralax from "../../components/SmallParralax"
import API from "../../utils/API";
import CreatePolicy from "../../components/CreatePolicy"
import CreateAgent from "../../components/CreateAgent"
import CreateClient from "../../components/CreateClient"
import QuoteCard from "../../components/AdminQuoteCard"
import CreateReferral from "../../components/CreateReferral"
import EditAgent from "../../components/EditAgent"
// import CreateAdmin from "../../components/CreateAdmin"
import CreateServiceRequest from "../../components/CreateServiceRequest"
import EditClient from "../../components/EditClient"
import EditPolicy from "../../components/EditPolicy"
import ServiceRequestController from '../../components/ServiceRequestController'
import FawnButton from "../../components/FawnButton";
import Modal from 'react-modal'
import CreateStandalonePolicy from "../../components/CreateStandalonePolicy";
// import Database from "../../components/Database";
import PolicyDatabase from "../../components/PolicyDatabase";
import QuoteDatabase from "../../components/QuoteDatabase";
import AgentDatabase from "../../components/AgentDatabase";
import ClientDatabase from "../../components/ClientDatabase";
import CreateCarrier from "../../components/CreateCarrier";
import CreateServiceRequestAdmin from "../../components/CreateServiceRequestAdmin";


const customStylesModal = {
    content: {
        padding: '5px 5px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        border: 'thick solid black',
        overflowX: 'auto'
    }
}

const customStylesModalQuoteCard = {
    content: {
        padding: '5px 5px', 
        background: 'transparent',
        border: '0px',
        height: '100%'
    }
}


class AdminPage extends Component {

    state = {
        showLanding: true,
        showAbout: false,
        showContact: false,
        showQuote: false,
        showServiceRequestController: false,
        showCreatePolicyStandalone: false,
        adminData: {
            admin: true
        }
    }

    componentDidMount = () => {
        console.log("Landing Page")
        // this.testGets()
        let cookieArr = document.cookie.split(";").map(cookie => cookie.split("="));
        // console.log(cookieArr);
        // Turns a multi dimensional array into a json object
        let cookieObj = cookieArr
                            .reduce((accumulator, [key, value]) => ({
                                ...accumulator, [key.trim()]: decodeURIComponent(value)
                            }),
                            {});
        // console.log(cookieObj);
        // console.log(cookieObj.fawnuid);
        this.checkAdminData(cookieObj.fawnuid);
        API.getAgents()
            .then(res => this.setState({ agents: res.data }))
            .catch(err => console.log(err));

        setTimeout(() => {
            console.log("Admin State:");
            console.log(this.state);
        }, 5000)
    }

    getData = () => {
        console.log( "getting data admin")
    }

    checkAdminData = (userID) => {
        console.log("Getting Admin Data...with: " + userID)
        API.findAdminLogin(userID)
            .then(res => {
                console.log(res);
                if (res.data.length > 0) {
                    this.setState({
                        adminData: res.data[0]
                    })
                    console.log(this.state.adminData);
                } else {
                    document.location.href = "/"
                    // console.log("Debug mode: No redirect")
                }
                setTimeout(() => { this.testAdmin() }, 1000)
                }
            )
            .catch(err =>{
                alert("Error finding user data")
                // document.location.href = "/"
            }

                 )

    }

    testAdmin = () => {

        if (this.state.adminData.admin === true) {
            // alert("Welcome")
        } else if (this.state.adminData.admin === false) {
            console.log("Admin == not true")
            alert("Error, not signed in")
            window.location.href = '/'
        }
    }

    testGets = () => {

        API.getAdmins()
            .then(res => console.log(res.data))
            .catch(err => console.log(err));

        API.getAgents()
            .then(res => this.setState({ agents: res.data }))
            .catch(err => console.log(err));

        API.getAutos()
            .then(res => this.setState({ autos: res.data }))
            .catch(err => console.log(err));

        API.getClients()
            .then(res => this.setState({ clients: res.data }))
            .catch(err => console.log(err));

        API.getContacts()
            .then(res => this.setState({ contacts: res.data }))
            .catch(err => console.log(err));

        API.getDrivers()
            .then(res => this.setState({ drivers: res.data }))
            .catch(err => console.log(err));

        // // Not working
        API.getProperties()
            .then(res => this.setState({ properties: res.data }))
            .catch(err => console.log(err));

        API.getQuotes()
            .then(res => this.setState({ quotes: res.data }))
            .catch(err => console.log(err));

        API.getReferrals()
            .then(res => this.setState({ referrals: res.data }))
            .catch(err => console.log(err));

        API.getServicers()
            .then(res => this.setState({ servicers: res.data }))
            .catch(err => console.log(err));

        API.getServicerequests()
            .then(res => this.setState({ serviceRequests: res.data }))
            .catch(err => console.log(err));
        // Not working

        API.getUmbrellas()
            .then(res => this.setState({ umbrellas: res.data }))
            .catch(err => console.log(err));

        API.getVehicles()
            .then(res => this.setState({ vehicles: res.data }))
            .catch(err => console.log(err));
    }


    showCreatePolicy = () => {
        if (this.state.showCreatePolicy === true) {
            this.setState({
                showCreatePolicy: false
            })
        } else {
            this.setState({
                showCreatePolicy: true,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateClient = () => {
        if (this.state.showCreateClient === true) {
            this.setState({
                showCreateClient: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: true,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateQuote = () => {
        if (this.state.showCreateQuote === true) {
            this.setState({
                showCreateQuote: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: true,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showEditPolicy = () => {
        if (this.state.showEditPolicy === true) {
            this.setState({
                showEditPolicy: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: true,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showEditAgent = () => {
        if (this.state.showEditAgent === true) {
            this.setState({
                showEditAgent: false
            })
        } else {
            this.setState({
                showEditAgent: true,
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false
            })
        }
    }

    showEditClient = () => {
        if (this.state.showEditClient === true) {
            this.setState({
                showEditClient: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: true,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateAgent = () => {
        if (this.state.showCreateAgent === true) {
            this.setState({
                showCreateAgent: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: true,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateReferral = () => {
        if (this.state.showCreateReferral === true) {
            this.setState({
                showCreateReferral: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: true,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateServicer = () => {
        if (this.state.showCreateServicer === true) {
            this.setState({
                showCreateServicer: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: true,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateServiceRequest = () => {
        if (this.state.showCreateServiceRequest === true) {
            this.setState({
                showCreateServiceRequest: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: true,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showServiceRequestController = () => {
        if (this.state.showServiceRequestController === true) {
            this.setState({
                showServiceRequestController: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: true,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreatePolicyStandalone = () => {
        if (this.state.showCreatePolicyStandalone === true){
            this.setState({
                showCreatePolicyStandalone: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: true,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showPolicyDatabase = () => {
        if (this.state.showPolicyDatabase === true){
            this.setState({
                showPolicyDatabase: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: true,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showQuoteDatabase = () => {
        if (this.state.showQuoteDatabase === true){
            this.setState({
                showQuoteDatabase: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: true,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showAgentDatabase = () => {
        if (this.state.showAgentDatabase === true){
            this.setState({
                showAgentDatabase: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: true,
                showClientDatabase: false,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showClientDatabase = () => { 
        if (this.state.showClientDatabase === true){
            this.setState({
                showClientDatabase: false
            })
        } else {
            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: true,
                showCreateCarrier: false,
                showEditAgent: false
            })
        }
    }

    showCreateCarrier = () => {
        if (this.state.showCreateCarrier === true){
            this.setState({
                showCreateCarrier: false
            })
        } else {

            this.setState({
                showCreatePolicy: false,
                showCreateClient: false,
                showCreateQuote: false,
                showEditPolicy: false,
                showEditClient: false,
                showCreateAgent: false,
                showCreateReferral: false,
                showCreateServicer: false,
                showCreateServiceRequest: false,
                showServiceRequestController: false,
                showCreatePolicyStandalone: false,
                showPolicyDatabase: false,
                showQuoteDatabase: false,
                showAgentDatabase: false,
                showClientDatabase: false,
                showCreateCarrier: true,
                showEditAgent: false
            })
     
        }
    }


    notActiveButton = () => {
        // e.preventDefault()
        console.log("Button not assigned function yet")
    }

    placeholder = () => {
        console.log("Place holder")
    }

    render() {
        return (
            <div className="container-fluid auroral-northern">

                <div>
                    {/* <SmallParralax /> */}
                    <div style={{backgroundColor: 'transparent'}}>
                        <div><h3 style={{ textAlign: 'center', color: 'black' }}><strong>Welcome {this.state.adminData.firstName}</strong></h3></div>
                        <div className="card-body" style={{ textAlign: 'center' }}>
                          
                        <div className="row" style={{marginTop: '5%', marginBottom: '5%'}}>
                                    {/* <div className="offset-lg-1 col-lg-3"> */}
                                        {/* <FawnButton 
                                            pf1={() => this.showCreateServicer()}
                                            buttonText='Create Admin'
                                        /> */}
                                        {/* {this.state.showCreateServicer ?
                                                <Modal 
                                                    isOpen={this.state.showCreateServicer}
                                                    onRequestClose={() => this.showCreateServicer()}
                                                    style={customStylesModal}
                                                >
                                                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={() => this.showCreateServicer()}>X</button>
                                               
                                                    <CreateAdmin />
                                                    <br />
                                          
                                                </Modal>
                                        : null} */}
                                    {/* </div> */}

                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                                pf1={this.showClientDatabase}
                                                buttonText='Client DB'
                                                style={{height: '300px'}}
                                            />
                                            {this.state.showClientDatabase ?

                                                <Modal 
                                                    isOpen={this.state.showClientDatabase}
                                                    onRequestClose={() => this.showClientDatabase()}
                                                    style={customStylesModal}
                                                    >

                                                    <ClientDatabase  
                                                        closeComponent={() => this.showClientDatabase()}
                                                        agents={this.state.agents}
                                                    />


                                                    
                                                
                                                </Modal>
                                    
                                            : null}
                                    </div>


                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                                pf1={this.showQuoteDatabase}
                                                buttonText='Quote DB'
                                                style={{height: '300px'}}
                                            />
                                            {this.state.showQuoteDatabase ?

                                                <Modal 
                                                    isOpen={this.state.showQuoteDatabase}
                                                    onRequestClose={() => this.showQuoteDatabase()}
                                                    style={customStylesModal}
                                                    >

                                                    <QuoteDatabase  
                                                        closeComponent={() => this.showQuoteDatabase()}
                                                        agents={this.state.agents}
                                                    />


                                                    
                                                
                                                </Modal>
                                    
                                            : null}
                                    </div>

                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                                pf1={this.showAgentDatabase}
                                                buttonText='Agent DB'
                                                style={{height: '300px'}}
                                            />
                                            {this.state.showAgentDatabase ?

                                                <Modal 
                                                    isOpen={this.state.showAgentDatabase}
                                                    onRequestClose={() => this.showAgentDatabase()}
                                                    style={customStylesModal}
                                                    >

                                                    <AgentDatabase  
                                                        closeComponent={() => this.showAgentDatabase()}
                                                        agents={this.state.agents}
                                                    />


                                                    
                                                
                                                </Modal>
                                    
                                            : null}
                                    </div>
                                

                             
                            </div>                                                



                            {/* /////////////////////////////////// */}

                            <div className="row" style={{marginTop: '5%', marginBottom: '5%'}}>

                                <div className="offset-lg-1 col-lg-3">
                                       <FawnButton 
                                            pf1={this.showPolicyDatabase}
                                            buttonText='Policy DB'
                                            style={{height: '300px'}}
                                        />
                                        {this.state.showPolicyDatabase ?

                                        <Modal 
                                            isOpen={this.state.showPolicyDatabase}
                                            onRequestClose={() => this.showPolicyDatabase()}
                                            style={customStylesModal}
                                            >

                                            <PolicyDatabase  
                                                closeComponent={() => this.showPolicyDatabase()}
                                                agents={this.state.agents}
                                            />
                                            
                                           
                                        </Modal>
                                 
                                        : null}
                                </div>
                                <br />
                                <br />

                                 <div className="offset-lg-1 col-lg-3">
                                       <FawnButton 
                                            pf1={this.showServiceRequestController}
                                            buttonText='Servicing'
                                        />
                                        {this.state.showServiceRequestController ?

                                        <Modal 
                                            isOpen={this.state.showServiceRequestController}
                                            onRequestClose={() => this.showServiceRequestController()}
                                            style={customStylesModal}
                                            >
                                            <div className="card">
                                                {/* <div className="card-header">Service Request Controller</div>
                                                <div className="card-body"> */}
                                                <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={() => this.showServiceRequestController()}>X</button>
                                                    <ServiceRequestController
                                                        closeComponent={() => this.showServiceRequestController()}
                                                        requests={this.state.serviceRequests}
                                                    />
                                                    {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showServiceRequestController()}>Close</button> */}
                                                {/* </div> */}
                                            </div>
                                        </Modal>
                                 
                                        : null}
                                </div>

                                <br />
                                <br />

                                <div className="offset-lg-1 col-lg-3">
                                       <FawnButton 
                                            pf1={this.showCreateServiceRequest}
                                            buttonText='New Service'
                                        />
                                        {this.state.showCreateServiceRequest ?

                                        <Modal 
                                            isOpen={this.state.showCreateServiceRequest}
                                            onRequestClose={() => this.showCreateServiceRequest()}
                                            style={customStylesModal}
                                        >
                                         {/* <div className="card" style={{margin: '20px', backgroundColor: 'transparent'}}> */}
                                                    {/* <div className="card-body"> */}
                                                        {/* <div className="row"> */}
                                                            {/* <div className="col"> */}
                                                                <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={() => this.showCreateServiceRequest()}>X</button>
                                               
                                                                <CreateServiceRequestAdmin 
                                                                    clients={this.state.clients}
                                                                    uid="0"
                                                                />
                                                                <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateServiceRequest()}>Close</button>
                                               
                                                            {/* </div> */}
                                                    {/* </div> */}
                                                 {/* </div> */}
                                            {/* </div> */}
                                        </Modal>                                        
                                           
                                        : null}
                                        
                                </div>
                                <br />
                                <br />
                            </div>
                            {/* <br />
                                    <br /> */}
                            <div className="row" style={{marginTop: '5%', marginBottom: '5%'}}>
                                   
                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                            pf1={this.showCreateQuote}
                                            buttonText='Quote'
                                        />
                                        {this.state.showCreateQuote ?
                                        <Modal 
                                        isOpen={this.state.showCreateQuote}
                                        onRequestClose={() => this.showCreateQuote()}
                                        style={customStylesModalQuoteCard}
                                        >
                                            <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showCreateQuote()}>X</button>
                                               
                                            <QuoteCard
                                                theme="light"
                                                text="black"
                                                agents={this.props.agents}
                                            />
                                        </Modal>

                                            : null}
                                    </div>

                                    <div className="offset-lg-1 col-lg-3">
                                       <FawnButton 
                                            pf1={() => this.showCreateClient()}
                                            buttonText='New Client'
                                        />
                                        {this.state.showCreateClient ?
                                            <Modal 
                                                isOpen={this.state.showCreateClient}
                                                onRequestClose={() => this.showCreateClient()}
                                                style={customStylesModal}
                                            >
                                            {/* <div className="card" style={{margin: '20px', backgroundColor: 'transparent'}}> */}
                                            <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showCreateClient()}>X</button>
                                               
                                                <CreateClient
                                                    agents={this.state.agents}
                                                />
                                                <br />
                                                {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateClient()}>Close</button> */}
                                            {/* </div> */}
                                            </Modal>
                                        : null}
                                    </div>

                                   
                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                            pf1={() => this.showEditClient()}
                                            buttonText='Edit Client'
                                        />
                                        {this.state.showEditClient ?
                                                <Modal 
                                                    isOpen={this.state.showEditClient}
                                                    onRequestClose={() => this.showEditClient()}
                                                    style={customStylesModal}
                                                >
                                                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showEditClient()}>X</button>
                                               
                                                    <EditClient
                                                        clients={this.state.clients}
                                                        agents={this.state.agents}
                                                    />
                                                {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showEditClient()}>Close</button> */}
                                                </Modal>
                                        : null}
                                         {this.state.showCreatePolicy ?
                                            <div className="card" style={{margin: '10px'}}>
                                                <div className="card-header">Create Policy</div>
                                                <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showCreatePolicy()}>X</button>
                                               
                                                <CreatePolicy      
                                                    agents={this.props.agents}
                                                />
                                            </div>
                                        : null}
                                        {this.state.showEditPolicy ?
                                            <div className="card" style={{margin: '20px'}}>
                                                <div className="card-header">Edit Policy</div>
                                                <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showEditPolicy()}>X</button>
                                               
                                                <EditPolicy />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            <div className="row" style={{marginTop: '5%', marginBottom: '5%'}}>
                                    <br />
                                    <br />

                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton
                                            pf1={() => this.showCreatePolicyStandalone()}
                                            buttonText='Create Policy'
                                        />
                                    </div>
                                    {this.state.showCreatePolicyStandalone ? 
                                    
                                            <Modal
                                                isOpen={this.state.showCreatePolicyStandalone}
                                                onRequestClose={() => this.showCreatePolicyStandalone()}
                                                style={customStylesModal}
                                            >
                                                <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showCreatePolicyStandalone()}>X</button>
                                               
                                                <CreateStandalonePolicy 
                                                    agents={this.props.agents}
                                                />


                                            </Modal>

                                    : null}


                                    <div className="offset-lg-1 col-lg-3">
                                       <FawnButton 
                                            pf1={() => this.showCreateAgent()}
                                            buttonText='Create Agent'
                                        />
                                        {this.state.showCreateAgent ?
                                            // <div className="card" style={{margin: '20px', backgroundColor: 'transparent'}}>
                                            <Modal 
                                                isOpen={this.state.showCreateAgent}
                                                onRequestClose={() => this.showCreateAgent()}
                                                style={customStylesModal}
                                            >
                                                <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showCreateAgent()}>X</button>
                                               
                                                <CreateAgent />
                                                <br />
                                                {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateAgent()}>Close</button> */}
                                            </Modal>
                                            // </div>
                                        : null}
                                    </div>

                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                            pf1={() => this.showCreateReferral()}
                                            buttonText='Create Referral'
                                        />
                                        {this.state.showCreateReferral ?
                                            // <div className="card" style={{margin: '20px', backgroundColor: 'transparent'}}>
                                            <Modal 
                                                isOpen={this.state.showCreateReferral}
                                                onRequestClose={() => this.showCreateReferral()}
                                                style={customStylesModal}
                                            >
                                                <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showCreateReferral()}>X</button>
                                               
                                                <CreateReferral />
                                                {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateReferral()}>Close</button> */}
                                            
                                            </Modal>
                                            // </div>
                                        : null}
                                    </div>
                                
                                    <br />
                                    <br />
                            </div>

                            <div className="row" style={{marginTop: '5%', marginBottom: '5%'}}>
                                    <br />
                                    <br />

                                    <div className="offset-lg-1 col-lg-3">
                                        <FawnButton
                                            pf1={() => this.showCreateCarrier()}
                                            buttonText='Create Carrier'
                                        />

                                        {this.state.showCreateCarrier ?
                                            // <div className="card" style={{margin: '20px', backgroundColor: 'transparent'}}>
                                            <Modal 
                                                isOpen={this.state.showCreateCarrier}
                                                onRequestClose={() => this.showCreateCarrier()}
                                                style={customStylesModal}
                                            >
                                                {/* <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={() => this.showCreateCArrier()}>X</button> */}
                                               
                                               <CreateCarrier />
                                                {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateReferral()}>Close</button> */}
                                            
                                            </Modal>
                                            // </div>
                                        : null}
                                    </div>
                                   


                                    <div className="offset-lg-1 col-lg-3">
                                       <FawnButton 
                                            pf1={() => this.placeholder()}
                                            buttonText='Link Policy to Client'
                                        />

                                    </div>

                                    {/* <div className="offset-lg-1 col-lg-3">
                                        <FawnButton 
                                            pf1={() => this.placeholder()}
                                            buttonText='Link Client'
                                        />
       
                                    </div> */}

                                    <div className="offset-lg-1 col-lg-3">
                                            <FawnButton 
                                                pf1={() => this.showEditAgent()}
                                                buttonText='Edit Agent'
                                            />
                                            {this.state.showEditAgent?
                                                <Modal 
                                                    isOpen={this.state.showEditAgent}
                                                    onRequestClose={() => this.showEditAgent()}
                                                    style={customStylesModal}
                                                >
                                                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.showEditAgent()}>X</button>
                                               
                                                    <EditAgent
                                                        agents={this.state.agents}
                                                    />
                                                {/* <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateAgent()}>Close</button> */}
                                                </Modal>
                                        : null}
                                    </div>
                                
                                    <br />
                                    <br />
                            </div>

                            {/* /////////////////// */}
                        </div>
                    </div>

                </div>
            </div>
        )

    }

}

export default AdminPage;