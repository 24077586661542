import React, { Component } from "react";
import "./style.css";
import './style.css'
import API from "../../utils/API";
import Modal from "react-modal";
import cogoToast from "cogo-toast";
import ClientUpdateForm from "../ClientUpdateForm";
import CreateClientRow from "../CreateClientRow";
import DocVault from "../DocVault";



const customStylesModal = {
    content: {
        padding: '5px 5px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        border: 'thick solid black',
        overflowX: 'auto'
    }
}

const docVaultModal = {
    content: {
        margin: '10%',
        padding: '5px 5px',
        backgroundImage: 'rgba(255,255,255,0.5)',
        // height: '100%',
        border: 'thick solid black',
        inset: '40px',
        overflowX: 'auto'
    } 
}


class ClientDatabase extends Component {

    state = {
        requests: [],
        clients: [],
        openEditModal: false,
        editableClient: '',
        searchTerm: '',
        searchedClients: [],
        sortedClients: [],
        policies: [],
        refreshClients: [],
        showCreateAutoRow: false,
        showCreatePropertyRow: false,
        showCreateUmbrellaRow: false,
        showCreateClientRow: false,
        docIDArray: [],
        policyType: "",
        showClientTable: true
    }

    componentDidMount = () => {
        console.log("Service Request Tracker Card Mounted")

        API.getClients()
            .then(res => {
                this.setState({clients: res.data});
                console.log(res);
                console.log(this.state.clients);
            })
    }

    openEditModal = (data) => {
        console.log("Opening up Edit Modal for Data ID: " + data._id)
        console.log(data)
        if(this.state.openEditModal === false){
            this.setState({
                openEditModal: true,
                editableClient: data
            })
        } else {
            this.setState({
                openEditModal: false
            })
        }
    }


    closeEditModal = (e) => {
        e.preventDefault();
        if (this.state.openEditModal === true){
            this.setState({
                openEditModal: false,
                editableClient: ''
            })
        } else {
            this.setState({
                openEditModal: true
            })
        }
        
    }
  

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    completeAndRemove(){
        // API.getServicerequests().then(res => {
        //     console.log("Retrieved Service Requests");
        //     console.log(res.data);
        //     this.setState({
        //         requests: res.data
        //     })
        // })

    }

    sortTable = (e, type) => {

        if (type === 'effective'){
            console.log("Sorting Effective Dates");

        }

        e.preventDefault();
        console.log(e);
        console.log("Sorting table of type: " + type);
        // let sortedPolicies = this.state.policies.sort((a, b) => {

        //     if (a.clientLastName )

        // })
        // console.log(sortedPolicies)
    }

    searchTable = (e) => {
        e.preventDefault();
        console.log("Search Table for: " + this.state.searchTerm)
        let rowsWithTerm = [];
        for (var i = 0; i < this.state.refreshClients.length; i++){

            let object = this.state.refreshClients[i]

            for (const client in object){
                console.log(`${client}: ${object[client]}`)
                console.log(typeof(client))
                console.log(typeof(object[client]))
                if (typeof(object[client]) === 'string'){

                    if (object[client].toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1){

                        if (rowsWithTerm.length === 0){
                            rowsWithTerm.push(object);
                        } else {
                            let rowFoundAlready = false;
                            for (var j = 0; j < rowsWithTerm.length; j++){
                                if (rowsWithTerm[j]._id === object._id){
                                    rowFoundAlready = true;
                                }
                                if (j === rowsWithTerm.length - 1){
                                    if (rowFoundAlready === false){
                                        rowsWithTerm.push(object);
                                    }
                                }
                            }
                            this.setState({
                                clients: rowsWithTerm
                            })
                        }
                       
                    }

                }

            }

            if (i === this.state.refreshClients.length - 1){
                console.log("Completed search");
                console.log("Rows with Term: " + this.state.searchTerm);
                console.log(rowsWithTerm)
                console.table(rowsWithTerm);
            }

        }
        // this.setState({

        // })
    }

    resetTable = (e) => {
        e.preventDefault();
        console.log("Resetting table");
        console.log("Transforming clients table: ")
        console.table(this.state.clients)
        console.log("Into original table: ");
        console.table(this.state.refreshClients)
        this.setState({
            clients: this.state.refreshClients
        })
    }

    resetTable2 = () => {
        console.log("Resetting table");
        console.log("Transforming clients table: ")
        console.table(this.state.clients)
        console.log("Into original table: ");
        console.table(this.state.refreshClients)
        this.setState({
            clients: this.state.refreshClients,
            showClientTable: false
        })
        this.showClientTableQueue()
    }

    showClientTableQueue = () => {

        let showClientTableLocal = this.showClientTable()
        console.log("live clients:")
        console.log(this.state.clients);
        console.log("refresh clients:");
        console.log(this.state.refreshClients)

        this.setTimeout(() => {
            console.log("Calling local function")
            showClientTableLocal()
        }, 500)
    }

    showClientTable = () => {
        console.log("local function successful")
        if (this.state.showClientTable === false){
            this.setState({
                showClientTable: true
            })
            console.log("state after showClientTable:")
            console.log(this.state)
        } else {
            this.setState({
                showClientTable: false
            })
            console.log("state after showClientTable:")
            console.log(this.state)
        }
    }

    addNewRowToPolicies = (rowEntry) => {
        console.log("Adding New Row to Policies");
        console.log(rowEntry);
        this.state.clients.push(rowEntry);
        this.state.refreshClients.push(rowEntry);
        this.resetTable2()

        this.setState({
            showCreateClientRow: false
        })
    }

    showCreateClientRow = (e) => {
        console.log("Showing Create Client Row")
        e.preventDefault();
        if (this.state.showCreateClientRow === true){
            this.setState({
                showCreateClientRow: false
            })
        } else {
            this.setState({
                showCreateClientRow: true
            })
        }
    }


    uploadDocument = (event, id) => {
        event.preventDefault();
        console.log("Uploading document for ID: " + id);
    }

 
    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
        
    }

    gatherVaultDocs(e, docIDArray, policyID, policyType){
        e.preventDefault();
        console.log("Gathering Documents from Vault for: ");
        console.log(docIDArray)
        this.setState({
            docIDArray: docIDArray,
            openDocVaultModal: true,
            clientID: policyID,
            policyType: policyType
        })

    }

    openDocVaultModal = (e) => {
        e.preventDefault();
        if (this.state.openDocVaultModal === false){
            this.setState({
                openDocVaultModal: true
            }) 
        } else {
            this.setState({
                openDocVaultModal: false,
                docIDArray: [],
                policyID: ""
            })    
        }
    }

    readNewRowData = (rowEntry) => {
      
        console.log("Data ready to  be inserted");
        console.log(rowEntry);
        console.log("Adding New Row to Quotes");
        console.log(rowEntry);
        this.state.clients.push(rowEntry);
        this.state.refreshClients.push(rowEntry);
        // this.resetTable2()

        this.setState({
            showCreateClientRow: false
        })
    }

    render() {
        return (
            <div>
     

                 <Modal
                    isOpen={this.state.openEditModal}
                    onRequestClose={(e) => this.closeEditModal(e)}
                    style={customStylesModal}
                 >  
                    <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={(e) => this.closeEditModal(e)}>X</button>                            
                    {/* <p>Edit Row</p> */}
                    {/* <p>{this.state.editablePolicy._id}</p> */}

                    <ClientUpdateForm
                        clientData={this.state.editableClient}
                        agents={this.props.agents}
                    />
                    
                 </Modal>

                 <Modal 
                    isOpen={this.state.openDocVaultModal}
                    onRequestClose={(e) => this.openDocVaultModal(e)}
                    style={docVaultModal}
                >
                    <DocVault
                        docIDArray={this.state.docIDArray}
                        rootPolicyID={this.state.clientID}
                        rootPolicyType={this.state.policyType}
                        closeModal={(e) => this.openDocVaultModal(e)}
                    />
                </Modal>

                 <div className="card" style={{overflowX: 'auto'}}>
                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.props.closeComponent()}>X</button>
                                                
                                                {/* <div className="card-header">Service Request Controller</div>
                                                <div className="card-body"> */}
                    <h3 style={{textAlign: 'center'}}>Client Database </h3>
                    <div className="row">
                        <div className="col-sm-2"><button style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.resetTable(e)}>Refresh</button></div>
                        <div className="col-sm-2"><button style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateClientRow(e)}>New Client</button></div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-2"></div>
                        <div className="col-sm-2"><button disabled style={{float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.sortTable(e, 'effective')}>Sort Created</button></div>
                        <div className="col-sm-2">
                            <form>
                                <input style={{width: '70%'}} value={this.state.searchTerm} onChange={this.handleInputChange} type="text" name="searchTerm" />
                                <button style={{width: '30%', backgroundColor: 'green', color: 'white'}} type="submit" onClick={(e) => this.searchTable(e)}> Go </button>
                            </form>
                        </div>
                    </div>
                    
                    {this.state.showClientTable ? 
                            <table
                                id="database"
                            >
                                                <thead>

                                                    {this.state.showCreateClientRow ? 
                                                    
                                                        <CreateClientRow
                                                            agents={this.props.agents}
                                                            sendRowToDatabase={(data) => this.addNewRowToPolicies(data)}
                                                            generateNewRow={(data) => this.readNewRowData(data)}
                                                        />
                                                    : null}

                                                    <tr>
                                                        <th>First Name</th>
                                                        {/* <th>First Name</th> */}
                                                        <th>Last Name</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                        <th>Service</th>
                                                        <th>Document</th>
                                                        <th># Autos</th>               
                                                        <th># Properties</th>
                                                        <th># Policies</th>
                                                        {/* <th></th>
                                                        <th></th>               
                                                        <th></th> */}


                                                        {/* <th>Column</th>                                                             */}
                                                    </tr>
                                                </thead>
                                                <tbody>

                                               


                                                
                                                            {this.state.clients.map(client => (
                                                                <tr key={client._id}>
                                                                    <td><strong>{client.firstName}</strong></td>
                                                                    <td><strong>{client.lastName}</strong></td>
                                                                    {/* <td>{policy.clientFirstName}</td> */}

                                                                    <td>{client.email}</td>
                                                                    <td>{client.telephone}</td>
                                                                    <td><button class="btn btn-secondary" onClick={(e) => this.openEditModal(client)}>Edit</button></td>
                                                                    <td>
                                                                        {/* <button className="btn-primary btn" onClick={(e) => this.openServiceRequestModal(e, policy._id)}>Request</button> */}
                                                                        {client.documents.length > 0 ? 
                                                                      
                                                                            <i onClick={(e) => this.gatherVaultDocs(e, client.documents, client._id, 'client')} class="fa fa-folder-open fa-2x" aria-hidden="true"></i>
                                                                      

                                                                     : null}   
                                                                    </td>

                                                                    <td>{client.autos.length}</td>
                                                                    <td>{client.properties.length}</td>
                                                                    <td>{client.autos.length + client.properties.length + client.umbrellas.length}</td>
                                                                    {/* <td></td>
                                                                    <td></td> */}
                                                                    {/* <td></td> */}
                                                                    
                                                                   
                                                                    {/* <td>   */}
                                                                        {/* {policy.declaration ? 
                                                                    
                                                                        <button className="btn btn-info" onClick={(e) => this.downloadBase64File(e, policy.declaration)}>Document</button>

                                                                    : null}    */}
                                                                    {/* </td> */}
                                                                </tr>
                                                            ))}


                                                            

                                                </tbody>
                            </table>                    
                
                    : null }


                                                    {/* <ServiceRequestController
                                                        requests={this.state.serviceRequests}
                                                    />
                                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showServiceRequestController()}>Close</button> */}
                                                {/* </div> */}
                    </div>
            </div >
        )

    }

}

export default ClientDatabase;