import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';


class FawnCarousel extends Component {

 

    componentDidMount = () => {
        console.log("Fawn Carousel Mounted!")
    }

    render(){
        return (

            <div>
                <Carousel style={{padding: '', backgroundColor: 'transparent'}}>
                    <Carousel.Item>
                        <Carousel.Caption>
                            {/* <h3>We provide Insurance Analysis and Recommendations</h3> */}
                        </Carousel.Caption>
                        <div className="card">
                            {/* <div className="card-header">
                                <h3>Welcome to Fawn Circle</h3>
                            </div> */}
                            <div className="card-body">
                                <img
                                    className="d-block"
                                    style={{width: '80%', marginLeft: '10%', height: '10%'}}
                                    src="/images/fawn-logo.png"
                                    alt="First slide"
                                />
                            </div>
                        </div>
                   
                    </Carousel.Item>
                    {/* <Carousel.Item>
                        <Carousel.Caption>
                        
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
        
    
                        <Carousel.Caption>
                            <h1  style={{color: 'whitesmoke'}}>Full Policy Reviews</h1>
                            <h3></h3>
                        </Carousel.Caption>
                    

                            <div className="card">
                                <div className="card-header">
                                    <h3>Apply for a Quote in 45 seconds!</h3>
                                </div>
                                <div className="card-body" style={{padding: '50px'}}>

                                    <FawnButton 
                                        pf1={() => this.props.showFastQuote()}
                                        buttonText='Quote Online'
                                        style={{height: '200px', width: '50%'}}
                                    />
                          
                                </div>
                            </div>
                    </Carousel.Item> */}
                </Carousel>
            </div>
        );
    }

}

export default FawnCarousel;