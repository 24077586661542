import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
import Moment from 'react-moment';
import Modal from 'react-modal';

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
}


class QuoteItem extends Component {

    state = {
        editActivated: false,
        editModalIsOpen: false,
        showAddtlEdits: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        setTimeout(() => {
            this.loadState()
        }, 1000)

    }

    handleInputChange = event => {
        // const { name, value } = event.target;
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };

    loadState = () => {
        this.setState({
            firstName: this.props.quote.firstName,
            lastName: this.props.quote.lastName,
            quickName: this.props.quote.quickName,
            active: this.props.quote.active,
            quoted: this.props.quote.quoted,
            presented: this.props.quote.presented,
            sold: this.props.quote.sold,
            dob: this.props.quote.dob,
            street: this.props.quote.street,
            city: this.props.quote.city,
            state: this.props.quote.state,
            zip: this.props.quote.zip,
            quickAddress: this.props.quote.quickAddress,
            email: this.props.quote.email,
            vehicles: this.props.quote.vehicles,
            drivers: this.props.quote.drivers,
            notes: this.props.quote.notes,
            savings: this.props.quote.savings,
            referrer: this.props.quote.referrer,
            quoteCarrier: this.props.quote.quoteCarrier,
            quoteAutoPremium: this.props.quote.quoteAutoPremium,
            quoteHomePremium: this.props.quote.quoteHomePremium,
            quoteUmbrellaPremium: this.props.quote.quoteUmbrellaPremium,
            mcNotes: this.props.quote.mcNotes,
            mcStatus: this.props.quote.mcStatus,
            mainAgent: this.props.quote.mainAgent,
            refAgentOne: this.props.quote.refAgentOne,
            refAgentTwo: this.props.quote.refAgentTwo,
            refAgentThree: this.props.quote.refAgentThree,
            refAgentFour: this.props.quote.refAgentFour
        })


        this.findReferrer()
    }

    findReferrer = () => {
        var referrer = "";

        for (var i = 0; i < this.props.agents.length; i++) {
            if (this.props.agents[i]._id === this.props.quote.refAgentOne) {
                referrer = this.props.agents[i].firstName[0] + ". " + this.props.agents[i].lastName
            }
        }

        this.setState({
            referrer: referrer
        })

    }





    saveQuoteEdits = (e) => {
        e.preventDefault();

        var editedData = {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            effectiveDate: this.state.effectiveDate,
            BI: this.state.BI,
            PD: this.state.PD,
            UM: this.state.UM,
            UIM: this.state.UIM,
            TORT: this.state.TORT,
            premium: parseInt(this.state.premium)
        }

        console.log(editedData)

        API.updateAutoPolicy(this.props.autoData._id, {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            BI: this.state.BI,
            PD: this.state.PD,
            UM: this.state.UM,
            UIM: this.state.UIM,
            TORT: this.state.TORT,
            premium: parseInt(this.state.premium)
        }).then(res => {
            alert("You've updated this policy!")
            
        }).catch(err => alert("The edit did not go through!"))

    }

    showEdit = () => {
        this.setState({
            editModalIsOpen: true
        })
    }

    openEditModal = () => {
        this.setState({
            editModalIsOpen: true
        })
    }

    onAfterOpen = () => {
        console.log("Modal Is Opened")

    }

    closeEditModal = () => {
        this.setState({
            editModalIsOpen: false
        })
    }

    saveEdits = (event) => {

        event.preventDefault()

        var newQuoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            quickName: this.state.quickName,
            active: this.state.active,
            quoted: this.state.quoted,
            presented: this.state.presented,
            sold: this.state.sold,
            dob: this.state.dob,
            street: this.state.street,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            quickAddress: this.state.quickAddress,
            email: this.state.email,
            vehicles: this.state.vehicles,
            drivers: this.state.drivers,
            notes: this.state.notes,
            savings: this.state.savings,
            referrer: this.state.referrer,
            quoteCarrier: this.state.quoteCarrier,
            quoteAutoPremium: this.state.quoteAutoPremium,
            quoteHomePremium: this.state.quoteHomePremium,
            quoteUmbrellaPremium: this.state.quoteUmbrellaPremium,
            mcNotes: this.state.mcNotes,
            mcStatus: this.state.mcStatus,
            mainAgent: this.state.mainAgent,
            refAgentOne: this.state.refAgentOne,
            refAgentTwo: this.state.refAgentTwo,
            refAgentThree: this.state.refAgentThree,
            refAgentFour: this.state.refAgentFour

        }

        API.editQuote(this.props.quote._id, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            quickName: this.state.quickName,
            active: this.state.active,
            quoted: this.state.quoted,
            presented: this.state.presented,
            sold: this.state.sold,
            dob: this.state.dob,
            street: this.state.street,
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            quickAddress: this.state.quickAddress,
            email: this.state.email,
            vehicles: this.state.vehicles,
            drivers: this.state.drivers,
            notes: this.state.notes,
            savings: this.state.savings,
            referrer: this.state.referrer,
            quoteCarrier: this.state.quoteCarrier,
            quoteAutoPremium: this.state.quoteAutoPremium,
            quoteHomePremium: this.state.quoteHomePremium,
            quoteUmbrellaPremium: this.state.quoteUmbrellaPremium,
            mcNotes: this.state.mcNotes,
            mcStatus: this.state.mcStatus,
            mainAgent: this.state.mainAgent,
            refAgentOne: this.state.refAgentOne,
            refAgentTwo: this.state.refAgentTwo,
            refAgentThree: this.state.refAgentThree,
            refAgentFour: this.state.refAgentFour
        }).then(res => {
            console.log("Successfully edited quote Data!");
            alert("Successfully updated!");
            this.props.rerender();
            this.closeEditModal();

        }).catch(err => {
            console.log("There was an error...")
        })

        console.log(newQuoteData)
    }

    showAddtlEdits = (event) => {
        event.preventDefault()

        if (this.state.showAddtlEdits === true) {
            this.setState({
                showAddtlEdits: false
            })
        } else {
            this.setState({
                showAddtlEdits: true
            })
        }
    }

    render() {
        return (
            <>

                <tr style={{backgroundColor: 'white'}}>
                    <td>
                        {/* Client Name */}
                        {this.props.quote.firstName + " " + this.props.quote.lastName}

                    </td>
                    <td>
                        {/* Active? */}
                        {this.props.quote.active ? "Active" : "Inactive"}
                    </td>
                    <td>
                        {/* Status */}
                        {this.props.quote.quoted ?
                            <>
                                {this.props.quote.presented ?
                                    <>
                                        {this.props.quote.sold ? "Sold" : "Need to Sell"}
                                    </>
                                    : "Need to Present "}
                            </>
                            : "Need to Quote"}
                    </td>
                    <td>
                        {/* Quotes */}
                        {this.props.quote.quoteAutoPremium ? <div>Auto: {this.props.quote.quoteAutoPremium} </div> : null} 
                        {this.props.quote.quoteHomePremium ? <div>Home: {this.props.quote.quoteHomePremium} </div> : null} 
                        {this.props.quote.quoteUmbrellaPremium ? <div>Umbrella: {this.props.quote.quoteUmbrellaPremium}</div> : null}
                    </td>
                    <td>
                        {/* Savings */}
                        {this.props.quote.savings ? <>{this.props.quote.savings}</> : null}
                    </td>
                    <td>
                        {/* Referred By */}
                        {this.state.referrer ? <>{this.state.referrer}</> : "none"}
                    </td>

                    <td>
                        {/* Last Updated */}
                        <Moment format="MM/DD" add={{ hours: 8 }}>
                            {this.props.quote.updatedAt}
                        </Moment>
                    </td>
                    <td>
                        {/* Created On */}
                        <Moment format="MM/DD" add={{ hours: 8 }}>
                            {this.props.quote.created_at}
                        </Moment>
                    </td>
                    <td><button className="btn btn-info" onClick={this.showEdit}>Edit</button></td>
                </tr>


                <tr className="form-group" id="appt-holder">
                    <Modal
                        isOpen={this.state.editModalIsOpen}
                        onAfterOpen={this.afterOpenEditModal}
                        onRequestClose={this.closeEditModal}
                        style={customStyles}
                        contentLabel={"Edit Quote Here!"}
                        // appElement={el}
                        ariaHideApp={false}
                        >

                        <div className="card bg-light" style={{ color: 'black', textAlign: 'center', overflow: 'auto', height: '700px' }}>
                            <div className="card-header">
                                <h4>-------------Working on {this.props.quote.firstName + " " + this.props.quote.lastName}-----------</h4>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" />
                                        <label>Last Name</label>
                                        <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleInputChange} name="lastName" />
                                        |
                                        <label> Active 
                                        <input type="checkbox" className="form-control" checked={this.state.active} onChange={this.handleInputChange} name="active" />
                                        </label> | 
                                        <label> Quoted 
                                        <input type="checkbox" className="form-control" checked={this.state.quoted} onChange={this.handleInputChange} name="quoted" />
                                        </label> | 

                                        <label> Presented 
                                        <input type="checkbox" className="form-control" checked={this.state.presented} onChange={this.handleInputChange} name="presented" />
                                        </label> |
                                        <label> Sold 
                                        <input type="checkbox" className="form-control" checked={this.state.sold} onChange={this.handleInputChange} name="sold" />
                                        </label> |

                                        <br />
                                        <label>Quote Carrier</label>
                                        <input type="text" className="form-control" value={this.state.quoteCarrier} onChange={this.handleInputChange} name="quoteCarrier" />

                                        <label>Quoted Auto Premium</label>
                                        <input type="number" className="form-control" value={this.state.quoteAutoPremium} onChange={this.handleInputChange} name="quoteAutoPremium" />

                                        <label>Quoted Home Premium</label>
                                        <input type="number" className="form-control" value={this.state.quoteHomePremium} onChange={this.handleInputChange} name="quoteHomePremium" />

                                        <label>Quoted Umbrella Premium</label>
                                        <input type="number" className="form-control" value={this.state.quoteUmbrellaPremium} onChange={this.handleInputChange} name="quoteUmbrellaPremium" />

                                        <label>Savings</label>
                                        <input type="number" className="form-control" value={this.state.savings} onChange={this.handleInputChange} name="savings" />

                                        <br />
                                        <button className="btn btn-outline-success" onClick={this.showAddtlEdits}>Edit More</button>
                                        <br />

                                        {this.state.showAddtlEdits ? 
                                        <>
                                        <label>Date of Birth</label>
                                        <input type="date" className="form-control" value={this.state.dob} onChange={this.handleInputChange} name="dob" />

                                        <label>Quick Address</label>
                                        <input type="text" className="form-control" value={this.state.quickAddress} onChange={this.handleInputChange} name="quickAddress" />

                                        <label>Street</label>
                                        <input type="text" className="form-control" value={this.state.street} onChange={this.handleInputChange} name="street" />

                                        <label>City</label>
                                        <input type="text" className="form-control" value={this.state.city} onChange={this.handleInputChange} name="city" />

                                        <label>State</label>
                                        <input type="text" className="form-control" value={this.state.state} onChange={this.handleInputChange} name="state" />

                                        <label>Zip Code</label>
                                        <input type="text" className="form-control" value={this.state.zip} onChange={this.handleInputChange} name="zip" />

                                        <label>Email</label>
                                        <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" />

                                        <label>Vehicles</label>
                                        <input type="textbox" className="form-control" rows={8} cols={50} value={this.state.vehicles} onChange={this.handleInputChange} name="vehicles" /> 

                                        <label>Drivers</label>
                                        <input type="textbox" className="form-control" rows={8} cols={50} value={this.state.drivers} onChange={this.handleInputChange} name="drivers"/>

                                        <label>Notes</label>
                                        <input type="textbox" className="form-control" rows={8} cols={50} value={this.state.notes} onChange={this.handleInputChange} name="notes" />

                                        <label>Referrer</label>
                                        <input type="text" className="form-control" value={this.state.referrer} onChange={this.handleInputChange} name="referrer" />

                                        
                                        </>
                                        
                                        
                                        
                                        : null }
                                    </div>


                                    <button className="btn btn-lg btn-secondary" onClick={this.saveEdits}>Submit Edits</button>
                                </form>
                            </div>
                        </div>
                    </Modal>
                </tr>

            </>

        )

    }

}

export default QuoteItem;