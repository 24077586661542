import React, { Component } from 'react';
import './style.css'
import Modal from 'react-modal';
import cogoToast from 'cogo-toast';


const customStylesModal = {
    content: {
        padding: '5px 5px',
        backgroundImage: 'linear-gradient(to top, lightblue 0%, whitesmoke 100%)',
        border: '0px',
        height: '50%',
        textAlign: 'center'
    }
}

class AgentToolbar extends Component {
    state = {
        serviceReqType: "",
        serviceReqName: "",
        serviceReqEmail: "",
        serviceReqBody: "",
        showCallModal: false,
        showEmailModal: false,
        agentPageContactEmail: 'evan@fawncircle.com',
        agentPageContactPhone: '6102999918'
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };



    showCallModal = (e) =>{
        e.preventDefault()
        console.log("Showing Call Modal")
        if (this.state.showCallModal === false){
            this.setState({
                showCallModal: true,
                showEmailModal: false
            })
        } else {
            this.setState({
                showCallModal: false
            })
        }
    }

    showEmailModal = (e) => {
        e.preventDefault()
        console.log("Showing Email Modal")
        if (this.state.showEmailModal === false){
            this.setState({
                showCallModal: false,
                showEmailModal: true
            })
        } else {
            this.setState({
                showEmailModal: false
            })
        }
    }

    copyText = (event, data) => {
        event.preventDefault();

        var copyText = data;
        navigator.clipboard.writeText(copyText);
        console.log(navigator.clipboard);
        cogoToast.success("Copied: " + data)
    }

    render() {
        return (
          <div>
            <div className="row" style={{ color: 'black', textAlign: 'center' }}>
              <div className="col-sm-6 col-xs-12" style={{ color: 'black', fontWeight: 'bold', backgroundColor: 'rgba(255,255,255,0.5)' }}>
                Call us at<br />
                <span onClick={(e) => { this.copyText(e, this.state.agentPageContactPhone) }} style={{ color: 'blue' }}>{this.state.agentPageContactPhone}</span>
                <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, this.state.agentPageContactPhone) }}></i>
              </div>
              <div className="col-sm-6 col-xs-12" style={{ color: 'black', fontWeight: 'bold', backgroundColor: 'rgba(255,255,255,0.5)' }}>
                Email us at<br />
                <span onClick={(e) => { this.copyText(e, this.state.agentPageContactEmail) }} style={{ color: 'blue' }}>{this.state.agentPageContactEmail}</span>
                <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, this.state.agentPageContactEmail) }}></i>
              </div>
            </div>
      
            <div className="row" style={{ color: 'black', textAlign: 'center' }}>
              <div className="col-md-2 col-sm-4" style={{ backgroundColor: 'transparent', padding: '0px' }}>
                <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openAccount(e)}>{this.props.openAccountButtonText}</button>
              </div>
              <div className="col-md-2 col-sm-4" style={{ backgroundColor: 'transparent', padding: '0px' }}>
                <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openQuoting(e)}>New Quote</button>
              </div>
              <div className="col-md-2 col-sm-4" style={{ backgroundColor: 'transparent', padding: '0px' }}>
                <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openMasterControl(e)}>Policies</button>
              </div>
              <div className="col-md-2 col-sm-4" style={{ backgroundColor: 'transparent', padding: '0px' }}>
                {/* <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openProfile(e)}>My Profile</button> */}
                <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openSales(e)}>Sales</button>
              </div>
              <div className="col-md-2 col-sm-4" style={{ backgroundColor: 'transparent', padding: '0px' }}>
                <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openToolkit(e)}>Agency Tools</button>
              </div>
              <div className="col-md-2 col-sm-4" style={{ backgroundColor: 'transparent', padding: '0px' }}>
                <button className="btn btn-primary btn-block" style={{width: '100%'}} onClick={(e) => this.props.openEducation(e)}>Education</button>
              </div>
            </div>
      
            <Modal
              isOpen={this.state.showCallModal}
              onRequestClose={(e) => this.showCallModal(e)}
              style={customStylesModal}
            >
              <h3 style={{ marginTop: '25%' }}>
                Call us at<br />610-299-9918
                <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, '6102999918') }}></i>
              </h3>
              <br />
              <a className="btn btn-info" href="tel:6102999918"><h4>Call now</h4></a>
            </Modal>
      
            <Modal
              isOpen={this.state.showEmailModal}
              onRequestClose={(e) => this.showEmailModal(e)}
              style={customStylesModal}
            >
              <h3 style={{ marginTop: '25%' }}>
                Email us at<br />evan@fawncircle.com
                <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, 'evan@fawncircle.com') }}></i>
              </h3>
              <br />
              <a className="btn btn-info" href="mailto:evan@fawncircle.com" target="_blank" rel="noopener noreferrer">
                <h4>Email now</h4>
              </a>
            </Modal>
          </div>
        );
      }
      
}


export default AgentToolbar;
