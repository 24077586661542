import React from "react";
import "./style.css";
// import CreateReferringAgent from '../../components/CreateReferringAgent';
import { useParams } from "react-router-dom";
import API from "../../utils/API";
import cogoToast from "cogo-toast";
// import ResponsiveParralaxCarousel from '../../components/ResponsiveParralaxCarousel'
// import ResponsiveParralaxLogin from '../../components/ResponsiveParralaxLogin'

// const id = useParams();

export function withRouter(Children){
    return(props)=>{

       const match  = {params: useParams()};
       return <Children {...props}  match = {match}/>
   }
 }

class QuotePage extends React.Component {

    state = {
        showForm: true,
        quoteData: "",
        proposalData: [],
        quote1Data: [],
        quote2Data: [],
        quote3Data: []
    }



    //   handleSubmit(e) {
    //     e.preventDefault();
    //     console.log('You clicked submit.');
    //   }

    alternatingColor = ['white', 'white'];
    alternatingText = ['black', 'black'];

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    // handleInputChange = event => {
    //     // const { name, value } = event.target;
    //     const target = event.target;
    //     const name = target.name;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     this.setState({
    //         [name]: value
    //     });
    // };

    componentDidMount = () => {
        console.log("Quote Page");
        console.log(this.props);
        console.log(this.props.match.params.id)

        API.getQuote(this.props.match.params.id)
            .then((res) => {
                console.log(res.data)
                this.setState({ 
                    quoteData: res.data[0]
                })

                if (res.data[0].proposals.length > 0){

                    API.getDocumentsByIdArray(res.data[0].proposals)
                        .then((res) => {
                            console.log("Found Proposals")
                            this.setState({
                                proposalData: res.data
                            })
                        }).catch(err => console.log(err))

                }

                if (res.data[0].documents1.length > 0){

                    API.getDocumentsByIdArray(res.data[0].documents1)
                        .then((res) => {
                            console.log("Found Quote 1")
                            this.setState({
                                quote1Data: res.data
                            })
                        }).catch(err => console.log(err))

                }

                if (res.data[0].documents2.length > 0){

                    API.getDocumentsByIdArray(res.data[0].documents2)
                        .then((res) => {
                            console.log("Found Quote 2")
                            this.setState({
                                quote2Data: res.data
                            })
                        }).catch(err => console.log(err))

                }

                if (res.data[0].documents3.length > 0){

                    API.getDocumentsByIdArray(res.data[0].documents3)
                        .then((res) => {
                            console.log("Found Quote 3")
                            this.setState({
                                quote3Data: res.data
                            })
                        }).catch(err => console.log(err))

                }

                setTimeout(() => {
                    console.log("State for Quote Page")
                    console.log(this.state);
                }, 2500)
                // console.log(this.state)
            })
            .catch(err => {
                console.log(err)
            })
        // setTimeout(() => {
        //     // console.log(id);
        //     console.log(params);
        // },1500)
    }


  


    downloadBase64File(e, base64Data, fileName){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        cogoToast.info("Document Proposal...")
        // cogoToast
        
    }


    copyText = (event, data) => {
        console.log(this.state);
        event.preventDefault();

        if (data !== undefined){
            var copyText = data;
            navigator.clipboard.writeText(copyText);
            console.log(navigator.clipboard);
            cogoToast.success("Copied: " + data)
        } else {
            cogoToast.error("Couldn't copy data")
        }

    }


  handleClick = () => {
    //   console.log("Button Clicked")
  }

  sendQuoteToApplication = (event, quoteNum) => {
    event.preventDefault();
    console.log("Sending Quote #: " + quoteNum + " to Application"); 
    
    // let selectedCarrier = ""
    // let selectedAutoPremium = ""
    // let selectedHomePremium = ""
    // let selectedUmbrellaPreium = ""
    // let selectedQuoteDoc = ""
    if (quoteNum === 1){
        this.selectedCarrier = this.state.quoteData.quote1Carrier
        this.selectedAutoPremium = this.state.quoteData.quote1AutoPremium
        this.selectedHomePremium = this.state.quoteData.quote1HomePremium
        this.selectedUmbrellaPremium = this.state.quoteData.quote1UmbrellaPremium
        this.selectedQuoteDoc = this.state.quoteData.quote1Doc
    } else if (quoteNum === 2){
        this.selectedCarrier = this.state.quoteData.quote2Carrier
        this.selectedAutoPremium = this.state.quoteData.quote2AutoPremium
        this.selectedHomePremium = this.state.quoteData.quote2HomePremium
        this.selectedUmbrellaPremium = this.state.quoteData.quote2UmbrellaPremium
        this.selectedQuoteDoc = this.state.quoteData.quote2Doc
    } else if (quoteNum === 3){
        this.selectedCarrier = this.state.quoteData.quote3Carrier
        this.selectedAutoPremium = this.state.quoteData.quote3AutoPremium
        this.selectedHomePremium = this.state.quoteData.quote3HomePremium
        this.selectedUmbrellaPremium = this.state.quoteData.quote3UmbrellaPremium
        this.selectedQuoteDoc = this.state.quoteData.quote3Doc
    } else {
        this.selectedCarrier = this.state.quoteData.quote1Carrier
        this.selectedAutoPremium = this.state.quoteData.quote1AutoPremium
        this.selectedHomePremium = this.state.quoteData.quote1HomePremium
        this.selectedUmbrellaPremium = this.state.quoteData.quote1UmbrellaPremium
        this.selectedQuoteDoc = this.state.quoteData.quote1Doc
    }

    let applicationData = {
        firstName: this.state.quoteData.firstName,
        lastName: this.state.quoteData.lastName,
        quickName: this.state.quoteData.quickName,
        quoteID: this.state.quoteData._id,
        street: this.state.quoteData.street,
        city: this.state.quoteData.city,
        state: this.state.quoteData.state,
        zip: this.state.quoteData.zip,
        quickAddress: this.state.quoteData.quickAddress,
        phone: this.state.quoteData.phone,
        email: this.state.quoteData.email,
        vehicles: this.state.quoteData.vehicles,
        drivers: this.state.quoteData.drivers,
        notes: this.state.quoteData.notes,
        targetPremium: this.state.quoteData.targetPremium,
        selectedCarrier: this.selectedCarrier,
        selectedAutoPremium: this.selectedAutoPremium,
        selectedHomePremium: this.selectedHomePremium,
        selectedUmbrellaPremium: this.selectedUmbrellaPremium,
        selectedQuoteDoc: this.selectedQuoteDoc,
        proposalDoc: this.state.quoteData.proposalDoc,
        proposalNotes: this.state.quoteData.proposalNotes,
        mainAgent: this.state.quoteData.mainAgent,
        refAgentOne: this.state.quoteData.refAgentOne,
        refAgentTwo: this.state.quoteData.refAgentTwo,
        refAgentThree: this.state.quoteData.refAgentThree,
        refAgentFour: this.state.quoteData.refAgentFour,
        uploadedLink: this.state.quoteData.uploadedLink,
        active: this.state.quoteData.active,
        sold: this.state.quoteData.sold
    }
    console.log("Creating Application using Data: ");
    console.log(applicationData)
    API.createApplication(applicationData)
        .then(res => {
            console.log("Created Application!");
            cogoToast.application("Created Application!");
            console.log(res.data);
        })
        .catch(err => console.log(err))
  }


    render() {
        return (
            <div className="container-fluid auroral-northern">
                <div className="row">
                    <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                    <div className="col-lg-9" style={{padding: '25px', borderRadius: '10px', backgroundColor: 'lightgreen', height: '250px'}}>

                        <div className="row">
                            <div className="col-lg-1" style={{borderRadius: '15px', backgroundColor: 'transparent'}}></div>
                            <div className="col-lg-3" style={{borderRadius: '15px', backgroundColor: 'darkseagreen', height: '150px'}}>
                                <div claassName="row" style={{padding: '10px 0px 10px 0px'}}>
                                    <div className="col" style={{textAlign: 'center', height: '40px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.8)'}}><p>{this.state.quoteData.quickName? this.state.quoteData.quickName : this.state.quoteData.firstName + " " + this.state.quoteData.lastName }</p></div>
                                    <div className="col" style={{textAlign: 'center', height: '40px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.8)'}}><p>{this.state.quoteData.email}</p></div>
                                    <div className="col" style={{textAlign: 'center', height: '40px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.8)'}}><p>{this.state.quoteData.phone}</p></div>
                                </div>
                            </div>
                            <div className="col-lg-7" style={{borderRadius: '15px', backgroundColor: 'darkolivegreen', height: '150px'}}>
                                <div className="row" style={{padding: '10px 0px 10px 0px'}}>
                                    <div className="col" style={{textAlign: 'center', height: '40px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.9)'}}>
                                        <h2>
                                            Your Insurance Proposal 
                                        </h2>
                                    </div>
                                </div>
                                <div className="row" style={{padding: '0px 0px 10px 0px'}}>
                                    <div className="col-lg-1" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                                    <div className="col-lg-7" style={{textAlign: 'center', padding: '20px 0px 20px 0px', overflowX: 'auto', height: '80px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.9)'}}>
                                        <p>{this.state.quoteData.proposalNotes}</p>

                                    </div>
                                    <div className="col-lg-3" style={{textAlign: 'center', padding: '20px', height: '80px', borderRadius: '20px', backgroundColor: 'rgba(255,255,255,0.9)'}}>
                                        {this.state.proposalData.length > 0 ? 
                                          <button className="btn btn-success" onClick={(e) => this.downloadBase64File(e, this.state.proposalData[0].document, this.state.proposalData[0].docName)}>Proposal</button>
                                        : 
                                        <p>Proposal is almost ready...please come back soon!</p>
                                        }
                                    </div>
                                    <div className="col-lg-1" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                                    
                           
                                </div>

                            </div>
                            <div className="col-lg-1" style={{borderRadius: '15px', backgroundColor: 'transparent'}}></div>
                        </div>

                    </div>
                    <div className="col-lg-2" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                </div>
                <div className="row">
                    <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                    <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'darkgreen', height: '450px'}}>

                        <div className="row">
                            <div className="col-lg-2" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                            <div className="col-lg-8" style={{textAlign: 'center', bprderRadius: '20px', padding: '10px',  height: '200px', backgroundColor: 'black'}}>
                                {/* Quote 1 Image Generator Component */}
                            </div>
                            <div className="col-lg-2" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                            <div className="col-lg-6" style={{textAlign: 'center', borderRadius: '20px', padding: '10px',  height: '225px', backgroundColor: 'white'}}>
                                <p><u>{this.state.quoteData.quote1Carrier}</u></p>
                                <p>{this.state.quoteData.quote1Notes}</p>
                            </div>
                            <div className="col-lg-4" style={{textAlign: 'center', borderRadius: '20px', padding: '10px',  height: '225px', backgroundColor: 'whitesmoke'}}>
                                <div className="row">
                                    <div className="col" style={{textAlign: 'center', height: '100px', borderRadius: '25px', backgroundColor: 'greenyellow'}}>
                                        <p>Savings:</p>
                                        {/* Logic for savings */}
                                        {this.state.quoteData.targetPremium ? this.state.quoteData.targetPremium - this.state.quoteData.quote1Premium : null}
                                    </div>
                                    <div className="col" style={{textAlign: 'center', height: '100px', borderRadius: '25px', backgroundColor: 'greenyellow'}}>
                                        <p>Quote PDF:</p>
                                        {/* Logic for savings */}
                                        {this.state.quote1Data.length > 0 ?  <button className="btn btn-success" onClick={(e) => this.downloadBase64File(e, this.state.quote1Data[0].document, this.state.quote1Data[0].docName)}>View Quote</button> : <p>Quote PDF not ready yet!</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                        </div>

                    </div>
                    <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'lightgreen', height: '450px'}}>

                            <div className="row">
                                <div className="col-lg-2" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                                <div className="col-lg-8" style={{textAlign: 'center', bprderRadius: '20px', padding: '10px',  height: '200px', backgroundColor: 'black'}}>
                                    {/* Quote 1 Image Generator Component */}
                                </div>
                                <div className="col-lg-2" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                                <div className="col-lg-6" style={{textAlign: 'center', borderRadius: '20px', padding: '10px',  height: '225px', backgroundColor: 'white'}}>
                                    <p><u>{this.state.quoteData.quote2Carrier}</u></p>
                                    <p>{this.state.quoteData.quote2Notes}</p>
                                </div>
                                <div className="col-lg-4" style={{textAlign: 'center', borderRadius: '20px', padding: '10px',  height: '225px', backgroundColor: 'whitesmoke'}}>
                                    <div className="row">
                                        <div className="col" style={{textAlign: 'center', height: '100px', borderRadius: '25px', backgroundColor: 'greenyellow'}}>
                                            <p>Savings:</p>
                                            {/* Logic for savings */}
                                            {this.state.quoteData.targetPremium ? this.state.quoteData.targetPremium - this.state.quoteData.quote2Premium : null}
                                        </div>
                                        <div className="col" style={{textAlign: 'center', height: '100px', borderRadius: '25px', backgroundColor: 'greenyellow'}}>
                                            <p>Quote PDF:</p>
                                            {/* Logic for savings */}
                                            {this.state.quote2Data.length > 0 ?  <button className="btn btn-success" onClick={(e) => this.downloadBase64File(e, this.state.quote2Data[0].document, this.state.quote2Data[0].docName)}>View Quote</button> : <p>Quote PDF not ready yet!</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                            </div>



                    </div>
                    <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'darkgreen', height: '450px'}}>

                            <div className="row">
                                <div className="col-lg-2" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                                <div className="col-lg-8" style={{textAlign: 'center', bprderRadius: '20px', padding: '10px',  height: '200px', backgroundColor: 'black'}}>
                                    {/* Quote 1 Image Generator Component */}
                                </div>
                                <div className="col-lg-2" style={{textAlign: 'center', borderRadius: '20px', backgroundColor: 'transparent'}}></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                                <div className="col-lg-6" style={{textAlign: 'center', borderRadius: '20px', padding: '10px',  height: '225px', backgroundColor: 'white'}}>
                                    <p><u>{this.state.quoteData.quote3Carrier}</u></p>
                                    <p>{this.state.quoteData.quote3Notes}</p>
                                </div>
                                <div className="col-lg-4" style={{textAlign: 'center', borderRadius: '20px', padding: '10px',  height: '225px', backgroundColor: 'whitesmoke'}}>
                                    <div className="row">
                                        <div className="col" style={{textAlign: 'center', height: '100px', borderRadius: '25px', backgroundColor: 'greenyellow'}}>
                                            <p>Savings:</p>
                                            {/* Logic for savings */}
                                            {this.state.quoteData.targetPremium ? this.state.quoteData.targetPremium - this.state.quoteData.quote3Premium : null}
                                        </div>
                                        <div className="col" style={{textAlign: 'center', height: '100px', borderRadius: '25px', backgroundColor: 'greenyellow'}}>
                                            <p>Quote PDF:</p>
                                            {/* Logic for savings */}
                                            {this.state.quote3Data.length > 0 ?  <button className="btn btn-success" onClick={(e) => this.downloadBase64File(e, this.state.quote3Data[0].document, this.state.quote3Data[0].docName)}>View Quote</button> : <p>Quote PDF not ready yet!</p>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                            </div>

                    </div>
                    <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent', height: '0'}}></div>
                </div>
                <div className="row">
                <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'lightgreen', height: '200px'}}>

                    <div className="row" style={{height: '100%'}}>
                        <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                        <div className="col-lg-6" style={{padding: '12% 3% 12% 3%', height: '100%', borderRadius: '10px', backgroundColor: 'transparent'}}>
                            <button style={{height: '100%', width: '100%'}} className="btn btn-primary" onClick={(e) => { this.sendQuoteToApplication(e, 1)}}><span>View {this.state.quoteData.quote1Carrier} Application</span></button>
                        </div>
                        <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                    </div>

                </div>
                <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'darkgreen', height: '200px'}}>

                    <div className="row" style={{height: '100%'}}>
                            <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                            <div className="col-lg-6" style={{padding: '12% 3% 12% 3%', height: '100%', borderRadius: '10px', backgroundColor: 'transparent'}}>
                            <button style={{height: '100%', width: '100%'}} className="btn btn-dark" onClick={(e) => { this.sendQuoteToApplication(e, 2)}}><span>View {this.state.quoteData.quote2Carrier} Application</span></button>
                        </div>
                            <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                    </div>
                </div>
                <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'lightgreen', height: '200px'}}>

                    <div className="row" style={{height: '100%'}}>
                        <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                        <div className="col-lg-6" style={{padding: '12% 3% 12% 3%', height: '100%', borderRadius: '10px', backgroundColor: 'transparent'}}>
                            <button style={{height: '100%', width: '100%'}} className="btn btn-danger" onClick={(e) => { this.sendQuoteToApplication(e, 3)}}><span>View {this.state.quoteData.quote3Carrier} Application</span></button>
                        </div>
                        <div className="col-lg-3" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                    </div>
                </div>
                <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'transparent'}}></div>
                {/* <div className="col-lg-1" style={{borderRadius: '10px', backgroundColor: 'lightgreen', height: '200px'}}></div> */}
                </div>

            </div >
        )

    }

}

// export default QuotePage;
export default withRouter(QuotePage);