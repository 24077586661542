import React, { Component } from "react";
import "./style.css";
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import API from "../../utils/API";
import { storage } from '../../config/Fire';
import cogoToast from "cogo-toast";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class UmbrellaPolicySummary extends Component {

    state = {
        editActivated: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        this.setState({
            carrier: this.props.umbrellaData.carrier,
            policyNumber: this.props.umbrellaData.policyNumber,
            effectiveDate: this.props.umbrellaData.effectiveDate,
            liability: this.props.umbrellaData.liability,
            premium: this.props.umbrellaData.premium,
            declaration: this.props.umbrellaData.declaration
        })
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    copyText = (event, data) => {
        console.log(this.state);
        event.preventDefault();

        if (data !== undefined){
            var copyText = data;
            navigator.clipboard.writeText(copyText);
            console.log(navigator.clipboard);
            cogoToast.success("Copied: " + data)
        } else {
            cogoToast.error("Couldn't copy data")
        }

    }

    fileSelectedHandler = event => {
        event.preventDefault();
        if (event.target.files[0]) {
            this.setState({
                declaration: event.target.files[0]
            })
        }
    }

    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        declaration: urlB,
                        uploaded: true
                    })
                })
            }
        )
    }


    activateEdit = (e) => {
        e.preventDefault();
        if (this.state.editActivated === true) {
            this.setState({
                editActivated: false
            })
        } else {
            this.setState({
                editActivated: true
            })
        }
    }

    saveEdits = (e) => {
        e.preventDefault();

        var editedData = {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            effectiveDate: this.state.effectiveDate,
            liability: this.state.liability,
            premium: parseInt(this.state.premium),
            declaration: this.state.declaration
        }

        console.log(editedData)

        API.updateUmbrellaPolicy(this.props.umbrellaData._id, {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            liability: this.state.liability,
            premium: parseInt(this.state.premium),
            declaration: this.state.declaration
        }).then(res => {
            alert("You've updated this policy!")
        }).catch(err => alert("The edit did not go through!"))

    }

    softDeleteUmbrella = (id) => {
        console.log(id + " has been deleted")
        API.softDeleteUmbrella(id)
            .then(res => {
                alert("You've deleted this umbrella policy")
            }).catch(err => alert("The deletion did not go through"))
    }

    showUmbrella = () => {
        if (this.state.showUmbrella === true) {
            this.setState({
                showUmbrella: false
            })
        } else {
            this.setState({
                showUmbrella: true
            })
        }
    }



    render() {
        return (
            // <div className="row">

                <div className={this.props.colSize}>
                    {this.props.umbrellaData.policyActive ?
                        <div className="card" style={{ overflow: 'auto' }}>
                            <div className="card-header" style={{ textAlign: 'center', color: 'black' }}>
                                <h5><strong>{this.props.umbrellaData.carrier} Umbrella</strong></h5>
                                <h6><strong>Policy #: {this.props.umbrellaData.policyNumber}</strong></h6>
                                <button className="btn btn-success" onClick={this.showUmbrella}>Show / Hide</button>
                                {this.props.admin ? <button className="btn btn-warning" onClick={this.activateEdit}>Edit</button> : null}
                                {this.props.admin ? <button className="btn-danger btn" onClick={() => this.softDeleteUmbrella(this.props.umbrellaData._id)}>Delete</button> : null}
                            </div>

                            {this.state.editActivated ?
                                <>
                                <div className="card-body" style={{ backgroundColor: 'white' }}>
                                    <Table striped bordered hover responsive>

                                        <tbody>
                                            <tr>
                                                <th><strong></strong></th>
                                                <th><strong></strong></th>
                                            </tr>   
                                            <tr>
                                                <td><strong>Carrier</strong></td>
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.carrier} name="carrier" /></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Policy Number</strong></td>
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.policyNumber} name="policyNumber" /></td>
                                                {/* <td>{this.props.autoData.policyNumber}</td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Effective Date</strong></td>
                                                {/* <td><input type="date" onChange={this.handleInputChange} value={this.state.effectiveDate} name="effectiveDate" /></td> */}
                                                <td><Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                                    {this.props.umbrellaData.effectiveDate}
                                                </Moment></td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <Table striped bordered hover responsive>

                                        <tbody>
                                            <tr>
                                                <th><strong>Coverage</strong></th>
                                                <th><strong>Amount</strong></th>
                                            </tr>
                                            <tr>
                                                <td><strong>Excess Liability</strong></td>
                                                {/* <td>{this.props.autoData.BI}</td> */}
                                                <td>$<input type="text" onChange={this.handleInputChange} value={this.state.liability} name="liability" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr>
                                                <td><strong>Premium</strong></td>
                                                <td>$<input type="number" onChange={this.handleInputChange} value={this.state.premium} name="premium" /></td>
                                            </tr>
                                            <tr>
                                                <td><strong>Declarations</strong></td>
                                                <td>
                                                    <input style={{width: '80%'}} type="file" onChange={this.fileSelectedHandler} />
                                                    <button onClick={this.fileUploadHandlerB}>Upload
                                                <span>
                                                            {this.state.uploaded === false ? <img alt="loading" src="/loading.gif" /> : null}
                                                            {this.state.uploaded === true ? <img alt="checkmark" src="/checkmark.png" style={{ height: '20px', width: '20px' }} /> : null}
                                                        </span>
                                                    </button>
                                                </td></tr>
                                        </tbody>
                                    </Table>
                                    <button style={{ textAlign: 'center' }} className="btn btn-success" onClick={this.saveEdits}>Save Edits</button>
                                </div>


                                </>
                                :
                                <div className="card-body" style={{ backgroundColor: 'white' }}>
                                    {this.state.showUmbrella ?
                                        <>
                                            <Table striped bordered hover responsive>
                                                <tbody>
                                                    <tr>
                                                        <th><strong></strong></th>
                                                        <th><strong></strong></th>
                                                    </tr>   
                                                    <tr>
                                                        <td><strong>Carrier</strong></td>
                                                        <td>{this.props.umbrellaData.carrier}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Policy Number</strong></td>
                                                        <td>{this.props.umbrellaData.policyNumber} <i class="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.props.umbrellaData.policyNumber)}}></i></td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Effective Date</strong></td>
                                                        <td>
                                                            <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                                                {this.props.umbrellaData.effectiveDate}
                                                            </Moment>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            <Table striped bordered hover responsive>

                                                <tbody>
                                                    <tr>
                                                        <th><strong>Coverage</strong></th>
                                                        <th><strong>Amount</strong></th>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Excess Liability</strong></td>
                                                        <td>${this.props.umbrellaData.liability}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Premium</strong></td>
                                                        <td>${this.props.umbrellaData.premium}</td>

                                                    </tr>
                                                    <tr>
                                                        <td><strong>Declarations Page</strong></td>
                                                        <td><a href={this.props.umbrellaData.declaration}>Download</a></td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </>
                                        : null}



                                </div>
                            }
                        </div>
                        : null}
                    <br />
                </div>
            // </div>
        )

    }

}

export default UmbrellaPolicySummary;