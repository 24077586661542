import React, { Component } from "react";
import "./style.css";
// import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
import RequestItem from '../../components/RequestItem'
import './style.css'
import API from "../../utils/API";

class ServiceRequestController extends Component {

    state = {
        requests: [],
        refreshRequests: [],
        searchTerm: ""
    }

    componentDidMount = () => {
        console.log("Service Request Tracker Card Mounted")
        // setTimeout(() => { this.calculateTotals() }, 3000)
        API.getServicerequests().then(res => {
            console.log("Retrieved Service Requests");
            console.log(res.data);
            this.setState({
                requests: res.data
            })
        })
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    completeAndRemove(){
        API.getServicerequests().then(res => {
            console.log("Retrieved Service Requests");
            console.log(res.data);
            this.setState({
                requests: res.data,
                refreshRequests: res.data
            })
        })

    }

    resetTable = (e) => {
        e.preventDefault();
        console.log("Resetting table");
        console.log("Transforming policies table: ")
        console.table(this.state.requests)
        console.log("Into original table: ");
        console.table(this.state.refreshRequests)
        this.setState({
            policies: this.state.refreshRequests
        })
    }

    searchTable = (e) => {
        e.preventDefault();
        console.log("Search Table for: " + this.state.searchTerm)
        let rowsWithTerm = [];
        for (var i = 0; i < this.state.refreshRequests.length; i++){

            let object = this.state.refreshRequests[i]

            for (const property in object){
                console.log(`${property}: ${object[property]}`)
                console.log(typeof(property))
                console.log(typeof(object[property]))
                if (typeof(object[property]) == 'string'){

                    if (object[property].toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1){

                        if (rowsWithTerm.length === 0){
                            rowsWithTerm.push(object);
                        } else {
                            let rowFoundAlready = false;
                            for (var j = 0; j < rowsWithTerm.length; j++){
                                if (rowsWithTerm[j]._id === object._id){
                                    rowFoundAlready = true;
                                }
                                if (j === rowsWithTerm.length - 1){
                                    if (rowFoundAlready === false){
                                        rowsWithTerm.push(object);
                                    }
                                }
                            }
                            this.setState({
                                requests: rowsWithTerm
                            })
                        }
                       
                    }

                }

            }

            if (i === this.state.refreshRequests.length - 1){
                console.log("Completed search");
                console.log("Rows with Term: " + this.state.searchTerm);
                console.log(rowsWithTerm)
                console.table(rowsWithTerm);
            }

        }
        // this.setState({

        // })
    }


    render() {
        return (
            <div>

                <div className="card" style={{overflowX: 'auto'}}>
                    {/* <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={() => this.props.closeComponent()}></button> */}

                    <h3 style={{textAlign: 'center'}}>Service Request Control Sheet</h3>
                    <div className="row">
                        <div className="col-lg-2"><button style={{float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.resetTable(e)}>Refresh</button></div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-2">
                            <form>
                                <input style={{width: '70%'}} value={this.state.searchTerm} onChange={this.handleInputChange} type="text" name="searchTerm" />
                                <button style={{width: '30%', backgroundColor: 'green', color: 'white'}} type="submit" onClick={(e) => this.searchTable(e)}> Go </button>
                            </form>
                        </div>
                    </div>

                    <table id="database">

                        <thead>
                            <tr>
                                <th>Client Name</th>
                                <th>E-mail</th>
                                <th>Type</th>
                                <th>Notes</th>
                                <th>Document</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {this.state.requests ?
                                    <>
                                        {this.state.requests.map(request => (

                                            <RequestItem
                                                request={request}
                                                key={request._id}
                                                complete={() => this.completeAndRemove()}
                                            />


                                        ))}

                                    </>
                            : null}


                        </tbody>


                    </table>

                </div>

                {/* <Card bg="light" style={{overflow: 'auto', width: "100%"}}> */}
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    {/* <Card.Header style={{ textAlign: 'center' }}> */}
                    {/* <h3 style={{textAlign: 'center'}}>
                     Service Request Controller
                    </h3> */}
                   
                    {/* </Card.Header> */}
                    {/* <Card.Body> */}
                        {/* <Table striped bordered hover responsive> */}
                            {/* <thead>
                                <tr>
                                    <th>Client Name</th>
                                    <th>E-mail</th>
                                    <th>Type</th>
                                    <th>Message</th>
                                    <th></th>
                                </tr>
                            </thead> */}
                            {/* <tbody>
                                {this.state.requests ?
                                    <>
                                        {this.state.requests.map(request => (

                                            <RequestItem
                                                request={request}
                                                key={request._id}
                                                complete={() => this.completeAndRemove()}

                                            />


                                        ))}

                                    </>
                                    : null}



                            </tbody> */}

{/* 
                        </Table>


                    </Card.Body>



                </Card> */}
            </div >
        )

    }

}

export default ServiceRequestController;