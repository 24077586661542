import React, { Component } from "react";
import "./style.css";
import cogoToast from "cogo-toast";
import Moment from 'react-moment';

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class MasterControlProperty extends Component {

    state = {
        commissions: "",
        split: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        setTimeout(() => { this.calculateCommissions(this.props.agentID) }, 1000)
    }

    calculateCommissions = (agentID) => {
        var commissions = 0;
        var split = 0;

        // for (var i = 0; i < this.props.property.length; i++) {
        if (agentID === this.props.property.mainAgent) {
            // console.log("This agent is main agent")
            commissions = (this.props.property.premium * (this.props.property.mainAgentSplit / 100)) * 0.06;
            split = this.props.property.mainAgentSplit
            // console.log("Commmission are: " + commissions)

        } else if (agentID === this.props.property.refAgentOne) {
            // console.log("This agent is ref agent one")
            commissions = (this.props.property.premium * (this.props.property.refAgentOneSplit / 100)) * 0.06;
            split = this.props.property.refAgentOneSplit
            // console.log("Commmission are: " + commissions)

        } else if (agentID === this.props.property.refAgentTwo) {
            // console.log("This agent is ref agent two")
            commissions = (this.props.property.premium * (this.props.property.refAgentTwoSplit / 100)) * 0.06;
            split = this.props.property.refAgentTwoSplit
            // console.log("Commissiona are : " + commissions)

        } else if (agentID === this.props.property.refAgentThree) {
            // console.log("This agent is ref agent three ")
            commissions = (this.props.property.premium * (this.props.property.refAgentThreeSplit / 100)) * 0.06;
            split = this.props.property.refAgentThreeSplit
            // console.log("Commissions are : " + commissions)

        } else if (agentID === this.props.property.refAgentFour) {
            // console.log("This agnet if ref agent four ")
            commissions = (this.props.property.premium * (this.props.property.refAgentFour / 100)) * 0.06;
            split = this.props.property.refAgentFourSplit
            // console.log("Commissions are : " + commissions)
        }

        setTimeout(() => {
            this.setState({
                commissions: commissions,
                split: split
            })

        }, 500)
        // }

    }

    viewPolicy = event => {
        event.preventDefault();
        console.log("Getting Policy information");
        cogoToast.warn("Website Feature coming soon!");
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };



    render() {
        const { property } = this.props;
        const { split, commissions } = this.state;
      
        return (
          <tr style={{ backgroundColor: 'white' }}>
            <td>
              Property
            </td>
            <td>
              <strong>{`${property.clientFirstName} ${property.clientLastName}`}</strong>
            </td>
            <td>
              {property.policyActive ? 'Active' : 'Inactive'}
            </td>
            <td>
              ${property.premium}
            </td>
            <td>
              {split ? <>{parseInt(split).toFixed(0)}%</> : <img alt="loading" src="/loading.gif" />}
            </td>
            <td>
              {commissions ? <>${parseFloat(commissions).toFixed(2)}</> : <img alt="loading" src="/loading.gif" />}
            </td>
            <td>
              <Moment format="MM/DD" add={{ hours: 8 }}>
                {property.effectiveDate}
              </Moment>
            </td>
            <td>
              <Moment format="MM/DD" add={{ hours: 8 }}>
                {property.updatedAt}
              </Moment>
            </td>
            <td>
              <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                {property.created_at}
              </Moment>
            </td>
            <td>
              {/* Additional content for the last column */}
              {/* View policy */}
              <button className="btn btn-small btn-black" style={{width: '100%'}} onClick={(e) => this.viewPolicy(e)}>View</button>
            </td>
          </tr>
        );
      }
      
}

export default MasterControlProperty;