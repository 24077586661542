import React, { Component } from "react";
import "./style.css";
import Moment from 'react-moment';
import cogoToast from "cogo-toast";


// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class MasterControlAuto extends Component {

    state = {
        commissions: "",
        split: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        setTimeout(() => { this.calculateCommissions(this.props.agentID) }, 1000)
    }

    calculateCommissions = (agentID) => {
        var commissions = 0;
        var split = 0;

        // for (var i = 0; i < this.props.property.length; i++) {
        if (agentID === this.props.auto.mainAgent) {
            // console.log("This agent is main agent")
            commissions = (this.props.auto.premium * (this.props.auto.mainAgentSplit / 100)) * 0.055;
            split = this.props.auto.mainAgentSplit
            // console.log("Commmission are: " + commissions)

        } else if (agentID === this.props.auto.refAgentOne) {
            // console.log("This agent is ref agent one")
            commissions = (this.props.auto.premium * (this.props.auto.refAgentOneSplit / 100)) * 0.055;
            split = this.props.auto.refAgentOneSplit
            // console.log("Commmission are: " + commissions)

        } else if (agentID === this.props.auto.refAgentTwo) {
            // console.log("This agent is ref agent two")
            commissions = (this.props.auto.premium * (this.props.auto.refAgentTwoSplit / 100)) * 0.055;
            split = this.props.auto.refAgentTwoSplit
            // console.log("Commissiona are : " + commissions)

        } else if (agentID === this.props.auto.refAgentThree) {
            // console.log("This agent is ref agent three ")
            commissions = (this.props.auto.premium * (this.props.auto.refAgentThreeSplit / 100)) * 0.055;
            split = this.props.auto.refAgentThreeSplit
            // console.log("Commissions are : " + commissions)

        } else if (agentID === this.props.auto.refAgentFour) {
            // console.log("This agnet if ref agent four ")
            commissions = (this.props.auto.premium * (this.props.auto.refAgentFour / 100)) * 0.055;
            split = this.props.auto.refAgentFourSplit
            // console.log("Commissions are : " + commissions)
        }

        setTimeout(() => {
            this.setState({
                commissions: commissions,
                split: split
            })

        }, 500)
        // }

    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };


    viewPolicy = event => {
        event.preventDefault();
        console.log("Getting Policy information");
        cogoToast.warn("Website Feature coming soon!");
    }



    render() {
        return (

            // <MasterControlProperty 
            //     property={property}
            //     agentID={this.props.agentData._id}
            // />
            <tr style={{backgroundColor: 'white'}}>
                <td>
                    Auto
                </td>
                <td>
                    <strong>{this.props.auto.clientFirstName + " " + this.props.auto.clientLastName}</strong>
                </td>
                <td>
                    {this.props.auto.policyActive ? "Active" : "Inactive"}
                </td>
                <td>
                    ${this.props.auto.premium}
                </td>
                <td>
                    {this.state.split ? <>{(parseInt(this.state.split)).toFixed(0)}%</> : <img  alt="loading" src="/loading.gif" />}
                </td>
                <td>
                    {this.state.commissions ? <>${(parseInt(this.state.commissions)).toFixed(3)}</> : <img  alt="loading" src="/loading.gif" />}
                </td>
                <td>

                    <Moment format="MM/DD" add={{ hours: 8 }}>{this.props.auto.effectiveDate}
                    </Moment>

                </td>
                <td>
                    <Moment format="MM/DD" add={{ hours: 8 }}>
                        {this.props.auto.updatedAt}
                    </Moment>
                </td>
                <td>
                    <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                        {this.props.auto.created_at}
                    </Moment>
                </td>
                <td>
              {/* Additional content for the last column */}
              {/* View policy */}
              <button className="btn btn-small btn-black" style={{width: '100%'}} onClick={(e) => this.viewPolicy(e)}>View</button>
            </td>
            </tr>


        )

    }

}

export default MasterControlAuto;