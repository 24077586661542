import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import Table from 'react-bootstrap/Table';
import API from "../../utils/API";


class CreateReferral extends Component {
    state = {
        refName: "",
        refContact: "",
        refRelation: "",
        custContact: ""

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleReferralSubmit = event => {
        event.preventDefault()
        console.log("Submitting Service Request....")

        var referralData = {
            name: this.state.refName,
            contact: this.state.refContact,
            relation: this.state.refRelation
        }

        console.log(referralData)

        API.saveReferral({
            name: this.state.refName,
            contact: this.state.refContact,
            relation: this.state.refRelation,
            referrerID: this.props.uid,
            custContact: this.state.custContact
        }).then(res => {
            console.log("Saved Referral")
            alert("Referral Sent!")
            console.log(res.data)
        }
        ).catch(err => console.log(err))

    }


    render() {
        return (

            < div >

                <div className="card" style={{borderRadius: '10px', color: 'black', overflow: 'auto', padding: '0'}}>
                    <div className="card-header" style={{textAlign: 'center'}}>
                        <h3 style={{ textAlign: 'center' }}>Referral Bonus</h3>
                        <p>We offer $40 as referral bonus if you put us in touch with one of your family members or friends!
                        </p>
                    </div>
                    <div className="card-body" style={{padding: '0'}}>
                        <form>
                        <ul>
                            <li>
                                <strong>Referral Name:</strong>
                                <ul>
                                <li>
                                    <input type="text" value={this.state.refName} onChange={this.handleInputChange} name="refName" />
                                </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Referral Phone #:</strong>
                                <ul>
                                <li>
                                    <input type="text" value={this.state.refContact} onChange={this.handleInputChange} name="refContact" />
                                </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Relation:</strong>
                                <ul>
                                <li>
                                    <input type="text" value={this.state.refRelation} onChange={this.handleInputChange} name="refRelation" />
                                </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Your Email:</strong>
                                <ul>
                                <li>
                                    <input type="text" value={this.state.custContact} onChange={this.handleInputChange} name="custContact" />
                                </li>
                                </ul>
                            </li>
                        </ul>
                            <div style={{ textAlign: 'center' }}>
                                {/* <button className="btn-dark btn" onClick={this.handleReferralSubmit}>Refer!</button> */}
                                <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}}  
                                                        onClick={(e) => this.handleReferralSubmit(e)}>Submit
                                </button>
                            </div>
                            
                        </form>
                    </div>
                </div>
                <br />


            </div >



        )
    }
}


export default CreateReferral;
