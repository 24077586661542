import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import API from '../../utils/API';
import CreatePolicy from "../CreatePolicy"
import AutoPolicySummary from '../AutoPolicySummary'
import PropertyPolicySummary from '../PropertyPolicySummary'
import UmbrellaPolicySummary from '../UmbrellaPolicySummary'
import CreateVehicle from '../CreateVehicle'
import CreateDriver from '../CreateDriver'
// import API from '../../utils'


class EditClient extends Component {

    state = {
        clientSelected: "",
        clients: "",
        editClientMetadata: false
    }

    componentDidMount = () => {
        API.getClients()
        .then(res => {
            this.setState({ clients: res.data });
            console.log(res);
            console.log(this.state.clients)
        
        })
        .catch(err => console.log(err));


        API.getAgents()
        .then(res => {
            this.setState({ agents: res.data });
            console.log(res);
            console.log(this.state.agents)

        })
        .catch(err => console.log(err));

        setTimeout( () => {
            console.log(this.state)
        }, 5000)

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    populateClientData = event => {
        event.preventDefault()

        API.findClient(this.state.clientSelected)
            .then(res => {
                console.log("Found Client Data")
                console.log(res.data)
                this.setState({
                    clientData: res.data
                })
            },
                this.showClientData()
            )
    }

    showClientData = () => {
        if (this.state.clientSelected) {
            this.setState({
                showClientData: true
            })
        } else {
            alert("Need to select a client!")
        }
    }

    showCreateVehicle = () => {
        if (this.state.showCreateVehicle === true) {
            this.setState({
                showCreateVehicle: false
            })
        } else {
            this.setState({
                showCreateVehicle: true
            })
        }
    }

    showCreateDriver = () => {
        if (this.state.showCreateDriver === true) {
            this.setState({
                showCreateDriver: false
            })
        } else {
            this.setState({
                showCreateDriver: true
            })
        }
    }

    showAutoPolicies = () => {
        if (this.state.showAutoPolicies === true) {
            this.setState({
                showAutoPolicies: false
            })
        } else {
            this.setState({
                showAutoPolicies: true
            })
        }
    }

    showPropertyPolicies = () => {
        if (this.state.showPropertyPolicies === true) {
            this.setState({
                showPropertyPolicies: false
            })
        } else {
            this.setState({
                showPropertyPolicies: true
            })
        }
    }

    showUmbrellaPolicies = () => {
        if (this.state.showUmbrellaPolicies === true) {
            this.setState({
                showUmbrellaPolicies: false
            })
        } else {
            this.setState({
                showUmbrellaPolicies: true
            })
        }
    }

    editClientMetadata = (e) => {
        e.preventDefault()
        if (this.state.editClientMetadata === false){
            this.setState({
                editClientMetadata: true
            })
        } else {
            this.setState({
                editClientMetadata: false
            })
        }
    }

    saveClientMetadata = (e) => {
        e.preventDefault();

        // Code Goes Here

        if (this.state.editClientMetadata === true){
            this.setState({
                editClientMetadata: false
            })
        }
    }


    render() {
        return (

            < div >

                <div className="card-body" style={{textAlign: 'center'}}>
                    <h3>Edit Client</h3>
                    <p>Select the Client to Edit below</p>
                    <form>
                        <input list="clients" value={this.state.clientSelected} name="clientSelected" onChange={this.handleInputChange} />

                        {this.state.clients ?
                            <datalist id="clients">
                                {this.state.clients.map(client => (
                                    <option key={client._id} value={client._id}>{client.firstName} {client.lastName}</option>
                                ))}
                            </datalist>

                            : null}
                        <button className="btn btn-sm btn-info" onClick={this.populateClientData}>Search</button>
                    </form>

                    {this.state.clientData ?
                        <>
                            <div className="card">
                                <div className="card-header">
                                    <h4>Client Metadata</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.editClientMetadata ?
                                    <form>
                                        <input type="text" />
                                        <br />
                                        <button onClick={(e) => {this.saveClientMetadata(e)}}>Save</button>
                                    </form>
                                    
                                    : 
                                    
                                    <div>
                                        {/* <ol> */}
                                            <p>Client Phone: 610-299-9918</p>
                                            <p>Client Email: evan@fawncircle.com</p>
                                            <p>Client Agent #1: ''</p>
                                            <p>Client Agent #2: ''</p>
                                            <p>Client Agent #3: ''</p>
                                            <button onClick={(e) => {this.editClientMetadata(e)}}>Edit</button>
                                        {/* </ol> */}
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    {/* {this.state.clientData ? */}
                                    <h3>{this.state.clientData.firstName + " " + this.state.clientData.lastName}:</h3>
                                    {/* : null} */}
                                </div>
                                <div className="card-body">
                                    <CreatePolicy
                                        clientID={this.state.clientData._id}
                                        clientFirstName={this.state.clientData.firstName}
                                        clientLastName={this.state.clientData.lastName}
                                        agents={this.state.agents}
                                        rerender={this.populateClientData}
                                    />
                                </div>
                            </div>

                            <br />
                            <div className="card">
                                <div className="card-header">
                                    <h4>Auto Policies</h4>
                                    <button className="btn btn-success" onClick={this.showAutoPolicies}>Show / Hide</button>
                                </div>
                                <div className="card-body">
                                    {this.state.showAutoPolicies ?
                                        <div className="row">
                                            {this.state.clientData.autos ?
                                                <>
                                                    <div className="col-lg-12">
                                                        {this.state.clientData.autos.map(autoData => (
                                                            <AutoPolicySummary
                                                                key={autoData._id}
                                                                autoData={autoData}
                                                                admin={true}
                                                                colSize="col-lg-12"
                                                                agents={this.state.agents}
                                                            />
                                                        ))}
                                                    </div>

                                                    <div className="col-lg-12">

                                                        <div className="card bg-light" style={{ color: 'whitesmoke', width: '' }}>
                                                            <div className="card-header">
                                                                <button className="btn btn-dark" onClick={this.showCreateVehicle}>Create Vehicle</button>
                                                            </div>
                                                            <div className="card-body">
                                                                {this.state.showCreateVehicle ?
                                                                    <CreateVehicle
                                                                        autoData={this.state.clientData.autos}
                                                                        clientID={this.state.clientData._id}
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </div>

                                                        <br />

                                                        <div className="card bg-light" style={{ color: 'whitesmoke', width: '' }}>
                                                            <div className="card-header">
                                                                <button className="btn btn-dark" onClick={this.showCreateDriver}>Create Driver</button>

                                                            </div>
                                                            <div className="card-body">
                                                                {this.state.showCreateDriver ?
                                                                    <CreateDriver
                                                                        autoData={this.state.clientData.autos}
                                                                        clientID={this.state.clientData._id}
                                                                    />
                                                                    : null}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </>

                                                : <p>No Autos for this Client yet</p>}
                                        </div>
                                        : null}
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header">
                                    <h4>Property Policies</h4>
                                    <button className="btn btn-success" onClick={this.showPropertyPolicies}>Show / Hide</button>
                                </div>
                                {this.state.showPropertyPolicies ?
                                    <div className="card-body">
                                        {this.state.clientData.properties ?
                                            <>
                                                {this.state.clientData.properties.map(propertyData => (
                                                    <PropertyPolicySummary
                                                        key={propertyData._id}
                                                        propertyData={propertyData}
                                                        colSize={"col-lg-12"}
                                                        admin={true}
                                                        agents={this.state.agents}
                                                    />
                                                ))}
                                            </>

                                            : <p>No Properties for this Client yet</p>}
                                    </div>
                                    : null}
                            </div>
                            <br />

                            <div className="card">
                                <div className="card-header">
                                    <h4>Umbrella Policies</h4>
                                    <button className="btn btn-success" onClick={this.showUmbrellaPolicies}>Show / Hide</button>
                                </div>
                                <div className="card-body">
                                    {this.state.showUmbrellaPolicies ?
                                        <>
                                            <div className="col-lg-12"></div>
                                            {this.state.clientData.umbrellas ?
                                                <>
                                                    {this.state.clientData.umbrellas.map(umbrellaData => (

                                                        <div className="col-lg-12" style={{ textAlign: 'center' }} key={umbrellaData._id}>
                                                            <UmbrellaPolicySummary
                                                                umbrellaData={umbrellaData}
                                                                admin={true}
                                                                colSize={"col-lg-12"}
                                                                agents={this.state.agents}
                                                            />
                                                        </div>
                                                    ))}
                                                </>
                                                : <p>No Umbrellas for this client yet</p>}
                                            <div className="col-lg-3"></div>
                                        </>
                                        : null}
                                </div>
                            </div>

                        </>


                        : null}


                </div>


            </div >



        )
    }
}


export default EditClient;
