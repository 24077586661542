import React from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'

// function CarrierBanner() {
//     return (

//         <div className="banner" style={{width: "100%", overflowX: "scroll", height: '10%'}}>
//             <div className="logo-slider">
//             <ul>
//                 <li><img width="200" height="140px"  src="/images/logos/nationwide-logo.png" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px"  src="/images/logos/travelers-logo.png" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/openly-logo.png" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/progressive-logo.jpeg" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/plymouth-logo.png" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/massmutual-logo.jpg" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/farmers-logo.svg" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/clearcover-logo.png" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px" src="/images/logos/pure-logo.jpeg" alt="logo" className="logo"/></li>
//                 <li><img width="200" height="140px"  src="/images/logos/greatbay-logo.png" alt="logo" className="logo"/></li>
//             </ul>
               
//             </div>
//         </div>

//     );
// }

// export default CarrierBanner;

function CarrierBanner() {

    const logos = [
        "/images/logos/nationwide-logo.png",
        "/images/logos/farmers-logo.svg",
        "/images/logos/aaa-logo.png",
        "/images/logos/pure-logo.jpeg",
        "/images/logos/greatbay-logo.png",
        "/images/logos/travelers-logo.png",
        "/images/logos/progressive-logo.jpeg",
        "/images/logos/plymouth-logo.png",
        "/images/logos/openly-logo.png",
        "/images/logos/massmutual-logo.jpg",
        "/images/logos/clearcover-logo.png",
    ];

    return (
        <div className="banner" style={{width: "100%", overflowX: "scroll", height: '20%'}}>
            <div className="logo-slider">
                <ul>
                    {logos.map((logo, index) => 
                        <li key={index}><img width="200" height="150px"  src={logo} alt="logo" className="logo"/></li>
                    )}
                    {logos.map((logo, index) => 
                        <li key={index + logos.length}><img width="200" height="150px"  src={logo} alt="logo" className="logo"/></li>
                    )}
                </ul>
            </div>
        </div>
    );
}

export default CarrierBanner;