import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class QuoteCard extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: ""

    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.DOB,
            street: this.state.streetAddress,
            city: this.state.cityAddress,
            zip: this.state.zipAddress,
            state: this.state.stateAddress,
            email: this.state.email,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            mainAgent: "61cbd89ddf3ae329f85d2c42"
        }

        console.log(quoteData)



        API.saveQuote({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.DOB,
            street: this.state.streetAddress,
            city: this.state.cityAddress,
            zip: this.state.zipAddress,
            state: this.state.stateAddress,
            email: this.state.email,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            mainAgent: "5e4f50f7e0b6a8883ab6be4f"
        }).then(res => {
            console.log("Saved Quote!")
            console.log(res.data)
            alert("Thank you for your business! Quote has been saved. You will hear from us in a couple hours!")
        }
        ).catch(err => {
            console.log(err);
            alert("We're sorry your quote couldn't be saved, please call 610-355-7895 to relay your quote information")
        })

    }





    render() {
        return (
            <div>
                <div className="card" style={{border: '0px', boxShadow: '0px 0px 4px 4px black', borderRadius: '10px', background: 'transparent', backgroundColor: 'transparent'}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div className="card-header" style={{ textAlign: 'center', backgroundColor: 'white' }}>
                        <h2 className="card-header" style={{ color: this.props.text, backgroundColor: 'white', border: '0px' }}><strong>Quote Form</strong></h2>
                    </div>
                    <div className="card-body" style={{padding: '20px', backgroundImage: 'url(https://picsum.photos/800)',  backgroundSize: 'cover'}}>
                        {/* <div className="card-title">
                            <h4 className="contact-text" style={{ color: this.props.text }}>Please fill out the form below, and submit for a quote!</h4>
                        </div> */}
                        <form style={{ color: this.props.text }}>
                            <div className="row">
                                <div className="col" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label><h5><strong>First Name</strong></h5></label>
                                    <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="John" />
                                </div>
                                <div className="col" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label><h5><strong>Last Name</strong></h5></label>
                                    <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Jacobs" />
                                </div>
                                <div className="col" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label><h5><strong>Date of Birth</strong></h5></label>
                                    <input type="date" className="form-control" value={this.state.DOB} onChange={this.handleInputChange} name="DOB" />
                                </div>
                            </div>

                            <div className="form-row row">
                                <div className="col-md-8" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label htmlFor="inputAddress"><h5><strong>Street Address</strong></h5></label>
                                    <input type="text" className="form-control" value={this.state.streetAddress} onChange={this.handleInputChange} name="streetAddress" id="inputAddress" placeholder="1234 Main St" />
                                </div>

                                <div className="col-md-4" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label htmlFor="inputCity"><h5><strong>City</strong></h5></label>
                                    <input type="text" className="form-control" value={this.state.cityAddress} onChange={this.handleInputChange} name="cityAddress" id="inputCity" />
                                </div>
                            </div>

                            <div className="form-row row">

                                <div className="form-group col-md-4" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label htmlFor="inputState"><h5><strong>State</strong></h5></label>
                                    <select id="inputState" className="form-control" value={this.state.stateAddress} onChange={this.handleInputChange} name="stateAddress">
                                        <option defaultValue="N/A">Choose...</option>
                                        <option value="AL">Alabama</option>
                                        <option value="AK">Alaska</option>
                                        <option value="AZ">Arizona</option>
                                        <option value="AR">Arkansas</option>
                                        <option value="CA">California</option>
                                        <option value="CO">Colorado</option>
                                        <option value="CT">Connecticut</option>
                                        <option value="DE">Delaware</option>
                                        <option value="DC">District Of Columbia</option>
                                        <option value="FL">Florida</option>
                                        <option value="GA">Georgia</option>
                                        <option value="HI">Hawaii</option>
                                        <option value="ID">Idaho</option>
                                        <option value="IL">Illinois</option>
                                        <option value="IN">Indiana</option>
                                        <option value="IA">Iowa</option>
                                        <option value="KS">Kansas</option>
                                        <option value="KY">Kentucky</option>
                                        <option value="LA">Louisiana</option>
                                        <option value="ME">Maine</option>
                                        <option value="MD">Maryland</option>
                                        <option value="MA">Massachusetts</option>
                                        <option value="MI">Michigan</option>
                                        <option value="MN">Minnesota</option>
                                        <option value="MS">Mississippi</option>
                                        <option value="MO">Missouri</option>
                                        <option value="MT">Montana</option>
                                        <option value="NE">Nebraska</option>
                                        <option value="NV">Nevada</option>
                                        <option value="NH">New Hampshire</option>
                                        <option value="NJ">New Jersey</option>
                                        <option value="NM">New Mexico</option>
                                        <option value="NY">New York</option>
                                        <option value="NC">North Carolina</option>
                                        <option value="ND">North Dakota</option>
                                        <option value="OH">Ohio</option>
                                        <option value="OK">Oklahoma</option>
                                        <option value="OR">Oregon</option>
                                        <option value="PA">Pennsylvania</option>
                                        <option value="RI">Rhode Island</option>
                                        <option value="SC">South Carolina</option>
                                        <option value="SD">South Dakota</option>
                                        <option value="TN">Tennessee</option>
                                        <option value="TX">Texas</option>
                                        <option value="UT">Utah</option>
                                        <option value="VT">Vermont</option>
                                        <option value="VA">Virginia</option>
                                        <option value="WA">Washington</option>
                                        <option value="WV">West Virginia</option>
                                        <option value="WI">Wisconsin</option>
                                        <option value="WY">Wyoming</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-2" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label htmlFor="inputZip"><h5><strong>Zip</strong></h5></label>
                                    <input type="text" className="form-control" value={this.state.zipAddress} onChange={this.handleInputChange} name="zipAddress" id="inputZip" />
                                </div>
                                <div className="col-md-1" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>
                                <div className="form-group col-md-4"  style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label htmlFor="inputEmail4"><h5><strong>Email</strong></h5></label>
                                    <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" id="inputEmail4" placeholder="Email" />
                                </div>
                                <div className="col-md-1" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>
                            </div>
                            <div className="form-row row">
                                <div className="form-group col-md-6" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label><h6><strong>Please list Year, Make + Models of cars</strong></h6></label>
                                    <textarea value={this.state.vehiclesBody} onChange={this.handleInputChange} name="vehiclesBody" className="form-control" rows="5" cols="60" />
                                </div>
                                <div className="form-group col-md-6" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label><h6><strong>Please list Name, Birthdates of drivers</strong></h6></label>
                                    <textarea value={this.state.driversBody} onChange={this.handleInputChange} name="driversBody" className="form-control" rows="5" cols="60" />
                                </div>
                            </div>
                            <div className="form-row row">
                                <div className="form-group col-12" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <label><h6><strong>Additional Remarks (ex: 2nd homes, RV's, Boats, etc.)</strong></h6></label>
                                    <textarea value={this.state.quoteNotes} onChange={this.handleInputChange} name="quoteNotes" className="form-control" rows="5" cols="60" />
                                </div>
                            </div>
                            <div className="row" style={{ textAlign: 'center' }}>
                                <div className="col" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                    <button className="btn btn-lg btn-success" onClick={this.handleQuoteSubmit}>Submit for Quote</button>
                                    <br />
                                    <p style={{ fontSize: '16px' }}>*Please note that insurance carriers run consumer credit reports from when running your quotes. These do not have any impact on your credit, and the information gathered will be used strictly for the purposes of quoting your insurances only.</p>
                                </div>
                            </div>
                        </form>
                    </div>



                    {/* <div className="card-body">
                        <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button>
                    </div> */}
                </div>
            </div>
        )

    }

}

export default QuoteCard;