import React, { Component } from "react";
import "./style.css";
import Parralax from '../../components/Parralax';
import styled from "styled-components"
// import SmallParralax from '../../components/SmallParralax';
import Table from 'react-bootstrap/Table';
// import CreateServiceRequest from '../../components/CreateServiceRequest'
// import CreateReferral from '../../components/CreateReferral';
import API from '../../utils/API';
import AutoPolicySummary from '../../components/AutoPolicySummary'
import PropertyPolicySummary from '../../components/PropertyPolicySummary'
import UmbrellaPolicySummary from '../../components/UmbrellaPolicySummary'
import ClientToolbar from "../../components/ClientToolbar";
import cogoToast from 'cogo-toast';
import CreateReferral from "../../components/CreateReferral";
import Modal from "react-modal"
import FawnButtonMini from "../../components/FawnButtonMini";
import SourcedCreateServiceRequest from "../../components/SourcedCreateServiceRequest";
import dateFormat from 'dateformat';

const StyledButton = styled.button`
  width: 100%;
  padding: 10px 15px;
  background: linear-gradient(to right, #667eea 0%, #000080 100%);
  color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 1em;
  transition: background 0.6s;
  cursor: pointer;
  
  &:hover {
    background: linear-gradient(to right, #000080 0%, #667eea 100%);
  }
`;


const customStylesModal = {
    content: {
        padding: '5px 5px',
        backgroundImage: 'linear-gradient(to top, lightblue 0%, whitesmoke 100%)',
        height: '50%',
        marginLeft: '5%',
        border: '0px',
        textAlign: 'center',
        margin: '5%'
    }
}


class ClientPage extends Component {

    state = {
        showAutoPolicies: false,
        showPropertyPolicies: false,
        showUmbrellaPolicies: false,
        showRequestForm: true,
        openDocs: false,
        openAccount: true,
        openBilling: false,
        openOther: false,
        editUserData: false,
        telephone: "",
        email: "",
        mailingAddressVerify: "",
        showLinkPolicyModal: false,
        policies: [],
        carriers: [],
        carrierData: [],
        activeCarrierData: [],
        showActiveCarriers: false,
        mainAgentID: "",
        mainAgentName: "",
        mainAgentEmail: "",
        mainAgentPhone: "",
        servicingAgentID: "",
        servicingAgentName: "",
        servicingAgentEmail: "",
        servicingAgentPhone: "",
        supervisorAgentID: "",
        supervisorAgentName: "",
        supervisorAgentEmail: "",
        supervisorAgentPhone: ""
    }

    componentDidMount = () => {
        console.log("Landing Page Mounted")
        setTimeout(() => {
            this.getUserData();
        }, 1000)

        API.getCarriers()
        .then((res) => {
            console.log("Carriers Retrieved from API")
            console.log(res);
            this.setState({
                carrierData: res.data
            })
            console.log(this.state);
        })
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };
    getUserData = () => {

        var userID = this.props.user.uid
        API.findClientLogin(userID)
            .then(res => {
                console.log("Found Client Data:")
                console.log(res.data[0])
                if (res.data.length > 0){

                    this.setState({
                        userData: res.data[0],
                        telephone: res.data[0].telephone,
                        email: res.data[0].email
                    })

                    this.assessCarriers();
                    this.getAgentData("any");

                } else {
                 document.location.href = "/"   
                }
            })
            .catch(err => alert("Error finding user data"))

    }
    getAgentData = (policyType) => {
        let agentID = "";
        if (policyType === "any") {
            if (this.state.userData.autos.length > 0){
                console.log("Found primary agent: " + this.state.userData.autos[0].mainAgent);
                agentID = this.state.userData.autos[0].mainAgent;
            } else if (this.state.userData.properties.length > 0){
                console.log("Found primary agent: " + this.state.userData.properties[0].mainAgent);
                agentID = this.state.userData.properties[0].mainAgent;
            } else if (this.state.userData.umbrellas.length > 0){
                console.log("Found primary agent: " + this.state.userData.umbrellas[0].mainAgent);
                agentID = this.state.userData.umbrellas[0].mainAgent;
            } else {
                console.log("No agents found");
                agentID = "-1";
            }
        }

        if (policyType === "auto"){
            if (this.state.userData.autos.length > 0){
                console.log("Found primary agent: " + this.state.userData.autos[0].mainAgent);
                agentID = this.state.userData.autos[0].mainAgent;
            } else {
                console.log("No agent found");
                agentID = "-1";
            }
        }

        if (policyType === "property") {
            if (this.state.userData.properties.length > 0){
                console.log("Found primary agent: " + this.state.userData.properties[0].mainAgent);
                agentID = this.state.userData.properties[0].mainAgent;
            } else {
                console.log("No agent found");
                agentID = "-1";
            }
        }

        if (policyType === "umbrella"){
            if (this.state.userData.umbrellas.length > 0){
                console.log("Found primary agent: " + this.state.userData.umbrellas[0].mainAgent);
                agentID = this.state.userData.umbrellas[0].mainAgent;
            } else {
                console.log("No agent found");
                agentID = "-1";
            }
        }

        if (agentID === "-1" || agentID === "") {
            alert("No agent found for your policy type: " + policyType);
        } else {
            // Set up Main Agent data to display to client
            API.findAgent(agentID)
                .then(res => {
                    console.log("Found Agent Data:");
                    console.log(res.data);
                    console.log(res.data);
                    this.setState({
                        mainAgentID: res.data._id,
                        mainAgentName: res.data.firstName + " " + res.data.lastName,
                        mainAgentEmail: res.data.email,
                        mainAgentPhone: res.data.telephone
                    })

                    // Set up CSR data to display to client
                    if (res.data.dedicatedCSR !== undefined && res.data.dedicatedCSR === null){
                        API.findAgent(res.data.dedicatedCSR)
                            .then(res2 => {
                                console.log("Found Dedicated CSR Agent Data:");
                                console.log(res2.data);
                                this.setState({
                                    servicingAgentID: res2.data._id,
                                    servicingAgentName: res2.data.firstName + " " + res.data.lastName,
                                    servicingAgentEmail: res2.data.email,
                                    servicingAgentPhone: res2.data.telephone
                                })
                            })
                    } else {
                        this.setState({
                            servicingAgentID: res.data._id,
                            servicingAgentName: res.data.firstName + " " + res.data.lastName,
                            servicingAgentEmail: res.data.email,
                            servicingAgentPhone: res.data.telephone
                        })
                    }

                    // Set up Supervisor data to display to client if necessary, right now it's not
                    if (res.data.supervisor !== undefined && res.data.supervisor === null){
                        API.findAgent(res.data.supervisor)
                           .then(res3 => {
                                console.log("Found Supervisor Agent Data:");
                                console.log(res3.data);
                                this.setState({
                                    supervisorAgentID: res3.data._id,
                                    supervisorAgentName: res3.data.firstName + " " + res.data.lastName,
                                    supervisorAgentEmail: res3.data.email,
                                    supervisorAgentPhone: res3.data.telephone
                                })
                            })   
                    } else {
                        this.setState({
                            supervisorAgentID: res.data._id,
                            supervisorAgentName: res.data.firstName + " " + res.data.lastName,
                            supervisorAgentEmail: res.data.email,
                            supervisorAgentPhone: res.data.telephone
                        })
                    }

                })
                .catch(err => alert("Error finding agent data"))
        }
    }
    assessCarriers = () => {
        // This method looks through the client's autos, homes and umbrella policies and gathers the carrier data and assigns it to groups to be worked with by app
                // This is important because each carrier has it's own contact information, and also billing portals so we want to know which ones to make available to the customer -EC112823
        console.log("Assessing all Carriers");
        console.log("Autos: " + this.state.userData.autos.length);
        console.log("Properties: " + this.state.userData.properties.length);
        console.log("Misc: "  + this.state.userData.umbrellas.length);
        let policiesObjArray = []

        for (var i = 0; i < this.state.userData.autos.length; i++){
            policiesObjArray.push(this.state.userData.autos[i])

            if (i === this.state.userData.autos.length - 1){
                console.log("Finished Assessing Autos...")

                for (var j = 0; j < this.state.userData.properties.length; j++){
                    policiesObjArray.push(this.state.userData.properties[j])

                    if (j === this.state.userData.properties.length - 1){
                        console.log("Finished Assessing Properties...");
    
                        for (var k = 0; k < this.state.userData.umbrellas.length; k++){
                            policiesObjArray.push(this.state.userData.umbrellas[k])
    
                            if (k === this.state.userData.umbrellas.length - 1){
                                console.log("Finished Assessing Misc...")
                                console.log("Finished consolidating policies")
                                console.log(policiesObjArray); 

                                for (var l = 0; l < policiesObjArray.length; l++){
                                    if (this.state.carriers.indexOf(policiesObjArray[l].carrier) === -1){
                                        this.state.carriers.push(policiesObjArray[l].carrier)
                                        for (var m = 0; m < this.state.carrierData.length; m++){
                                            if (this.state.carrierData[m].name === policiesObjArray[l].carrier){
                                                this.state.activeCarrierData.push(this.state.carrierData[m])
                                            }
                                        }
                                    }
                                    if (l === policiesObjArray.length - 1){
                                        
                                        console.log("Active Carriers for: " + this.state.userData.firstName + " are: ");
                                        console.log(this.state.carriers);
                                        console.log(this.state.activeCarrierData)
                                        this.setState({
                                            showActiveCarriers: true
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    showAutoPolicy = (e) => {
        e.preventDefault()
        if (this.state.showAutoPolicies === true) {
            this.setState({
                showAutoPolicies: false
            })
        } else {
            this.setState({
                showAutoPolicies: true,
                showPropertyPolicies: false,
                showUmbrellaPolicies: false
            })
        }
    }
    showPropertyPolicy = (e) => {
        e.preventDefault()
        if (this.state.showPropertyPolicies === true) {
            this.setState({
                showPropertyPolicies: false
            })
        } else {
            this.setState({
                showAutoPolicies: false,
                showPropertyPolicies: true,
                showUmbrellaPolicies: false
            })
        }
    }
    showUmbrellaPolicy = (e) => {
        e.preventDefault()
        if (this.state.showUmbrellaPolicies === true) {
            this.setState({
                showUmbrellaPolicies: false
            })
        } else {
            this.setState({
                showAutoPolicies: false,
                showPropertyPolicies: false,
                showUmbrellaPolicies: true
            })
        }
    }
    showRequestForm = (e) => {
        e.preventDefault()
        if (this.state.showRequestForm === true) {
            this.setState({
                showRequestForm: false
            })
        } else {
            this.setState({
                showRequestForm: true
            })
        }
    }
    openDocs = (e) => {
        e.preventDefault()
        // alert("Showing Docs")
        if (this.state.openDocs === false){
            this.setState({
                openDocs: true,
                openAccount: false,
                openBilling: false,
                openOther: false
            })
        }
    }
    openAccount = (e) => {
        if (e){
            e.preventDefault()
        }

        // alert("Showing Account")
        if (this.state.openAccount === false){
            this.setState({
                openDocs: false,
                openAccount: true,
                openBilling: false,
                openOther: false
            })
        }
    }
    openBilling = (e) => {
        e.preventDefault()
        // alert("Showing Billing")
        if (this.state.openBilling === false){
            this.setState({
                openBilling: true,
                openDocs: false,
                openAccount: false,
                openOther: false
            })
        } 
    }
    openOther = (e) => {
        e.preventDefault()
        // alert("Showing Other")
        if (this.state.openOther === false){
            this.setState({
                openDocs: false,
                openAccount: false,
                openBilling: false,
                openOther: true
            })
        }
    }
    copyText = (event, data) => {
        console.log(this.state);
        event.preventDefault();
        if (data !== undefined){
            var copyText = data;
            navigator.clipboard.writeText(copyText);
            console.log(navigator.clipboard);
            cogoToast.success("Copied: " + data)
        } else {
            cogoToast.error("Couldn't copy data")
        }

    }

    editUserData = (event) => {
        event.preventDefault()
        console.log("Editing User Data");
        if (this.state.editUserData === false){
            this.setState({
                editUserData: true
            })
        } else {
            this.setState({
                editUserData: false
            })
        }
    }

    submitUserEditData = (e) => {
        console.log("Sending new User Data");
        console.log(this.state.userData);
        
        API.updateClientContact({
            id: this.state.userData._id,
            email: this.state.email,
            telephone: this.state.telephone
        }).then(res => {
            console.log("Updated data");
            console.log(res.data);
            cogoToast.success("Updated data successfully!")
        }).catch(err => {
            console.log(err)
            alert("Updated data was not submitted");
            cogoToast.error("Sorry, there was an error. Please try again!")
        })
        console.log(this.state);
    }
    showLinkPolicyModal = (e) => {
        if (this.state.showLinkPolicyModal === false){
            this.setState({
                showLinkPolicyModal: true
            })
        } else {
            this.setState({
                showLinkPolicyModal: false
            })
        }
    }
    searchForLinkedPolicies = () => { // Incomplete, possibly unnecessary
        console.log("Searching for Linked Policies associated with address: " + this.state.mailingAddressVerify)
    }



    render() {
        return (
            <div className="container-fluid auroral-northern">
            <Parralax />

            {this.state.userData ?
                    <div className="card" style={{ color: 'black', backgroundColor: 'transparent', backgroundZier: 'cover' }}>
                        <div className="card-header" style={{ textAlign: 'center', color: 'black', backgroundColor: 'rgba(255,255,255,0.8)' }}>
                            <h2><strong>Welcome {this.state.userData.firstName}!</strong></h2>
                            <p style={{fontSize: '1.2em'}}>Your agent is: {this.state.mainAgentName}</p>
                            <p style={{fontSize: '1.2em'}}>Their phone # is: {this.state.mainAgentPhone} <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.state.mainAgentPhone)}}></i></p>
                            <p style={{fontSize: '1.2em'}}>Their e-mail is: {this.state.mainAgentEmail} <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.state.mainAgentEmail)}}></i></p>
                            <ClientToolbar 
                            style={{height: '10%'}}
                            openDocs={(e) => {this.openDocs(e)}}
                            openAccount={(e) => {this.openAccount(e)}}
                            openBilling={(e) => {this.openBilling(e)}}
                            openOther={(e) => {this.openOther(e)}}
                        />

                        </div>

                    
                    <div className="card-body" style={{ height: '2000px', padding: '10px 0px', backgroundColor: 'transparent',  backgroundSize: 'cover'}}>


                        {this.state.openAccount ? 

                        <div style={{width: '100%', padding: '0px', margin: '0px'}}>

                                {/* <br /> */}

                                {/* <h3 style={{textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.75)', color: 'black', padding: '10px'}}>View Policy Details</h3> */}

                                <div className="row">
                                    <div className="col-lg-4 offset-lg-0 col-8 offset-2">
                                        {/* <StyledButton onClick={(e) => {this.showAutoPolicy(e)}}>Auto Policy</StyledButton> */}
                                        <FawnButtonMini 
                                        buttonText="View Auto"
                                        pf1={(e) => this.showAutoPolicy(e)}
                                      />
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-8 offset-2">
                                        <FawnButtonMini buttonText="View Home" pf1={(e) => this.showPropertyPolicy(e)} />
                                        {/* <StyledButton onClick={(e) => {this.showPropertyPolicy(e)}}>Home Policy</StyledButton> */}
                                    </div>
                                    <div className="col-lg-4 offset-lg-0 col-8 offset-2">
                                        <FawnButtonMini buttonText="View Umbrella" pf1={(e) => this.showUmbrellaPolicy(e)} />
                                        {/* <StyledButton onClick={(e) => {this.showUmbrellaPolicy(e)}}>Other Policy</StyledButton> */}
                                    </div>
                                </div>

                                {/* Policy data is shown using code below */}
                                <div style={{width: '100%', float: 'left'}}> 
                                    <div className="row">
                                        {this.state.showAutoPolicies ? 
                                            // <p>Auto Policies</p>
                                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                                                <div className="card" style={{overflow: 'auto', margin: '5px'}}>
                                                <div className="card-header" style={{ textAlign: 'center', color: 'black' }}>
                                                    <h4>Auto Policies</h4>
                                                    {/* <button className="btn-info btn" onClick={this.showAutoPolicy}>Toggle</button> */}
                                                </div>
                                                {this.state.showAutoPolicies ?
                                                    <div className="card-body" style={{backgroundColor: 'black', overflow: 'auto', textAlign: 'center'}}>
                                                        <div className="row">
                                                            {this.state.userData.autos ?
                                                                <>
                                                                    {this.state.userData.autos.map(autoData => (
                                                                        <AutoPolicySummary
                                                                            key={autoData._id}
                                                                            autoData={autoData}
                                                                            admin={false}
                                                                            colSize="col-lg-12"
                                                                        />
                                                                    ))}

                                                                </>

                                                                : <p>No Autos for this Client yet</p>}
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                            </div>
                                            
                                        : null}

                                        {this.state.showPropertyPolicies ? 
                                            // <p>Property Policies</p>
                                            // <div className="row">
                                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                                                <div className="card" style={{textAlign: 'center', margin: '2px'}}>
                                                    <div className="card-header" style={{ textAlign: 'center', color: 'black' }}>
                                                        <h4>Property Policies</h4>
                                                        {/* <button className="btn-info btn" onClick={this.showPropertyPolicy}>Toggle</button> */}
                                                    </div>
                                                        {this.state.showPropertyPolicies ?
                                                            <div className="card-body" style={{backgroundColor: 'black', overflow: 'auto'}}>
                                                                {this.state.userData.properties ?
                                                
                                                                    <>
                                                                        {this.state.userData.properties.map(propertyData => (
                                                                            <>
                                                                                <PropertyPolicySummary
                                                                                    key={propertyData._id}
                                                                                    propertyData={propertyData}
                                                                                    colSize="col-lg-12"
                                                                                    admin={false}
                                                                                />
                                                                                <br />
                                                                        </>
                                                                        ))}
                                                
                                                                </>
                                                
                                                                : <p>No Properties Insured with us yet!</p>}
                                                
                                                            </div>
                                                        : null}
                                                </div>
                                            </div>
                                            // </div>
                                          
                                        : null}

                                        {this.state.showUmbrellaPolicies ?
                                            // <p>Other Policies</p>
                                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12">
                                                <div className="card" style={{textAlign: 'center', margin: '2px'}}>
                                                        <div className="card-header" style={{ color: 'black'}}>
                                                            <h4>Umbrella Policies</h4>
                                                        </div>
                                                        {this.state.showUmbrellaPolicies ?
                                                            <div className="card-body" style={{backgroundColor: 'black', overflow: 'auto'}}>
                                                            {/* <div className="col-lg-3"></div> */}
                                                                {this.state.userData.umbrellas ?
                                                                    <>
                                                                        {this.state.userData.umbrellas.map(umbrellaData => (
                                                
                                                                            <div className="col-lg-12" style={{ textAlign: 'center' }}>
                                                                            <UmbrellaPolicySummary
                                                                                key={umbrellaData._id}
                                                                                umbrellaData={umbrellaData}
                                                                                    admin={false}
                                                                                    colSize={"col-lg-12"}
                                                                                />
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                    : <p>No Umbrellas for this client yet</p>}
                                                                {/* <div className="col-lg-3"></div> */}
                                                            </div>
                                                        : null}
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div className="row">

                                    <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-10 offset-1">
                                    <div className="card" style={{ overflow: 'auto', padding: '5px', backgroundColor: 'transparent'}}>
                                        <div className="card-header" style={{ overflow: '', color: 'black', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.8)' }}>
                                           <h2>Policy Assistance</h2>
                                        </div>
                                        <div className="card-body" style={{ height: '100%', padding: '10px', fontSize: '1.2em', backgroundColor: 'rgba(255,255,255,0.7)', color: 'black' }}>

                                            {/* <ol> */}
                                                <li><strong>Account Manager:</strong> 
                                                    <ul><li>{this.state.servicingAgentName}</li></ul></li>
                                                <li><strong>Account Manager Phone #:</strong> <br />   
                                                    <ul><li>{this.state.servicingAgentPhone} <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.state.servicingAgentPhone)}}></i>  </li></ul> </li>
                                                <li><strong>Account Manager Email:</strong>   <br />   
                                                    <ul><li>  {this.state.servicingAgentEmail} <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.state.servicingAgentEmail)}}></i></li></ul> </li>
                                                <li>
                                                    <strong>Carriers: </strong>
                                                    <ul>
                                                        {this.state.carriers.map(carrier => (
                                                            <li>
                                                                {carrier}
                                                            </li>
                                                        ))}    
                                                    </ul>

                                                </li>
                                                <li><strong>Primary Insured:</strong> 
                                                    <ul><li>{this.state.userData.firstName} {this.state.userData.lastName}</li></ul></li>
                                                <li><strong>Your best contact Email:</strong>
                                                    <ul><li> {this.state.userData.email} <i className="fa fa-pencil" aria-hidden="true" onClick={(e) => {this.editUserData(e)}}></i></li></ul> </li>
                                                <li><strong>Your best contact Phone #:</strong>
                                                    <ul><li>{this.state.userData.telephone} <i className="fa fa-pencil" aria-hidden="true" onClick={(e) => {this.editUserData(e)}}></i></li></ul></li>
                                                
                                            {/* </ol> */}

                                        </div>
                                    </div>
                                </div>


                                </div>
                               


                                <br />

                                {this.state.showActiveCarriers ? 
                                

                                <div className="row">
                                    {/* <div className="col-md-4 col-sm-2"></div> */}

                                    {this.state.activeCarrierData.map(carrierData => (
                                        <div className="card bg-dark text-white col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-10 offset-1" style={{marginBottom: '10px'}}>
                                            <div className="card-header" style={{textAlign: 'center'}}>
                                                <h3>{carrierData.name} Resources</h3>
                                            </div>
                                            <div className="card-body" style={{fontSize: '1.2em'}}>
                                                <ol>
                                                    <li>Service Phone #: {carrierData.servicePhone}</li>
                                                    <li>Claims Phone #: {carrierData.claimsPhone}</li>
                                                    <li>Service Portal <a target="_blank" rel="noopener noreferrer" href={carrierData.serviceURL}>{carrierData.name}</a></li>
                                                </ol>
                                            </div>
                                        </div>
                                        
                                        // <p>{carrierData}</p>
                                    )
                                    )}

                                </div>
                           

                                :
                                
                                <p> No Active Carrier Data yet!</p>}

                              

                        </div>
                    
                        : null}

                        {this.state.openDocs ? 
                        <div className="row">
                            {/* <div className="col-6 offset-3" style={{width: '10%', height: '100px', float: 'left'}}></div> */}
                            
                            <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-10 offset-1">
                            
                                <div className="card" style={{padding: '0px', backgroundColor: 'rgba(255,255,255,0.6)'}}>
                                    {/* <div className="card-header" style={{ color: 'black', textAlign: 'center' }}> */}
                                        {/* <h4>Document Request Form</h4> */}
                                        {/* <button className="btn btn-outline-info" onClick={this.showRequestForm} style={{ textAlign: 'center' }}>Show</button> */}
                                        {/* <p>*Changes in coverage can not be made via this tool, please call us for that.</p> */}
                                    {/* </div> */}

                                    {this.state.showRequestForm ?
                                        <div className="card-body" style={{height: '100%', backgroundColor: 'transparent', padding: '0px'}}>
                                            <SourcedCreateServiceRequest 
                                                uid={this.state.userData._id}
                                                sourceType='client'
                                                source={this.state.userData._id}
                                                sourceEmail={this.state.userData.email}
                                                sourcePhone={this.state.userData.telephone}
                                                sourceReturn={this.openAccount}
                                                sourceName={this.state.userData.firstName + this.state.userData.lastName}
                                            />
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-10 offset-1" style={{textAlign: 'center'}}>
                                                        <h5>Need to make changes?</h5>
                                                        <p style={{margin: '0px'}}>Call us at: 6102999918 <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, '6102999918')}}></i></p>
                                                        <a className="btn btn-info" href="tel:6102999918"><h6 style={{margin: '0px'}}>Call now</h6></a>
                                                        <br />
                                                        <br />
                                                        
                                                        <p style={{margin: '0px'}}>Email us at: evan@fawncircle.com  <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, 'evan@fawncircle.com')}}></i></p>
                                                        <a className="btn btn-info" href="mailto:evan@fawncircle.com" target="_blank" rel="noopener noreferrer"><h6 style={{margin: '0px'}}>Email now</h6></a>
                                                        
                                                
                                                </div>
                                            </div>
                                    </div>
                                        </div>


                                        : null}
                                </div>
                                <br />
                            </div>
                            {/* <div style={{width: '10%', height: '100px', float: 'left'}}></div> */}

                            <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="card" style={{padding: '0px', backgroundColor: 'transparent'}}>
                                    <div className="card-header" style={{backgroundColor: 'rgba(255,255,255,0.8)'}}>
                                        <h4>Service Requests</h4>
                                    </div>
                                    <div className="card-body" style={{backgroundColor: 'rgba(255,255,255,0.7)'}}>
                                        <table striped>
                                            <tr>
                                                <th>Requested</th>
                                                <th>Message</th>
                                                <th>Type</th>
                                                <th>Resolved?</th>
                                            </tr>
                                            {this.state.userData.servicerequests.map(reqData => (
                                                <tr>
                                                    <td>{dateFormat(reqData.created_at, "mmmm dS, yyyy")}</td>
                                                    <td>{reqData.message}</td>
                                                    <td>{reqData.type}</td>
                                                    <td>{reqData.completed}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                
                                </div>
                            </div>

                        </div>
                        : null}


                          
                            <br />
                    
                        {this.state.openBilling ? 

                        <div className="row">
                        
                        <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="card" style={{padding: '0px', backgroundColor: 'transparent'}}>
                                    <div className="card-header" style={{ color: 'white', textAlign: 'center', backgroundColor: 'rgba(0,0,0,0.8)' }}>
                                        <h2>Billing Summary</h2>
                                        {/* <button className="btn btn-outline-info" onClick={this.showRequestForm} style={{ textAlign: 'center' }}>Show</button> */}
                                        <p>*Billing shown here may be outdated, please see carrier for real-time billing information.</p>
                                    </div>
                                        <div className="card-body" style={{height: '100%', color: 'white', fontSize: '1.2em', backgroundColor: 'rgba(0,0,0,0.5)', padding: '10px'}}>
                                            <div className="row">
                                                <div className="col-10 offset-1">
                                                <ul>
                                                    <li>
                                                        <strong>Policy Type:</strong>
                                                        <ul>
                                                            <li>Auto</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Policy Number:</strong>
                                                        <ul>
                                                            <li>328957927-0</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Billing Method:</strong>
                                                        <ul>
                                                            <li>Automatic EFT</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Effective Date:</strong>
                                                        <ul>
                                                            <li>01/01/2022</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Carrier Portal:</strong>
                                                        <ul>
                                                            <li>
                                                                <a href="https://google.com" target="_blank" rel="noopener noreferrer">Billing Portal 1</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>EZ Lynx Portal:</strong>
                                                        <ul>
                                                            <li>
                                                                <a href="https://yahoo.com" target="_blank" rel="noopener noreferrer">Billing Portal 2</a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                                </div>
                                            </div>
                                            <div className="row" style={{backgroundColor: 'transparent', padding: '5px'}}>
                                                <div className="col-10 offset-1" style={{textAlign: 'center'}}>
                                                    <h5>Need to make changes?</h5>
                                                    <p style={{margin: '0px'}}>Call us at: 6102999918 <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, '6102999918')}}></i></p>
                                                    <a className="btn btn-info" href="tel:6102999918"><h6 style={{margin: '0px'}}>Call now</h6></a>
                                                    <br />
                                                    <br />
                                                    
                                                    <p style={{margin: '0px'}}>Email us at: evan@fawncircle.com  <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, 'evan@fawncircle.com')}}></i></p>
                                                    <a className="btn btn-info" href="mailto:evan@fawncircle.com" target="_blank" rel="noopener noreferrer"><h6 style={{margin: '0px'}}>Email now</h6></a>
                                                    
                                                </div>
                                            </div>
                                            </div>

                                  
                                </div>
                                <br />
                            </div>

                        </div>
                
                        : null}

                        {this.state.openOther ?
                            <div className="row">

                                <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-10 offset-1">
                                    <div className="card" style={{padding: '0px', backgroundColor: 'transparent'}}>
                                        <div className="card-header" style={{ color: 'black', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.8)' }}>
                                            <h2>Other Tools</h2>
                                            {/* <button className="btn btn-outline-info" onClick={this.showRequestForm} style={{ textAlign: 'center' }}>Show</button> */}
                                            <p>Here are some other helpful options</p>

            
                                        </div>

                                        <div className="card-body" style={{height: '100%', color: 'black', backgroundColor: 'rgba(255,255,255,0.7)', padding: '10px', textAlign: 'center'}}>
                                            <h4>Sign up for a AAA Membership below!</h4>
                                            <a href="https://cluballiance.aaa.com/insurance/ia?promo=FAWN&ian=Fawn%20Circle%20LLC" target="_blank" rel="noopener noreferrer">
                                                <img width="200" height="140px" src="/images/logos/aaa-logo.png" alt="AAA Membership Referral Link"/>
                                            </a>
                                        </div>
                    
                                         <div className="card-body" style={{height: '100%', backgroundColor: 'rgba(255,255,255,0.75)', padding: '10px', textAlign: 'center'}}>


                                                {/* <div className="row"> */}
                                                    {/* <div className="col-12" style={{ marginTop: '20px' }}> */}
                                                        <CreateReferral 
                                                            uid={this.state.userData._id}
                                                        />
                                                    {/* </div> */}
                                            {/* </div> */}

                                            <h5>Phone: 6102999918 <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, '6102999918')}}></i></h5>
                                            <a className="btn btn-info" href="tel:6102999918"><h6 style={{margin: '0px'}}>Call now</h6></a>
                                            <br />
                                                
                                            <h5>Email: evan@fawncircle.com  <i className="fa-solid fa-copy" onClick={(e) => {this.copyText(e, 'evan@fawncircle.com')}}></i></h5>
                                            <a className="btn btn-info" href="mailto:evan@fawncircle.com" target="_blank" rel="noopener noreferrer"><h6 style={{margin: '0px'}}>Email now</h6></a>
                                        </div>

                                    
                                    </div>
                                </div>

                            </div>
                    
                        
                        : null}
                            
                    </div>

                </div>
            : null}

            <Parralax />
             {/* Edit Info Modal */}
             <Modal
                    isOpen={this.state.editUserData}
                    onRequestClose={(e) => {this.editUserData(e)}}
                    style={customStylesModal}
                >
                    <div style={{textAlign: 'center'}}>

                        {this.state.userData ? 
                            <form style={{marginTop: '20%', width: '75%', marginLeft: '12.5%', color: 'black'}}>
                                <h3>Edit Your Info</h3>
                                <p>*Please note, changing your email will not change the email you use to login</p>
                                <br />
                                        <label><h5>Email</h5></label>
                                        <br />
                                        <input type="text" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" />
                                        <br />
                                        <label><h5>Phone Number</h5></label>
                                        <input type="tel" className="form-control" value={this.state.telephone} onChange={this.handleInputChange} name="telephone" />
                                        <br />
                            
                            
                    

                                <FawnButtonMini 
                                    buttonText="Submit"
                                    pf1={this.submitUserEditData}
                                />
                            </form>
                        : null }
                    </div>
                </Modal>
        </div>
        )

    }

}

export default ClientPage;