import React, { Component } from 'react';
import './style.css'

class AIForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  componentDidMount = () => {
    console.log(this.props);
    for (var i = 0; i < this.props.formData.length; i++){
        let formDataInputRead = this.props.formData[i].inputName
        this.setState({
            [formDataInputRead]: ''
        })
        if (i === this.props.formData.length - 1){
            console.log("Finished generating state");
            console.log(this.state);
        }
    }
  }

  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
    // console.log(this.state);

  }

  render() {
    return (
      <div className="formContainer">
        <h3>{this.props.formTitle}</h3>
        <form className="form">

        {this.props.formData.map((form, i) => (
            <div key={i} className="form-input">
                <label>{form.inputLabel}</label>
                <input 
                    type={form.inputType}
                    name={form.inputName}
                    onChange={(e) => this.handleChange(e)}
                />
            </div>
        ))}

        <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                onClick={(e) => this.props.returnFormData(e, this.state)}>Submit
                                                {/*      onClick={(e) => this.handleServiceReqSubmit(e)}>Submit */}
        </button>


{/*   


          <div className="form-input">
            <label>Text Field: </label>
            <input
              type="text"
              name="textField"
              value={this.state.textField}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-input">
            <label>Number Field: </label>
            <input
              type="number"
              name="numberField"
              value={this.state.numberField}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-input">
            <label>Date Field: </label>
            <input
              type="date"
              name="dateField"
              value={this.state.dateField}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-input">
            <label>Password Field: </label>
            <input
              type="password"
              name="passwordField"
              value={this.state.passwordField}
              onChange={this.handleChange}
            />
          </div> */}
        </form>
      </div>
    )
  }
}

export default AIForm;