import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup'
import './style.css'
import API from '../../utils/API';
import cogoToast from 'cogo-toast';
import { storage } from '../../config/Fire';


class CreateServiceRequestAgent extends Component {
    state = {
        serviceReqType: "",
        serviceReqName: "",
        serviceReqEmail: "",
        serviceReqBody: "",
        url: "",
        declaration: "",
        clientID: "",
        clients: []
    }

    componentDidMount = () => {
        console.log(storage);
        console.log(this.props);
        
    }

    // getBase64(file) {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function () {
    //       console.log(reader.result);
    //     };
    //     reader.onerror = function (error) {
    //       console.log('Error: ', error);
    //     };
    //  }

    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleServiceReqSubmit = event => {
        event.preventDefault()
        console.log("Submitting Service Request....")
        console.log("File; ");
        console.log(this.state);
        console.log(this.state.declaration)

        var serviceReqData = {
            type: this.state.serviceReqType,
            name: this.state.serviceReqName,
            requester: "Servicing",
            email: this.state.serviceReqEmail,
            message: this.state.serviceReqBody,
            fileData: this.state.declaration,
            client: this.state.clientID
        }

            console.log(serviceReqData)

        API.saveServiceRequest(serviceReqData).then(res => {
            console.log("Saved Service Request")
            alert("Service Request Saved!");
            cogoToast.success("Service Request Saved!")
        }

            ).catch(err => console.log(err))

    }
    

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    declaration: res
                })
            })
        }


            // this.setState({
            //     declaration: this.getBase64(event.target.files[0])
            // })
       
    }

    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        console.log(uploadTask);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        url: urlB,
                        uploaded: true
                    })
                    console.log("Upload Successful!");
                    console.log(this.state);
                    console.log(this.state.url);
                    // Run code to create a retrieval map in the database, add prikey to clients
                })
            }
        )
    }

    render() {
        return (

            < div className="card" style={{backgroundColor: 'rgba(0,0,0,0.75)', borderRadius: '5px' }}>
                <div className="card-header" style={{textAlign: 'center', backgroundColor: 'transparent'}}>
                    <h3 style={{color: 'whitesmoke'}}>Client Service Request</h3>
                </div>

                <div className="card-body" style={{backgroundColor: 'transparent'}}>
                <div className="row">

                    <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

                        {/* <h3>How may we serve you today?</h3> */}
                        <ListGroup className="list-group-flush">
                            <form>
                                <ListGroup.Item>
                                    <label>Type of Request</label><br />
                                    <select style={{width: '100%'}} value={this.state.serviceReqType} onChange={this.handleInputChange} name="serviceReqType">
                                        <option value=""></option>
                                        <option value="billing">Billing</option>
                                        <option value="general">General</option>
                                        <option value="req-mortgage-doc">Lienholder</option>
                                        <option value="req-other">Other Request</option> 
                                        <option value="renewal-quote">New Quote</option>
                                    </select>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Customer Name</label>
                                    <input value={this.state.serviceReqName} onChange={this.handleInputChange} type="text" name="serviceReqName" className="form-control" />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Choose Client</label>
                                <input list="clients" value={this.state.clientID} name="clientID" onChange={this.handleInputChange} />
                                    {this.props.clients ?
                                        <datalist id="clients">
                                            {this.props.clients.map(client => (
                                                <option key={client._id} value={client._id}>{client.firstName} {client.lastName}</option>
                                            ))}
                                        </datalist>

                                    : null}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Customer E-mail</label>
                                    <input value={this.state.serviceReqEmail} onChange={this.handleInputChange} type="email" name="serviceReqEmail" className="form-control" />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>How may we help you?</label>
                                    <textarea value={this.state.serviceReqBody} onChange={this.handleInputChange} name="serviceReqBody" className="form-control" rows="5" cols="30" />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Please upload any related documentation (optional)</label>
                                    <input name="fileData" type="file" onChange={this.fileSelectedHandler} />
                                        {/* <button onClick={this.fileUploadHandlerB}>Upload
                                            <span>
                                                {this.state.uploaded === false ? <img alt="loading" src="/loading.gif" /> : null}
                                                {this.state.uploaded === true ? <img alt="checkmark" src="/checkmark.png" style={{ height: '20px', width: '20px' }} /> : null}
                                            </span>
                                    </button> */}
                                </ListGroup.Item>
                                <ListGroup.Item style={{textAlign: 'center'}}>
                                    {/* <button onClick={this.handleServiceReqSubmit} className="btn btn-info">Send Message</button> */}
                                    <button type="button" style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                onClick={(e) => this.handleServiceReqSubmit(e)}>Submit
                                            </button>
                                </ListGroup.Item>
                            </form>
                        </ListGroup>

                    </div>
                </div>
                    
                </div>


            </div >



        )
    }
}


export default CreateServiceRequestAgent;
