import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class QuoteCard extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.DOB,
            street: this.state.streetAddress,
            city: this.state.cityAddress,
            zip: this.state.zipAddress,
            state: this.state.stateAddress,
            email: this.state.email,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.props.agentData._id,
            refAgentTwo: this.state.refAgentTwo
        }

        console.log(quoteData)



        API.saveQuote({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.DOB,
            street: this.state.streetAddress,
            city: this.state.cityAddress,
            zip: this.state.zipAddress,
            state: this.state.stateAddress,
            email: this.state.email,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.props.agentData._id,
            refAgentTwo: this.state.refAgentTwo
        }).then(res => {
            console.log("Saved Quote!")
            console.log(res.data)
        }
        ).catch(err => console.log(err))

    }





    render() {
        return (
            <div>
                <div className="card bg-light">
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div className="card-header" style={{ textAlign: 'center' }}>
                        <h2 style={{ color: this.props.text }}><strong>Quote Form</strong></h2>
                        {/* <h4 style={{ color: this.props.text }}>Please fill out the form below and submit for a quote!</h4> 
                        <h4 style={{color: this.props.text }}>We will get back to you shortly with various rates from the marketplace.</h4> */}
                    </div>
                    <div className="card-body" style={{padding: '20px',  backgroundSize: 'cover'}}>
                    

                    <form style={{ color: this.props.text }}>
                        <div className="row">
                            <div className="col-lg-3 col-md-6" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label ><h6><strong>First Name</strong></h6></label>
                                <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="John" />
                            </div>
                            <div className="col-lg-3 col-md-6" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h6><strong>Last Name</strong></h6></label>
                                <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Jacobs" />
                            </div>
                            <div className="col-lg-6 col-md-12" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>Date of Birth</strong></h5></label>
                                <input type="date" className="form-control" value={this.state.DOB} onChange={this.handleInputChange} name="DOB" />
                            </div>
                        </div>

                        <div className="form-row row">
                            <div className="col-md-2"  style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>
                            <div className="col-md-3" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>Select Specialist</strong></h5></label>
                                {this.props.agents ? <div>
                                    <select id="protegeDropMenu" value={this.state.chosenAgent} onChange={this.handleInputChange} name="chosenAgent">
                                        <option value="">--Select an Agent--</option>
                                        {this.props.agents.map(agent => (
                                            <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                        ))}
                                    </select>

                                </div> : <div>No agents yet</div>}
                            </div>
                            <div className="col-md-2"  style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>
                            <div className="col-md-3" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>Partnering Agent</strong></h5></label>
                                {this.props.agents ? <div>
                                    <select id="protegeDropMenu" value={this.state.refAgentTwo} onChange={this.handleInputChange} name="refAgentTwo">
                                        <option value="">--Select an Agent--</option>
                                        {this.props.agents.map(agent => (
                                            <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                        ))}
                                    </select>
                                </div>
                                    : <div>No agents yet</div>}
                            </div>
                            <div className="col-md-2"  style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>

                        </div>

                        <div className="form-row row">
                        <div className="col-lg-8" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>Street Address</strong></h5></label>
                                <input type="text" className="form-control" value={this.state.streetAddress} onChange={this.handleInputChange} name="streetAddress" id="inputAddress" placeholder="1234 Main St" />
                            </div>

                            <div className="col-lg-4" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>City</strong></h5></label>
                                <input type="text" className="form-control" value={this.state.cityAddress} onChange={this.handleInputChange} name="cityAddress" id="inputCity" />
                            </div>
                        </div>

                        <div className="form-row row">

                            <div className="form-group col-md-4" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>State</strong></h5></label>
                                <select id="inputState" className="form-control" value={this.state.stateAddress} onChange={this.handleInputChange} name="stateAddress">
                                    <option value="">Choose...</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                            </div>
                            <div className="form-group col-md-2" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h5><strong>Zip</strong></h5></label>
                                <input type="text" className="form-control" value={this.state.zipAddress} onChange={this.handleInputChange} name="zipAddress" id="inputZip" />
                            </div>
                            <div className="col-md-1"  style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>
                            <div className="form-group col-md-4" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label ><h5><strong>Email</strong></h5></label>
                                <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" id="inputEmail4" placeholder="Email" />
                            </div>
                            <div className="col-md-1"  style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}></div>
                        </div>
                        <div className="form-row row">
                            <div className="form-group col-md-6" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h6><strong>Please list Year, Make and Models of vehicles</strong></h6></label>
                                <textarea value={this.state.vehiclesBody} onChange={this.handleInputChange} name="vehiclesBody" className="form-control" rows="5" cols="60" />
                            </div>
                            <div className="form-group col-md-6" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h6><strong>Please list Name, Birthdates of drivers</strong></h6></label>
                                <textarea value={this.state.driversBody} onChange={this.handleInputChange} name="driversBody" className="form-control" rows="5" cols="60" />
                            </div>
                        </div>
                        <div className="form-row row">
                            <div className="form-group col-12" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '5px'}}>
                                <label><h6><strong>Additional Remarks (ex: 2nd homes, RV's, Boats, etc.)</strong></h6></label>
                                <textarea value={this.state.quoteNotes} onChange={this.handleInputChange} name="quoteNotes" className="form-control" rows="5" cols="60" />
                            </div>
                        </div>
                        <div className="row" style={{ textAlign: 'center' }}>
                                    
                            <div className="col" style={{backgroundColor: 'rgba(255,255,255,0.6)', padding: '30px'}}>
                                <button className="btn btn-lg btn-success" onClick={this.handleQuoteSubmit}>Submit for Quote</button>
                                <br />
                                <h7 style={{ fontSize: '16px' }}>*Please note that insurance carriers run consumer credit reports from when running your quotes. These do not have any impact on your credit, and the information gathered will be used strictly for the purposes of quoting your insurances only.</h7>
                            </div>
                        </div>
                    </form>
                    </div>



                    {/* <div className="card-body">
                        <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button>
                    </div> */}
                </div>
            </div>
        )

    }

}

export default QuoteCard;