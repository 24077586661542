import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
import { storage } from '../../config/Fire';
import cogoToast from 'cogo-toast';


class AgentUpdateForm extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        residentState: "",
        address: ""
    }

    componentDidMount = () => {
        console.log("Agent Update  Form Component Launched with props:")
        console.log(this.props.agentData);
        this.setState({
            firstName: this.props.agentData.firstName,
            lastName: this.props.agentData.lastName,
            email: this.props.agentData.email,
            telephone: this.props.agentData.telephone,
            residentState: this.props.agentData.residentState,
            address: this.props.agentData.address
        })
        console.log("Agent Update Form State")
        console.log(this.state);
        setTimeout(() => {
            console.log(this.state);
        }, 3000)
        // this.state.editableData = this.props.policyData
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };


    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    proposalDoc: res
                })
            })
        }
    }


    fileSelectedHandler1 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    quote1Doc: res
                })
            })
        }
    }


    fileSelectedHandler2 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    quote2Doc: res
                })
            })
        }
    }


    fileSelectedHandler3 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    quote3Doc: res
                })
            })
        }
    }


    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        url: urlB,
                        uploaded: true
                    })
                })
            }
        )
    }

    handleQuoteUpdate = (event) => {
        event.preventDefault();
        console.log("Submitting Quote Update");
        console.log(this.state);

        let quoteInformation = this.state;

        if (this.state.quoted === 'on'){
            this.setState({quoted: true})
        } else if (this.state.quoted === 'off'){
            this.setState({quoted: false})
        }

        console.log(quoteInformation)

        setTimeout(() => {

            API.updateQuote(this.props.quoteData._id, quoteInformation)
            .then(res => {
                console.log("Updated Quote!")
                console.log(res.data)
                alert("Updated Quote")
                cogoToast.success("Quote has been updated");
            })
            .catch(err => console.log(err))


        }, 1000)
    }

    handleAgentUpdate = (event) => {
        event.preventDefault();
        console.log("Submitting Agent Update");
        console.log(this.state);

        let agentInformation = this.state

        API.updateAgent(this.props.agentData._id, agentInformation)
            .then(res => {
                console.log("Updated Agent!");
                console.log(res.data);
                alert("Updated Agent!");
                cogoToast.success("Agent has been updated")
            })
            .catch(err => console.log(err))
    }

    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
        
    }


    render() {
        return (

            < div >

                <div className="card">
                    {/* <div className="card-header"> */}
                        <h3 style={{ textAlign: 'center' }}>Update Agent</h3>
                        {/* <p>
                        </p> */}
                    {/* </div> */}
                    
                    <div className="card-body">
                        <form>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td><strong>Question</strong></td> <td><strong>Input</strong></td>
                                    </tr> */}
                                     <tr>
                                        <td>First Name</td>
                                        <td><input type="text" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="First Name" /></td>
                        
                                        <td>Last Name</td>
                                        <td><input type="text" value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Last Name" /></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td><input type="text" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="E-mail" /></td>
                                        <td>Phone</td>
                                        <td><input type="text" value={this.state.telephone} onChange={this.handleInputChange} name="telephone" placeholder="Phone #"/></td>

                                    </tr>
                                   
                                    {/* <tr>
                                        <td>Effective Date</td>
                                        <td><input type="date" value={this.state.editableData.effectiveDate.toString().split("T")[0]} onChange={this.handleInputChange} name="effectiveDate" placeholder="effectiveDate" />
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>Resident State</td>
                                        <td><input type="text" maxLength="2" value={this.state.residentState} onChange={this.handleInputChange} name="residentState" placeholder="Resident State" /></td>
                                        <td>Full Address</td>
                                        <td><input type="text" value={this.state.address} onChange={this.handleInputChange} name="address" placeholder="Complete Address" /></td>
                                        
                                    </tr>
                                

                                </tbody>
                            </Table>
                          
                            <div style={{ textAlign: 'center' }}>
                                <button className="btn-success btn-sm btn" onClick={(e) => this.handleAgentUpdate(e)}>Update Agent</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div >



        )
    }
}


export default AgentUpdateForm;
