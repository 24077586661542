import React, { Component } from "react";
import "./style.css";
import Modal from "react-modal";
import PublicQuoteCard from "../../components/PublicQuoteCard";
import SignupCard from "../../components/SignupCard";
import LoginCard from '../../components/LoginCard';
import CreateServiceRequest from "../../components/CreateServiceRequest";
import ContactCard from "../../components/ContactCard";
import QuoteCard from "../../components/QuoteCard";


const customStylesModal = {
    content: {
        padding: '2px 2px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        background: 'transparent',
        overflowX: 'auto',
        border: '0px'
    }
}

class AuthPage extends Component {

    state = {
        showAuth: true,
        showLogin: true,
        showSignup: false,
        showQuote: false,
        showBadges: false,
        showQuoteModule: false,
        mobile: false,
        showCreateServiceRequest: false,
        showFastQuote: false
    }

    status = {
        QA: {
            questions: [],
            answers: [],
        }
    }


   
    alternatingColor = ['white', 'white'];
    alternatingText = ['black', 'black'];


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };


    componentDidMount = () => {
        // console.log("Landing Page")
        // API.getAdmins()
        //     .then(res => 
        //         {console.log(res.data)
        //         })
        //     .catch(err => console.log(err));
        // let defaultQuestionRef = this.status.activeDialogue.activeQuestion;
        // let defaultAnswersRef = this.status.activeDialogue.activeAnswers;
        // console.log("Getting Question Ref: " + defaultQuestionRef);
        // console.log("Getting Answers Ref: " + defaultAnswersRef);

    // 768px portrait
            if (window.screen.width === 360) { 
              this.setState({
                  mobile: true
              })
            }
          

     }


    showAuth = () => {
        if (this.state.showAuth === true) {
            this.setState({
                showAuth: false
            })
        } else {
            this.setState({
                showAuth: true,
                showQuote: false
            })
        }
    }


    showQuote = () => {
        if (this.state.showQuote === true) {
            this.setState({
                showQuote: false,
                showLanding: true
            })
        } else {
            this.setState({
                showAuth: false,
                showQuote: true
            })
        }
    }

    showBadges = () => {
        if (this.state.showBadges === true){
            this.setState({
                showBadges: false
            })
        } else {
            this.setState({
                showBadges: true
            })
        }
    }

   
    hideQuoteModule = () => {
        if (this.state.showQuoteModule === true){
            this.setState({
                showQuoteModule: false
            })
        } else {
            this.setState({
                showQuoteModule: true
            })
        }
    }


    handleClick = () => {
        //   console.log("Button Clicked")
    }

    // showServiceRequest = () => {

    // }

    switchToSignupForm = () => {
        this.setState({
            showSignup: true,
            showLogin: false
        })
    }



    showFastQuote = () => {
        // e.preventDefault();
        console.log("This state showfast quote: " + this.state.showFastQuote);
        if (this.state.showFastQuote === true){
            this.setState({
                showFastQuote: false
            })
        } else {
            this.setState({
                showFastQuote: true
            })
        }
       
    }

    render() {
        return (
            // {
            //     this.state.mobile ? 
            
            <div className="container-fluid auroral-northern">

                <Modal  
                            isOpen={this.state.showFastQuote}
                            onRequestClose={(e) => this.showFastQuote(e)}
                            style={customStylesModal}
                        >
                            {/* <button style={{width: '12.5%', marginLeft: '87.5%', textAlign: 'center', borderRadius: '10px', backgroundColor: 'red', color: 'white'}} onClick={(e) => this.showFastQuote(e)}>X</button> */}
                                                        
                            <PublicQuoteCard 
                                theme="light"
                                text="black"
                                agents={[]}
                            
                            />    

                </Modal>

                {this.state.showAuth ?
                <div className="row">
                    
                    <div className="col-12" style={{
                            padding: '0px'}}
                            >
                        {/* {this.state.noToolLoaded ? 


                            <div style={{
                                    marginTop: '5%', 
                                    marginBottom: '5%',
                                    padding: '10px',
                                    borderRadius: '5px'}}>
                                <div style={{
                                        height: '100%', 
                                        width: '100%', 
                                        backgroundColor: 'transparent'}}>
                                        <Carousel 
                                            showFastQuote={(e) => this.showFastQuote(e)}
                                        />
                                </div>
                            </div>
                            :
                            null
                        }    */}

                        <div className="card miniscreen-bg" 
                            style={{
                                textAlign: 'center', 
                                border: '0px', 
                                padding: '0px'}}
                            >
                            
                                <br />

                                <div className="row">

                                    {this.state.showSignup ? 
                                        <div className="col-lg-8 offset-lg-2">
                                            <SignupCard />
                                            <br />
                                        </div>
                                    : null}

                                    {this.state.showLogin ? 
                                        <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                                            <LoginCard 
                                                switchToSignup={() => {this.switchToSignupForm()}}
                                            />
                                            <br />
                                        </div>
                                    : null}


                                  
                                </div>
                        </div> 
                    </div>

                    <div className="col-12"
                         style={{
                            backgroundColor: 'transparent', 
                            padding: '25px', 
                            overflow: 'auto'}}
                        >                                    

                 

                       {this.state.showCreateServiceRequest ?
                        <div className="container " style={{background: 'transparent', padding: '0px'}}>

                                <CreateServiceRequest />
                            
                   
                        </div>
                        : null}
                       

                    </div>


                    
                </div>
                        : null
                   
                }

                {
                    this.state.showContact ?
                        <div className="container" style={{ background: 'black' }}>
                            {/* <Parralax /> */}

                            <ContactCard
                                return={this.showLanding}
                                theme="secondary"
                            />

                            {/* <Parralax /> */}

                        </div>
                        : null
                }



                {
                    this.state.showQuote ?
                        <div className="container" style={{ background: 'black', padding: '50px' }}>
                            <QuoteCard
                                return={this.showLanding}
                                theme="secondary"
                                text="white"
                            />
                        </div> : null
                }
            </div >

        
            // }
            
        )

    }

}

export default AuthPage;



 // loadBooks = () => {
    //     API.getBooks()
    //         .then(res => this.setState({ books: res.data }))
    //         .catch(err => console.log(err));
    // };

    // testGets = () => {

    //     API.getAgents()
    //         .then(res => this.setState({ agents: res.data }))
    //         .catch(err => console.log(err));

    //     API.getAutos()
    //         .then(res => this.setState({ autos: res.data }))
    //         .catch(err => console.log(err));

    //     API.getClients()
    //         .then(res => this.setState({ clients: res.data }))
    //         .catch(err => console.log(err));

    //     API.getContacts()
    //         .then(res => this.setState({ contacts: res.data }))
    //         .catch(err => console.log(err));

    //     API.getDrivers()
    //         .then(res => this.setState({ drivers: res.data }))
    //         .catch(err => console.log(err));

    //     // Not working
    //     API.getProperties()
    //         .then(res => this.setState({ properties: res.data }))
    //         .catch(err => console.log(err));

    //     API.getQuotes()
    //         .then(res => this.setState({ quotes: res.data }))
    //         .catch(err => console.log(err));

    //     API.getReferrals()
    //         .then(res => this.setState({ referrals: res.data }))
    //         .catch(err => console.log(err));

    //     API.getServicers()
    //         .then(res => this.setState({ servicers: res.data }))
    //         .catch(err => console.log(err));

    //     API.getServicerequests()
    //         .then(res => this.setState({ serviceRequests: res.data }))
    //         .catch(err => console.log(err));


    //     // Not working
    //     API.getUmbrellas()
    //         .then(res => this.setState({ umbrellas: res.data }))
    //         .catch(err => console.log(err));

    //     API.getVehicles()
    //         .then(res => this.setState({ vehicles: res.data }))
    //         .catch(err => console.log(err));
    // }