import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import CreateAuto from '../CreateAuto'
import CreateProperty from '../CreateProperty'
import CreateUmbrella from '../CreateUmbrella'
import Modal from 'react-modal'
// import SmallParralax from '../SmallParralax';

const customStylesModal = {
    content: {
        padding: '10px 5px',
        backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        border: '0px',
        margin: '10px'
    }
}

// const customStylesModalTransparent = {
//     content: {
//         padding: '5px 5px',
//         background: 'transparent',
//         border: '0px',
//         height: '100%',
//         border: '0px'
//     }
// }


class CreatePolicy extends Component {
    state = {
        showCreateAuto: false,
        showCreateProperty: false,
        showCreateUmbrella: false
    }

    componentDidMount = () => {
        console.log(this.props);
        // CONTINUE HERE -- 06-28-2022
            // NEED TO SEE WHY CREATING AUTO POLICY DOESN'T WORK, checking props to see what's being passed through, props scope on a lot of these a broken because i try to isolate the GETS to the local component
    }

    showCreateAuto = () => {
        if (this.state.showCreateAuto === true) {
            this.setState({
                showCreateAuto: false
            })
        } else {
            this.setState({
                showCreateAuto: true,
                showCreateProperty: false,
                showCreateUmbrella: false
            })
        }
    }

    showCreateProperty = () => {
        if (this.state.showCreateProperty === true) {
            this.setState({
                showCreateProperty: false
            })
        } else {
            this.setState({
                showCreateAuto: false,
                showCreateProperty: true,
                showCreateUmbrella: false
            })
        }
    }

    showCreateUmbrella = () => {
        if (this.state.showCreateUmbrella === true) {
            this.setState({
                showCreateUmbrella: false
            })
        } else {
            this.setState({
                showCreateAuto: false,
                showCreateProperty: false,
                showCreateUmbrella: true
            })
        }
    }

    render() {
        return (

            < div >

                <div className="card-body">

                    <div className="row">
                        <div className="offset-md-4 col-md-4 btn btn-dark" onClick={this.showCreateAuto}>Create Vehicle Policy</div>
                            {this.state.showCreateAuto ?
                                // <div className="card">

                                <Modal 
                                isOpen={this.state.showCreateAuto}
                                onRequestClose={() => this.showCreateAuto()}
                                style={customStylesModal}
                                >
                                    <CreateAuto 
                                        clientID={this.props.clientID}
                                        clientFirstName={this.props.clientFirstName}
                                        clientLastName={this.props.clientLastName}
                                        agents={this.props.agents}
                                        rerender={this.props.rerender}
                                    />
                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateAuto()}>Close</button>
                                               
                                </Modal>
                            : null}
                        <div className="offset-md-4 col-md-4 btn btn-dark" onClick={this.showCreateProperty}>Create Property Policy</div>
                            {this.state.showCreateProperty ?
                                // <div className="card">


                                <Modal 
                                isOpen={this.state.showCreateProperty}
                                onRequestClose={() => this.showCreateProperty()}
                                style={customStylesModal}
                                >
                                    <CreateProperty 
                                        clientID={this.props.clientID}
                                        clientFirstName={this.props.clientFirstName}
                                        clientLastName={this.props.clientLastName}
                                        agents={this.props.agents}
                                    />
                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateProperty()}>Close</button>
                                </Modal>
                                // </div>

                            : null}
                        <div className="offset-md-4 col-md-4 btn btn-dark" onClick={this.showCreateUmbrella}>Create Umbrella Policy</div>
                            {this.state.showCreateUmbrella ?
                                // <div className="card">
                                <Modal 
                                    isOpen={this.state.showCreateUmbrella}
                                    onRequestClose={() => this.showCreateUmbrella()}
                                    style={customStylesModal}
                                >               
                                    <CreateUmbrella 
                                        clientID={this.props.clientID}
                                        clientFirstName={this.props.clientFirstName}
                                        clientLastName={this.props.clientLastName}
                                        agents={this.props.agents}
                                    />
                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showCreateUmbrella()}>Close</button>
                                
                                </Modal>
                                // </div>

                            : null}
                    </div>
                    {/* {this.state.showCreateAuto ?
                            <div className="card">
                                <CreateAuto 
                                    clientID={this.props.clientID}
                                    clientFirstName={this.props.clientFirstName}
                                    clientLastName={this.props.clientLastName}
                                    agents={this.props.agents}
                                    rerender={this.props.rerender}
                                />
                            </div>
                        : null} */}

                    {/* {this.state.showCreateProperty ?
                        <div className="card">
                            <CreateProperty 
                                clientID={this.props.clientID}
                                clientFirstName={this.props.clientFirstName}
                                clientLastName={this.props.clientLastName}
                                agents={this.props.agents}
                            />
                        </div>

                        : null} */}

                    {/* {this.state.showCreateUmbrella ?
                        <div className="card">
                            <CreateUmbrella 
                                clientID={this.props.clientID}
                                clientFirstName={this.props.clientFirstName}
                                clientLastName={this.props.clientLastName}
                                agents={this.props.agents}
                            />
                        </div>

                        : null} */}


                </div>


            </div >

        )
    }
}


export default CreatePolicy;
