import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
// import FawnButton from "../FawnButton";
// import PlacesAutocomplete, {
//     geocodeByAddress,
//     getLatLng
// } from "react-places-autocomplete"
import './style.css'
import FawnButtonMini from "../FawnButtonMini";
// import InputAnimation from "../InputAnimation";
import { Table } from "react-bootstrap";
import cogoToast from "cogo-toast";
// import { registerables } from "chart.js";





class PublicQuoteCard extends Component {

    state = {
        fullName: "",
        DOB: "",
        email: "",
        address: "",
        phone: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        beginQuoting: false,
        formSequence: 1,
        editName: false
    }

 
    componentDidMount = () => {
        console.log("Public Quote Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            fullName: this.state.fullName,
            dob: this.state.DOB,
            address: this.state.address,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            phone: this.state.phone,
            mainAgent: '61cbd89ddf3ae329f85d2c42',
            refAgentOne: '62d8adb41352c1edce515138'
        }

        console.log(quoteData)



        API.saveQuote({
            quickQuote: true,
            quickName: this.state.fullName,
            dob: this.state.DOB,
            quickAddress: this.state.address,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            phone: this.state.phone,
            mainAgent: '61cbd89ddf3ae329f85d2c42',
            refAgentOne: '62d8adb41352c1edce515138'
        }).then(res => {
            console.log("Saved Quote!")
            console.log(res.data)
            this.setState({
                formSequence: 10
            })
            alert("Your quote has been submitted successfully!")
            cogoToast.success("We have received your quote!")
        }
        ).catch(err => {
            console.log(err)
            this.setState({
                formSequence: 11
            })
            alert("Your quote was not submitted")
            cogoToast.error("There was an error with your quote, please try again!")
        })

    }

    beginQuotingToggle = () => {
        if (this.state.beginQuoting === false){
            this.setState({
                beginQuoting: true
            })
        } else {
            this.setState({
                beginQuoting: false
            })
        }
    }

    incrementForm = () => {
        this.setState({
            formSequence: this.state.formSequence += 1
        })
        console.log(this.state)
    }

    resetForm = () => {
        this.setState({
            formSequence: 1,
            firstName: "",
            lastName: "",
            fullName: "",
            DOB: "",
            phone: "",
            email: "",
            address: "",
            streetAddress: "",
            cityAddress: "",
            zipAddress: "",
            stateAddress: "",
            vehiclesBody: "",
            driversBody: "",
            quoteNotes: "",
            beginQuoting: false,
        })
    }


    editNameInSummary = (e) => {
        e.preventDefault()
        if (this.state.editName === false){
            this.setState({
                editName: true
            })
        } else {
            this.setState({
                editName: false
            })
        }
    }

    editAddressInSummary = (e) => {
        e.preventDefault()
        if (this.state.editAddress === false){
            this.setState({
                editAddress: true
            })
        } else {
            this.setState({
                editAddress: false
            })
        }
    }

    editDOBInSummary = (e) => {
        e.preventDefault()
        if (this.state.editDOB === false){
            this.setState({
                editDOB: true
            })
        } else {
            this.setState({
                editDOB: false
            })
        }
    }

    editPhoneInSummary = (e) => {
        e.preventDefault(e)
        if (this.state.editPhone === false){
            this.setState({
                editPhone: true
            })
        } else {
            this.setState({
                editPhone: false
            })
        }
    }

    editEmailInSummary = (e) => {
        e.preventDefault();
        if (this.state.editEmail === false){
            this.setState({
                editEmail: true
            })
        } else {
            this.setState({
                editEmail: false
            })
        }
    }

    editVehiclesInSummary = (e) => {
        e.preventDefault();
        if (this.state.editVehicles === false){
            this.setState({
                editVehicles: true
            })
        } else {
            this.setState({
                editVehicles: false
            })
        }
    }

    editDriversInSummary = (e) => {
        e.preventDefault();
        if (this.state.editDrivers === false){
            this.setState({
                editDrivers: true
            })
        } else {
            this.setState({
                editDrivers: false
            })
        }
    }

    editNotesInSummary = (e) => {
        e.preventDefault();
        if (this.state.editNotes === false){
            this.setState({
                editNotes: true
            })
        } else {
            this.setState({
                editNotes: false
            })
        }
    }

    // handleSelect = async value => {}

    // input = document.getElementById('autocomplete');
    // // autocomplete = new window.google.maps.places.Autocomplete(this.input);

    render() {
        return (
            <div>
{/* 
                <PlacesAutocomplete 
                    value={address}
                    onChange={setAddress}
                    onSelect={this.handleSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                        <div>
                            <input {...getInputProps({placeholder: "Type address"})} />
                            <div>
                                {loading ? <div>...loading</div> : null}
                                {suggestions.map((suggestion => {
                                    return <div>{suggestion.description}</div>
                                }))}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete> */}
                {/* <input type="text" id="autocomplete" /> */}

                <div className="card" style={{ backgroundColor: 'transparent', textAlign: 'center'}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    {/* <div className="card-header" style={{ textAlign: 'center' }}> */}
                        {/* <h2 className="contact-text" style={{ color: this.props.text }}>Quote Form</h2> */}
                    {/* </div> */}
                    {/* <div className="card-body" style={{backgroundColor: 'transparent'}}> */}
                        {/* <div className="card-title"> */}
                            {/* <h4 className="contact-text" style={{ color: this.props.text }}>Rember to tag correct producers</h4> */}
                        {/* </div> */}
                    {/* </div> */}

                    <form className="form-screen" style={{ color: this.props.text }}>
                        <p className="quote-screen-text" style={{textAlign: 'center'}}>Fast Quote</p>
                        {this.state.beginQuoting ? 
                        <div id="quoting-screen" >

                        <div className="row">
                            {this.state.formSequence === 1 ? 
                                <div className="offset-md-3 col-md-6">
                                      <label><h3 className="w3-container w3-center mycustom_class_r1 w3-animate-right" >Full Name</h3></label>
                                      <input type="text" className="form-control" value={this.state.fullName} onChange={this.handleInputChange} name="fullName" />
                                      <br />
                                      <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                      />
                                  </div>
                        
                            : null}

                            {this.state.formSequence === 2 ? 
                            
                                <div className="offset-md-3 col-md-6">
                                    <label><h3>Email</h3></label>
                                    <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" id="inputEmail4" />
                                    <br />
                                    <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                    />
                                </div>
                            
                            : null}

                            {this.state.formSequence === 3 ? 
                                <div className="offset-md-3 col-md-6">
                                <label><h3>Phone</h3></label>
                                    <input type="tel" className="form-control" value={this.state.phone} onChange={this.handleInputChange} name="phone"/>
                                    <br />
                                      <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                      />
                                  
                                  </div>
                        
                            : null}                            
                
                            {/* <div className="col">
                            </div> */}
                            {/* <div className="col">
                                <label>Date of Birth</label>
                                <input type="date" className="form-control" value={this.state.DOB} onChange={this.handleInputChange} name="DOB" />
                            </div> */}
                        </div>





                        <div className="form-row row">

                            
                            {this.state.formSequence === 4 ? 
                            
                            <div className="offset-md-3 col-md-6">
                                <label><h3>Home Address</h3></label>
                                <input type="text" className="form-control" value={this.state.streetAddress} onChange={this.handleInputChange} name="address" id="inputAddress" />
                                <br />
                                <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                    />
                            </div>
                                
                            : null}


                            {this.state.formSequence === 5 ? 
                            
                                <div className="offset-md-3 col-md-6">
                                    <label><h3>Date of Birth</h3></label>
                                    <input type="date" className="form-control" value={this.state.DOB} onChange={this.handleInputChange} name="DOB" id="inputDob" />
                                    <br />
                                    <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                    />
                                </div>
                                
                            : null}


      

           
                        </div>


                      


                            {/* <div className="form-group col-md-2">
                                <label>Zip</label>
                                <input type="text" className="form-control" value={this.state.zipAddress} onChange={this.handleInputChange} name="zipAddress" id="inputZip" />
                            </div> */}

            
                      
                        <div className="form-row row">

                                {this.state.formSequence === 6 ?
                                
                                    <div className="offset-md-3 col-md-6">
                                        <label><h3>Year/Make/Model of Vehicles</h3></label>
                                        <textarea value={this.state.vehiclesBody} onChange={this.handleInputChange} name="vehiclesBody" className="form-control" rows="5" cols="60" />
                                        <br />
                                        <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                        />
                                    </div>

                                : null }
    
    
                                {this.state.formSequence === 7 ? 
                                    
                                    <div className="offset-md-3 col-md-6">
                                        <label><h3>Name / Age of Drivers</h3></label>
                                        <textarea value={this.state.driversBody} onChange={this.handleInputChange} name="driversBody" className="form-control" rows="5" cols="60" />
                                        <br />
                                        <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                        />
                                    </div>

                                : null}
    
    
                                {this.state.formSequence === 8 ?
                                

                                        <div className="offset-md-3 col-md-6">
                                            <label><h3>Comments</h3></label>
                                            <textarea value={this.state.quoteNotes} onChange={this.handleInputChange} name="quoteNotes" className="form-control" rows="5" cols="60" />
                                            <br />
                                            <FawnButtonMini 
                                                buttonText="Next"
                                                pf1={this.incrementForm}
                                            />
                                        </div>
     
    
                                : null}

                        </div>

                                {this.state.formSequence === 9 ? 

                                    <div style={{ textAlign: 'center' }}>
                                        <br />
                                        {/* <button className="btn btn-lg btn-success" onClick={this.handleQuoteSubmit}>Submit Quote</button> */}
                                        {/* <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, #640e27 74%, white 0%)', width: '100%', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                        onClick={() => this.handleQuoteSubmit()}>Submit
                                        </button> */}

                                        <div className="form-row row">

                                            <div className="col" style={{padding: '0px'}}>

                                                <div className="card" style={{textAlign: 'center'}}>
                                                    <h3>Review Info:</h3>                                                    
                                                    {/* <ol> */}
                                                    <Table striped bordered responsive style={{maxWidth: '100%', overflow: 'auto'}}>
                                                        <thead>
                                                            <th>Prompt</th>
                                                            <th>Answer</th>

                                                         </thead> 
                                                        <tbody>
                                                            <tr>
                                                                <td>Name</td>
                                                                {this.state.editName ? 
                                                                <td>
                                                                    <input type="text" style={{width: '80%'}} className="form-control" value={this.state.fullName} onChange={this.handleInputChange} name="fullName" />
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editNameInSummary(e)}}>Save</button>
                                                                </td>
                                                                
                                                                : 
                                                                <td><p>{this.state.fullName}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editNameInSummary(e)}}>Edit</button></td>
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td>Address</td>
                                                                { this.state.editAddress ?
                                                                <td>
                                                                    <input type="text" className="form-control" value={this.state.address} onChange={this.handleInputChange} name="address" id="inputAddress" />
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editAddressInSummary(e)}}>Save</button>                                                                    
                                                                </td>
                                                                :
                                                                <td><p>{this.state.address}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editAddressInSummary(e)}}>Edit</button></td>                                                                
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td>Date of Birth</td>
                                                                {this.state.editDOB ? 
                                                                <td>
                                                                    <input type="date" className="form-control" value={this.state.DOB} onChange={this.handleInputChange} name="DOB" id="inputDob" />       
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editDOBInSummary(e)}}>Save</button>        
                                                                </td>
                                                          
                                                                :

                                                                <td><p>{this.state.DOB}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editDOBInSummary(e)}}>Edit</button></td>
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td>Phone</td>
                                                                {this.state.editPhone ? 
                                                                <td>
                                                                    <input type="text" className="form-control" value={this.state.phone} onChange={this.handleInputChange} name="phone"/>
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editPhoneInSummary(e)}}>Save</button>
                                                                </td>
                                                                :
                                                                <td><p>{this.state.phone}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editPhoneInSummary(e)}}>Edit</button></td>                                                           
                                                                }
 
                                                            </tr>
                                                            <tr>
                                                                <td>Email</td>
                                                                {this.state.editEmail ? 
                                                                <td>
                                                                    <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" id="inputEmail" />
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editEmailInSummary(e)}}>Save</button>
                                                                </td>
                                                                :

                                                                <td><p>{this.state.email}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editEmailInSummary(e)}}>Edit</button></td>
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td>Vehicles</td>
                                                                {this.state.editVehicles ?
                                                                <td>
                                                                    <textarea value={this.state.vehiclesBody} onChange={this.handleInputChange} name="vehiclesBody" className="form-control" rows="5" cols="60" />
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editVehiclesInSummary(e)}}>Save</button>
                                                                </td>
                                                            
                                                                :
                                                                
                                                                <td><p>{this.state.vehiclesBody}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editVehiclesInSummary(e)}}>Edit</button></td>
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td>Drivers</td>
                                                                {this.state.editDrivers ?
                                                                <td>
                                                                    <textarea value={this.state.driversBody} onChange={this.handleInputChange} name="driversBody" className="form-control" rows="5" cols="60" />
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editDriversInSummary(e)}}>Save</button>
                                                                </td>
                                                            
                                                                : 
                                                                
                                                                <td><p>{this.state.driversBody}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editDriversInSummary(e)}}>Edit</button></td>
                                                                }

                                                            </tr>
                                                            <tr>
                                                                <td>Notes</td>
                                                                {this.state.editNotes ? 
                                                                <td>
                                                                    <textarea value={this.state.quoteNotes} onChange={this.handleInputChange} name="quoteNotes" className="form-control" rows="5" cols="60" />
                                                                    <button className="btn btn-sm btn-success" style={{float: 'right'}} onClick={(e) => {this.editNotesInSummary(e)}}>Save</button>
                                                                </td>
                                                            
                                                                :
                                                                
                                                                <td><p>{this.state.quoteNotes}</p> <button className="btn btn-sm btn-warning" style={{float: 'right'}} onClick={(e) => {this.editNotesInSummary(e)}}>Edit</button></td>
                                                                }

                                                            </tr>
                                                        </tbody>
                                                  

                                                    </Table>
                                                  
                                                    
                                                    {/* </ol> */}
                                                </div>
                                                <br />
                                                <FawnButtonMini 
                                                    buttonText="Submit"
                                                    pf1={(e) => this.handleQuoteSubmit(e)}
                                                />                                        
                                                <br />
                                                <FawnButtonMini 
                                                    buttonText="Restart"
                                                    pf1={this.resetForm}
                                                />
                                            </div>
                                    
                                        </div>

                                          </div>

                                : null }

                                {this.state.formSequence === 10 ?
                                        

                                        <div className="offset-md-3 col-md-6" style={{textAlign: 'center', backgroundColor: "rgba(255,255,255,0.5)", padding: '5px', borderRadius: '5px 10px 5px 10px'}}>
                                            
                                            <label><h3>Success</h3></label>
                                            <p>Thank you for submitting your quote, one of our in-house agents will be with you shortly!</p>
                                            <p>If you'd like an automated quote, please feel free to go back to the previous menu and choose one of our other quoting options</p>
                                            <p>If you have any questions please feel free to email us feedback at: evan@fawncircle.com</p>
                                            <br />
                                            <FawnButtonMini 
                                                buttonText="Enter another quote"
                                                pf1={this.resetForm}
                                            />
                                        </div>


                                : null}

                                {this.state.formSequence === 11 ?
                                        

                                        <div className="offset-md-3 col-md-6" style={{textAlign: 'center', padding: '5px', backgroundColor: "rgba(255,255,255,0.5)", borderRadius: '5px 10px 5px 10px'}}>
                                            <label><h3>Error</h3></label>
                                            <p>Thank you for submitting your quote,  unfortunately we had an error!</p>
                                            <p>If you'd like an automated quote, please feel free to go back to the previous menu and choose one of our other quoting options</p>
                                            <p>If you have any questions please feel free to email us feedback at: evan@fawncircle.com</p>
                                            <br />
                                            <FawnButtonMini 
                                                buttonText="Enter another quote"
                                                pf1={this.resetForm}
                                            />
                                        </div>


                                : null}
                            

                        
                                

                            </div>    
                         : 

                         <div>

                             <div className="row">
                                 <div className="offset-md-3 col-md-6">
                                    <FawnButtonMini 
                                        pf1={this.beginQuotingToggle}
                                        buttonText="Go!"
                                    />
                                 </div>
                             </div>
  
                         </div>
                        }
               

                       
               <p style={{ fontSize: '12px', position: 'absolute', bottom: 0, textAlign: 'center', padding: '0' }}>*Please note our carriers use credit-based insurance scores when rating policies.</p>
                    </form>
              
                                  



                    {/* <div className="card">
                        <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button>
                    </div> */}
                                   {/* <p style={{ fontSize: '12px', bottom: 0, position: 'absolute' }}>*Please note our carriers use credit-based insurance scores when rating policies.</p> */}
                </div>
            </div>
        )

    }

}

export default PublicQuoteCard;