import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
// import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
// import { storage } from '../../config/Fire';
import fire from '../../config/Fire';
import cogoToast from 'cogo-toast';


class CreateClientRow extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        address: "",
        docName: "",
        document: ""
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    componentDidMount = () => {
        // API.getClientIDs()
        // .then(res => {
        //     this.setState({ clients: res.data });
        //     console.log(res);
        //     console.log(this.state.clients)
        
        // })
        // .catch(err => console.log(err));


        // API.getAgents()
        // .then(res => {
        //     this.setState({ agents: res.data });
        //     console.log(res);
        //     console.log(this.state.agents)

        // })
        // .catch(err => console.log(err));

        // setTimeout( () => {
        //     console.log(this.state)
        // }, 5000)

    }

    signup = e => {
        e.preventDefault()
        let password = 'Fawn3002'
        fire.auth().createUserWithEmailAndPassword(this.state.email, password).then(data => {
            console.log("Successfully created Client")
            console.log(data)

            API.saveClient({
                uid: data.user.uid,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: data.user.email,
                telephone: this.state.telephone,
                address: this.state.address
            }).then(res => {
                console.log(res.data);
                cogoToast.success("Saved New Client!");
                alert("Successfully Created a New Client!");
                this.props.generateNewRow(res.data)
                if (this.state.document.length > 0){

                    if (this.state.docName.length > 0){

                        API.createDocumentForClient(res.data._id, {
                            docName: this.state.docName,
                            document: this.state.document,
                            policyid: res.data._id,
                            type: "client"
                        }).then((res) => {
                            console.log(res.data)
                            cogoToast.success("Uploaded Client Document")
                        }).catch( err => console.log(err))

                    } else {
                        API.createDocumentForClient(res.data._id, {
                            docName: "Umbrella Document",
                            document: this.state.document,
                            policyid: res.data._id,
                            type: "client"
                        }).then((res) => {
                            console.log(res.data)
                            cogoToast.success("Uploaded Client Document")
                        }).catch( err => console.log(err))  

                    }
     
                }

            })
        }).catch((error) => {
            console.log(error)
        })
    }



    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        this.setState({
            docName: event.target.files[0].name
        })
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document: res
                })
            })
        }
    }



   


    render() {
        return (

        // <span>


            <tr>
                <td>
                    <input type="text" style={{width: '150px'}} value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="First Name" />
            
                </td>
                <td>
                    {/* <input type="date"  style={{width: '150px'}} value={this.state.dob} onChange={this.handleInputChange} name="dob" placeholder="Date of Birth" /> */}
                    <input type="text" style={{width: '150px'}} value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Last Name" />
                </td>
           
         
                         
                <td>

                    <input style={{width: '200px'}} type="text" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="E-mail" />

                </td>       
                
                <td>
                    <input type="text" style={{width: '200px'}} value={this.state.phone} onChange={this.handleInputChange} name="telephone" placeholder="Phone #" />
                </td>
              

                <td>
                    <input type="text"  style={{width: '300px'}} value={this.state.address} onChange={this.handleInputChange} name="address" placeholder="Address" />
                </td>

                <td>
                    {/* <input type="password" style={{width: '150px'}} value={this.state.agentPassword} name="agentPassword" onChange={this.handleInputChange} placeholder="Password" /> */}
                    <input name="fileData" style={{width: '250px'}} type="file" onChange={this.fileSelectedHandler} />       
                </td>
             
                <td>

                    <button className="btn-success btn" onClick={(e) => this.signup(e)}>Create</button>

                </td>
                <td>
                </td>
                <td> 
                </td>
            </tr>

        )
    }
}


export default CreateClientRow;
