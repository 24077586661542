import React, { Component } from "react";
import "./style.css";
// import API from "../../utils/API";
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
// import Flashcard from './Flashcard';



class PAFlashcards extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        showCoverPath: false,
        showIcons: true,
        activeCardSide: 1,
        stashedCards: [],
        activeCards: [],
        loadedCard: [{"uid":"001","title":"310.2 Powers and duties of department","code":"2","side1":["Responsibilities.--"],"side2":["","The,commissioner,shall,do,all,of,the,following:","License,insurance,producers,in,accordance,with,this","Approve,and,administer,or,contract,for,the,overall,administration,of,the,preexamination,program,,preexamination,courses,of,study,,insurance,producer,licensing,examinations,and,continuing,education,programs.,A,preexamination,education,program,approved,by,the,department,shall,include,no,less,than,three,credit,hours,on"]}],
        PAFlashCards:[]
    }


    componentDidMount = () => {
        console.log("Quote Module Mounted")
        // let jsonFlashCards = []
        console.log(this.state);
        for (var i = 0; i < this.state.PAFlashCards.length; i++){
            if (this.state.PAFlashCards[i] !== undefined){
                if (this.state.PAFlashCards[i].length > 1){
                    for (var j = 0; j < this.state.PAFlashCards[i].length; j++){
                        this.state.activeCards.push(this.state.PAFlashCards[i][j]);
                    }
                } else if (this.state.PAFlashCards[i].length === 1){
                    this.state.activeCards.push(this.state.PAFlashCards[i][0])
                }
    
                if (i === this.state.PAFlashCards.length - 1){
                    console.log(this.state.activeCards);
                    this.setLoadedCard(this.state.activeCards);
                }   
            }
        }
    }



    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    showCoverPath = () => {
        if (this.state.showCoverPath === true){
            this.setState({
                showCoverPath: false,
                showIcons: true,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        } else {
            this.setState({
                showCoverPath: true,
                showIcons: false,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        }
    }

    returnToModule = () => {
        if (this.state.showIcons === true){
            this.setState({
                showIcons: true,
                showCoverPath: false,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        } else {
            this.setState({
                showIcons: true,
                showCoverPath: false,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        }
    }

    showTraverse = () => {
        if (this.state.showTraverse === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        } else {
            this.setState({
                showIcons: false,
                showTraverse: true,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false
            })           
        }
    }

    showTextQuote = () => {
        if (this.state.showTextQuote === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        } else {
            this.setState({
                showIcons: false,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: true,
                showCoverPathMass: false
            })
        }
    }

    // showCoverPath = () => 
    showCoverPathMass = () => {
        if (this.state.showCoverPathMass === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false
            })
        } else {
            this.setState({
                showIcones: false,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: true
            })
        }
    }

    redirect = (url) => {
        console.log("redirecting to: " + url);
        window.open(url, '_blank')
    }

    flipSide = (num) => {
        console.log("Flipping to side: " + num)
        this.setState({
            activeCardSide: num
        })
    }

    skip = () => {
        console.log("Skipping Card...");
    }

    stash = () => {
         console.log("Stashing Card...");
    }

    setLoadedCard = (arr) => {
        this.setState({
            loadedCard: this.state.activeCards[Math.floor(Math.random() * this.state.activeCards.length)]
        });
        console.log(this.state.activeCards[Math.floor(Math.random() * this.state.activeCards.length)])
        
    }

    // {this.props.agents.map(agent => (
    //     <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
    // ))}


    render() {
        return (
            <div>
                <div className="card" style={{backgroundColor: 'white', height: '1000px', overflow: 'auto'}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    {/* <div className="card-header" style={{ textAlign: 'center', backgroundColor: 'white' }}>
                        <h2 className="card-header" style={{ color: this.props.text, backgroundColor: 'white', border: '0px' }}><strong>Quote Form</strong></h2>
                    </div> */}
                    <div className="card-body" style={{padding: '20px', backgroundImage: 'url(https://picsum.photos/800)',  backgroundSize: 'cover'}}>
                        {/* <div className="card-title">
                            <h4 className="contact-text" style={{ color: this.props.text }}>Please fill out the form below, and submit for a quote!</h4>
                        </div> */}
                        <div className="row">
                            <div className="col-1"></div>
                            <div className="col-10">
                                <div className="card" style={{textAlign: 'center'}}>
                                    <div className="card-header">
                                        <h3>{this.state.loadedCard.title}</h3>
                                    </div>
                                    <div className="card-body">
                                        {this.state.activeCardSide === 1 ? 
                                            <span>
                                                <span>
                                                    <h4>{this.state.loadedCard.uid}</h4>
                                                    <button onClick={() => this.flipSide(2)}>Flip</button>
                                                </span>
                                            </span>
                                        : 
                                        <span>
                                            {this.state.loadedCard.side2.map(textArr => (
                                                <p>{textArr.join(" ")}</p>
                                            ))}
                                            <button onClick={() => this.flipSide(1)}>Flip</button>
                                            <button onClick={() => this.skip()}>Skip</button>
                                            <button onClick={() => this.stash()}>Stash</button>
                                        </span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-1"></div>
                        </div>
                        {/* <br /> */}
                        <div className="row" style={{marginTop: '100px'}}>
                            <div className="col-1"></div>
                            <div className="col-5">
                                <div className="card" style={{textAlign: 'center'}}>
                                    <div className="card-header">
                                        <h3>Cards Memorized</h3>
                                    </div>
                                    <div className="card-body">
                                        <h1><strong>5</strong></h1>
                                    </div>
                                </div>
                            </div>

                            <div className="col-5">
                                <div className="card" style={{textAlign: 'center'}}>
                                    <div className="card-header">
                                        <h3>Cards Remaining</h3>
                                    </div>
                                    <div className="card-body">
                                        <h1><strong>45</strong></h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-1"></div>
                        </div>
                         
                    </div>
                   



                    {/* <div className="card-body">
                        <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button>
                    </div> */}
                </div>
            </div>
        )

    }

}

export default PAFlashcards;