import axios from "axios";

export default {
  // Gets all books
  testAPI: function(){
    console.log("Running Test API");
    return axios.get("/api/");
  },
  updateClientContact: function(data){
    return axios.put("/api/clients/", data)
  },
  createLicense: function(data){
    return axios.post("/api/licenses", data)
  },
  findLicense: function(data){
    return axios.put("/api/licenses", data)
  },
  saveCarrier: function(data){
    return axios.post("/api/carriers", data)
  },
  getCarriers: function(){
    return axios.get("/api/carriers")
  },
  updateCarrier: function(id, data){
    return axios.put("/api/carriers/" + id, data)
  },


//~~ Admin Routes
  getAdmins: function(){
    return axios.get("/api/admins")
  },
  findAdminLogin: function(id) {
    console.log("Running find admin login...")
    return axios.get("/api/admins/login/" + id)
  },
  saveAdmin: function(data) {
    return axios.post("/api/admins/", data)
  },
                                      // getLicenseInfo: function(data) {
                                      //   return axios.post('/api/license', data)
                                      // },
                                      // Saves a book to the database

//~~ Agent Routes
  updateAgent: function(id, data){
    return axios.put("/api/agents/update/" + id, data)
  },
  getAgentAutos: function(id) {
    return axios.get("/api/agents/autos/" + id)
  },
  findAgent: function(id) {
    return axios.get("/api/agents/find/" + id)
  },
  getAgents: function() {
    return axios.get("/api/agents")
  },
  findAgentLogin: function(id) {
    return axios.get("/api/agents/login/" + id)
  },
  addClientToAgent: function(data){
    return axios.put("/api/agents/clients", data)
  },
  getAgentProperties: function(id) {
    return axios.get("/api/agents/properties/" + id)
  },
  getAgentUmbrellas: function(id) {
    return axios.get("/api/agents/umbrellas/" + id)
  },
  getAgentClients: function(id) {
    return axios.get("/api/agents/clients/" + id)
  },
  getWritingAgentQuotes: function(id) {
    return axios.get("/api/quotes/" + id)
  },
  getAllAgentQuotes: function(id) {
    return axios.get("/api/agents/quotes/" + id)
  },
  saveAgent: function(data) {
    return axios.post("/api/agents", data)
  },
  saveAgentProspectSubmission: function(id, data){
    // Got the right date to come through, time to 
    console.log("Running saveAgentProspectSubmission API")
    console.log(data)
    return axios.post("/api/prospects/agent/" + id, data)
    // return ({ message: "Successfully found saveAgentProspectSubmissionAPI function, no API yet..."})
  },

//~~ Application APIs
  createApplication: function(data){
    return axios.post("/api/applications/", data)
  },
  createApplicationFromQuote: function(id, data){
    return axios.post("/apli/applications/quote/" + id, data)
  },
  getApplications: function(data){
    return axios.get("/api/applications")
  },
  getApplicationsByID: function(id){
    return axios.get("/api/applications/" + id)
  },
  getApplicationByQuoteID: function(id){
    return axios.get("/api/applications/quote/" + id)
  },
  updateApplicationByID: function(id, data){
    return axios.put("/api/applications/" + id, data)
  },
  updateApplicationByQuoteID: function(id, data){
    return axios.put("/api/applications/quote/" + id, data)
  },


//~~ Auto APIs
  getAutos: function() {
    return axios.get("/api/autos")
  },
  findClientsAutoPolicies: function(id) {
    return axios.get("/api/autos/client/" + id)
  },
  saveAutoPolicy: function(id, data) {
    return axios.post("/api/autos/client/" + id, data)
  },
  saveAutoStandalonePolicy: function(data){
    return axios.post("/api/autos/", data)
  },
  updateAutoPolicy: function(id, data) {
    return axios.put("/api/autos/policy/" + id, data)
  },
  createDocumentForAuto: function(id, data){
    return axios.post("/api/documents/auto/" + id, data)
  },
  softDeleteAuto: function(id) {
    return axios.put("/api/autos/delete/" + id)
  },

//~~ Client APIs
  getClients: function() {
    return axios.get("/api/clients")
  },
  saveClient: function(data) {
    return axios.post("/api/clients", data)
  },
  findClient: function(id) {
    return axios.get("/api/clients/find/" + id)
  },
  findClientLogin: function(id) {
    return axios.get("/api/clients/login/" + id)
  },
  updateClient: function(id, data){
    return axios.put("/api/clients/update/" + id, data)
  },


//~~ Contacts APIs
  getContacts: function() {
    return axios.get("/api/contacts")
  },


//~~ Carrier APIs


//~~ Document APIs
  createDocument: function(data){
    return axios.post("/api/documents", data)
  }, // Should have Policy ID, and Data
  // getDocumentsByPolicyId: function(id){
  //   return axios.get("/api/documents/policy/" + id)
  // },
  createDocumentForProperty: function(id, data){
    return axios.post("/api/documents/property/" + id, data)
  },
  createDocumentForQuote: function(id, data){
    return axios.post("/api/documents/quote/" + id, data)
  },
  createDocumentsForQuoteProposal: function(id, data){
    return axios.post("/api/documents/quote/proposal/" + id, data)
  },
  createDocumentForQuote1: function(id, data){
    return axios.post("/api/documents/quote/1/" + id, data)
  },
  createDocumentForQuote2: function(id, data){
    return axios.post("/api/documents/quote/2/" + id, data)
  },
  createDocumentForQuote3: function(id, data){
    return axios.post("/api/documents/quote/3/" + id, data)
  },
  createDocumentForReferral: function(id, data){
    return axios.post("/api/documents/referral/" + id, data)
  },
  createDocumentForServiceRequest: function(id, data){
    return axios.post("/api/documents/servicerequest/" + id, data)
  },
  createDocumentForUmbrella: function(id, data){
    return axios.post("/api/documents/umbrella/" + id, data)
  },
  createDocumentForVehicle: function(id, data){
    return axios.post("/api/documents/vehicle/" + id, data)
  },
  createDocumentForClient: function(id, data){
    return axios.post("/api/documents/client/" + id, data)
  },
  createDocumentForContact: function(id, data){
    return axios.post("/api/documents/contact/" + id, data)
  },
  createDocumentForDriver: function(id, data){
    return axios.post("/api/documents/driver/" + id, data)
  },
  createDocumentForEnrollment: function(id, data){
    return axios.post("/api/documents/enrollment/" + id, data)
  },
  createDocumentForLicense: function(id, data){
    return axios.post("/api/documents/license/" + id, data)
  },
  createDocumentForAgent: function(id, data){
    return axios.post("/api/documents/agent/" + id, data)
  },
  createDocumentForApplication: function(id, data){
    return axios.post("/api/documents/application/" + id, data)
  },
  getDocumentById: function(id){
    return axios.get("/api/documents/" + id)
  },
  getDocumentsByIdArray: function(data){
    return axios.post("/api/documents/group", data)
  },


//~~ Driver APIs
  
  getDrivers: function() {
    return axios.get("/api/drivers")
  },
  saveDriver: function(id, data) {
    return axios.post("/api/drivers/" + id, data)
  },
  findDrivers: function(id) {
    return axios.get("/api/drivers/" + id)
  },
  softDeleteDriver: function(id) {
    return axios.put("/api/drivers/" + id)
  },

//~~ Enrollments API
  saveEnrollmentData: function(data){
    return axios.post("/api/enrollments", data)
  },

//~~ Orders API

  getOrder: function(){
    return axios.get("/api/orders")
  },
  saveOrder: function(data) {
    return axios.post("/api/orders")
  },
  saveOrderForExistingClient: function(id, data) {
    return axios.post("/api/orders/client/" + id, data)
  },
  saveOrderForReferringAgent: function(id, data) {
    return axios.post("/api/orders/referringagent/" + id, data)
  },
  assignOrderToServicer: function(id, data) {
    return axios.post("/api/orders/servicingagent/" + id, data)
  },
  assignOrderToWritingAgent: function(id, data){
    return axios.post("/api/orders/writingagent/" + id, data)
  },

//~~ Properties APIs

    getProperties: function() {
      return axios.get("/api/properties")
    },
    savePropertyPolicy: function(id, data) {
      return axios.post("/api/properties/client/" + id, data)
    },
    savePropertyStandalonePolicy: function(data){
      return axios.post("/api/properties/", data)
    },
    updatePropertyPolicy: function(id, data) {
      return axios.put("/api/properties/policy/" +id, data)
    },
    softDeleteProperty: function(id) {
      return axios.put("/api/properties/delete/" + id)
    },

//~~ Quote APIs

  editQuote: function(id, data) {
    return axios.put("/api/quotes/edit/" + id, data)
  },
  getQuotes: function() {
    return axios.get("/api/quotes")
  },
  getQuotesForAgent: function(id){
    return axios.get("/api/quotes/agent/" + id)
  },
  getQuote: function(id) { 
    return axios.get("/api/quotes/loc/" + id)
  },
  saveQuote: function(data) {
    return axios.post("/api/quotes", data)
  },
  updateQuote: function(id, data){
    return axios.put("/api/quotes/update/" + id, data)
  },
  saveAgentQuoteRequest: function(id, data) {
    return axios.post("/api/quotes/" + id, data)
  },

//~~ Referrals APIs

  getReferrals: function() {
    return axios.get("/api/referrals")
  },
  saveReferral: function(data) {
    return axios.post("/api/referrals", data)
  },


//~~ Servicing APIs

  updateServiceRequest: function(id, data) {
    return axios.put("/api/servicerequests/" + id, data)
  },
  getServicers: function() {
    return axios.get("/api/servicers")
  },
  getServicerequests: function() {
    return axios.get("/api/servicerequests")
  },
  getServiceRequestsForManyClients: function(data) {
    return axios.post("/api/servicerequests/agent", data)
  },
  saveServiceRequest: function(data) {
    return axios.post("/api/servicerequests", data)
  },
  saveServiceRequestForClient: function(data, id){
    return axios.post("/api/servicerequests/client/" + id, data)
  },


//~~ Baseball APIs

  saveBaseballData: function(data){
    return axios.post("/api/baseballs", data)
  },

//~~ Umbrella APIs
 
  getUmbrellas: function() {
    return axios.get("/api/umbrellas")
  },
  saveUmbrellaPolicy: function(id, data) {
    return axios.post("/api/umbrellas/client/" + id, data)
  },
  saveUmbrellaStandalonePolicy: function(data){
    return axios.post("/api/umbrellas/", data)
  },
  updateUmbrellaPolicy: function(id, data) {
    return axios.put("/api/umbrellas/policy/" + id, data)
  },
  softDeleteUmbrella: function(id) {
    return axios.put("/api/umbrellas/delete/" + id)
  },

//~~ Vehicles APIs

  getVehicles: function() {
    return axios.get("/api/vehicles")
  },
  findVehicles: function(id) {
    return axios.get("/api/vehicles/" + id)
  },
  saveVehicle: function(id, data) {
    return axios.post("/api/vehicles/" + id, data)
  },
  softDeleteVehicle: function(id) {
    return axios.put("/api/vehicles/" + id)
  }


};
