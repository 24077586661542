import React, { Component } from "react";
import "./style.css";
import './style.css'
import API from "../../utils/API";
import Modal from "react-modal";
import CreateServiceRequest from "../CreateServiceRequest";
import cogoToast from "cogo-toast";
import QuoteUpdateForm from "../QuoteUpdateForm";
import CreateQuoteRow from "../CreateQuoteRow";
import DocVault from "../DocVault";



const customStylesModal = {
    content: {
        padding: '5px 5px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        border: 'thick solid black',
        overflowX: 'auto'
    }
}

const docVaultModal = {
    content: {
        margin: '10%',
        padding: '5px 5px',
        backgroundImage: 'rgba(255,255,255,0.5)',
        // height: '100%',
        border: 'thick solid black',
        inset: '40px',
        overflowX: 'auto'
    } 
}


class QuoteDatabase extends Component {

    state = {
        requests: [],
        policies: [],
        openEditModal: false,
        editableQuote: '',
        searchTerm: '',
        searchedPolicies: [],
        sortedPolicies: [],
        quotes: [],
        refreshQuotes: [],
        showCreateAutoRow: false,
        showCreatePropertyRow: false,
        showCreateUmbrellaRow: false,
        docIDArray: [],
        policyType: "",
        quoteID: ""
    }

    componentDidMount = () => {
        console.log("Service Request Tracker Card Mounted")
        let quotes = []

        API.getQuotes().then(res => {
            console.log("Retrieved Quotes");
            console.log(res.data);

            for (var i = 0; i < res.data.length; i++){
                quotes.push(res.data[i]);

                if (i === res.data.length - 1){
                    this.setState({
                        quotes: quotes,
                        refreshQuotes: quotes
                    })
                    console.log(this.state);
                }
            }
        })

    }

    openEditModal = (data) => {
        console.log("Opening up Edit Modal for Data ID: " + data._id)
        console.log(data)
        if(this.state.openEditModal === false){
            this.setState({
                openEditModal: true,
                editableQuote: data
            })
        } else {
            this.setState({
                openEditModal: false
            })
        }
    }


    closeEditModal = (e) => {
        e.preventDefault();
        if (this.state.openEditModal === true){
            this.setState({
                openEditModal: false,
                editableQuote: ''
            })
        } else {
            this.setState({
                openEditModal: true
            })
        }
        
    }
  

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };



    sortTable = (e, type) => {

        if (type === 'effective'){
            console.log("Sorting Effective Dates");
        }

        e.preventDefault();
        console.log(e);
        console.log("Sorting table of type: " + type);
        // let sortedPolicies = this.state.policies.sort((a, b) => {

        //     if (a.clientLastName )

        // })
        // console.log(sortedPolicies)
    }

    searchTable = (e) => {
        e.preventDefault();
        console.log("Search Table for: " + this.state.searchTerm)
        let rowsWithTerm = [];
        for (var i = 0; i < this.state.refreshQuotes.length; i++){

            let object = this.state.refreshQuotes[i]

            for (const property in object){
                console.log(`${property}: ${object[property]}`)
                console.log(typeof(property))
                console.log(typeof(object[property]))
                if (typeof(object[property]) === 'string'){

                    if (object[property].toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1){

                        if (rowsWithTerm.length === 0){
                            rowsWithTerm.push(object);
                        } else {
                            let rowFoundAlready = false;
                            for (var j = 0; j < rowsWithTerm.length; j++){
                                if (rowsWithTerm[j]._id === object._id){
                                    rowFoundAlready = true;
                                }
                                if (j === rowsWithTerm.length - 1){
                                    if (rowFoundAlready === false){
                                        rowsWithTerm.push(object);
                                    }
                                }
                            }
                            this.setState({
                                quotes: rowsWithTerm
                            })
                        }
                       
                    }

                }

            }

            if (i === this.state.refreshQuotes.length - 1){
                console.log("Completed search");
                console.log("Rows with Term: " + this.state.searchTerm);
                console.log(rowsWithTerm)
                console.table(rowsWithTerm);
            }

        }
        // this.setState({

        // })
    }

    resetTable = (e) => {
        e.preventDefault();
        console.log("Resetting table");
        console.log("Transforming policies table: ")
        console.table(this.state.quotes)
        console.log("Into original table: ");
        console.table(this.state.refreshQuotes)
        this.setState({
            quotes: this.state.refreshQuotes
        })
    }

    addNewRowToPolicies = (rowEntry) => {
        console.log("Adding New Row to Policies");
        console.log(rowEntry);
        this.state.quotes.push(rowEntry);
        this.state.refreshQuotes.push(rowEntry);

        this.setState({
            showCreateQuoteRow: false
        })
    }

    readNewRowData = (rowEntry) => {
      
        console.log("Data ready to  be inserted");
        console.log(rowEntry);
        console.log("Adding New Row to Policies");
        console.log(rowEntry);
        this.state.quotes.push(rowEntry);
        this.state.refreshQuotes.push(rowEntry);
        // this.resetTable2()

        this.setState({
            showCreateQuoteRow: false
        })
    }


    showCreateQuoteRow = (e) => {
        e.preventDefault();

        if (this.state.showCreateQuoteRow === true){
            this.setState({
                showCreateQuoteRow: false
            })
        }  else {
            this.setState({
                showCreateQuoteRow: true
            })
        }
    }

    uploadDocument = (event, id) => {
        event.preventDefault();
        console.log("Uploading document for ID: " + id);
    }

    openServiceRequestModal = (event, id) => {
        // event.preventDefault();
        if (this.state.openServiceRequestModal !== true){
            this.setState({
                openServiceRequestModal: true,
                serviceID: id
            })
        } else {
            this.setState({
                openServiceRequestModal: false,
                serviceID: id
            })
        }
    }

    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
        
    }


    copyText = (event, data) => {
        console.log(this.state);
        event.preventDefault();

        if (data !== undefined){
            var copyText = data;
            navigator.clipboard.writeText(copyText);
            console.log(navigator.clipboard);
            cogoToast.success("Copied: " + data)
        } else {
            cogoToast.error("Couldn't copy data")
        }

    }

    gatherVaultDocsForQuote(e, docIDArray, policyID, policyType, docIDArray1, docIDArray2, docIDArray3){
        e.preventDefault();
        console.log("Gathering Documents from Vault for: ");
        console.log(docIDArray)

        let masterDocIDArray = [];

        for (var i = 0; i < docIDArray.length; i++){
            masterDocIDArray.push(docIDArray[i])
            if (i === docIDArray.length - 1){
                console.log("End 0")

                if (docIDArray1.length > 0){

                    for (var j = 0; j < docIDArray1.length; j++){
                        console.log("J :" + j)
                        masterDocIDArray.push(docIDArray1[j])
                        if (j === docIDArray1.length - 1){
                            console.log("End 1")
    
                            if (docIDArray2.length > 0){

                                for (var k = 0; k < docIDArray2.length; k++){
                                    masterDocIDArray.push(docIDArray2[k])
                                    if (k === docIDArray2.length - 1){
                                        console.log("End 2")
        
                                        if (docIDArray3.length > 0 ){

                                            for (var l = 0; l < docIDArray3.length; l++){
                                                masterDocIDArray.push(docIDArray3[l])
                                                if (l === docIDArray3.length - 1){
            
                                                    this.setState({
                                                        docIDArray: masterDocIDArray,
                                                        openDocVaultModal: true,
                                                        quoteID: policyID,
                                                        policyType: policyType
                                                    })
            
                                                }
                                            }

                                        } else {

                                            // 
                                            this.setState({
                                                docIDArray: masterDocIDArray,
                                                openDocVaultModal: true,
                                                quoteID: policyID,
                                                policyType: policyType
                                            })
                                        }
                                        
                                    }
                                }

                            } else {

                                // 
                                if (docIDArray3.length > 0 ){

                                    for (var l2 = 0; l2 < docIDArray3.length; l2++){
                                        masterDocIDArray.push(docIDArray3[l2])
                                        if (l2 === docIDArray3.length - 1){
    
                                            this.setState({
                                                docIDArray: masterDocIDArray,
                                                openDocVaultModal: true,
                                                quoteID: policyID,
                                                policyType: policyType
                                            })
    
                                        }
                                    }

                                } else {

                                    // 
                                    this.setState({
                                        docIDArray: masterDocIDArray,
                                        openDocVaultModal: true,
                                        quoteID: policyID,
                                        policyType: policyType
                                    })
                                }
                            }

                           
                        }
                    }

                } else {

                    if (docIDArray2.length > 0){

                        for (var k2 = 0; k2 < docIDArray2.length; k2++){
                            masterDocIDArray.push(docIDArray2[k2])
                            if (k2 === docIDArray2.length - 1){
                                console.log("End 2")

                                if (docIDArray3.length > 0 ){

                                    for (var l3 = 0; l3 < docIDArray3.length; l3++){
                                        masterDocIDArray.push(docIDArray3[l3])
                                        if (l3 === docIDArray3.length - 1){
    
                                            this.setState({
                                                docIDArray: masterDocIDArray,
                                                openDocVaultModal: true,
                                                quoteID: policyID,
                                                policyType: policyType
                                            })
    
                                        }
                                    }

                                } else {

                                    // 
                                    this.setState({
                                        docIDArray: masterDocIDArray,
                                        openDocVaultModal: true,
                                        quoteID: policyID,
                                        policyType: policyType
                                    })
                                }
                                
                            }
                        }

                    } else {

                        // 
                        if (docIDArray3.length > 0 ){

                            for (var l4 = 0; l4 < docIDArray3.length; l4++){
                                masterDocIDArray.push(docIDArray3[l4])
                                if (l4 === docIDArray3.length - 1){

                                    this.setState({
                                        docIDArray: masterDocIDArray,
                                        openDocVaultModal: true,
                                        quoteID: policyID,
                                        policyType: policyType
                                    })

                                }
                            }

                        } else {

                            // 
                            this.setState({
                                docIDArray: masterDocIDArray,
                                openDocVaultModal: true,
                                quoteID: policyID,
                                policyType: policyType
                            })
                        }
                    }
 
                }
      
            }
        }
    }

    openDocVaultModal = (e) => {
        e.preventDefault();
        if (this.state.openDocVaultModal === false){
            this.setState({
                openDocVaultModal: true
            }) 
        } else {
            this.setState({
                openDocVaultModal: false,
                docIDArray: [],
                policyID: ""
            })    
        }
    }

    render() {
        return (
            <div>
                <Modal  
                    isOpen={this.state.openServiceRequestModal}
                    onRequestClose={() => this.openServiceRequestModal()}
                    style={customStylesModal}
                >

                    {/* <p>Create Service Request</p> */}
                    <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={() => this.openServiceRequestModal()}>X</button>
                                                
                    <CreateServiceRequest 
                        uid={this.state.serviceID}
                    />


                </Modal>

                 <Modal
                    isOpen={this.state.openEditModal}
                    onRequestClose={(e) => this.closeEditModal(e)}
                    style={customStylesModal}
                 >  
                    <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={(e) => this.closeEditModal(e)}>X</button>                            
                        <QuoteUpdateForm
                            quoteData={this.state.editableQuote}
                            agents={this.props.agents}
                        />  
                 </Modal>

                <Modal
                    isOpen={this.state.openDocVaultModal}
                    onRequestClose={(e) => this.openDocVaultModal(e)}
                    style={docVaultModal}                
                >
                    <DocVault
                        docIDArray={this.state.docIDArray}
                        rootPolicyID={this.state.quoteID}
                        rootPolicyType={this.state.policyType}
                        closeModal={(e) => this.openDocVaultModal(e)}
                    />
                </Modal>

                 <div className="card" style={{overflowX: 'auto'}}>
                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.props.closeComponent()}>X</button>

                    <h3 style={{textAlign: 'center'}}>Quote Database </h3>
                    <div className="row" style={{textAlign: 'center'}}>
                        <div className="col-sm-2" style={{textAlign: 'center'}}>
                            <button style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.resetTable(e)}>
                                Refresh
                            </button>
                        </div>
                        <div className="col-sm-2" style={{textAlign: 'center'}}>
                            {/* <button style={{float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateAutoRow(e)}>New Auto</button> */}
                        </div>
                        <div className="col-sm-2" style={{textAlign: 'center'}}>
                            <button style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateQuoteRow(e)}>New Quote</button>
                            </div>
                        <div className="col-sm-2" style={{textAlign: 'center'}}>
                            {/* <button style={{float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateUmbrellaRow(e)}>New Umbrella</button> */}
                            </div>
                        <div className="col-sm-2" style={{ textAlign: 'center'}}>
                            <button disabled style={{height: '100%', width: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.sortTable(e, 'effective')}>Sort Created</button>
                            </div>
                        <div className="col-sm-2" style={{ textAlign: 'center'}}>
                            <form>
                                <input style={{width: '70%'}} value={this.state.searchTerm} onChange={this.handleInputChange} type="text" name="searchTerm" />
                                <button style={{width: '30%', backgroundColor: 'green', color: 'white'}} type="submit" onClick={(e) => this.searchTable(e)}> Go </button>
                            </form>
                        </div>
                    </div>
                    
                    

                                                <table
                                                    id="database"
                                                >
                                                    <thead>

                                                        {this.state.showCreateQuoteRow ? 
                                                        
                                                            <CreateQuoteRow
                                                                agents={this.props.agents}
                                                                sendRowToDatabase={(data) => this.addNewRowToPolicies(data)}
                                                                generateNewRow={(data) => this.readNewRowData(data)}
                                                            />
                                                
                                                        
                                                        
                                                        : null}

                                                                <tr>
                                                                <th>Client Name</th>
                                                                {/* <th>First Name</th> */}
                                                                <th>Edit</th>
                                                                <th>Task</th>
                                                                <th>Proposal</th>
                                                                <th>Created</th>
                                                                <th>Address</th>
                                                                <th>Quick Quote?</th>
                                                                <th>Share Link</th>               
                                                                <th>Quoted?</th>
                                                                <th>Presented?</th>
                                                                <th>Sold?</th>               
                                                                <th>Contact</th>

                                                            </tr>

                                                    
                                                    </thead>
                                                    <tbody>

           
                                                    
                                                   


                                     
                                                
                                                    
                                            


                                                    
                                                                {this.state.quotes.map(quote => (
                                                                    <tr key={quote._id}>
                                                                            <td><strong>
                                                                            
                                                                                {quote.quickName ? quote.quickName : quote.lastName + ", " + quote.firstName}
                                                                            
                                                                            {/* {quote.lastName}, {quote.firstName} */}
                                                                            
                                                                            </strong></td>
                                                                            <td><button className="btn btn-secondary" onClick={(e) => this.openEditModal(quote)}>Edit</button></td>
                                                                            <td>
                                                                                <button className="btn-primary btn" onClick={(e) => this.openServiceRequestModal(e, quote._id)}>Task</button>
                                                                            </td>
                                                                            <td>  
                                                                                {quote.documents.length > 0 ? 
                                                                        
                                                                                // <button className="btn btn-info" onClick={(e) => this.gatherVaultDocsForQuote(e, quote.documents, quote._id, 'quote', quote.documents1, quote.documents2, quote.documents3)}>Vault</button>

                                                                                <i onClick={(e) => this.gatherVaultDocsForQuote(e, quote.documents, quote._id, 'quote', quote.documents1, quote.documents2, quote.documents3)} class="fa fa-folder-open fa-2x" aria-hidden="true"></i>
                                                                           

                                                                                : null}  
                                                                            </td>

                                                                        <td>{quote.created_at ? quote.created_at.split("T")[0] : null}</td>
                                                                        <td>
                                                                            {quote.quickAddress ? 
                                                                                quote.quickAddress
                                                                            : 
                                                                               quote.street + " " + quote.city + ", " + quote.state + " " + quote.zip
                                                                            }

                                                                            
                                                                            
                                                                            </td>
                                                                        <td>{quote.quickQuote ? 'Yes' : 'No'}</td>
                                                                        <td><button className="btn-dark btn" onClick={(e) => {this.copyText(e, 'localhost:3000/quote/' + quote._id)}}>Share</button></td>
                                                                        <td>{quote.quoted ? 'Yes' : 'No'}</td>
                                                                        <td>{quote.presented ? ' Yes ' : 'No'}</td>
                                                                        <td>{quote.sold ? 'Yes' : 'No'}</td>
                                                                        <td>{quote.email ? quote.email : quote.phone}</td>
                                                                       
                                                                    </tr>
                                                                ))}


                                                                

                                                    </tbody>
                        </table>
                                                    {/* <ServiceRequestController
                                                        requests={this.state.serviceRequests}
                                                    />
                                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showServiceRequestController()}>Close</button> */}
                                                {/* </div> */}
                    </div>
            </div >
        )

    }

}

export default QuoteDatabase;