import React, { Component } from 'react';
import './style.css'
import API from '../../utils/API';
import Table from 'react-bootstrap/Table';
import cogoToast from 'cogo-toast';


class EditAgent extends Component {

    state = {
        agentSelected: "",
        agents: "",
        editAgentData: false,
        agentFirstName: "",
        agentLastName: "",
        agentEmail: "",
        agentTelephone: "",
        agentPassword: "",
        agentResidentState: "",
        agentAddress: "",
        agentDedicatedCSR: "",
        agentSupervisor: ""
    }

    componentDidMount = () => {
        API.getAgents()
        .then(res => {
            this.setState({ agents: res.data });
            console.log(res);
            console.log(this.state.agents)

        })
        .catch(err => console.log(err));

        setTimeout( () => {
            console.log(this.state)
        }, 5000)

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    populateAgentData = event => {
        event.preventDefault()

        API.findAgent(this.state.agentSelected)
            .then(res => {
                console.log("Found Agent Data")
                console.log(res.data)
                this.setState({
                    agentData: res.data,
                    agentFirstName: res.data.firstName,
                    agentLastName: res.data.lastName,
                    agentEmail: res.data.email,
                    agentTelephone: res.data.telephone,
                    agentResidentState: res.data.residentState,
                    agentAddress: res.data.address,
                    agentDedicatedCSR: res.data.dedicatedCSR,
                    agentSupervisor: res.data.supervisor
                })
                console.log(this.state);
            },
                this.showAgentData()
            )
    }

    showAgentData = () => {
        if (this.state.agentSelected) {
            this.setState({
                showAgentData: true
            })
        } else {
            alert("Need to select an agent!")
        }
    }
 
    editAgentData = (e) => {
        e.preventDefault()
        console.log("Editing Agent Data");
        if (this.state.editAgentData === false){
            this.setState({
                editAgentData: true
            })
        } else {
            this.setState({
                editAgentData: false
            })
        }
    }

    saveAgentData = (e) => {
        e.preventDefault();

        // Code Goes Here
        console.log("Saving Updated Agent Data:");
        console.log(this.state.agentData);
        console.log(this.state);
        
        this.setState({
            agentData: {
                firstName: this.state.agentFirstName,
                lastName: this.state.agentLastName,
                email: this.state.agentEmail,
                telephone: this.state.agentTelephone,
                residentState: this.state.agentResidentState,
                address: this.state.agentAddress,
                dedicatedCSR: this.state.agentDedicatedCSR,
                supervisor: this.state.agentSupervisor
            }
        })

        console.log("Sending data to backend:");
        console.log(this.state);

        API.updateAgent(this.state.agentData._id, {
            firstName: this.state.agentFirstName,
            lastName: this.state.agentLastName,
            email: this.state.agentEmail,
            telephone: this.state.agentTelephone,
            residentState: this.state.agentResidentState,
            address: this.state.agentAddress,
            dedicatedCSR: this.state.agentDedicatedCSR,
            supervisor: this.state.agentSupervisor
        })
        .then(res => {
            console.log("Updated Agent!");
            console.log(res.data);
            alert("Updated Agent!");
            cogoToast.success("Agent has been updated");
            this.setState({
                agentData: res.data,
                    agentFirstName: res.data.firstName,
                    agentLastName: res.data.lastName,
                    agentEmail: res.data.email,
                    agentTelephone: res.data.telephone,
                    agentResidentState: res.data.residentState,
                    agentAddress: res.data.address,
                    agentDedicatedCSR: res.data.dedicatedCSR,
                    agentSupervisor: res.data.supervisor
            })
        })
        .catch(err => console.log(err));

        if (this.state.editAgentData === true){
            this.setState({
                editAgentData: false
            })
        }
    }


    render() {
        return (
            < div >
                <div className="card-body" style={{textAlign: 'center'}}>
                    <h3>Edit Agent</h3>
                    <p>Select the Agent to Edit below</p>
                    <form>
                        <input list="agents" value={this.state.agentSelected} name="agentSelected" onChange={this.handleInputChange} />
                        {this.state.agents ?
                            <datalist id="agents">
                                {this.state.agents.map(agent => (
                                    <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                ))}
                            </datalist>
                            : null}
                        <button className="btn btn-sm btn-info" onClick={this.populateAgentData}>Search</button>
                    </form>
                    {this.state.agentData ?
                        <>
                            <div className="card">
                                <div className="card-header">
                                    <h4>Agent Data</h4>
                                </div>
                                <div className="card-body">
                                    {this.state.editAgentData ?
                                
                                <form>

                                    <Table bordered hover style={{backgroundColor: 'white'}}>
                                        <tbody>
                                            <tr>
                                                <th>Question</th>
                                                <th>Input</th>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>First name</td>
                                                <td><input type="text" value={this.state.agentFirstName} onChange={this.handleInputChange} name="agentFirstName" placeholder="First Name" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Last name</td>
                                                <td><input type="text" value={this.state.agentLastName} onChange={this.handleInputChange} name="agentLastName" placeholder="Last Name" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Email</td>
                                                <td> <input type="text" value={this.state.agentEmail} onChange={this.handleInputChange} name="agentEmail" placeholder="E-mail address" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Telephone</td>
                                                <td><input type="text" value={this.state.agentTelephone} onChange={this.handleInputChange} name="agentTelephone" placeholder="Telephone number" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Address</td>
                                                <td><input type="text" value={this.state.agentAddress} onChange={this.handleInputChange} name="agentAddress" placeholder="Address" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Resident State</td>
                                                <td><input type="text" value={this.state.agentResidentState} onChange={this.handleInputChange} name="agentResidentState" placeholder="Resident State" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Dedicated CSR</td>
                                                <td>
                                                    {/* <input type="text" value={this.state.agentDedicatedCSR} onChange={this.handleInputChange} name="agentDedicatedCSR" placeholder="Dedicated CSR" /> */}
                                                    {this.state.agents ? <div>
                                                        <select id="protegeDropMenu" value={this.state.agentDedicatedCSR} onChange={this.handleInputChange} name="agentDedicatedCSR">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.state.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Supervisor</td>
                                                <td>
                                                    {/* <input type="text" value={this.state.agentSupervisor} onChange={this.handleInputChange} name="agentSupervisor" placeholder="Supervisor" /> */}
                                                    {this.state.agents ? <div>
                                                    <select id="protegeDropMenu" value={this.state.agentSupervisor} onChange={this.handleInputChange} name="agentSupervisor">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.state.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                        onClick={(e) => this.saveAgentData(e)}>Submit
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    </form>
                                    
                                    : 
                                  
                                    <div>
                                        {/* <ol> */}
                                            <p>Agent Name: {this.state.agentData.firstName + " " + this.state.agentData.lastName}</p>
                                            <p>Agent Phone: {this.state.agentData.telephone}</p>
                                            <p>Agent Email: {this.state.agentData.email}</p>
                                            <button onClick={(e) => {this.editAgentData(e)}}>Edit</button>
                                        {/* </ol> */}
                                    </div>
                                    }
                                </div>
                            </div>
                        </>
                        : null}
                </div>
            </div >
        )
    }
}


export default EditAgent;
