import React, { Component } from "react";
import "./style.css";
import Parralax from '../../components/Parralax'
import QuoteCard from '../../components/AgentQuoteCard'
import MasterControl from '../../components/MasterControl'
import QuoteController from "../../components/QuoteController"
import API from '../../utils/API'
import Licensing from '../../components/Licensing'
import AgentToolbar from "../../components/AgentToolbar";
import Modal from "react-modal";
import UpcomingRenewals from "../../components/UpcomingRenewals";
import LatestActivity from "../../components/LatestActivity";
import QuickQuoteViewer from "../../components/QuickQuoteViewer";
import QuickQuote from "../../components/QuickQuote";
import Table from 'react-bootstrap/Table';
import QuickQuoteAgent from "../../components/QuickQuoteAgent";
import ProspectMasterControl from "../../components/ProspectMasterControl";
import LoginCard from "../../components/LoginCard";
import QuoteDatabase from "../../components/QuoteDatabase";
import AgentQuoteDatabase from "../../components/AgentQuoteDatabase";
import AgentMasterControl from "../../components/AgentMasterControl";
import CreateServiceRequestAgent from "../../components/CreateServiceRequestAgent";
// import Charts from 'react-chartjs-2';
import cogoToast from 'cogo-toast';
import dateFormat from 'dateformat';

const customStylesModal = {
    content: {
        padding: '5px 5px',
        backgroundImage: 'linear-gradient(to top, lightblue 0%, whitesmoke 100%)',
        border: '0px',
        height: '50%',
        textAlign: 'center'
    }
}


class AgentPage extends Component {

    state = {
        showAgentDashboard: true,
        showAgentMasterControl: false,
        showAgentQuoteRequestForm: false,
        totalPremiums: 0,
        agentUmbrellas: [],
        agentProperties: [],
        agentAutos: [],
        showMainPanel: true,
        showQuoteCard: false,
        showQuoteController: false,
        showMasterControl: false,
        showCharts: false,
        openAccount: false,
        openQuoting: false,
        openMasterControl: false,
        openProfile: false,
        openToolkit: false,
        openEducation: true,
        submitRequest: false,
        showLoginCard: false,
        openSales: false,
        allQuotes: [],
        agentPageServicePhone: '6103557895',
        agentPageServiceEmail: 'randy@fawncircle.com',
        agentReferralsTotal: 0,
        agentPoliciesTotal: 0,
        agentClientsTotal: 0,
        agentQuotesTotal: 0,
        agentNewestCaseReferred: '',
        agentNewestCaseSold: '',
        agentClientBookLink: '',
        agentProfileLink: '',
        agentServiceLink: '',
        agentEmail: '',
        agentPhone: '',
        agentName: 'Evan Cleary',
        openAccountButtonText: 'Home',
        loaded: true,
        gatheredDataServiceRequests: []
    }

    // openAccount={(e) => {this.openAccount(e)}}
    // openQuoting={(e) => {this.openQuoting(e)}}
    // openMasterControl={(e) => {this.openMasterControl(e)}}
    // openProfile={(e) => {this.openProfile(e)}}
    // openToolkit={(e) => {this.openToolkit(e)}}
    // openEducation={(e) => {this.openEducation(e)}}

    componentDidMount = () => {
        console.log("Agent Dash Mounted")
        setTimeout(() => {
            this.getUserData()
        }, 1000)

        setTimeout(() => {
            this.setState({
                showCharts: true
            })
            console.log("Agent Page State");
            console.log(this.state);
        }, 5000)
    }

    getUserData = () => {

        var userID = this.props.user.uid
        
        API.findAgentLogin(userID)
            .then(res => {
                if (res.data.length > 0){
                    this.setState({
                        agentData: res.data[0],
                        openAccount: true
                    })
                    console.log("Agent Data saved to state:");
                    console.log(this.state);
                    setTimeout(() => { this.getItems() }, 1000)
                } else {
                    let showLoginCard = () => {
                        this.setState({
                            showLoginCard: true
                        })
                    }
                    if (window.confirm("Agent Data not found, log in?") === true){
                        showLoginCard();
                    } else {
                        document.location.href = "/"
                    }
                }
            })
            .catch(err => {
                let showLoginCard = () => {
                    this.setState({
                        showLoginCard: true
                    })
                }
                if (window.confirm("Agent Data not found, log in?") === true){
                    showLoginCard();
                } else {
                    document.location.href = "/"
                }

            })
        }

    copyText = (event, data) => {
        event.preventDefault();

        var copyText = data;
        navigator.clipboard.writeText(copyText);
        console.log(navigator.clipboard);
        cogoToast.success("Copied: " + data)
    }

    getItems = () => {

        var agentID = this.state.agentData._id

        API.getAgentClients(this.state.agentData._id)
            .then(res => {
                console.log("Found client data");
                console.log(res);
               
                this.setState({ clients: res.data })
                let clientIDs = this.state.clients.map(client => client._id)
                console.log("Searching for Service requests using client ids: ");
                console.log(clientIDs);
                API.getServiceRequestsForManyClients(clientIDs)
                    .then(res => {
                        console.log("Found service requests for clients of agent");
                        // To-Do Sort requested by last created
                        console.log(res.data);
                        this.setState({ gatheredDataServiceRequests: res.data });
                    }).catch(err => console.log(err));
            })
            .catch(err => console.log(err));

        API.getAgentAutos(agentID)
            .then(res => {
                this.setState({ agentAutos: res.data })
                console.log(res.data);  
            } )
            .catch(err => console.log(err))

        API.getAgentProperties(this.state.agentData._id)
            .then(res => this.setState({ agentProperties: res.data }))
            .catch(err => console.log(err));

        API.getAgentUmbrellas(this.state.agentData._id)
            .then(res => this.setState({ agentUmbrellas: res.data }))
            .catch(err => console.log(err));

        API.getWritingAgentQuotes(this.state.agentData._id)
            .then(res => this.setState({ quotes: res.data }))
            .catch(err => console.log(err));

        API.getQuotesForAgent(this.state.agentData._id)
            .then(res => {this.setState({ allQuotes: res.data })
            console.log("All quotes tagged to agent:")    
            console.log(res.data)
            })
            .catch(err => console.log(err));

        setTimeout(() => {
            this.calculateAutoPremiums()
            this.breakDownCommissions(agentID)
            console.log("Agent State: ");
            console.log(this.state);
        }, 1000)
    }

    refreshQuotes = () => {
        API.getAllAgentQuotes(this.state.agentData._id)
        .then(res => this.setState({ allQuotes: res.data }))
        .catch(err => console.log(err)); 
    }

    refreshWritingAgentQuotes = () => {
        API.getWritingAgentQuotes(this.state.agentData._id)
            .then(res => this.setState({ quotes: res.data}))
            .catch(err => console.log(err))
        
        this.refreshQuotes()
    }

    breakDownCommissions = (id) => {
        console.log(this.state.agentAutos)
        console.log(this.state.agentProperties)
        console.log(this.state.agentUmbrellas)
        var agentPolicies = {
            autos: this.state.agentAutos,
            props: this.state.agentProperties,
            umbs: this.state.agentUmbrellas
        }
        console.log(agentPolicies)
        var autoComm = 0;
        var propComm = 0;
        var umbComm = 0;
        for (var i = 0; i < agentPolicies.autos.length; i++) {
            if (id === agentPolicies.autos[i].mainAgent) {
                autoComm += (agentPolicies.autos[i].premium * (agentPolicies.autos[i].mainAgentSplit / 100)) * 0.06
            } else if (id === agentPolicies.autos[i].refAgentOne) {
                autoComm += (agentPolicies.autos[i].premium * (agentPolicies.autos[i].refAgentOneSplit / 100)) * 0.06
            } else if (id === agentPolicies.autos[i].refAgentTwo) {
                autoComm += (agentPolicies.autos[i].premium * (agentPolicies.autos[i].refAgentTwoSplit / 100)) * 0.06
            } else if (id === agentPolicies.autos[i].refAgentThree) {
                autoComm += (agentPolicies.autos[i].premium * (agentPolicies.autos[i].refAgentThreeSplit / 100)) * 0.06
            } else if (id === agentPolicies.autos[i].refAgentFour) {
                autoComm += (agentPolicies.autos[i].premium * (agentPolicies.autos[i].refAgentFourSplit / 100)) * 0.06
            }
            // console.log(agentPolicies.autos[i].clientFirstName + " " + agentPolicies.autos[i].clientLastName + " Commissions: " + autoComm)
        }
        for (var j = 0; j < agentPolicies.props.length; j++) {
            if (id === agentPolicies.props[j].mainAgent) {
                propComm += (agentPolicies.props[j].premium * (agentPolicies.props[j].mainAgentSplit / 100)) * 0.06
            } else if (id === agentPolicies.props[j].refAgentOne) {
                propComm += (agentPolicies.props[j].premium * (agentPolicies.props[j].refAgentOneSplit / 100)) * 0.06
            } else if (id === agentPolicies.props[j].refAgentTwo) {
                propComm += (agentPolicies.props[j].premium * (agentPolicies.props[j].refAgentTwoSplit / 100)) * 0.06
            } else if (id === agentPolicies.props[j].refAgentThree) {
                propComm += (agentPolicies.props[j].premium * (agentPolicies.props[j].refAgentThreeSplit / 100)) * 0.06
            } else if (id === agentPolicies.props[j].refAgentFour) {
                propComm += (agentPolicies.props[j].premium * (agentPolicies.props[j].refAgentFourSplit / 100)) * 0.06
            }

            // console.log(agentPolicies.props[j].clientFirstName + " " + agentPolicies.props[j].clientLastName + " Commissions: " + propComm)
        }
        for (var k = 0; k < agentPolicies.umbs.length; k++) {
            if (id === agentPolicies.umbs[k].mainAgent) {
                umbComm += (agentPolicies.umbs[k].premium * (agentPolicies.umbs[k].mainAgentSplit / 100)) * 0.06
            } else if (id === agentPolicies.umbs[k].refAgentOne) {
                umbComm += (agentPolicies.umbs[k].premium * (agentPolicies.umbs[k].refAgentOneSplit / 100)) * 0.06
            } else if (id === agentPolicies.umbs[k].refAgentTwo) {
                umbComm += (agentPolicies.umbs[k].premium * (agentPolicies.umbs[k].refAgentTwoSplit / 100)) * 0.06
            } else if (id === agentPolicies.umbs[k].refAgentThree) {
                umbComm += (agentPolicies.umbs[k].premium * (agentPolicies.umbs[k].refAgentThreeSplit / 100)) * 0.06
            } else if (id === agentPolicies.umbs[k].refAgentFour) {
                umbComm += (agentPolicies.umbs[k].premium * (agentPolicies.umbs[k].refAgentFourSplit / 100)) * 0.06
            }
            // console.log(agentPolicies.umbs[k].clientFirstName + " " + agentPolicies.umbs[k].clientLastName + " Commissions: " + umbComm)
        }
        setTimeout(() => {
            this.setState({
                estRenewals: (autoComm + propComm + umbComm).toFixed(2)
            })
            console.log("Estimated Renewals for Agent: $" + this.state.estRenewals)
        }, 1000)
    }

    calculateAutoPremiums = () => {
        var totalPremiums = 0;
        for (var i = 0; i < this.state.agentAutos.length; i++) {
            totalPremiums += this.state.agentAutos[i].premium
        }
        console.log(totalPremiums)
        this.calculatePropertyPremiums(totalPremiums)
    }

    calculatePropertyPremiums = (premium) => {
        var totalPremiums = premium;
        for (var i = 0; i < this.state.agentProperties.length; i++) {
            totalPremiums += this.state.agentProperties[i].premium
        }
        console.log("New total: " + totalPremiums)
        this.calculateUmbrellaPremiums(totalPremiums)
    }

    calculateUmbrellaPremiums = (premium) => {
        var totalPremiums = premium;
        for (var i = 0; i < this.state.agentUmbrellas.length; i++) {
            totalPremiums += this.state.agentUmbrellas[i].premium
        }
        console.log("New Total: " + totalPremiums)
        this.setState({
            totalPremiums: totalPremiums
        })
    }

    showMainPanelTab = () => {
        
        this.setState({
            showMainPanel: true,
            showMasterControl: false,
            showQuoteCard: false,
            showQuoteController : false,
            showLicensing: false,
            openSales: false
        })
    }

    showMasterControlTab = () => {
        this.setState({
            showMainPanel: false,
            showMasterControl: true,
            showQuoteCard: false,
            showQuoteController: false,
            showLicensing: false,
            openSales: false
        })
    }

    showQuoteCardTab = () => {
        this.setState({
            showMainPanel: false,
            showMasterControl: false,
            showQuoteCard: true,
            showQuoteController: false,
            showLicensing: false,
            openSales: false
        })
    }

    showQuoteControllerTab = () => {
        this.setState({
            showMainPanel: false,
            showMasterControl: false,
            showQuoteCard: false,
            showQuoteController: true,
            showLicensing: false,
            openSales: false
        })
    }

    showLicensingTab = () => {
        this.setState({
            showMainPanel: false,
            showMasterControl: false,
            showQuoteCard: false,
            showQuoteController: false,
            showLicensing: true,
            openSales: false
        })
    }

    openAccount = (event) => {
        if(this.state.openAccount === false){
            this.setState({
                openAccount: true,
                openQuoting: false,
                openMasterControl: false,
                openProfile: false,
                openToolkit: false,
                openEducation: true,
                openAccountButtonText: 'Home',
                openSales: false
            })
        } else {
            this.setState({
                openAccountButtonText: 'Already home!'
            })
        }
    }

    openQuoting = (event) => {
        if(this.state.openQuoting === false){
            this.setState({
                openAccount: false,
                openQuoting: true,
                openMasterControl: false,
                openProfile: false,
                openToolkit: false,
                openEducation: false,
                openAccountButtonText: 'Back',
                openSales: false
            })
        }
       
    }

    openSales = (event) => {
        if(this.state.openSales === false){
            this.setState({
                openAccount: false,
                openQuoting: false,
                openMasterControl: false,
                openProfile: false,
                openToolkit: false,
                openEducation: false,
                openAccountButtonText: 'Back',
                openSales: true

            })
        }
       
    }

    openMasterControl = (event) => {
        if(this.state.openMasterControl === false){
            this.setState({
                openAccount: false,
                openQuoting: false,
                openMasterControl: true,
                openProfile: false,
                openToolkit: false,
                openEducation: false,
                openAccountButtonText: 'Back',
                openSales: false
            })
        }
    }

    openProfile = (event) => {
        if(this.state.openProfile === false){
            this.setState({
                openAccount: false,
                openQuoting: false,
                openMasterControl: false,
                openProfile: true,
                openToolkit: false,
                openEducation: false,
                openAccountButtonText: 'Back',
                openSales: false
            })
        }
    }

    openToolkit = (event) => {
        if(this.state.openToolkit === false){
            this.setState({
                openAccount: true,
                openQuoting: false,
                openMasterControl: false,
                openProfile: false,
                openToolkit: true,
                openEducation: true,
                openAccountButtonText: 'Back',
                openSales: false
            })
        }
    }

    openEducation = (event) => {
        if(this.state.openEducation === false){
            this.setState({
                openAccount: false,
                openQuoting: false,
                openMasterControl: false,
                openProfile: false,
                openToolkit: false,
                openEducation: true,
                openAccountButtonText: 'Back',
                openSales: false
            })
        }
    }

    submitRequest = (event) => {
        if (this.state.submitRequest === false){
            this.setState({
                submitRequest: true
            })
        } else {
            this.setState({
                submitRequest: false
            })
        }
    }

    render() {
        return (
            <div className="container-fluid auroral-northern" style={{padding: '0px'}}>
                <Parralax />
                {this.state.showLoginCard ? 
                                        <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3" >
                                            <LoginCard  />
                                                {/* // switchToSignup={() => {this.switchToSignupForm()}} */}
                                            {/* /> */}
                                            <br />
                                        </div>
                                    : null}

                {/* <AgentToolbar 
                    openAccount={(e) => {this.openAccount(e)}}
                    openQuoting={(e) => {this.openQuoting(e)}}
                    openMasterControl={(e) => {this.openMasterControl(e)}}
                    openProfile={(e) => {this.openProfile(e)}}
                    openToolkit={(e) => {this.openToolkit(e)}}
                    openEducation={(e) => {this.openEducation(e)}}
                    openAccountButtonText={this.state.openAccountButtonText}
                    openSales={(e) => {this.openSales(e)}}
                /> */}
                <br />

                <Modal
                    isOpen={this.state.submitRequest}
                    onRequestClose={(e) => {this.submitRequest(e)}}
                    style={customStylesModal}
                >


                    <CreateServiceRequestAgent
                            clients={this.state.clients}
                    />
                </Modal>

                <div className="card" style={{ height: '100%', backgroundColor: 'transparent',  backgroundSize: 'cover' }}>
 
                    <div className="tab-content" id="myTabContent" style={{ textAlign: 'center' }}>
                    <br />
                    {this.state.openAccount ? 

                        <div className="container" style={{height: '100%'}}>
                            {/* <div style={{width: '10%', height: '100px', float: 'left'}}></div> */}
                            <div className="row" style={{marginBottom: '20px'}}>
                               
                            <div className="col-lg-6 col-md-12">
                            {/* <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2 col-10 offset-1"> */}
                                <div className="card text-white mb-3" style={{ overflow: 'auto', padding: '0px', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.2)' }}>
                                    <div className="card-header" style={{ overflow: '', textAlign: 'center' }}>
                                    <h4>Welcome, {this.state.agentData ? this.state.agentData.firstName : null}!</h4>
                                    </div>
                                    <div className="card-body" style={{ height: '100%', padding: '0px', textAlign: 'left', backgroundColor: 'transparent' }}>

                                            <ul>
 
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                                 <strong>Agent Name: </strong>
 
                                                        <span style={{color: 'white'}}>{this.state.agentName}</span>
                                                </li>
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                                    <strong>Dedicated Service Contact#: </strong>
                                                    
                                                        {/* {this.state.agentPageServicePhone} */}
                                                        <u><span onClick={(e) => { this.copyText(e, this.state.agentPageServicePhone)}} style={{color: 'white'}}>{this.state.agentPageServicePhone}</span> <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, this.state.agentPageServicePhone)}}></i></u>
                                                    
                                                </li>
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                             
                                                        <strong>Dedicated Service E-mail: </strong>
                                         
                                                   
                                                        <u><span onClick={(e) => { this.copyText(e, this.state.agentPageServiceEmail)}} style={{color: 'white'}}>{this.state.agentPageServiceEmail}</span> <i className="fa-solid fa-copy" onClick={(e) => { this.copyText(e, this.state.agentPageServiceEmail)}}></i></u>
                                                    
                                                </li>
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                                    <strong>Quotes: </strong>
                                                    {/* {this.state.allQuotes.length > 0 ?  */}
                                                        <span style={{color: 'white'}}>{this.state.allQuotes.length}</span>                                                    
                                                    {/* : null} */}

                                                   
                                                </li>
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                       
                                                        <strong>Policies: </strong>
                                                    
                                        
                                                    <span style={{color: 'white'}}>{this.state.agentProperties.length + this.state.agentAutos.length + this.state.agentUmbrellas.length}</span>
                                          
                                                </li>
                                                {/* <li style={{padding: '5px', border: '0px solid black'}}>
                         
                                                        <strong>Clients: </strong>
                                     
                                                        <span style={{color: 'white'}}>{this.state.agentClientsTotal}</span>
                                  
                                                </li> */}
                                                {/* <li style={{padding: '5px', border: '0px solid black'}}>
                                        
                                            
                                                        <button  style={{marginRight: '5%', width: '150px'}}  className="btn btn-sm btn-light" onClick={(e) => {this.openQuoting(e)}}>New Case</button>
                                                 
                                                </li> */}
                                                {/* <li style={{padding: '5px', border: '0px solid black'}}>
                                          

                                                 
                                                        <button  style={{marginRight: '5%', width: '150px'}}  className="btn btn-sm btn-secondary" onClick={(e) => {this.openMasterControl(e)}}>Show Clients</button>
                                           
                                                </li> */}

                                                {/* <li style={{padding: '5px', border: '0px solid black'}}>
                                     

                                                        <button  style={{marginRight: '5%', width: '150px'}}  className="btn btn-sm btn-dark" onClick={(e) => {this.openProfile(e)}}>View Sales</button>
                                        
                                                </li> */}

                                                {/* <li style={{padding: '5px', border: '0px solid black'}}>
                                
                                    
                                        
                                                        <button  style={{marginRight: '5%', width: '150px'}}  className="btn btn-sm btn-dark" onClick={(e) => {this.openProfile(e)}}>View Profile</button>
                                         
                                                </li> */}
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                         
                                                        {/* <strong>Create Service Request</strong> */}
                                             
                                              
                                                        <button  style={{marginRight: '5%', width: '150px'}}  className="btn btn-sm btn-light" onClick={(e) => {this.submitRequest(e)}}>Submit Request</button>
                                                   
                                                </li>

                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                                
                                                        {/* <strong>Main Email</strong> */}
                                               
                                                  
                                                        {/* {this.state.userData.email} <i class="fa fa-pencil" aria-hidden="true" onClick={(e) => {this.editUserData(e)}}></i> */}
                                                        {/* <br /> */}
                                                        <button style={{marginRight: '5%', width: '150px'}} className="btn btn-sm btn-secondary">Edit E-mail</button>
                                                 
                                                </li>
                                                <li style={{padding: '5px', border: '0px solid black'}}>
                                                    {/* <td> */}
                                                        {/* <strong>Main Phone</strong> */}
                                                        {/* </td> */}
                                                    {/* <td> */}
                                                        {/* {this.state.userData.telephone} <i class="fa fa-pencil" aria-hidden="true" onClick={(e) => {this.editUserData(e)}}></i> */}
                                                        {/* <br /> */}
                                                        <button style={{marginRight: '5%', width: '150px'}} className="btn btn-sm btn-dark">Edit Phone</button>
                                                    {/* </td> */}
                                                </li>
                                            
                                            
                                            </ul>
                                            {this.state.openEducation && this.state.loaded ? 
                                                <Licensing 
                                                    agentData={this.state.agentData}
                                                    uid={this.props.user.uid}
                                                />
                                            : null}

                                            {/* </tbody> */}
                                        {/* </Table> */}


                                    </div>
                                </div>
                        </div>

                            <div className="col-lg-6 col-md-12">
                                {/* <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2 col-10 offset-1"> */}
                                    {/* <div className="card"> */}
                                    <div className="card text-white mb-3" style={{ overflow: 'auto', padding: '0px', textAlign: 'center', backgroundColor: 'rgba(0,0,0,0.2)' }}>
                                        <div className="card-header">
                                            <h4>Quote Submission</h4>
                                        </div>
                                        <div className="card-body">
                                            {/* Submit Quote Here! */}
                                            <QuickQuoteAgent
                                                        agents={this.props.agents}
                                                        agentData={this.state.agentData}
                                                        rerender={this.refreshQuotes}
                                                        backgroundColor='transparent'
                                                    />
                                        </div>
                                    </div>
                            </div>

                            

                            <div className="col-12">
                                {/* <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2 col-10 offset-1"> */}
                                    {/* <div className="card"> */}
                                    <div className="card text-white mb-3" style={{ overflow: 'auto', padding: '0px', textAlign: 'center', backgroundColor: 'rgba(0,0,0,0.2)' }}>
                                        <div className="card-header">
                                            <h4>Quote Tracker</h4>
                                        </div>
                                        <div className="card-body">
                                            {/* Submit Quote Here! */}
                                            <AgentQuoteDatabase 
                                                agentID={this.state.agentData._id}
                                            />
                                        </div>
                                    </div>
                            </div>

                            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-10 offset-1">
                                <div className="card" style={{padding: '0px', backgroundColor: 'transparent'}}>
                                    <div className="card-header" style={{backgroundColor: 'rgba(255,255,255,0.8)'}}>
                                        <h4>Client Service Requests</h4>
                                    </div>
                                    <div className="card-body" style={{backgroundColor: 'rgba(255,255,255,0.7)'}}>
                                        <table striped>
                                            <tr>
                                                <th>Requested</th>
                                                <th>Message</th>
                                                <th>Type</th>
                                                <th>Resolved?</th>
                                            </tr>
                                            {this.state.gatheredDataServiceRequests.map(reqData => (
                                                <tr>
                                                    <td>{dateFormat(reqData.created_at, "mmmm dS, yyyy")}</td>
                                                    <td>{reqData.message}</td>
                                                    <td>{reqData.type}</td>
                                                    <td>{reqData.completed}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">

                                <div className="card mb-3" style={{overflow: 'auto', padding: '0px', textAlign: 'center', backgroundColor: 'rgba(0,0,0,0.2)'}}>
                                    <div className="card-header">
                                        <h4 style={{color: 'white'}}>Client List</h4>
                                    </div> 
                                    <div className="card-body">
                                        <AgentMasterControl
                                            autos={this.state.autos}
                                            properties={this.state.properties}
                                            umbrellas={this.state.umbrellas}
                                            agentAutos={this.state.agentAutos}
                                            agentProperties={this.state.agentProperties}
                                            agentUmbrellas={this.state.agentUmbrellas}
                                            agentID={this.state.agentData}
                                        />
                                    </div>
                                </div>
                               
                                
                            </div>
                            {/* <div style={{width: '10%', height: '100px', float: 'left'}}></div> */}

                            </div>
                            

                            <br />
                            <br />
                            {/* <SmallParralax /> */}
                            {/* <div style={{marginTop: '10px'}}> */}
                            {/* <div style={{width: '14.5%', height: '100px', float: 'left'}}></div> */}

                            {/* <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2 col-10 offset-1">
                                    <UpcomingRenewals style={{width: '100%'}}/>
                                </div>
                            </div> */}
                            {/* <div style={{width: '14.5%', height: '100px', float: 'left'}}></div> */}
                            {/* </div> */}


                            <br />
                            <br />
                            {/* <SmallParralax /> */}
                            {/* <div style={{width: '15%', height: '100px', float: 'left'}}></div> */}

                            {/* <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-sm-8 offset-sm-2 col-10 offset-1">
                                    <LatestActivity style={{width: '100%'}}/>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col">

                                <div className="card" style={{ height: '100%', backgroundColor: 'transparent', border: '0px'}}>
                                {/* <div className="card-header" style={{backgroundColor: 'transparent'}}> */}
                                    {/* <h2>Main Dashboard</h2> */}
                                {/* </div> */}
                                <div className="card-body" style={{ padding: '5%', backgroundColor: 'transparent', backgroundSize: 'cover'}}>


                                    <div className="row">
                                        {/* <div className="col-lg-12">
                                            <QuickQuoteViewer
                                                quotes={this.state.allQuotes}
                                            />

                                        </div> */}
                                        {/* <Parralax /> */}
                                        <br />
                                        <br />
                                        {/* End of Active Quotes Col */}

                                        <div className="col-lg-12" style={{marginTop: '50px'}}>
                                            {/* <div>
                                                <div className="card-body">

                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    Active Policies
                                                                </div>
                                                                <div className="card-body">
                                                                    {this.state.agentUmbrellas.length + this.state.agentAutos.length + this.state.agentProperties.length}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <div className="card">
                                                                 <div className="card-header">
                                                                        In-force Premium
                                                                </div>
                                                                <div className="card-body">
                                                                    ${this.state.totalPremiums}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-4">
                                                        <div className="card">
                                                                <div className="card-header">
                                                                    Expected Commissions
                                                                </div>
                                                                <div className="card-body">
                                                                    ${this.state.estRenewals}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Table striped bordered hover style={{backgroundColor: 'white'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td>Autos submitted</td>
                                                                <td>{this.state.agentAutos ? this.state.agentAutos.length : <img alt="loading" src="/loading.gif" />}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Homes submitted</td>
                                                                <td>{this.state.agentProperties ? this.state.agentProperties.length : <img  alt="loading" src="/loading.gif" />}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Umbrellas submitted</td>
                                                                <td>{this.state.agentUmbrellas ? this.state.agentUmbrellas.length : <img  alt="loading" src="/loading.gif" />}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Total Policies submitted</strong></td>
                                                                <td>{this.state.agentUmbrellas ? <strong>{
                                                                    this.state.agentUmbrellas.length +

                                                                    this.state.agentAutos.length +

                                                                this.state.agentProperties.length}</strong> : <img  alt="loading" src="/loading.gif" />}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Premium submitted</td>
                                                                <td>{this.state.totalPremiums ?
                                                                    <>${this.state.totalPremiums}</> : <img  alt="loading" src="/loading.gif" />
                                                                }
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Expected Renewals</td>
                                                                <td>

                                                                    {this.state.estRenewals ?

                                                                        <p>${this.state.estRenewals}</p>
                                                                        : <img  alt="loading" src="/loading.gif" />}

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </Table>

                                                </div>
                                            </div> */}
                                        </div>

                                        {/* <div className="col-lg-12" style={{marginTop: '50px'}}>
                                            {this.state.showCharts ? 
                                                <Charts 
                                                    autos={this.state.autos}
                                                    properties={this.state.properties}
                                                    umbrellas={this.state.umbrellas}
                                                    agentAutos={this.state.agentAutos}
                                                    agentProperties={this.state.agentProperties}
                                                    agentUmbrellas={this.state.agentUmbrellas}
                                                    agentID={this.state.agentData}
                                                />
                                            : null}
 
                                        </div> */}
                                        {/* <div className="col-lg-6" style={{marginTop: '50px'}}>
                                            <div className="row" style={{padding: '5px'}}>
                                                <div className="col">
                                                    <QuickQuote
                                                        agents={this.props.agents}
                                                        agentData={this.state.agentData}
                                                        rerender={this.refreshQuotes}
                                                    />
                                                </div>
                                            </div>
                                         </div> */}
                                    </div>

                                </div>

                            </div>  

                                </div>
                            </div>
                            {/* <div style={{width: '15%', height: '100px', float: 'left'}}></div> */}
                        </div>
                       

                                         
                    : null }

                        {/* <div className="tab-pane fade show active" id="prospect" role="tabpanel"> */}
                            
                        {/* </div> */}

                        {/* <div className="tab-pane fade" id="client" role="tabpanel" aria-labelledby="client-tab"> */}
                        {this.state.openMasterControl ? 
                               <div className="card" style={{ height: '100%', borderRadius: '15px' }}>

                               <MasterControl
                                   autos={this.state.autos}
                                   properties={this.state.properties}
                                   umbrellas={this.state.umbrellas}
                                   agentAutos={this.state.agentAutos}
                                   agentProperties={this.state.agentProperties}
                                   agentUmbrellas={this.state.agentUmbrellas}
                                   agentID={this.state.agentData}
                               />

                           </div>
                        
                        : null}

                        {this.state.openSales ? 
                            <div className="card" style={{height: '100%', borderRadius: '15px'}}>
                                {/* <QuoteDatabase /> */}
                                <AgentQuoteDatabase 
                                  agentID={this.state.agentData._id}
                                />
                            </div>
                        : null }

                        {/* {this.state.openMasterControl ? 
                               <div className="card" style={{ height: '100%', borderRadius: '15px' }}>

                               <ClientMasterControl
                                   autos={this.state.autos}
                                   properties={this.state.properties}
                                   umbrellas={this.state.umbrellas}
                                   agentAutos={this.state.agentAutos}
                                   agentProperties={this.state.agentProperties}
                                   agentUmbrellas={this.state.agentUmbrellas}
                                   agentID={this.state.agentData}
                               />

                           </div>
                        
                        : null} */}
                     
                        {/* </div> */}

                        {/* <div className="tab-pane fade" id="natural" role="tabpanel" aria-labelledby="natural-tab"> */}
                        {this.state.openQuoting ? 
                            <div className="card" style={{backgroundColor: 'lightblue'}}>
                                <div className="col-8 offset-2">
                                            {/* Submit Quote Here! */}
                                            <QuickQuoteAgent
                                                        agents={this.props.agents}
                                                        agentData={this.state.agentData}
                                                        rerender={this.refreshQuotes}
                                                        backgroundColor='transparent'
                                                    />
                                </div>
                            </div>
                        : null}

                        {/* </div> */}

                        {/* <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab"> */}
{/* 
                        {this.state.openToolkit ? 
                            <div className="card" style={{ height: '100%', borderRadius: '15px' }}>

                                <QuoteController
                                    quotes={this.state.allQuotes}
                                    rerender={this.refreshWritingAgentQuotes}
                                    agents={this.props.agents}
                                />
                    
                            </div>
                        : null } */}

                        {/* {this.state.openProfile ? 
                            <div style={{height: '500px', width: '100%'}}>Profile Data:</div>
                        : null} */}

                        {/* {this.state.openEducation ? 
                            <Licensing 
                                agentData={this.state.agentData}
                                uid={this.props.user.uid}
                            />
                        : null} */}

                        {/* </div> */}

                    </div>

                </div>
            </div>
        )

    }

}

export default AgentPage;