import React, { Component } from "react";
import "./style.css";
import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class TeamCard extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }


    redirect = (url) => {
        console.log("redirecting to: " + url);
        window.open(url, '_blank')
    }


    render() {
        return (
            <div>
                <div className="row" style={{padding: '10px', height: '800px', overflow: 'auto' }}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div  className="card">
                        <div className="card-header">
                            <h2>Our Team</h2>
                        </div>

                        <div className="card-body">
                        <ListGroup className="offset-lg-3 col-lg-6 list-group-flush" style={{boxShadow: '0px 0px 4px 4px black', borderRadius: '10px',}}>

                            <ListGroup.Item>

                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <img alt="" id="headshot-img" style={{ height: '75%', width: '100%', borderRadius: '50%' }} src="/images/ec-headshot.png" />
                                        <button className="btn primary-btn" onClick={() => this.redirect('https://www.linkedin.com/in/evan-cleary-29862036/')}> 
                                                <i className="fa fa-linkedin-square" target="_blank" style={{fontSize:'36px', color: 'blue'}}></i>

                                            </button>
                                            
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-12">
                                        <h4><strong>Evan Cleary - CEO / Founder</strong></h4>
                                        <h6><strong>Evan is the Principal Agent of Fawn Circle. He also built this website! Evan's unique skillset propels Fawn Circle's service model to one of excellence</strong></h6>
                                        <h6><strong>Evan lives with his wife in Manayunk, PA and loves spending time with his 8 nieces and nephews.</strong></h6>
                                        {/* <button style={{height: '20px', width: '25%', backgroundColor: 'blue', color: 'white'}}
                                        // onClick={() => this.showCoverPath()} 
                                        > */}
                                            {/* <button className="btn primary-btn" onClick={() => this.redirect('https://www.linkedin.com/in/evan-cleary-29862036/')}> 
                                                <i className="fa fa-linkedin-square" target="_blank" style={{fontSize:'36px', color: 'blue'}}></i>

                                            </button> */}
                                            
                                        {/* </button> */}
                                        
                                    </div>
                                </div>

                            </ListGroup.Item>


                            <ListGroup.Item>

                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <img alt="" id="headshot-img" style={{ height: '75%', width: '100%', borderRadius: '50%' }} src="/images/jn-headshot.jpg" />
                                        <button className="btn primary-btn" onClick={() => this.redirect('https://www.linkedin.com/in/joseph-naselli-jr-569bb0124/')}> 
                                                <i className="fa fa-linkedin-square" target="_blank" style={{fontSize:'36px', color: 'blue'}}></i>
                                        </button>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-12">
                                        <h4><strong>Joseph V. Naselli, Jr. - Partner / Founder</strong></h4>
                                        <h6><strong>Joseph is an experienced Manager with a track record of leading multiple teams to the highest levels of national success. His management and delegation skills let his associates flourish through calculated decision-making.</strong></h6>

                                        {/* https://www.linkedin.com/in/joseph-naselli-jr-569bb0124/ */}
                                    </div>
                                </div>

                            </ListGroup.Item>

                            <ListGroup.Item>

                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <img alt="" id="headshot-img" style={{ height: '75%', width: '100%', borderRadius: '50%' }} src="/images/rf-headshot.png" />
                                        <button className="btn primary-btn" onClick={() => this.redirect('https://www.linkedin.com/in/randy-forster-b83a61229/')}> 
                                                <i className="fa fa-linkedin-square" target="_blank" style={{fontSize:'36px', color: 'blue'}}></i>

                                        </button>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-12">
                                        <h4><strong>Randall Forster - CSR / Agent</strong></h4>
                                        <h6><strong>Randall is the main point of contact for most of our relationships. His ability to evaluate and address a situation is one of our greatest assets. </strong></h6>
                                        <h6><strong>When Randall is not assisting clients or saving his customers money, he is coaching LaCrosse in the Philadelphia Catholic League! </strong></h6>
                                

                                    </div>
                                </div>

                            </ListGroup.Item>
                            <ListGroup.Item>

                                <div className="row">
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4">
                                        <img alt="" id="headshot-img" style={{ height: '75%', width: '100%', borderRadius: '50%' }} src="/images/jc-headshot.jpeg" />
                                        <button className="btn primary-btn" onClick={() => this.redirect('https://www.linkedin.com/in/james-w-cleary-chfc%C2%AE-55139963/')}> 
                                                <i className="fa fa-linkedin-square" target="_blank" style={{fontSize:'36px', color: 'blue'}}></i>
                                        </button>
                                        
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-12">
                                        <h4><strong>James W. Cleary - Partner / Founder</strong></h4>
                                        <h6><strong>James Cleary is Senior Partner of a large financial planning firm in the Tri-State area. His 40 years of experience in the Financial Services industry provides a treasure trove of knowledge for the team.</strong></h6>



                                        {/* https://www.linkedin.com/in/james-w-cleary-chfc%C2%AE-55139963/ */}
                                    </div>
                                </div>

                            </ListGroup.Item>



                        </ListGroup>
                        <button className="btn btn-dark" onClick={this.props.return} style={{ float: 'right' }}>Return</button>

                        </div>

                    </div>
                </div>
            </div>
        )

    }

}

export default TeamCard;