import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'


class FawnButton extends Component {
    render() {
        return (

            <div>

                <button 
                    style={{
                        color: 'black', 
                        fontSize: '20px', 
                        background: 'transparent',
                        backgroundImage: 'linear-gradient(147deg, white 90%, black 0%)',
                        width: '100%', 
                        border: 'thick solid black'
                    }} 
                    // style={{
                    //     color: 'white', 
                    //     fontSize: '24px', 
                    //     boxShadow: '0px 0px 4px 4px black', 
                    //     backgroundImage: 'linear-gradient(147deg, darkgreen 90%, white 0%)', 
                    //     width: '100%', 
                    //     padding: '5px 20px 5px 20px', 
                    //     borderRadius: '5px'
                    // }} 

                    onClick={() => this.props.pf1()}>
                        
                        {this.props.buttonText}
                </button>


            </div>



        )
    }
}

export default FawnButton;
