import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
// import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
import { storage } from '../../config/Fire';
import cogoToast from 'cogo-toast';


class UploadDocWidget extends Component {
    state = {
        fileName: "",
        fileData: ""
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    componentDidMount = () => {
        console.log("Upload Document Widget mounted!")
    }

    triggerRefresh = (newDocID) => {
        console.log("Refreshing Vault after successful upload")
        this.props.refreshVault(newDocID)
    }



    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))
        // if (this.state.fileName == ""){
            this.setState({
                fileName: event.target.files[0].name
            })
        // }


        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    fileData: res
                })
            })
        }
    }

    fileSelectedHandler2 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    declaration2: res
                })
            })
        }
    }

    fileSelectedHandler3 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    declaration3: res
                })
            })
        }
    }


    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        url: urlB,
                        uploaded: true
                    })
                })
            }
        )
    }



    handleFileUpload = (event) => {
        event.preventDefault()

        // console.log("Uploading File: ")

        var fileInformation = {
            policyid: this.props.policyID,
            type: this.props.policyType.toLowerCase(),
            document: this.state.fileData,
            docName: this.state.fileName
        }

        console.log("Creating Document:");
        console.log(fileInformation);

        switch (this.props.policyType){
            case 'Property':
                console.log("Adding Document to Policy of Type: " + this.props.policyType)
                API.createDocumentForProperty(this.props.policyID, fileInformation)
                    .then((res) => { 
                        console.log(res.data);
                        cogoToast.success("Uploaded Property Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading policy");
                        console.log(err);
                    })
                break;
            case 'property':
                console.log("Adding Document to Policy of Type: " + this.props.policyType)
                API.createDocumentForProperty(this.props.policyID, fileInformation)
                    .then((res) => { 
                        console.log(res.data);
                        cogoToast.success("Uploaded Property Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading policy");
                        console.log(err);
                    })
                break;
            case 'auto':
                console.log("Adding Document to Policy of Type: " + this.props.policyType)
                API.createDocumentForAuto(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Auto Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err)
                    })

                break;
            case 'Auto':
                console.log("Adding Document to Policy of Type: " + this.props.policyType)
                API.createDocumentForAuto(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Auto Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err)
                    })
                
                break;
            case 'Umbrella':

                console.log("Adding Document to Policy of Type: " + this.props.policyType)
                API.createDocumentForUmbrella(this.props.policyID, fileInformation)
                     .then((res) => {
                         console.log(res.data);
                        cogoToast.success("Uploaded Umbrella Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })

                break;
            case 'umbrella':

                console.log("Adding Document to Policy of Type: " + this.props.policyType)
                API.createDocumentForUmbrella(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Umbrella Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })

                break;
            case 'client':
                console.log("Adding Document for Policy of Type: " + this.props.policyType)
                API.createDocumentForClient(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Client Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })
                break;
            case 'Client':
                console.log("Adding Document for Policy of Type: " + this.props.policyType)
                API.createDocumentForClient(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Client Document!")
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })
                break;
            case 'agent':
                console.log("Adding Document for Policy of Type: " + this.props.policyType)
                API.createDocumentForAgent(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Agent Document!")
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })
                break;
            case 'Agent':
                console.log("Adding Document for Policy of Type: " + this.props.policyType)
                API.createDocumentForAgent(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Agent Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })
                break;
            case 'quote':
                console.log("Adding Document for Policy of Type: " + this.props.policyType)
                API.createDocumentForQuote(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Quote Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })
                break;
            case 'Quote':
                console.log("Adding Document for Policy of Type: " + this.props.policyType)
                API.createDocumentForQuote(this.props.policyID, fileInformation)
                    .then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Quote Document!");
                        this.triggerRefresh(res.data._id);
                    }).catch((err) => {
                        cogoToast.error("Error uploading document");
                        console.log(err);
                    })
                break;
            default:
                console.log("Didn't recognize Policy type: " + this.props.policyType)
                break;
        }

        // API.savePropertyStandalonePolicy(propertyInformation).then(res => {
        //     console.log("Saved Property Policy")
        //     console.log(res.data)
        //     alert("Created Property Policy!")

        //     API.createDocumentForProperty(res.data._id, {
        //         docName: "Property Document",
        //         document: this.state.document,
        //         policyid: res.data._id,
        //         type: "property"
    
        //     }).then((res) => {
        //         console.log(res.data);
        //         cogoToast.success("Uploaded Property Document!")
        //     }).catch( err => console.log(err))
        // }
        // ).catch(err => console.log(err))


    }


    render() {
        return (

        // <span>


        // <div className="card text-white bg-dark mb-3" style={{maxWidth: '100%'}}>
        //     <div className="card-header">Attach File</div>
        //         <div className="card-body">
        //             <h5 className="card-title">Upload Document</h5>
        //             <p className="card-text">Please Attach file and press Submit</p>
        //             {/* <input type="text"  style={{width: '150px'}} value={this.state.fileName} onChange={this.handleInputChange} name="fileName" placeholder="File Name" /> */}

        //             <input style={{marginLeft: '7.5rem'}} name="fileData1" type="file" onChange={this.fileSelectedHandler} />
        //             <button className="btn-success btn" onClick={this.handleFileUpload}>Submit</button>
        //         </div>
        // </div>


        <div style={{maxWidth: '100%', border: 'thick solid black'}}>
        {/* <div className="card-header">Attach File</div> */}
            {/* <div className="card-body"> */}
            <div>
                {/* <h5 className="card-title">Upload Document</h5> */}
                <br />
                <h5 className="card-text">Upload File to Vault:</h5>
                {/* <input type="text"  style={{width: '150px'}} value={this.state.fileName} onChange={this.handleInputChange} name="fileName" placeholder="File Name" /> */}
                <br />
                <input style={{marginLeft: '7.5rem', }} name="fileData1" type="file" onChange={this.fileSelectedHandler} />
                <br />
                <button style={{backgroundColor: 'transparent', border: 'thick solid black'}} onClick={this.handleFileUpload}>Submit</button>
                <br />
            </div>
        </div>

                        // <p>File 1 attached</p>
                    

                  

 

        )
    }
}


export default UploadDocWidget;
