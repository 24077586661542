import React, { Component } from "react";
import "./style.css";
import CreateReferringAgent from '../../components/CreateReferringAgent';
// import ResponsiveParralaxCarousel from '../../components/ResponsiveParralaxCarousel'
// import ResponsiveParralaxLogin from '../../components/ResponsiveParralaxLogin'



class EnrollPage extends Component {

    state = {
        showForm: true
    }

    //   handleSubmit(e) {
    //     e.preventDefault();
    //     console.log('You clicked submit.');
    //   }

    alternatingColor = ['white', 'white'];
    alternatingText = ['black', 'black'];

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    // handleInputChange = event => {
    //     // const { name, value } = event.target;
    //     const target = event.target;
    //     const name = target.name;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     this.setState({
    //         [name]: value
    //     });
    // };

    componentDidMount = () => {
        console.log("Enroll Page")
    }


  





  handleClick = () => {
    //   console.log("Button Clicked")
  }



    render() {
        return (
            <div className="container-fluid" style={{height: '1500px'}}>
         
                <CreateReferringAgent
                 source={this.props.source}
                 />
            </div >
        )

    }

}

export default EnrollPage;