import React, { Component } from "react";
import "./style.css";
// import API from "../../utils/API";
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
import TextToQuoteCard from "../TextToQuoteCard";
import CoverPathMass from "../CoverPathMass";
import PublicQuoteCard from "../PublicQuoteCard";
import Modal from "react-modal";
// import NeptuneWidget from 'https://neptuneflood.com/dtc-widget/NeptuneWidget.umd.js'

const customStylesModal = {
    content: {
        padding: '2px 2px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        background: 'transparent',
        overflowX: 'auto',
        border: '0px'
    }
}


class QuoteModule extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        showCoverPath: false,
        showIcons: true,
        showFastQuote: false
    }

    

    componentDidMount = () => {
        console.log("Quote Module Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    showCoverPath = () => {
        if (this.state.showCoverPath === true){
            this.setState({
                showCoverPath: false,
                showIcons: true,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showCoverPath: true,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        }
    }

    returnToModule = () => {
        if (this.state.showIcons === true){
            this.setState({
                showIcons: true,
                showCoverPath: false,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showCoverPath: false,
                showTraverse: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        }
    }

    showTraverse = () => {
        if (this.state.showTraverse === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showTraverse: true,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })           
        }
    }

    showTextQuote = (e) => {
        e.preventDefault()
        if (this.state.showTextQuote === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: true,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        }
    }

    // showCoverPath = () => 
    showCoverPathMass = () => {
        if (this.state.showCoverPathMass === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: true,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        }
    }

    showNWExpress = () => {
        if (this.state.showNWExpress === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: true,
                showFastQuote: false
            })
        }
    }

    showNeptuneFlood = () => {
        if (this.state.showNeptuneFlood === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showNeptuneFlood: false,
                showFastQuote: false
            })
        } else {
            this.setState({
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showFastQuote: false,
                showNeptuneFlood: true
            })
        }
    }

    redirect = (url) => {
        console.log("redirecting to: " + url);
        window.open(url, "", "width=800, height=800, top=+200, left=+400");
        // var left = (screen.width/2)
        // var top = (screen.height/2)
        // 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left
    }

    showFastQuote = (e) => {
        e.preventDefault();
        if (this.state.showFastQuote === true){
            this.setState({
                showIcons: true,
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showFastQuote: false,
                showNeptuneFlood: false
            })
        } else {
            this.setState({
                showTraverse: false,
                showCoverPath: false,
                showTextQuote: false,
                showCoverPathMass: false,
                showNWExpress: false,
                showFastQuote: true,
                showNeptuneFlood: false
            })
        }
       
    }


    render() {
        return (
            <div>
                <div className="card" style={{border: '0px', backgroundColor: 'transparent', height: '100%', overflow: 'auto'}}>
                    <div className="card-body" style={{padding: '0px'}}>


                        <Modal  
                            isOpen={this.state.showFastQuote}
                            onRequestClose={(e) => this.showFastQuote(e)}
                            style={customStylesModal}
                        >
                            <button style={{width: '12.5%', marginLeft: '87.5%', textAlign: 'center', borderRadius: '10px', backgroundColor: 'red', color: 'white'}} onClick={(e) => this.showFastQuote(e)}>X</button>
                                                        
                            <PublicQuoteCard 
                                theme="light"
                                text="black"
                                agents={[]}
                            
                            />    

                        </Modal>

                    {/* {this.state.showFastQuote === true ?
                        <div style={{padding: '0% 10% 0% 10%'}}>
                                                
                        </div>

                        : null} */}
                        {this.state.showIcons === true ? 
                        
                        <span>
                        
                            
                            <div className="row no-gutters row-fixed" style={{padding: '20px'}}>


                                <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button className="box-shadow-button" style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width: '100%', backgroundColor: 'white'}}
                                    onClick={(e) => this.showFastQuote(e)}
                                    >
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <img alt="fawn-general" style={{height: '50px', width: '75px'}} src="/images/paper_plane_PNG71.png"></img>
                                            </div>
                                            <div className="col-xl-12">
                                            <h5 style={{color: 'black'}}><strong>Auto and/or Home Quote</strong></h5>
                                            </div>
                                        </div>

                                    </button>
                                </div>

                           
                            
                                <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button className="box-shadow-button" style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width: '100%', backgroundColor: 'red', color: 'white'}}
                                    onClick={() => this.redirect('https://cluballiance.aaa.com/insurance/ia?promo=FAWN&ian=Fawn%20Circle%20LLC')}
                                    >
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <img alt="aaa-membership" style={{height: '50px', width: '75px'}} src="/images/logos/aaa-logo.png"></img>
                                            </div>
                                            <div className="col-xl-12">
                                            <h5 style={{color: 'white'}}><strong>AAA Membership</strong></h5>
                                            </div>
                                        </div>

                                    </button>
                                </div>
                          


                            {/* NEPTUNE FLOOD PROMPT */}


                        
                            <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width:'100%', backgroundColor: 'white', color:'black'}}
                                            onClick={() => this.redirect('https://neptuneflood.com/consumer-app/?source=Sa38oXQAsJxkbvhRezGuvCFvo2g9AVLhtYFeJG0IhfWyLEDSxnHdZo8Ci27%2FphyVSmM8UAUQ2KEM4ib%2Fkdf1%2BVCdZ07Vp79X9rP6g%2FrVh0E%3D')}
                                    >
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <img alt="neptuneflood" style={{height: '50px', width: '75px'}} src="/images/neptune-logo.svg"></img>
                                            </div>
                                            <div className="col-xl-12">
                                                <h5 style={{color: 'black'}}><strong>Flood Insurance Quote</strong></h5>
                                            </div>
                                        </div>

                                    </button>
                                </div>


                               

         
                            <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <button style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width:'100%', backgroundColor: '#4ccbeb', color:'black'}}
                                            onClick={() => this.redirect('https://coverpath.massmutual.com//?fg=Evan-Cleary-621160')}
                                    >
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <img alt="massmutual-life-quote" style={{height: '60px', width: '60px'}} src="/images/logos/massmutual-logo.jpg"></img>
                                            </div>
                                            <div className="col-xl-12">
                                                <h5 style={{color: '#042d6c'}}><strong>Life Insurance Quote</strong></h5>
                                            </div>
                                        </div>

                                    </button>
                                </div>


                                <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width: '100%', backgroundColor: '#368ddf'}}
                                        onClick={() => this.redirect('https://track.nextinsurance.com/links?agent_affiliation=MItk8UCb86PK8XPT&serial=992855993&channel=affiliation')}
                                        >
                                            <div className="row">
                                                <div className="col-xl-12">

                                                    <i className="fa fa-cubes" aria-hidden="true"></i>
                                                </div>
                                                <div className="col-xl-12">
                                                <h5 style={{color: 'white'}}><strong>Business Liability Quote</strong></h5>
                                                </div>
                                            </div>
                                        </button>
                                </div>


                            <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width:'100%', backgroundColor: '#f5f7f8', color:'#258ccc'}}
                                                onClick={() => this.redirect('https://www.eventsured.com/partnership/fawn-circle-llc-2/')}
                                        >
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <img alt="eventsured" style={{height: '50px', width: '50px'}} src="/images/logos/es-logo.png"></img>
                                                </div>
                                                <div className="col-xl-12">
                                                    <h5 style={{color: '#258ccc'}}><strong>Special Events Quote</strong></h5>
                                                </div>
                                            </div>

                                        </button>
                                    </div>

                     
                        
                    
                            <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button className="box-shadow-button" style={{ backgroundColor: '#214D9B', boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width: '100%',  color: 'white'}}

                                    onClick={() => this.redirect('https://nwexpress.com/fawncircle')}
                                    >
                                        <div className="row" >
                                            <div className="col-xl-12">
                                                <img alt="fawncircle" style={{height: '50px', width: '75px'}} src="/images/nationwide-logo.png"></img>
                                            </div>
                                            <div className="col-xl-12">
                                                <h5 style={{color: 'white'}}><strong>Try Nationwide Express!</strong></h5>
                                            </div>
                                        </div>
                                    </button>
                                </div>


                                <div className="col-6 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <button className="box-shadow-button" style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '100px', width:'100%', backgroundColor: 'orange', color:'#3f7695'}}
                                            onClick={(e) => this.showTextQuote(e)}
                                    >
                                        <div className="row">
                                            <div className="col-xl-12" style={{padding: '0px 0px 0px 0px'}}>
                                                <img alt="plymouthrock" style={{height: '55px', width: '100px'}} src="/images/plymouth-logo.png"></img>
                                            </div>
                                            <div className="col-xl-12">
                                                <h5 style={{color: 'black'}}><strong>Plymouth Rock Text Quote</strong></h5>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                </div>
                        </span>
                        : 
                        <div className="row">
                            <div className="col-lg-4 offset-lg-4" style={{padding: '5px', textAlign: 'center'}}>
                                <button class="btn btn-light"onClick={() => this.returnToModule()}>Return</button>
                            </div>
                        </div>
                        }

                        <Modal  
                            isOpen={this.state.showTextQuote}
                            onRequestClose={(e) => this.showTextQuote(e)}
                            style={customStylesModal}
                        >

                            <button style={{width: '12.5%', marginLeft: '87.5%', textAlign: 'center', borderRadius: '10px', backgroundColor: 'red', color: 'white'}} onClick={(e) => this.showTextQuote(e)}>X</button>
                                                        
                            <TextToQuoteCard/>


                        </Modal>

                 

                        {this.state.showCoverPathMass === true ? 
                            <CoverPathMass />
                        : null}

                   

                        {/* {this.state.showNeptuneFlood === true ?
                            NeptuneWidget.init({ element: "#app", source: "Sa38oXQAsJxkbvhRezGuvCFvo2g9AVLhtYFeJG0IhfWyLEDSxnHdZo8Ci27/phyVSmM8UAUQ2KEM4ib/kdf1+VCdZ07Vp79X9rP6g/rVh0E=", primaryColor:"", buttonText:"" })
                        : null} */}
                         
                    </div>
                   
                </div>
            </div>
        )

    }

}

export default QuoteModule;