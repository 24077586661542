import React, { Component } from "react";
import "./style.css";
import API from '../../utils/API'
import cogoToast from "cogo-toast";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
import UploadDocWidget from "../UploadDocWidget";



class DocVault extends Component {

    state = {
        filename: "",
        fileData: "",
        policyID: "",
        type: "",
        fileArray: []
    }

    componentDidMount = () => {
        console.log("Doc Vault Mounted");
        console.log(this.props);
        console.log(this.props.docIDArray);
        API.getDocumentsByIdArray(this.props.docIDArray)
            .then((res) => {
                console.log("Found Documents")
                // console.log(res)
                this.setState({
                    fileArray: res.data,
                    policyID: this.props.rootPolicyID,
                    policyType: this.props.rootPolicyType
                })
                console.log(this.state)
            })
            .catch((err) => {
                console.log("Error, could not obtain documents")
                console.log(err)
            })

        this.setState({
            vaultDocIDs: this.props.docIDArray
        })
    }



    refreshDocuments = (newDocID) =>{
        
        console.log(newDocID);
        // console.log(vaultDocIDsUpdated)
        console.log(this.state.vaultDocIDs)
        let vaultDocIDsUpdated = this.state.vaultDocIDs.splice(0)
        vaultDocIDsUpdated.push(newDocID);
        // vaultDocIDsUpdated.push()
        // console.log(vaultDocIDsUpdated)
        this.setState({
            vaultDocIDs: vaultDocIDsUpdated
        })
        // console.log(this.state.vaultDocIDs)

        API.getDocumentsByIdArray(this.state.vaultDocIDs)
            .then((res) => {
                console.log("Found Documents")
                // console.log(res)
                this.setState({
                    fileArray: res.data,
                    policyID: this.props.rootPolicyID,
                    policyType: this.props.rootPolicyType
                })
                console.log(this.state)
            })
            .catch((err) => {
                console.log("Error, could not obtain documents")
                console.log(err)
            })
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleFileSubmit = event => {
        event.preventDefault()
    };

    downloadBase64File(e, base64Data, docName){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = docName;
        downloadLink.click();
        cogoToast.info("Document downloading...")
    };

    render() {
        return (
            <div>
                <div style={{textAlign: 'center', backgroundColor: 'whitesmoke'}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    {/* <div className="card-header" style={{ textAlign: 'center' }}> */}
                        {/* Quick Quote */}
                    {/* </div> */}
                    <div style={{float: 'right'}}><button className="btn btn-secondary" onClick={(e) => this.props.closeModal(e)}>X</button></div>
                    <br />
                    <h3 style={{marginTop: '25px'}}>Document Vault</h3>
                    <hr />
                    <div>

                    <div className="row">
                        {/* <div className="col" style={{height: '50px'}}><h2></h2></div> */}
                        {this.state.fileArray.map(file => (
                            <div className="col-lg-4" style={{padding: '15px'}} key={file._id}>
                                <p>{file.docName}</p>

                                {file.docName.split(".")[1] === 'pdf' ? 

                                    <i className="hoverhand" onClick={(e) => this.downloadBase64File(e, file.document, file.docName)} class="fa fa-file-pdf-o fa-3x" aria-hidden="true"></i>

                                : 
            
                                    <i className="hoverhand" onClick={(e) => this.downloadBase64File(e, file.document, file.docName)} class="fa fa-file-code-o fa-3x"  aria-hidden="true"></i>

                                }
                                {/* <p onClick={(e) => this.downloadBase64File(e, file.document, file.docName)}>Download</p> */}
                                <br />
                                <button style={{border: '0px'}} className="btn btn-primary" onClick={(e) => this.downloadBase64File(e, file.document, file.docName)}>Download</button>
                                {/* <hr /> */}
                            </div>
                        ))}
                        {/* <div className="col-lg-4">

                        </div> */}
                    </div>
                    <br />
                    <br />
                    {/* <SmallPara */}
                    {/* <Para */}
                    <div className="row">
                        {/* <div className="col-lg-3" style={{height: '10px'}}></div> */}
                        <div className="col">
                            <UploadDocWidget 
                                policyID={this.state.policyID}
                                policyType={this.state.policyType}
                                refreshVault={this.refreshDocuments}
                            />
                        </div>
                        {/* <div className="col-lg-3" style={{height: '10px'}}></div> */}

                    </div>

                    </div>
                
                    {/* <form>
                        <table
                          id="database"
                        >
                            <thead>
                                <tr> 
                                    <th>Upload File Name</th>
                                    <th>Upload File Attach</th>
                                    <th>Upload File Submit</th>
                                </tr>
                                
                            </thead>

                            <tbody>
                                <tr>
                                  
                                    <td>
                                        <label><strong>File Name</strong></label>
                                        <br />
                                        <input type="text" id="filename" name="filename" />
                                    </td>
                                    <td>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label><strong>Upload Files</strong></label>
                                                <br />
                                                <input type="file" id="dec-1" name="declarations" accept="image/png, image/jpeg" />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        
                                            <div style={{ textAlign: 'center' }}>
                                                <button disabled className="btn btn-sm btn-success" onClick={this.handleFileSubmit}>Upload File</button>
                                            </div>
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form> */}

                 

                </div>
            </div>
        )

    }

}

export default DocVault;