import React, { Component } from "react";
import "./style.css";
import API from '../../utils/API'

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class QuickQuoteAgent extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        chosenAgent: "",
        address: "",
        dec1: "",
        dec2: "",
        dec3: "",
        name: "",
        notes: "",
        consumerConsent: true
    }

    componentDidMount = () => {
        console.log("Quick Quote Agent Tool Mounted");
        console.log("Props:")
        console.log(this.props);
        this.setState({
            chosenAgent: this.props.agentData._id
        })
        console.log("State:")
        console.log(this.state);
    }

    // handleInputChange = event => {
    //     const { name, value } = event.target;
    //     this.setState({
    //         [name]: value
    //     });
    // };

    handleInputChange = event => {
        // const { name, value } = event.target;
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };

    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler1 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    dec1: res
                })
            })
        }
    }
    fileSelectedHandler2 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    dec2: res
                })
            })
        }
    }
    fileSelectedHandler3 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    dec3: res
                })
            })
        }
    }



    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            name: this.state.name,
            dob: this.state.dob,
            address: this.state.address,
            email: this.state.email,
            telephone: this.state.telephone,
            referee: 'evan',
            quoter: 'randy',
            dec1: this.state.dec1,
            dec2: this.state.dec2, 
            dec3: this.state.dec3,
            notes: this.state.notes
        }

        console.log(quoteData)

        // for (var i = 0; i < this.state)
        if (this.state.dec1.length == 0 && this.state.dec2.length === 0 && this.state.dec3.length === 0){
                console.log("No decs loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                email: this.state.email,
                telephone: this.state.telephone,
                referee: 'evan',
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                quoter: 'randy',
                documents: [],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else if (this.state.dec1.length > 0 && this.state.dec2.length === 0 && this.state.dec3.length === 0){
            console.log("Dec 1 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                email: this.state.email,
                telephone: this.state.telephone,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec1],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else if (this.state.dec1.length === 0 && this.state.dec2.length > 0 && this.state.dec3.length === 0){
            console.log("Dec 2 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                email: this.state.email,
                telephone: this.state.telephone,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec2],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else if (this.state.dec1.length === 0 && this.state.dec2.length === 0 && this.state.dec3.length > 0){
            console.log("Dec 3 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                email: this.state.email,
                telephone: this.state.telephone,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec3],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)

        } else if (this.state.dec1.length > 0 && this.state.dec2.length > 0 && this.state.dec3.length === 0){
            console.log("Dec 1 and Dec 2 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                email: this.state.email,
                telephone: this.state.telephone,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec1, this.state.dec2],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else if (this.state.dec1.length === 0 && this.state.dec2.length > 0 && this.state.dec3.length > 0){
            console.log("Dec 2 and Dec 3 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                referrerID: this.props.agentData._id,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                email: this.state.email,
                telephone: this.state.telephone,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec2, this.state.dec3],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else if (this.state.dec1.length > 0 && this.state.dec2.length === 0 && this.state.dec3.length > 0){
            console.log("Dec 1 and Dec 3 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                email: this.state.email,
                telephone: this.state.telephone,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec1, this.state.dec3],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else if (this.state.dec1.length > 0 && this.state.dec2.length > 0 && this.state.dec3.length > 0){
            console.log("Dec 1 Dec 2 and Dec3 loaded")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                email: this.state.email,
                telephone: this.state.telephone,
                referee: 'evan',
                quoter: 'randy',
                documents: [this.state.dec1, this.state.dec2, this.state.dec3],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        } else {
            alert("Unknown number of decs loaded, no docs found, attempting to save quote anyway..")
            API.saveAgentProspectSubmission(this.props.agentData._id, {
                name: this.state.name,
                dob: this.state.dob,
                address: this.state.address,
                referreeID: "61cbd89ddf3ae329f85d2c42",
                referrerID: this.props.agentData._id,
                email: this.state.email,
                telephone: this.state.telephone,
                referee: 'evan',
                quoter: 'randy',
                documents: [],
                notes: this.state.notes
            }).then(res => {
                // cogoToast.info("Saved Note!")
                // this.props.rerender();
                alert("Quote Saved!")
                this.props.rerender()
            }
            ).catch(err => console.log(err))
    
            setTimeout(() => {
                // cogoToast.loading("Re-loading appointments")
                this.props.rerender()
            }, 1000)
        }


     
        // TO-DO 02-16-23
            // Need to change form below to match new inputs
            // Need to prefill proper codes to referee and quoter
            // Need to figure out how to load a documents array above
            // Need to build API

        API.saveAgentQuoteRequest(this.props.agentData._id, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.dob,
            referrerID: this.props.agentData._id,
            quickAddress: this.state.quickAddress,
            notes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.props.agentData._id,
            refAgentOneName: this.props.agentData.firstName + " " + this.props.agentData.lastName,
            refAgentTwo: this.state.refAgentTwo
       }).then(res => {
            // cogoToast.info("Saved Note!")
            // this.props.rerender();
            alert("Quote Saved!")
            this.props.rerender()
        }
        ).catch(err => console.log(err))

        setTimeout(() => {
            // cogoToast.loading("Re-loading appointments")
            this.props.rerender()
        }, 1000)

    }





    render() {
        return (
            <div>
                <div className="card" style={{backgroundColor: this.props.backgroundColor}}>

                    <form style={{padding: '10px'}}>
                        <div className="row">
                            <div className="col-6">
                                <label >Name</label>
                                <input type="text" className="form-control" value={this.state.name} onChange={this.handleInputChange} name="name" placeholder="Name" />
                            </div>
                            <div className="col-6">
                                <label>Date of Birth </label>
                                <input type="date" className="form-control" value={this.state.dob} onChange={this.handleInputChange} name="dob" />
                            </div>
                        </div>
                        <hr />
                        <div className="form-row row">
                            {/* <div className="col-md-2"></div> */}
                            <div className="col-md-6 offset-md-3">
                            <label>Referring Agent</label>
                                <div>
                                    <p>{this.props.agentData.firstName} {this.props.agentData.lastName}</p>
                                </div>
                                
                            </div>
                            {/* <div className="col-md-2"></div> */}
                            {/* <div className="col-md-3 offset-md-2">
                            <label>Split Agent</label>
                                {this.props.agents ? 
                                // <div>
                                    <select id="protegeDropMenu" value={this.state.chosenRefAgentTwo} onChange={this.handleInputChange} name="refAgentTwo">
                                        <option value="">--None--</option>
                                        {this.props.agents.map(agent => (
                                            <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option> 
                                        ))}
                                    </select>
                                //  </div>   
                                 : <div>No agents yet</div>}
                            </div> */}
                            {/* <div className="col-md-2"></div> */}
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label >Email</label>
                                <input type="text" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="e-mail" />
                            </div>
                            <div className="col-6">
                                <label>Phone</label>
                                <input type="text" className="form-control" value={this.state.telephone} onChange={this.handleInputChange} name="telephone" />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="col-lg-12">
                                <label>Client Address</label>
                                <input type="text" className="form-control" value={this.state.address} onChange={this.handleInputChange} name="address" id="inputAddress" placeholder="Address" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-12">
                                <label>Additional Notes</label>
                                <textarea value={this.state.notes} onChange={this.handleInputChange} name="notes" className="form-control" rows="2" cols="60" placeholder="Notes go here!"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8 offset-2">
                                        <label>Client consent to running consumer reports?</label>
                            </div>
                            <div className="col-6 offset-3" style={{float: 'left'}}>
                                        <input type="checkbox" checked={this.state.consumerConsent} onChange={this.handleInputChange} name="consumerConsent" />
                            </div>
                        </div>
                   
                        <div className="row">
                            <div className="col-md-4">
                                <label>Declarations I</label>
                                <input name="fileData1" type="file" onChange={this.fileSelectedHandler1} />
                            </div>
                            <div className="col-md-4">
                                <label>Declarations II</label>
                                <input name="fileData2" type="file" onChange={this.fileSelectedHandler2} />
                            </div>
                            <div className="col-md-4">
                                <label>Declarations III</label>
                               <input name="fileData3" type="file" onChange={this.fileSelectedHandler3} />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                            <button className="btn btn-primary" onClick={this.handleQuoteSubmit}>Submit Quote</button>
                        </div>
                    </form>

                </div>
            </div>
        )

    }

}

export default QuickQuoteAgent;