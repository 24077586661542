import React, { Component } from 'react';
// import ListGroup from 'react-bootstrap/ListGroup'
import './style.css'
// import API from '../../utils/API';
import styled from 'styled-components';
import Modal from 'react-modal';
import cogoToast from 'cogo-toast';
import FawnButtonMini from '../FawnButtonMini';

const StyledButton = styled.button`
  width: 100%;
  padding: 10px 15px;
  background: linear-gradient(to right, #667eea 0%, #000080 100%);
  color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 1em;
  transition: background 0.6s;
  cursor: pointer;
  
  &:hover {
    background: linear-gradient(to right, #000080 0%, #667eea 100%);
  }
`;

const customStylesModal = {
    content: {
        padding: '5px 5px',
        backgroundImage: 'linear-gradient(to top, lightblue 0%, whitesmoke 100%)',
        height: '50%',
        marginLeft: '5%',
        border: '0px',
        textAlign: 'center',
        margin: '5%'
    }
}

// const customStylesModalTransparent = {
//     content: {
//         padding: '5px 5px',
//         background: 'transparent',
//         border: '0px',
//         height: '100%',
//         border: '0px'
//     }
// }

class ClientToolbar extends Component {
    state = {
        serviceReqType: "",
        serviceReqName: "",
        serviceReqEmail: "",
        serviceReqBody: "",
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    copyText = (event, data) => {
        event.preventDefault();

        var copyText = data;
        navigator.clipboard.writeText(copyText);
        console.log(navigator.clipboard);
        cogoToast.success("Copied: " + data)
    }

    render() {
        return (
            < div >
                <div style={{color: 'black', textAlign: 'center'}}>
                    {/* <p>Menu options</p> */}
                    <div className="row">
                        {/* <div className="col-md-4 offset-md-1 col-10 offset-1" ><StyledButton onClick={(e) => this.props.openAccount(e)} style={{width: '100%'}}>Account</StyledButton></div>
                        <div className="col-md-4 offset-md-2 col-10 offset-1" ><StyledButton onClick={(e) => this.props.openDocs(e)} style={{width: '100%'}}>Service</StyledButton></div>
                        <div className="col-md-4 offset-md-1 col-10 offset-1" ><StyledButton onClick={(e) => this.props.openBilling(e)} style={{width: '100%'}}>Billing</StyledButton></div>
                        <div className="col-md-4 offset-md-2 col-10 offset-1" ><StyledButton onClick={(e) => this.props.openOther(e)} style={{width: '100%'}}>Other</StyledButton></div>
                         */}

                        <div className="col-lg-3 offset-lg-0 col-md-4 offset-md-1 col-10 offset-1">
                            <FawnButtonMini buttonText="Account" pf1={(e) => this.props.openAccount(e)} />
                        </div>
                        <div className="col-lg-3 offset-lg-0 col-md-4 offset-md-2 col-10 offset-1">
                            <FawnButtonMini buttonText="Service" pf1={(e) => this.props.openDocs(e)}/>
                        </div>
                        <div className="col-lg-3 offset-lg-0 col-md-4 offset-md-1 col-10 offset-1">
                            <FawnButtonMini buttonText="Billing" pf1={(e) => this.props.openBilling(e)}/>
                        </div>
                        <div className="col-lg-3 offset-lg-0 col-md-4 offset-md-2 col-10 offset-1">
                            <FawnButtonMini buttonText="Other" pf1={(e) => this.props.openOther(e)}/>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}


export default ClientToolbar;
