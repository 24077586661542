import React, { Component } from "react";
import "./style.css";
import Button from 'react-bootstrap/Button'
// import FawnCarousel from "../../components/Carousel2"
// import Parralax from "../../components/Parralax"
// import LPCard from "../../components/LPCard"
import ContactCard from "../../components/ContactCard"
import AboutCard from "../../components/AboutCard"
import QuoteCard from "../../components/QuoteCard"
// import API from "../../utils/API";
import LoginCard from '../../components/LoginCard'
import CreateReferral from "../../components/CreateReferral";
import SignupCard from "../../components/SignupCard";
import TeamCard from '../../components/TeamCard';
import WebsiteCard from "../../components/WebsiteCard";
import ProductCard from "../../components/ProductCard";
import QuoteModule from "../../components/QuoteModule";
import CreateServiceRequest from "../../components/CreateServiceRequest";
import PAFlashcards from "../../components/PAFlashcards";
import Carousel from "../../components/Carousel"
import Modal from "react-modal";
import PublicQuoteCard from "../../components/PublicQuoteCard"
import CarrierBanner from "../../components/CarrierBanner"
// import ResponsiveParralaxCarousel from '../../components/ResponsiveParralaxCarousel'
// import ResponsiveParralaxLogin from '../../components/ResponsiveParralaxLogin'

const customStylesModal = {
    content: {
        padding: '2px 2px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        background: 'transparent',
        overflowX: 'auto',
        border: '0px'
    }
}

class LandingPage extends Component {

    state = {
        showLanding: true,
        showAbout: false,
        showContact: false,
        showQuote: false,
        showBadges: true,
        questionsAnswered: 0,
        showIFrame: false,
        showTeam: false,
        showWebsite: false,
        lastAnswer: 999,
        priorAnswer: 9999,
        hideGoBack: false,
        showProduct: false,
        showPAFlashcards: false,
        noToolLoaded: true,
        showQuoteModule: true,
        mobile: true,
        showCreateServiceRequest: false,
        showFastQuote: false
    }

    status = {
        QA: {
            questions: [],
            answers: [],
        },
        activeDialogue: {
            activeQuestion: 1,
            activeQuestionHeader: "Welcome to Fawn Circle!",
            activeQuestionFooter: "What would you like to do today?",
            activeAnswerStrings: ["1|Learn more about you.",
            "2|Get a Quote.",
            "3|Review my policy.",
            "4|Licensing.",
            "5|Log into my account."],
            lastAnswer: 99,
            activeAnswers: [1,2,3,4,5,0],
            questionRefs: [0,1,2,3,4,5,6,8,9,10,11,12],
            answerRefs: [0,101,102,1,2,3,4,5,6,7,8,9,10,11,12,13,14],
            questionStringsNewTest: [
                "0|0+28|Thank you for reaching out!!|Please contact us below at:|101+102+99",
                "1|99|Welcome!|What may we help you with today?|103+104+2+1+3+4+5+32+0",
                "2|3+5|Thank you,|Please Log In.|?RunCheckIfAccountAndOrLoginModule+98+99",
                "3|4+29|Welcome to Career Eduction!|For Licensing, please select your State.|6+7+8+9+30+31+98+99",
                "4|1|We are an insurance agency.|Do you need policy service or something else?|?34+35",
                "5|13|Thank you for finding us!| You can do many things on our website,|101+98+99",
                "6|23+24+25+26|Thank you for your interest.|Please Review Below!|?RunQuoteModule+98+99",
                "9|6|We welcome your interest!|Please begin by reviewing the tools:|?RunSetupDownloadLink:palicenseguide.pdf+15+14+30+98+99",
                "10|7|We have a guide you can download.|Please also make an account|?RunSetupDownloadLink:delicenseguide.pdf+14+16+30+98+99",
                "11|8|We have a guide you can download.|Please also make an account|?RunSetupDownloadLink:njlicenseguide.pdf+14+17+30+98+99",
                "12|9|We have a guide you can download.|Please also make an account|?RunSetupDownloadLink:genlicenseguide.pdf+14+18+30+98+99",
                "13|15+16+17+18|Downloading...|If you have not received, please reach out.|98+99+101",
                "14|101|Evan Cleary|#: 610-299-9918 / Email: evan@fawncircle.com|98+99",
                "15|102|Randy Forster|#: 215-704-2656 / Email: randy@fawncircle.com|98+99",
                "8|14|Create account.|Please use the module|?RunCheckIfAccountAndOrLoginModule+98+99",
                "16|10|We sell Auto, Home and Life!| Which are you interested in today?|?ShowProductModule+23+24+25+26+98+99",
                "17|11|Thank you for finding us!| You can do many things here, such as:|?ShowWebsiteModule+2+14+28+27+29+98+99",
                "17|12|We are an insurance brokerage| We provide insurance solutions online:|?ShowAboutModule+2+14+28+27+29+98+99",
                "17|13|Our team is here for you 24/7| Please reach out or use our online tools:|?ShowTeamModule+101+2+14+27+29+98+99",
                "18|27|Thank you for your refererral!|Please submit via the form below:|?ShowCreateReferral+98+99",
                "19|30|Your Regional Manager|They will consult your licensing process|1011+98+99",
                "20|31|Our Team can assist|Please call us to discuss your needs|1011+98+99",
                "21|32|Sign up for an account|Please complete the module!|?RunSignUpModule+98+99",
                "22|98|Returning to last tab|If you're seeing this, please reset.|?RunLastTab+98+99",
                "23|2|Quote Options|Please use our Quoting Tool.|?ShowQuoteModule+98+99",
                "24|33|PA Flashcards|Please use our Flashcard Module.|?ShowPAFlashcards+98+99",
                "25|34|Policy Service|Do you have a policy with our agency?|36+37",
                    "26|36|Policy Service - cont.|Do you need to either log in or create an account?|38+37",
                    "27|37|Policy Service - cont.|Please call our team for clarification!|101+102",
                "25|38|User Tools|Please select a tool below!|5+32",
                "27|103+104|Do you have an account with us?|40+41+42",
            ],
            answerStringsNewTest: [
                "0|Other",
                "101|Evan (Agent): 610-299-9918",
                "1011|Evan (Manager): 610-299-9918",
                "102|Randy (CSR): 215-704-2656",
                "103|I am an existing Client",
                "104|I am an insurance Agent",
                "1|More information",
                "2|I'd like a Quote please",
                "3|I'd like to review my policy",
                "4|I'd like to get licensed",
                "5|I'd like to log in",
                "6|Pennsylvania",
                "7|New Jersey",
                "8|Delaware",
                "9|Another State",
                "10|Tell me about your products",
                "11|Tell me about your website",
                "12|Tell me about your company",
                "13|Tell me about your team",
                "14|Create an account",
                "15|Download PA Licensing Guide",
                "16|Download NJ Licensing Guide",
                "17|Download DE Licensing Guide",
                "18|Download Gen. Licensing Guide",
                "19|#610-299-9918",
                "20|evan@fawncircle.com",
                "21|#215-704-2656",
                "22|randy@fawncircle.com",
                "23|Auto Quotes",
                "24|Home Quotes",
                "25|Umbrella Quotes",
                "26|Other Quotes",
                "27|Refer a Customer",
                "28|Contact a Representative",
                "29|Get Licensed to Sell",
                "30|Contact Manager for training",
                "31|Non Licensing Education",
                "32|Sign Up",
                "33|PA Regulations Flashcards",
                "35|More Information",
                "36|Yes",
                "37|Not sure",
                "98|Go Back",
                "38|Yes",
                "39|Get Documents",
                "40|Yes",
                "41|Not sure",
                "42|No",
                "99|Reset."
            ],
            questionStrings: [ // first line is promptkey, second line is premise, third line is instructions, 4th line is answerkeys
                "0|0+28|If we can't help you here|Please reach out below at:|101+102+99",
                "1|99|||2+3+4+5+43+0",
                "2|3+5+40|Please log in or sign up||?RunCheckIfAccountAndOrLoginModule+98+99",
                "3|4+29|Career Education|Select your state|6+7+8+9+30+31+98+99",
                "4|1|Fawn Circle, LLC|Is a PA-based insurance agency|?ShowAboutModule+11+12+13+98+99",
                "5|13|Website|Customer Service, Sales and Admin|101+102+98+99",
                "6|23+24+25+26|Insurance Quotes|Please select a type of insurance|?RunQuoteModule+98+99",
                "9|6|PA - State Guidelines|Please sign up|?RunSetupDownloadLink:palicenseguide.pdf+15+14+30+98+99",
                "10|7|DE - State Guidelines|Please sign up|?RunSetupDownloadLink:delicenseguide.pdf+14+16+30+98+99",
                "11|8|NJ - State Guidelines|Please sign up|?RunSetupDownloadLink:njlicenseguide.pdf+14+17+30+98+99",
                "12|9|Other - State Guidelines|Please sign up|?RunSetupDownloadLink:genlicenseguide.pdf+14+18+30+98+99",
                "13|15+16+17+18|For more information|Call us below|98+99+101",
                "14|101|Evan Cleary|#: 610-299-9918 / Email: evan@fawncircle.com|98+99",
                "15|102|Randy Forster|#: 215-704-2656 / Email: randy@fawncircle.com|98+99",
                "8|14|Create account|Log in or create an account|?RunCheckIfAccountAndOrLoginModule+98+99",
                "16|10|Products|Car, Home, Business, and Life|?ShowProductModule+23+98+99",
                "17|11|Website|Web Tools:|?ShowWebsiteModule+2+14+28+27+29+98+99",
                "17|12|About us| We provide insurance solutions online|?ShowAboutModule+2+14+28+27+29+98+99",
                "17|13|Team|Here for you 24/7|?ShowTeamModule+101+2+14+29+99",
                "18|27|Refer-a-friend|See below for more information|?ShowCreateReferral+98+99",
                "19|30|Regional Manager|Please reach out|1011+98+99",
                "20|31|Here for you 24/7|Please call us any time|1011+98+99",
                "21|32+41+42|Sign up|Log in or create an account|?RunSignUpModule+98+99",
                "22|98|Error|Please Press Return!|?RunLastTab+98+99",
                "23|2|Quote|Choose a type of insurance|?ShowQuoteModule+98+99",
                "24|33|PA Flashcards|See Flashcards Below|?ShowPAFlashcards+98+99",
                "27|103+104|Do you have an account with us?||40+42+98+99",
                "28|39|Please enter your request below||?ShowServiceRequest+98+99"
            ],

            answerStrings: [
                "0|Other",
                "101|Contact us: 610-299-9918",
                "1011|Evan (Manager): 610-299-9918",
                "102|Randy (CSR): 215-704-2656",
                "103|Client Login",
                "104|Agent Login",
                "1|What is Fawn Circle?",
                "2|Shop Insurance",
                "3|Review Account",
                "4|Become an Agent",
                "5|Agent or Client Login",
                "6|Pennsylvania",
                "7|New Jersey",
                "8|Delaware",
                "9|Another State",
                "10|Tell me about your products",
                "11|Tell me about this website",
                "12|Tell me about the company",
                "13|Tell me about your team",
                "14|Create an account",
                "15|Download PA Licensing Guide",
                "16|Download NJ Licensing Guide",
                "17|Download DE Licensing Guide",
                "18|Download Gen. Licensing Guide",
                "19|#610-299-9918",
                "20|evan@fawncircle.com",
                "21|#215-704-2656",
                "22|randy@fawncircle.com",
                "23|Quote Form",
                "24|Home Quotes",
                "25|Umbrella Quotes",
                "26|Other Quotes",
                "27|Refer a Customer",
                "28|Contact a Representative",
                "29|Get Licensed to Sell",
                "30|Contact Manager for training",
                "31|Non Licensing Education",
                "32|Create Account",
                "33|PA Regulations Flashcards",
                "39|Customer Service",
                "40|Yes",
                "41|Not sure",
                "42|No",
                "43|File a Claim",
                "98|Return",
                "99|Quit"
                 ]
        }
    }

    //   handleSubmit(e) {
    //     e.preventDefault();
    //     console.log('You clicked submit.');
    //   }

    // componentDidMount(){
 
    // }    
    // Question 
        // QRef:int | QuestionHeader: string | QuestionString:string | Answers:boolean
    // Answer
        // ARef:int | AnswerString:string | Chosen:boolean


    //TO-DO:
        // [ ] - In get New Question, cycle through answers an dget answer texts
        // [ ] - Get it where text is reran after change, possibly make a subcomponent for header and footer
        // [ ] - Set up where the buttons are generated dynamically based on the new questions answer Refs, make sure they generate a question with appropriate source answer ref
        // [ ] - Set up a means to parse functions and set up custom functions
            // [ ] - runCheckIfAccountAndOrLoginModule
            // [ ] - runStateDropdown
            // [ ] - runQuoteModule
            // [ ] - runSetupDownloadLink
            // [ ] - runSignUpModule

    questionStrings = []; // Used: 0, 1, 2, 3, 4, 5, 10, 11, 12, 13

    answerStrings = [];

    modules = [
        "RunCheckIfAccountAndOrLoginModule|2",
        "RunStateDropdown|3",
        "RunQuoteModule|6",
        "RunSetupDownloadLink|7",
        "RunSignUpModule|8"
    ]

    alternatingColor = ['white', 'white'];
    alternatingText = ['black', 'black'];

    // Build 3 - State Analyzer and Response tool
    // Build

    // loadQuestionString()

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };


    componentDidMount = () => {
        // console.log("Landing Page")
        // API.getAdmins()
        //     .then(res => 
        //         {console.log(res.data)
        //         })
        //     .catch(err => console.log(err));
        // let defaultQuestionRef = this.status.activeDialogue.activeQuestion;
        // let defaultAnswersRef = this.status.activeDialogue.activeAnswers;
        // console.log("Getting Question Ref: " + defaultQuestionRef);
        // console.log("Getting Answers Ref: " + defaultAnswersRef);
        this.getNewQuestion('', this.status.activeDialogue.lastAnswer, '', '', '');

    // 768px portrait
            if (window.screen.width == 360) { 
              this.setState({
                  mobile: true
              })
            }
          

     }

    getNewQuestion = (a, oSrcARef, qRef, aRefArr, e) => {
        console.log("AnswerRef: " + oSrcARef);

        let srcARef = '';

        if (oSrcARef == '98'){
            srcARef = this.state.priorAnswer;
            this.setState({
                priorAnswer: '99',
                lastAnswer: '99'
            })
        } else if (oSrcARef == '43') {
            document.location.href = "/claim"
        } else {
            srcARef = oSrcARef
            this.setState({
                lastAnswer: oSrcARef,
                priorAnswer: this.state.lastAnswer
            })
        }

        this.setState({
            showBadges: false,
            showIFrame: false,
            showLogin: false,
            showReferral: false,
            showSignup: false,
            showAbout: false,
            showTeam: false,
            showWebsite: false,
            showProduct: false,
            showQuoteModule: false,
            showPAFlashcards: false,
            showCreateServiceRequest: false
        })
        
        this.status.activeDialogue.activeAnswers = [];
        this.status.activeDialogue.lastAnswer = srcARef;
        // console.log("Getting New Question based off Answer Ref: " + srcARef);
        // console.log("Question Ref: " + qRef);
        // console.log("AnswerArr: " + aRefArr);
            for (var h = 0; h < this.status.activeDialogue.questionStrings.length; h++){
                // let precedingAnswer = this.status.activeDialogue.questionStrings[h].split("|")[1];
                let precedingAnswerArr = this.status.activeDialogue.questionStrings[h].split("|")[1].split("+")
                // console.log(precedingAnswerArr);
                for (var b = 0; b < precedingAnswerArr.length; b++){
                    // console.log("Looping at ")
                    if (srcARef == precedingAnswerArr[b]){
                        // console.log("Found Preceding Answer: " + srcARef + " == " + precedingAnswerArr[b]);
                        console.log("Found Question String: ");
                        console.log(this.status.activeDialogue.questionStrings[h]);
                        // let qRef = this.status.activeDialogue.questionStrings[h].split("|")[0];
                        // let pAns = this.status.activeDialogue.questionStrings[h].split("|")[1];
                        let qHeader = this.status.activeDialogue.questionStrings[h].split("|")[2];
                        let qFooter = this.status.activeDialogue.questionStrings[h].split("|")[3];
                        let answers = this.status.activeDialogue.questionStrings[h].split("|")[4];
                        console.log(answers);
                        for (var c = 0; c < answers.split("+").length; c++){
                            if (answers.split("+")[c].includes("?")){
                                    console.log("Found a Command");
                                    this.runAnswerMethod(answers.split("+")[c]);
                            }
                        }
                        // console.log("QRef = " + qRef);
                        // console.log("PAns = " + pAns);
                        // console.log("qHeader = " + qHeader);
                        // console.log("qFooter = " + qFooter);
                        // console.log("Answers = " + answers);
                        this.status.activeDialogue.activeQuestionHeader = qHeader;
                        this.status.activeDialogue.activeQuestionFooter = qFooter;
                        this.status.activeDialogue.activeAnswers = answers.split("+");
                    }
                }   
                if (h == this.status.activeDialogue.questionStrings.length - 1){
                    // console.log("Completed generating new question info");
                    // console.log(this.status);
                    let answerRefs = this.status.activeDialogue.activeAnswers.slice()
                    this.getAnswerStrings(answerRefs)
                }
            }
    }


    runAnswerMethod(methodString){
        // console.log("Running Method: " + methodString);
        // this.showIFrame();
        this.setState({
            noToolLoaded: false
        })
        switch(methodString){
            case '?RunCheckIfAccountAndOrLoginModule':
                // console.log("Running: " + methodString);
                this.setState({
                    showLogin: true
                })
                // console.log("Nothing here yet...");
                break;
            case '?RunQuoteModule':
                // console.log("Running: " + methodString);
                // console.log("Generating Quote Module");
                this.showIFrame();
                break;
            case '?RunSetupDownloadLink:palicenseguide.pdf':
                // console.log("Running: " + methodString);
                // console.log("Nothing here yet...");
                break;
            case '?RunSetupDownloadLink:njlicenseguide.pdf':
                // console.log("Running: " + methodString);
                // console.log("Nothing here yet...");
                break;
            case '?RunSetupDownloadLink:delicenseguide.pdf':
                // console.log("Running: " + methodString);
                // console.log("Nothing here yet...");
                break;
            case '?RunSetupDownloadLink:genlicenseguide.pdf':
                // console.log("Running: " + methodString);
                // console.log("Nothing here yet...");
                break;
            case '?RunSignUpModule':
                // console.log("Running: " + methodString);
                // console.log("Nothing here yet...");
                this.setState({
                    showSignup: true
                })
                // this.showSignup()
                break;
            case '?ShowCreateReferral':
                // console.log("Running: " + methodString);
                // console.log("Showing Referral Component...");
                this.setState({
                    showReferral: true
                })
                break;
            case '?ShowAboutModule':
                this.setState({
                    showAbout: true
                })
                break;
            case '?ShowTeamModule':
                this.setState({
                    showTeam: true
                })
                break;
            case '?ShowWebsiteModule':
                this.setState({
                    showWebsite: true
                })
                break;
            case '?ShowProductModule':
                this.setState({
                    showProduct: true
                })
                break;
            case '?ShowQuoteModule':
                this.setState({
                    showQuoteModule: true
                });
                break;
            case '?ShowPAFlashcards':
                this.setState({
                    showPAFlashcards: true
                });
                break;
            case '?ShowServiceRequest':
                this.setState({
                    showCreateServiceRequest: true
                })
                break;
            default:
                // console.log("Running: " + methodString);
                // console.log("Nothing here yet...");
                break;
        }
    }

    showIFrame = () =>{
        if (this.state.showIFrame === false){
            this.setState({
                showIFrame: true
            })
        } else {
            this.setState({
                showIFrame: false
            })
        }
    }

    getAnswerStrings(answerRefs){
        // console.log("Getting Answers from Answer Refs:");
        // console.log(answerRefs);
        this.status.activeDialogue.activeAnswerStrings = [];
        for (var i = 0; i < answerRefs.length; i++){
            for (var j = 0; j < this.status.activeDialogue.answerStrings.length; j++){
                if (answerRefs[i] === this.status.activeDialogue.answerStrings[j].split("|")[0]){
                    // console.log("Answer Ref Found: " + answerRefs[i] + " == " + this.status.activeDialogue.answerStrings[j].split("|")[1]);
                    this.status.activeDialogue.activeAnswerStrings.push(this.status.activeDialogue.answerStrings[j]);
                    this.setState({
                        showBadges: true
                    })
                }
            }
        }
    }

    showLanding = () => {
        if (this.state.showLanding === true) {
            this.setState({
                showLanding: false
            })
        } else {
            this.setState({
                showLanding: true,
                showAbout: false,
                showContact: false,
                showQuote: false
            })
        }
    }

    showContact = () => {
        if (this.state.showContact === true) {
            this.setState({
                showContact: false,
                showLanding: true,
                noToolLoaded: true
            })
        } else {
            this.setState({
                showLanding: false,
                showAbout: false,
                showContact: true,
                showQuote: false,
                noToolLoaded: false
            })
        }
    }

    showAbout = () => {
        if (this.state.showAbout === true) {
            this.setState({
                showAbout: false,
                showLanding: true,
                noToolLoaded: true
            })
        } else {
            this.setState({
                showLanding: false,
                showAbout: true,
                showContact: false,
                showQuote: false,
                noToolLoaded: false
            })
        }
    }

    showQuote = () => {
        if (this.state.showQuote === true) {
            this.setState({
                showQuote: false,
                showLanding: true,
                noToolLoaded: true
            })
        } else {
            this.setState({
                showLanding: false,
                showAbout: false,
                showContact: false,
                showQuote: true,
                noToolLoaded: false
            })
        }
    }

    showBadges = () => {
        if (this.state.showBadges === true){
            this.setState({
                showBadges: false
            })
        } else {
            this.setState({
                showBadges: true
            })
        }
    }

    hideAbout = () => {
        if (this.state.showAbout === true){
            this.setState({
                showAbout: false
            })
        } else {
            this.setState({
                showAbout: true
            })
        }
    }

    hideTeam = () => {
        if (this.state.showTeam === true){
            this.setState({
                showTeam: false
            })
        } else {
            this.setState({
                showTeam: true
            })
        }
    }

    hideWebsite = () => {
        if (this.state.showWebsite === true){
            this.setState({
                showWebsite: false
            })
        } else {
            this.setState({
                showWebsite: true
            })
        }
    }

    hideProduct = () => {
        if (this.state.showProduct === true){
            this.setState({
                showProduct: false
            })
        } else {
            this.setState({
                showProduct: true
            })
        }
    }

    hideQuoteModule = () => {
        if (this.state.showQuoteModule === true){
            this.setState({
                showQuoteModule: false
            })
        } else {
            this.setState({
                showQuoteModule: true
            })
        }
    }

    hidePAFlashCards = () => {
        if (this.state.showPAFlashcards === true){
            this.setState({
                showPAFlashcards: false
            })
        } else {
            this.setState({
                showPAFlashcards: true
            })
        }
    }

    questionGenerator(e, question, answer){
        // console.log("Asking Question: ")
    }

    questionAnswer(question, answer, e){
        // console.log(this.state.questionsAnswered);
        // console.log(question);
        // console.log(answer);
        // console.log(e);
    }

    handleClick = () => {
        //   console.log("Button Clicked")
    }

    // showServiceRequest = () => {

    // }

    switchToSignupForm = () => {
        this.setState({
            showSignup: true,
            showLogin: false
        })
    }

    showFastQuote = () => {
        // e.preventDefault();
        console.log("This state showfast quote: " + this.state.showFastQuote);
        if (this.state.showFastQuote === true){
            this.setState({
                showFastQuote: false
            })
        } else {
            this.setState({
                showFastQuote: true
            })
        }
       
    }

    render() {
        return (
            // {
            //     this.state.mobile ? 
            
                <div className="container-fluid auroral-northern"  style={{overflow: 'hidden', minHeight: '1200px'}}>
                      {/* backgroundImage: 'url(/images/tall-tree-bg.png) */}

                <Modal  
                            isOpen={this.state.showFastQuote}
                            onRequestClose={(e) => this.showFastQuote(e)}
                            style={customStylesModal}
                        >
                            {/* <button style={{width: '12.5%', marginLeft: '87.5%', textAlign: 'center', borderRadius: '10px', backgroundColor: 'red', color: 'white'}} onClick={(e) => this.showFastQuote(e)}>X</button> */}
                                                        
                            <PublicQuoteCard 
                                theme="light"
                                text="black"
                                agents={[]}
                            
                            />    

                </Modal>

                <div className="row" style={{marginTop: '50px'}}>
                    <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                        <div style={{
                            height: '100%', 
                            width: '100%', 
                            backgroundColor: 'transparent'}}>
                                <Carousel 
                                    showFastQuote={(e) => this.showFastQuote(e)}
                                />
                        </div>
                    </div>
                </div>
                {/* <div style={{
                                    marginTop: '5%', 
                                    marginBottom: '5%',
                                    padding: '10px',
                                    borderRadius: '5px'}}>

      
                </div> */}
          

                {this.state.showLanding ?
                <div className="row">
                    
                    <div className="col-12" style={{
                            padding: '0px'}}
                            >
                      

                        <div className="card miniscreen-bg" 
                            style={{
                                textAlign: 'center', 
                                border: '0px', 
                                padding: '0px'}}
                            >
                                {this.status.activeDialogue.activeQuestionHeader.length > 0 ? 
                                 <div className="row">
                                    {/* <div className="col-lg-2"></div> */}
                                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                                    <p style={{
                                            boxShadow: '0px 0px 3px 3px black', 
                                            fontFamily: 'Verdana,sans-serif', 
                                            borderRadius: '10px', 
                                            backgroundColor: 'white',
                                            padding: '20px', 
                                            color: "black", 
                                            fontSize: '24px', 
                                            margin: '20px 20px 5px 20px', 
                                            backgroundImage: 'linear-gradient(147deg, white 90%, black 0%)'}}
                                    >
                                        {this.status.activeDialogue.activeQuestionHeader}
                                        <br />
                                        <span style={{fontSize: '22px'}}>
                                        {this.status.activeDialogue.activeQuestionFooter}
                                        </span>

                                    </p>
                                    </div>
                                    {/* <div className="col-lg-2"></div> */}
                                </div>
                                : null}

                                <br />


                                {/* <p style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', backgroundColor: 'white', paddingLeft: '20px', justifyContent: 'center', color: "black", fontSize: '18px', margin: '5px 20px 20px 20px', width: '80%', backgroundImage: 'linear-gradient(147deg, white 88%, black 0%)'}}>
                                    {/* What would you like to do? */}

                                {/* </p> */}
                                


                                <div className="row">

                                    {this.state.showSignup ? 
                                        <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                            <SignupCard />
                                            <br />
                                        </div>
                                    : null}

                                    {this.state.showLogin ? 
                                        <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3" >
                                            <LoginCard 
                                                switchToSignup={() => {this.switchToSignupForm()}}
                                            />
                                            <br />
                                        </div>
                                    : null}


                                    {this.state.showIFrame ? 
                                        <div style={{maxHeight: '880px', overflow: 'scroll'}}>
                                            <QuoteCard />
                                        </div>
                                    : null}

                                    {this.state.showQuoteModule ? 
                                        // <div className="container" style={{background: 'transparent', padding: '0px'}}>
                                            <QuoteModule
                                                return={this.hideQuoteModule}
                                                theme="secondary"
                                            />
                                        // </div>   
                                    : null }


                                    {this.state.showBadges ? 
                                        <div className="badges col-lg-12">
                                                {this.status.activeDialogue.activeAnswerStrings.map(function(item, i){
                                                    let answerRef = item.split("|")[0];
                                                    let answerString = item.split("|")[1]
                                                    return (
                                                        <div key={i} 
                                                        // className="w3-container w3-center mycustom_class_r1 w3-animate-right" 
                                                        >
                                                            <p className="miniscreen-btn"
                                                                style={{ 
                                                                    textAlign: 'center'
                                                                }}    
                                                            >
                                                                <Button 
                                                                    size="lg" 
                                                                    className="badge-button" 
                                                                    style={{ 
                                                                        fontFamily: 'Verdana,sans-serif', 
                                                                        boxShadow: '1px 1px 1px 1px black',
                                                          
                                                                        width: '300px', 
                                                                        backgroundImage: 'linear-gradient(147deg, white 90%, black 0%)', 
                                                                        backgroundColor: this.alternatingColor[i % this.alternatingColor.length], color: this.alternatingText[i % this.alternatingText.length]}} 
                                                                        variant="dark" 
                                                                        onClick={() => {this.getNewQuestion('_', answerRef,'-', "AB", "CD")}}
                                                                >
                                                                    {/* {answerRef + "|" + answerString} */} 
                                                                    {/* Code line above is for debugging */}
                                                                {answerString}
                                                                </Button>
                                                            </p>
                                                        </div>
                                                        )
                                                    }, this)
                                                }
                                        </div>      
                                    : null}
                                </div>
                        </div> 
                    </div>


                    <div className="col-12"
                         style={{
                            backgroundColor: 'transparent',
                            padding: '0'}}
                        >                                    

                       

                       {/* {this.state.showSignup ? 
                           <div >
                               <SignupCard />
                               <br />
                           </div>
                       : null}

                       {this.state.showLogin ? 
                           <div >
                               <LoginCard />
                               <br />
                           </div>
                       : null} */}

                       {this.state.showReferral ? 
                           <div style={{paddingLeft: '15%', paddingRight: '15%'}}>
                               <CreateReferral />
                               <br />
                           </div>
                       : null}

                       {this.state.showAbout ?
                           <div className="container" style={{background: 'transparent', padding: '0px' }}>
                               <AboutCard
                                   return={this.hideAbout}
                                   theme="secondary"
                               />
                           </div>
                       : null}

                       {this.state.showTeam ? 
                           <div className="container" style={{background: 'transparent', padding: '0px'}}>
                               <TeamCard
                                   return={this.hideTeam}
                                   theme="secondary"
                               />
                           </div>
                       : null}

                       {this.state.showWebsite ? 
                           <div className="container" style={{background: 'transparent', padding: '0px'}}>
                               <WebsiteCard
                                   return={this.hideWebsite}
                                   theme="secondary"
                               />
                           </div>
                       : null}

                       {this.state.showProduct ?
                           <div style={{background: 'transparent', padding: '0px'}}>
                               <ProductCard
                                   return={this.hideProduct}
                                   theme="secondary"
                               />
                           </div>  
                       : null}

                     

                       {this.state.showPAFlashcards ?
                           <div className="container" style={{background: 'transparent', padding: '0px'}}>
                               <PAFlashcards
                                   return={this.hidePAFlashCards}
                                   theme="secondary"
                               />
                           </div>  
                       : null}

                       {this.state.showCreateServiceRequest ?
                        <div className="container " style={{background: 'transparent', padding: '0px'}}>

                                <CreateServiceRequest />
                            
                   
                        </div>
                        : null}
                       

                    </div>


                    
                </div>
                        : null
                   
                }

                {
                    this.state.showContact ?
                        <div className="container" style={{ background: 'black' }}>
                            {/* <Parralax /> */}

                            <ContactCard
                                return={this.showLanding}
                                theme="secondary"
                            />

                            {/* <Parralax /> */}

                        </div>
                        : null
                }



                {
                    this.state.showQuote ?
                        <div className="container" style={{ background: 'black', padding: '50px' }}>
                            <QuoteCard
                                return={this.showLanding}
                                theme="secondary"
                                text="white"
                            />
                        </div> : null
                }

                <div className="row" style={{backgroundColor: 'transparent'}}>
                    <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-8 offset-sm-2 col-xs-12 offset-xs-0">
                        <div className="card" style={{background: 'transparent'}}>
                            <div className="card-body" style={{height: '100%', color: 'black', backgroundColor: 'rgba(255,255,255,0.7)', borderRadius: '5px', padding: '10px', textAlign: 'center'}}>
                                <h4>Sign up for a AAA Membership below!</h4>
                                <a href="https://cluballiance.aaa.com/insurance/ia?promo=FAWN&ian=Fawn%20Circle%20LLC" target="_blank" rel="noopener noreferrer">
                                     <img width="200" height="140px" src="/images/logos/aaa-logo.png" alt="AAA Membership Referral Link"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12" style={{padding: '0px', marginTop: '15px'}}>
                        <div className="card" style={{background: 'transparent'}}>
                            {/* <div className="card-header">
                                Carrier Partners
                            </div> */}
                            <div className="card-body" style={{backgroundColor: 'rgba(255,255,255,0.7)'}}>
                                <CarrierBanner />
                            </div>
                        </div>
                    </div>
                </div> 
            </div >

            // : 
            // <p>Create Desktop View</p>
        
            // }
            
        )

    }

}

export default LandingPage;



 // loadBooks = () => {
    //     API.getBooks()
    //         .then(res => this.setState({ books: res.data }))
    //         .catch(err => console.log(err));
    // };

    // testGets = () => {

    //     API.getAgents()
    //         .then(res => this.setState({ agents: res.data }))
    //         .catch(err => console.log(err));

    //     API.getAutos()
    //         .then(res => this.setState({ autos: res.data }))
    //         .catch(err => console.log(err));

    //     API.getClients()
    //         .then(res => this.setState({ clients: res.data }))
    //         .catch(err => console.log(err));

    //     API.getContacts()
    //         .then(res => this.setState({ contacts: res.data }))
    //         .catch(err => console.log(err));

    //     API.getDrivers()
    //         .then(res => this.setState({ drivers: res.data }))
    //         .catch(err => console.log(err));

    //     // Not working
    //     API.getProperties()
    //         .then(res => this.setState({ properties: res.data }))
    //         .catch(err => console.log(err));

    //     API.getQuotes()
    //         .then(res => this.setState({ quotes: res.data }))
    //         .catch(err => console.log(err));

    //     API.getReferrals()
    //         .then(res => this.setState({ referrals: res.data }))
    //         .catch(err => console.log(err));

    //     API.getServicers()
    //         .then(res => this.setState({ servicers: res.data }))
    //         .catch(err => console.log(err));

    //     API.getServicerequests()
    //         .then(res => this.setState({ serviceRequests: res.data }))
    //         .catch(err => console.log(err));


    //     // Not working
    //     API.getUmbrellas()
    //         .then(res => this.setState({ umbrellas: res.data }))
    //         .catch(err => console.log(err));

    //     API.getVehicles()
    //         .then(res => this.setState({ vehicles: res.data }))
    //         .catch(err => console.log(err));
    // }