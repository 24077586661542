import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
import Table from 'react-bootstrap/Table';
import Moment from 'react-moment';
import cogoToast from "cogo-toast";

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class AutoPolicySummary extends Component {

    state = {
        editActivated: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        this.setState({
            id: this.props.autoData._id,
            carrier: this.props.autoData.carrier,
            policyNumber: this.props.autoData.policyNumber,
            effectiveDate: this.props.autoData.effectiveDate,
            BI: this.props.autoData.BI,
            PD: this.props.autoData.PD,
            UM: this.props.autoData.UM,
            UIM: this.props.autoData.UIM,
            TORT: this.props.autoData.TORT,
            premium: this.props.autoData.premium
        })

        this.gatherVehicles()
        this.gatherDrivers()
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    gatherVehicles = () => {
        API.findVehicles(this.props.autoData._id)
            .then(res => {
                this.setState({
                    vehicles: res.data
                })
            }).catch(err => console.log(err))
    }

    gatherDrivers = () => {
        API.findDrivers(this.props.autoData._id)
            .then(res => {
                this.setState({
                    drivers: res.data
                })
            }).catch(err => console.log(err))
    }

    activateEdit = (e) => {
        e.preventDefault();
        if (this.state.editActivated === true) {
            this.setState({
                editActivated: false
            })
        } else {
            this.setState({
                editActivated: true
            })
        }
    }



    saveEdits = (e) => {
        e.preventDefault();

        var editedData = {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            effectiveDate: this.state.effectiveDate,
            BI: this.state.BI,
            PD: this.state.PD,
            UM: this.state.UM,
            UIM: this.state.UIM,
            TORT: this.state.TORT,
            premium: parseInt(this.state.premium)
        }

        console.log(editedData)

        API.updateAutoPolicy(this.props.autoData._id, {
            carrier: this.state.carrier,
            policyNumber: this.state.policyNumber,
            BI: this.state.BI,
            PD: this.state.PD,
            UM: this.state.UM,
            UIM: this.state.UIM,
            TORT: this.state.TORT,
            premium: parseInt(this.state.premium)
        }).then(res => {
            alert("You've updated this policy!")
        }).catch(err => alert("The edit did not go through!"))

    }

    softdeleteVehicle = (id) => {
        console.log(id + " has been deleted")
        API.softDeleteVehicle(id)
            .then(res => {
                alert("You've deleted this vehicle")
            }).catch(err => alert("The deletion did not go through"))

    }

    softdeleteDriver = (id) => {
        console.log(id + " has been deleted")
        API.softDeleteDriver(id)
            .then(res => {
                alert("You've deleted this driver")
            }).catch(err => alert("The deletion did not go through"))
    }

    softDeleteAuto = (id) => {
        console.log(id + " has been deleted")
        API.softDeleteAuto(id)
            .then(res => {
                alert("You've deleted this vehicle policy")
            }).catch(err => alert("The deletion did not go through"))
    }

    showInfo = () => {
        if (this.state.showInfo === true) {
            this.setState({
                showInfo: false
            })
        } else {
            this.setState({
                showInfo: true
            })
        }
    }

    copyText = (event, data) => {
        console.log(this.state);
        event.preventDefault();

        if (data !== undefined){
            var copyText = data;
            navigator.clipboard.writeText(copyText);
            console.log(navigator.clipboard);
            cogoToast.success("Copied: " + data)
        } else {
            cogoToast.error("Couldn't copy data")
        }

    }

    render() {
        return (

            <div className={this.props.colSize}>
                {this.props.autoData.policyActive ?
                    <div className="card" style={{ overflow: 'auto' }}>
                        <div className="card-header" style={{ color: 'black' }}>
                            <h5><strong>{this.props.autoData.carrier} Auto</strong></h5>
                            <h6><strong>Policy #: {this.props.autoData.policyNumber}</strong></h6>
                            <button className="btn-success btn" onClick={this.showInfo}>Show / Hide</button>
                            {this.props.admin ? <button className="btn-warning btn" onClick={this.activateEdit}>Edit</button> : null}
                            {this.props.admin ? <button className="btn-danger btn" onClick={() => this.softDeleteAuto(this.props.autoData._id)}>Delete</button> : null}
                        </div>
                        {/* {this.state.editActivated ? : null } */}
                        {this.state.editActivated ?
                            <>
                                <div className="card-body" style={{ backgroundColor: 'black' }}>
                                    <Table striped bordered hover responsive>
                                        <tbody>
                                            <tr>
                                                <th><h4><strong><u></u></strong></h4></th>
                                                <th><strong></strong></th>
                                            </tr>   
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Carrier</td>
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.carrier} name="carrier" /></td>
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Policy Number</td>
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.policyNumber} name="policyNumber" /></td>
                                                {/* <td>{this.props.autoData.policyNumber}</td> */}
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Annual Premium</td>
                                                <td><input type="number" onChange={this.handleInputChange} value={this.state.premium} name="premium" /></td>
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Effective Date</td>
                                                {/* <td><input type="date" onChange={this.handleInputChange} value={this.state.effectiveDate} name="effectiveDate" /></td> */}
                                                <td>
                                                    <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                                        {this.props.autoData.effectiveDate}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                    <Table striped bordered hover responsive >

                                        <tbody>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <th>Coverage</th>
                                                <th>Amount</th>
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Bodily Injury</td>
                                                {/* <td>{this.props.autoData.BI}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.BI} name="BI" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Property Damage</td>
                                                {/* <td>{this.props.autoData.PD}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.PD} name="PD" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Uninsured Motorist</td>
                                                {/* <td>{this.props.autoData.UM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.UM} name="UM" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Underinsured Motorist</td>
                                                {/* <td>{this.props.autoData.UIM}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.UIM} name="UIM" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                            <tr style={{backgroundColor: 'white'}}>
                                                <td>Tort Option</td>
                                                {/* <td>{this.props.autoData.TORT}</td> */}
                                                <td><input type="text" onChange={this.handleInputChange} value={this.state.TORT} name="TORT" /></td>
                                                {/* <td><button>More info</button></td> */}
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <button style={{ textAlign: 'center' }} className="btn btn-success" onClick={this.saveEdits}>Save Edits</button>
                                    

                                </div>
                            </>

                            :
                            <div className="card-body" style={{ backgroundColor: 'white' }}>
                                {this.state.showInfo ?
                                    <>
                                        <Table striped bordered hover responsive>
                                            <tbody>
                                                <tr>
                                                    <th><strong></strong></th>
                                                    <th><strong></strong></th>
                                                </tr>   
                                                <tr>
                                                    <td><strong>Carrier</strong></td>
                                                    <td>{this.props.autoData.carrier}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Policy Number</strong></td>
                                                    <td>{this.props.autoData.policyNumber} <i class="fa-solid fa-copy" onClick={(e) => {this.copyText(e, this.props.autoData.policyNumber)}}></i></td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Premium</strong></td>
                                                    <td>${this.props.autoData.premium}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Effective Date</strong></td>
                                                    <td>
                                                        <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                                            {this.props.autoData.effectiveDate}
                                                        </Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>


                                        <Table striped bordered hover responsive>

                                            <tbody>
                                                <tr>
                                                    <th><strong>Coverage</strong></th>
                                                    <th><strong>Amount</strong></th>
                                                </tr>
                                                <tr>
                                                    <td><strong>Bodily Injury</strong></td>
                                                    <td>${this.props.autoData.BI}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Property Damage</strong></td>
                                                    <td>${this.props.autoData.PD}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Uninsured Motorist</strong></td>
                                                    <td>${this.props.autoData.UM}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Underinsured Motorist</strong></td>
                                                    <td>${this.props.autoData.UIM}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Tort Option</strong></td>
                                                    <td>{this.props.autoData.TORT}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Table striped bordered hover responsive>

                                            <tbody>
                                                <tr>
                                                    <th><strong>Type</strong></th>
                                                    <th><strong>Description</strong></th>
                                                    {this.props.admin ? <th>Action</th> : null}
                                                </tr>

                                                {/* Mapping out vehicles saved to this auto policy */}
                                                {this.state.vehicles ?
                                                    <>
                                                        {this.state.vehicles.map(vehicle => (
                                                            <tr key={vehicle._id}>
                                                                {
                                                                    vehicle.activeOnPolicy ?
                                                                        <>
                                                                            <td><strong>Vechicle</strong></td>
                                                                            <td>{vehicle.year + " " + vehicle.make + " " + vehicle.model} </td>
                                                                            <td><p>Comprehensive: {vehicle.compDed}</p><p> Collision: {vehicle.collDed}</p><p> Glass: {vehicle.glassDed}</p></td>
                                                                            <td><a href={vehicle.IDCard}>ID Card</a></td>
                                                                            {this.props.admin ? <td><button className="btn btn-danger" onClick={() => this.softdeleteVehicle(vehicle._id)}>Delete</button></td> : <td></td>}
                                                                        </>
                                                                        : null
                                                                }
                                                            </tr>
                                                        ))}
                                                    </>


                                                    : null}


                                                {/* Mapping out drivers saved to this auto policy */}
                                                {this.state.drivers ?
                                                    <>
                                                        {this.state.drivers.map(driver => (
                                                            <tr key={driver._id}>
                                                                {
                                                                    driver.active ?
                                                                        <>
                                                                            <td><strong>Driver</strong></td>
                                                                            <td>{driver.firstName + " " + driver.lastName}</td>
                                                                            
                                                                            {this.props.admin ? <td><button className="btn btn-danger" onClick={() => this.softdeleteDriver(driver._id)}>Delete</button></td> : <td></td>}
                                                                        </>
                                                                        : null
                                                                }
                                                            </tr>
                                                        ))}
                                                    </>

                                                    : null}



                                                {/* <tr>
                                        <td>Service Contacct #:</td>
                                        <td>[610-355-7896]</td>
                                        <td></td>
                                    </tr> */}

                                            </tbody>
                                        </Table>
                                    </>
                                    : null}


                            </div>
                        }



                    </div>
                    : null}
                <br />
            </div>
        )

    }

}

export default AutoPolicySummary;