import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
//This index.js file imports the necessary modules, renders the main App component, and hooks it up to the root element in our HTML.

//We have also leveraged the create-react-app's service worker file for potential Progressive Web App (PWA) functionality. However, it's currently unregistered, reinforcing that we've've chosen not to opt for PWA right now.


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
