import React, {Component} from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-boostrap/NavDropdown';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import fire from '../../config/Fire';
import Modal from 'react-modal'
import LoginCard from '../LoginCard';
// import {Redirect } from 'react-router-dom'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:'400px',
        borderRadius: '10px',
        border: '0px'
    }
}


class FawnNav extends Component {

    state = {
        editActivated: false,
        editModalIsOpen: false
    }

    logOut = e => {
        console.log("Logging out");
        e.preventDefault();
        fire.auth().signOut();
        document.cookie = "fawnuid=null"
        document.location.href = '/'
        alert("You've signed out")
    }

    openLogin = () => {
        this.setState({
            editModalIsOpen: true
        })
    }

    openEditModal = () => {
        this.setState({
            editModalIsOpen: true
        })
    }

    onAfterOpen = () => {
        console.log("Login modal is opened")
    }

    closeEditModal = () => {
        this.setState({
            editModalIsOpen: false
        })
    }

    openDashboard = () => {
        console.log("opening dashboard")
    }

    openBlog = () => {
        console.log("opening blog")
    }

    openResources = () => {
        console.log("opening resources")
    }

    openTicket = () => {
        console.log("opening tickets")
    }

    openAgent = () => {
        document.location.href ='/agent'
    }

    openClient = () => {
        document.location.href="/client"
    }

    render() {
        return (
            <div className="item-shadow">
                <Navbar bg="light" expand="lg" style={{width: '100%', padding: '1px', color: 'white'}}>
                {/* <img className="boxshadow" style={{width: '50px', height: '50px', borderRadius: '10px'}}src="/images/fawn-mini-invert.png" alt="fawnminilogo">
                                    </img>  */}
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Brand href="/">
                                <strong>
                                    F A W N | Insurance    
                                </strong>
                    </Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                     
                                    <Nav.Link href="/">
                                        <div className="nav-div"><strong>Home</strong></div>
                                    </Nav.Link>

                                    {/* <Nav.Link>
                                        <div className="nav-div" onClick={this.openDashboard}><strong>Manage</strong></div>
                                    </Nav.Link> */}
                               
                                    
        
                               
                                  

                                    <Nav.Link>
                                        <div className="nav-div" onClick={this.openAgent}><strong>Agent</strong></div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div className="nav-div" onClick={this.openClient}><strong>Client</strong></div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div className="nav-div" onClick={this.openTicket}><strong>Quote</strong></div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div className="nav-div" onClick={this.openLogin}><strong>Login</strong></div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div className="nav-div" onClick={this.openResources}><strong>Resources</strong></div>
                                    </Nav.Link>
                                    <Nav.Link>
                                        <div className="nav-div" onClick={this.logOut}><strong>Logout</strong></div>
                                    </Nav.Link>
                              
                        </Nav>  
                    </Navbar.Collapse>
                </Navbar>

                <Modal
                        isOpen={this.state.editModalIsOpen}
                        onAfterOpen={this.afterOpenEditModal}
                        onRequestClose={this.closeEditModal}
                        style={customStyles}
                        contentLabel={"Edit Quote Here!"}
                        // appElement={el}
                        ariaHideApp={false}
                        >
                            {/* <p>"Login Form"</p> */}
                            <LoginCard />
                </Modal>
            </div>
        );
    }
}

export default FawnNav;
