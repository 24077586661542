import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css';
import API from '../../utils/API';

class CreateReferringAgent extends Component {
    state = {
        source: this.props.source,
        agentFirstName: "",
        agentLastName: "",
        agentEmail: "",
        agentTelephone: "",
        agentPassword: "",
        agentAuthority: "agent",
        licensingInterest: false,
        timeTableChosen: "",
        timeTables: [
            'ASAP',
            'Already licensed',
            'Within 3 months',
            'In 3-6 months',
            'In 6-12 months',
            'Next year',
            'Unsure',
            'Not Applicable',
        ]
    }

    componentDidMount = () => {
        this.setState({
            source: this.props.source
        })
    }


    handleInputChange = event => {
        // const { name, value } = event.target;
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };


    submitForm = e => {
        e.preventDefault()
 
        console.log("Enrolled!")
        console.log(this.state);

        var enrollmentData = {
            firstName: this.state.agentFirstName,
            lastName: this.state.agentLastName,
            email: this.state.agentEmail,
            telephone: this.state.agentTelephone,
            licensingInterest: this.state.licensingInterest.toString(),
            timeTable: this.state.timeTableChosen,
            source: this.state.source
        }

        API.saveEnrollmentData({
            firstName: enrollmentData.firstName,
            lastName: enrollmentData.lastName,
            email: enrollmentData.email,
            telephone: enrollmentData.telephone,
            licensingInterest: enrollmentData.licensingInterest,
            timeTable: enrollmentData.timeTable,
            source: enrollmentData.source
        }).then(res => {
            console.log("Saved Enrollment!");
            alert("Enrollment Successful, we'll be in touch!");

        }).catch(err => {
            console.log(err);
            alert("Something went wrong, please try again!");
        })
    }


    render() {
        return (

            < div >
                <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8">
                        <div className="card" style={{backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>
                            <div className="card-header" style={{textAlign: 'center'}}>
                                
                                {/* <h5 style={{ textAlign: 'center' }}>Welcome to Fawn Circle</h5> */}
                                <img class="boxshadow" style={{borderRadius: '10px', width: '50%', height: '20%'}}src="/images/fawn-logo.png" alt="fawnlogo"></img>
                            </div>
                            <div className="card-body" style={{textAlign: 'center', backgroundColor: 'white', color: 'black'}}>
                                <form>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>First Name</label>
                                        <br />
                                        <input type="text" value={this.state.agentFirstName} onChange={this.handleInputChange} name="agentFirstName" placeholder="Jack" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Last Name</label>
                                        <br />
                                        <input type="text" value={this.state.agentLasttName} onChange={this.handleInputChange} name="agentLastName" placeholder="Black" />
                                    </div>
                                </div>
                                <br />
                                <div className="row">


                                    <div className="col-lg-6">
                                        <label>Email</label>
                                        <br />
                                        <input type="text" value={this.state.agentEmail} onChange={this.handleInputChange} name="agentEmail" placeholder="@"/>
                                    </div>

                                    <div className="col-lg-6">
                                        <label>Phone</label>
                                        <br />
                                        <input type="text" value={this.state.agentTelephone} onChange={this.handleInputChange} name="agentTelephone" placeholder="#"/>
                                    </div>


                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-12">
                                        <label>Would you like to get licensed, or are you already licensed?</label>
                                        <br />
                                        <input type="checkbox" checked={this.state.licensingInterest} onChange={this.handleInputChange} name="licensingInterest" />
                                    </div>
                                    {this.state.licensingInterest === true ? 
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <label>When would you like to get licensed?</label>
                                            <br />
                                            <select id="licensingTimeTable" value={this.state.timeTableChosen} onChange={this.handleInputChange} name="timeTableChosen">
                                                <option value=""></option>
                                                {this.state.timeTables.map(time => (
                                                    <option key={time} value={time}>{time}</option>
                                                ))}
                                            </select>
                                        </div>   

                                    </div>
                     
                                    : null}
        
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col-lg-4"></div>
                                    <div className="col-lg-4" style={{textAlign: 'center'}}>
                                        <button className="btn-success btn-sm btn" onClick={this.submitForm}>Enroll</button>
                                    </div>
                                    <div className="col-lg-4"></div>
                                </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>

 


            </div >



        )
    }
}


export default CreateReferringAgent;
