import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
// import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
import fire from '../../config/Fire';
import AIForm from '../../components/AIForm'


class CreateClient extends Component {
    state = {
        clientFirstName: "",
        clientLastName: "",
        clientEmail: "",
        clientTelephone: "",
        clientServicer: "",
        clientPassword: "",
        agent1: "",
        agent2: "",
        agent3: "",
        agent4: "",
        
    }

    componentDidMount = () => {
        console.log(this.props);
        API.getAgents()
        .then(res => this.setState({ agents: res.data }))
        .catch(err => console.log(err));

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });


    };

    signup = e => { // Deprecated for code below readFormData
        e.preventDefault();
        console.log(this.state);
        fire.auth().createUserWithEmailAndPassword(this.state.clientEmail, this.state.clientPassword).then(data => {
            console.log("Successfully created user")
            console.log(data)
            alert("User: " + this.state.clientFirstName + " " + this.state.clientLastName + " was created!")

            API.saveClient({
                uid: data.user.uid,
                firstName: this.state.clientFirstName,
                lastName: this.state.clientLastName,
                email: data.user.email,
                telephone: this.state.clientTelephone,
                servicer: this.state.clientServicer,
                agent1: this.state.agent1,
                agent2: this.state.agent2,
                agent3: this.state.agent3,
                agent4: this.state.agent4
            })

            // if (this.state.agent1){
            //     if (!this.state.agent2 && this.state.agent3){
            //         API.addClientToAgent({
            //             clientid: data.user.uid,
            //             agentid: this.state.agent1,
            //             split: '100%'
            //         })
            //     } else {
            //         API.addClientToAgent({
            //             clientid: data.user.uid,
            //             agentid: this.state.agent1,
            //             split: '50%'
            //         })
            //     }
            // } 
            // if (this.state.agent2){
            //     if (!this.state.agent3){
            //         API.addClientToAgent({
            //             clientid: data.user.uid,
            //             agentid: this.state.agent2,
            //             split: '50%'
            //         })
            //     } else {
            //         API.addClientToAgent({
            //             clientid: data.user.uid,
            //             agentid: this.state.agent2,
            //             split: '25%'
            //         })
            //     }
            // }

            // if (this.state.agent3){
            //     API.addClientToAgent({
            //         clientid: data.user.uid,
            //         agentid: this.state.agent3,
            //         split: '25%'
            //     })
            // }
            
        }).catch((error) =>  {
            console.log(error)
            alert("Error: User not created!")
        })
    }

    readFormData = (e, formData) => {
        e.preventDefault();
        console.log(formData)

        fire.auth().createUserWithEmailAndPassword(formData.email, formData.clientPassword).then(data => {
            console.log("Successfully created user")
            console.log(data);
            alert("User: " + formData.firstName + " " + formData.lastName)

            API.saveClient({
                uid: data.user.uid,
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: data.user.email,
                telephone: formData.telephone,
                servicer: 'Fawn Circle',
                agent1: this.state.agent1,
                agent2: this.state.agent2,
                agent3: this.state.agent3,
                agent4: this.state.agent4
            })

            // if (this.state.agent1)

        }).catch((error) => {
            console.log(error)
            alert("Error: User not created!")
        })

    }

    render() {
        return (

            <div>

                <h2>Tag Agents to be associated with this client</h2>
                <div className="row">
                    <div className="col-lg-4 offset-lg-1 col-12">
                        <label>Choose Agent 1</label>
                                <input list="agents" value={this.state.agent1} name="agent1" onChange={this.handleInputChange} />
                                    {this.state.agents ?
                                        <datalist id="agents">
                                            {this.state.agents.map(agent => (
                                                <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                            ))}
                                        </datalist>
                                    : null}

                    </div>
                    <div className="col-lg-4 offset-lg-1 col-12">
                    <label>Choose Agent 2</label>
                                <input list="agents" value={this.state.agent2} name="agent2" onChange={this.handleInputChange} />
                                    {this.state.agents ?
                                        <datalist id="agents">
                                            {this.state.agents.map(agent => (
                                                <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                            ))}
                                        </datalist>
                                    : null}
                    </div>
                    <div className="col-lg-4 offset-lg-1 col-12">
                    <label>Choose Agent 3</label>
                                <input list="agents" value={this.state.agent3} name="agent3" onChange={this.handleInputChange} />
                                    {this.state.agents ?
                                        <datalist id="agents">
                                            {this.state.agents.map(agent => (
                                                <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                            ))}
                                        </datalist>
                                    : null}

                    </div>
                    <div className="col-lg-4 offset-lg-1 col-12">
                    <label>Choose Agent 4</label>
                                <input list="agents" value={this.state.agent4} name="agent4" onChange={this.handleInputChange} />
                                    {this.state.agents ?
                                        <datalist id="agents">
                                            {this.state.agents.map(agent => (
                                                <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                            ))}
                                        </datalist>
                                    : null}

                    </div>
                </div>
                <AIForm
                    formTitle='Create Client'
                    formData={[
                        {
                            id: '1',
                            inputName: 'firstName',
                            inputLabel: 'First Name',
                            inputType: 'text',
                            inputValue: ''
                        },
                        {
                            id: '2',
                            inputName: 'lastName',
                            inputLabel: 'Last Name',
                            inputType: 'text',
                            inputValue: ''
                        },
                        {
                            id: '3',
                            inputName: 'telephone',
                            inputLabel: 'Phone Number',
                            inputType: 'text',
                            inputValue: ''
                        },
                        {
                            id: '9',
                            inputName: 'email',
                            inputLabel: 'Client Email',
                            inputType: 'text',
                            inputValue: ''
                        },
                        {
                            id: '10',
                            inputName: 'clientDOB',
                            inputLabel: 'Date of Birth',
                            inputType: 'date',
                            inputValue: ''
                        },
                        {
                            id: '11',
                            inputName: 'clientPassword',
                            inputLabel: 'New Password',
                            inputType: 'password',
                            inputValue: ''
                        }
                    ]}
                    returnFormData={this.readFormData}
                />

                {/* <div className="card" style={{borderRadius: '10px', color: 'black', overflow: 'auto', padding: '0'}}> */}
                    {/* <div className="card-header"> */}
                        {/* <h5 style={{ textAlign: 'center' }}>Create a Client</h5> */}
                        {/* <p> */}
                        {/* </p> */}
                    {/* </div> */}
                    {/* <div className="card-body" style={{backgroundColor: 'transparent'}}> */}
                            {/* <h3 style={{textAlign: 'center'}}> */}
                            {/* Create Client  */}
                            {/* </h3> */}
                        {/* <div className="row"> */}
                            {/* <div className="col-lg-3" ></div> */}
                            {/* <div className="col-xs-6 offset-xs-3" > */}
                            {/* <form> */}
                            {/* <Table bordered hover style={{backgroundColor: 'white', height: '100%', width: '600px'}}> */}
                                {/* <thead>
                                    
                                </thead> */}
                                {/* <tbody> */}
                                    {/* <tr>
                                    <td style={{width: '200px'}}><strong>Question</strong></td>
                                    <td style={{width: '400px'}}><strong>Input</strong></td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>First Name</td>
                                        <td><input type="text" value={this.state.clientFirstName} onChange={this.handleInputChange} name="clientFirstName" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Last Name</td>
                                        <td><input type="text" value={this.state.clientLastName} onChange={this.handleInputChange} name="clientLastName" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td> <input type="text" value={this.state.clientEmail} onChange={this.handleInputChange} name="clientEmail" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Password</td>
                                        <td><input type="text" value={this.state.clientPassword} onChange={this.handleInputChange} name="clientPassword" /></td>
                                    </tr>
                                    <tr>
                                        <td>Telephone</td>
                                        <td><input type="text" value={this.state.clientTelephone} onChange={this.handleInputChange} name="clientTelephone" />
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td><label><strong>P+C Agent</strong></label></td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.chosenAgent} onChange={this.handleInputChange} name="agent1">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent.uid}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label><strong>Agent 2</strong></label></td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.chosenAgent} onChange={this.handleInputChange} name="agent2">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent.uid}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>
                                            </div> : <div>No agents yet</div>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><label><strong>Agent 3</strong></label></td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.chosenAgent} onChange={this.handleInputChange} name="agent3">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent.uid}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}
                                        </td>
                                    </tr> */}

                                           
        
                                    {/* <tr>
                                        <td></td>
                                        <td> */}
                                            {/* <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                onClick={(e) => this.signup(e)}>Submit */}
                                                {/*      onClick={(e) => this.handleServiceReqSubmit(e)}>Submit */}
                                            {/* </button>
                                        </td>
                                    </tr> */}

                                {/* </tbody>
                            </Table>
                            <div style={{ textAlign: 'center' }}> */}
                                {/* <button className="btn-success btn-sm btn" onClick={this.signup}>Submit!</button> */}
        
                                {/* <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 74%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                onClick={() => this.signup()}>Submit
                                </button> */}
                            {/* </div> */}
                        {/* </form> */}
                            {/* </div> */}
                            {/* <div className="col-lg-3 " ></div> */}
                        {/* </div> */}
                        
                    {/* </div> */}
                {/* </div> */}


            </div >



        )
    }
}


export default CreateClient;
