import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
import Table from 'react-bootstrap/Table';

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class CreateDriver extends Component {

    state = {
        createActivated: false,
        firstName: "",
        lastName: "",
        dob: "",
        license: "",
        activeOnPolicy: true
    }

    componentDidMount = () => {

        setTimeout(() => { this.getPolicies(this.props.clientID) }, 1000)

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    activateCreate = (e) => {
        e.preventDefault();
        if (this.state.createActivated === true) {
            this.setState({
                createActivated: false
            })
        } else {
            this.setState({
                createActivated: true
            })
        }
    }

    getPolicies = (clientID) => {

        API.findClientsAutoPolicies(clientID)
            .then(res => {
                this.setState({
                    policies: res.data
                })
            })
    }

    handleDriverSubmit = (e) => {
        e.preventDefault()

        API.saveDriver(this.props.autoData[0]._id, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.dob,
            license: this.state.license,
            parentPolicy: this.state.parentPolicy,
            active: true
        }).then(res => {
            alert("You've saved the Driver")
        }).catch(err => alert("WARNING, DRIVER NOT SAVED. PLEASE TRY AGAIN, IF ERROR PERSISTS CALL 610-299-9918"))

    }


    render() {
        return (

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        Create a Driver
                            {/* <br /> */}
                        {/* <button className="btn-warning btn" onClick={this.activateCreate}>Create</button> */}
                    </div>
                    {/* {this.state.createActivated ? */}
                    <div className="card-body">
                        <Table>

                            <tbody>
                                <tr>
                                    <th><strong>Info needed</strong></th>
                                    <th><strong>Input</strong></th>
                                </tr>
                                <tr>
                                    <td>First Name: </td>
                                    <td><input type="text" onChange={this.handleInputChange} value={this.state.firstName} name="firstName" /></td>
                                </tr>
                                <tr>
                                    <td>Last Name: </td>
                                    <td><input type="text" onChange={this.handleInputChange} value={this.state.lastName} name="lastName" /></td>
                                </tr>
                                {/* <tr>
                                    <td>Date of Bith: </td>
                                    <td><input type="date" onChange={this.handleInputChange} value={this.state.dob} name="dob" /></td>
                                </tr>
                                <tr>
                                    <td>License # (optional)</td>
                                    <td><input type="text" onChange={this.handleInputChange} value={this.state.license} name="license" /></td>
                                </tr> */}
                                <tr>
                                    <td>Policy Assignment</td>
                                    {this.state.policies ?

                                        <td><select id="protegeDropMenu" value={this.props.parentPolicy} onChange={this.handleInputChange} name="parentPolicy" type="">
                                            <option value="">--Select a Policy--</option>
                                            {this.state.policies.map(policy => (
                                                <option key={policy._id} value={policy._id}>{policy.carrier} #{policy.policyNumber}</option>
                                            ))}
                                        </select>
                                        </td>


                                        : <td>"No auto policies yet"</td>}
                                </tr>

                            </tbody>
                        </Table>
                        <button style={{ textAlign: "center" }} className="btn btn-success" onClick={this.handleDriverSubmit}>Submit</button>
                    </div>

                    {/* : null} */}


                </div>
            </div>
        )

    }

}

export default CreateDriver;