import React, { Component } from "react";
import "./style.css";
import Table from 'react-bootstrap/Table';

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class QuickQuoteViewer extends Component {

    state = {
        editActivated: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };


    render() {
        return (
            <div className="" style={{ overflow: 'auto' }}>
                {/* <div className="card-header"> */}
                    {/* <h3><strong>Active Prospects</strong></h3> */}
                {/* </div> */}
                <div className="card-body">
                    <Table responsive bordered hover size="sm" style={{backgroundColor: 'white'}}>
                        <thead>
                            <tr>

                                <th>Client Name</th>
                                <th>Status</th>
                                <th>Savings</th>
                                <th>Carrier</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.quotes ?
                                <>

                                    {this.props.quotes.map(quote => (
                                        <tr key={quote._id}>
                                            {quote.active ?
                                                <>
                                                    <td style={{ width: '35%' }}>{quote.firstName} {quote.lastName}</td>
                                                    <td style={{ width: '35%' }}>{quote.quoted ?
                                                        <>
                                                            {quote.presented ?
                                                                <>
                                                                    {quote.sold ? "Sold" : "Need to Sell"}
                                                                </>
                                                                : "Need to Present "}
                                                        </>
                                                        : "Need to Quote"}
                                                    </td>
                                                    <td style={{ width: '15%' }}>
                                                        ${quote.savings}
                                                    </td>
                                                    <td style={{ width: '15%' }}>
                                                        {quote.quoteCarrier}
                                                    </td>
                                            </>
                                                : null}
                                        </tr>
                                    ))}

                                </>

                                :
                                <tr>
                                    <td><img alt="loading" src="/loading.gif" /></td>
                                    <td><img alt="loading" src="/loading.gif" /></td>
                                    <td><img alt="loading" src="/loading.gif" /></td>
                                    <td><img alt="loading" src="/loading.gif" /></td>
                                </tr>}
                        </tbody>
                    </Table>
                </div>
            </div>

        )

    }

}

export default QuickQuoteViewer;



