import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css';
import API from '../../utils/API';

class CreateBaseballPlayer extends Component {
    state = {
        source: this.props.source,
        agentFirstName: "",
        agentLastName: "",
        agentEmail: "",
        agentTelephone: "",
        agentPassword: "",
        agentAuthority: "agent",
        licensingInterest: false,
        timeTableChosen: "",
        residentState: "",
        states: [
            'PA',
            'NJ',
            'DE',
            'MD',
            'Other'
        ]
    }

    componentDidMount = () => {
        this.setState({
            source: this.props.source
        })
    }

    // handleInputChange = event => {
    //     const { name, value } = event.target;
    //     this.setState({
    //         [name]: value
    //     });
    // };

    handleInputChange = event => {
        // const { name, value } = event.target;
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };


    submitForm = e => {
        e.preventDefault()
 
        console.log("Enrolled!")
        console.log(this.state);

        var baseballData = {
            firstName: this.state.agentFirstName,
            lastName: this.state.agentLastName,
            email: this.state.agentEmail,
            telephone: this.state.agentTelephone,
            residentState: this.state.residentState,
            baseRunners: '0',
            hits: '0',
            runs: '0'
        }

        API.saveBaseballData({
            firstName: baseballData.firstName,
            lastName: baseballData.lastName,
            email: baseballData.email,
            telephone: baseballData.telephone,
            residentState: baseballData.residentState,
            baseRunners: baseballData.baseRunners,
            hits: baseballData.hits,
            runs: baseballData.runs
        }).then(res => {
            console.log("Saved Baseball Player!");
            alert("Player Enrollment Successful!");

        }).catch(err => {
            console.log(err);
            alert("Something went wrong, please try again!");
        })
    }


    render() {
        return (

            <div style={{fontSize: '20px'}}>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-">
                        <div className="card" style={{backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>
                            <div className="card-header" style={{textAlign: 'center'}}>
                                
                                {/* <h5 style={{ textAlign: 'center' }}>Welcome to Fawn Circle</h5> */}
                                <img class="boxshadow" style={{borderRadius: '10px', width: '50%', height: '20%'}}src="/images/fawn-logo.png" alt="fawn logo"></img>
                            </div>
                            <div className="card-body" style={{textAlign: 'left', backgroundColor: 'white', color: 'black'}}>
                                <form>
                                <div className="row">
                                    <div className="col">
                                        <label>First Name</label>
                                        <br />
                                        <input type="text" value={this.state.agentFirstName} onChange={this.handleInputChange} name="agentFirstName" placeholder="" />
                                    </div>
                                    <div className="col">
                                        <label>Last Name</label>
                                        <br />
                                        <input type="text" value={this.state.agentLasttName} onChange={this.handleInputChange} name="agentLastName" placeholder="" />
                                    </div>
                                </div>
                                <div className="row">


                                    <div className="col">
                                        <label>Email</label>
                                        <br />
                                        <input type="text" value={this.state.agentEmail} onChange={this.handleInputChange} name="agentEmail" placeholder=""/>
                                    </div>

                                    <div className="col">
                                        <label>Phone</label>
                                        <br />
                                        <input type="text" value={this.state.agentTelephone} onChange={this.handleInputChange} name="agentTelephone" placeholder=""/>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="row">
                                            <div className="col">
                                                <label>Resident state</label>
                                                <br />
                                                <select id="residentState" value={this.state.residentState} onChange={this.handleInputChange} name="residentState">
                                                    <option value=""></option>
                                                    {this.state.states.map(state => (
                                                        <option key={state} value={state}>{state}</option>
                                                    ))}
                                                </select>
                                            </div>   

                                        </div>
                                    </div>        
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col" style={{textAlign: 'center'}}>
                                        <button className="btn-success btn-sm btn" onClick={this.submitForm}>Enroll</button>
                                    </div>
                                    <div className="col"></div>
                                </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2"></div>
                </div>

 


            </div >



        )
    }
}


export default CreateBaseballPlayer;
