import React, { Component } from "react";
import "./style.css";
import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class WebsiteCard extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }



    render() {
        return (
            <div>
                <div class="row">
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div className="card" style={{ textAlign: 'center' }}>
                       
                        <div className="card-header">
                            <h2  style={{ color: 'black' }}>Our Website</h2>
                        </div> 

                        <div className="card-body">
                            <ListGroup className="offset-lg-3 col-lg-6 list-group-flush">

                                <ListGroup.Item>
                                    <h6>Fawn Circle, is proud to present this fully interactive insurance web application. </h6>
                                    <h6>We provide everything from online quoting, client document access, service request submissions, agent tools, and more.</h6>
                                    <h6>We leverage our insurance carriers' various online web tools to provide a custom user experience you won't find at other independent agencies! </h6>
                                    <h6>Through our product development team, we plan to bring real-time quoting to you for your Auto/Home insurance needs as well as other products like Health Insurance and Disability Insurance.</h6>
                                    <h6>We're so happy to have you be a part of our journey, and please do regularly check-in to see what fun new applications we've built on-top of our website!</h6>
                                    <h6 style={{float: 'right', textIndent: ''}}>Sincerely yours,
                                    <br /> <strong>Evan J. Cleary</strong>
                                    <br /> Founder / CEO</h6>
                                
                                </ListGroup.Item>
                                <button className="btn btn-dark" onClick={this.props.return} style={{ float: 'right' }}>Return</button>


                            </ListGroup>
                        </div>                   
                    </div>
                    {/* <div className="card-body">
                        <div className="card-title">
                            <h4 className="" style={{ color: 'black' }}>Here's a little bit about how we got started and what our goals are!</h4>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        )

    }

}

export default WebsiteCard;