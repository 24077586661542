import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
// import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
// import { storage } from '../../config/Fire';
import fire from '../../config/Fire';
import cogoToast from 'cogo-toast';


class CreateAgentRow extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        residentState: "",
        docName: "",
        document: ""
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    componentDidMount = () => {
        // API.getClientIDs()
        // .then(res => {
        //     this.setState({ clients: res.data });
        //     console.log(res);
        //     console.log(this.state.clients)
        
        // })
        // .catch(err => console.log(err));


        // API.getAgents()
        // .then(res => {
        //     this.setState({ agents: res.data });
        //     console.log(res);
        //     console.log(this.state.agents)

        // })
        // .catch(err => console.log(err));

        // setTimeout( () => {
        //     console.log(this.state)
        // }, 5000)

    }

    signup = e => {
        e.preventDefault()
        let password = 'Fawn2076'
        fire.auth().createUserWithEmailAndPassword(this.state.email, password).then(data => {
            console.log("Successfully created Agent")
            console.log(data)

            API.saveAgent({
                uid: data.user.uid,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: data.user.email,
                telephone: this.state.telephone,
                residentState: this.state.residentState
            }).then(res => {
                console.log(res.data)
                cogoToast.success("Successfully Created a New Agent!");
                alert("Successfully Created a New Agent!");
                this.props.generateNewRow(res.data)
                if (this.state.document.length > 0){
                    
                    if (this.state.docName.length > 0){

                        API.createDocumentForAgent(res.data._id, {
                            docName: this.state.docName,
                            document: this.state.document,
                            policyid: res.data._id,
                            type: "agent"
                        }).then((res) => {
                            console.log(res.data);
                            cogoToast.success("Uploaded Agent Document")
                        }).catch( err => console.log(err))

                    } else {

                        API.createDocumentForAgent(res.data._id, {
                            docName: "Agent Document",
                            document: this.state.document,
                            policyid: res.data._id,
                            type: "agent"
                        }).then((res) => {
                            console.log(res.data);
                            cogoToast.success("Uploaded Agent Document")
                        }).catch( err => console.log(err))

                    }
                }
            })
        }).catch((error) => {
            console.log(error)
        })
    }



    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        this.setState({
            docName: event.target.files[0].name
        })
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document: res
                })
            })
        }
    }



    render() {
        return (

        // <span>


            <tr>
                <td>
                    <input type="text" style={{width: '100px'}} value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="First" />
                </td>

                <td>
                    {/* <input type="date"  style={{width: '150px'}} value={this.state.dob} onChange={this.handleInputChange} name="dob" placeholder="Date of Birth" /> */}
                    <input type="text" style={{width: '100px'}} value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Last" />
                </td>
                <td>
                   <input name="fileData" style={{width: '200px'}} type="file" onChange={this.fileSelectedHandler} />     
                </td>
                <td>
                    <input style={{width: '150px'}} type="text" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="E-mail" />
                </td>  
                <td>
                    <input type="text" style={{width: '150px'}} value={this.state.telephone} onChange={this.handleInputChange} name="telephone" placeholder="Phone #" />
                </td>
                <td>
                    <input style={{width: '150px'}} maxLength="2" type="text" value={this.state.residentState} onChange={this.handleInputChange} name="residentState" placeholder="Resident State" />
                </td>
                <td>
                    <input type="text"  style={{width: '300px'}} value={this.state.address} onChange={this.handleInputChange} name="address" placeholder="Address" />
                </td>
                <td>
                    <button className="btn-success btn" onClick={(e) => this.signup(e)}>Create</button>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                </td>
                <td> 
                </td>
                <td>
                </td> 
            </tr>

        )
    }
}


export default CreateAgentRow;
