import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
// import { storage } from '../../config/Fire';
import cogoToast from 'cogo-toast';


class QuoteUpdateForm extends Component {
    state = {
        quickQuote: "",
        firstName: "",
        lastName: "",
        quickName: "",
        dob: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        quickAddress: "",
        phone: "",
        email: "",
        vehicles: "",
        drivers: "",
        notes: "",
        savings: "",
        referrer: "",
        quote1Carrier: "",
        targetPremium: "",
        quote1AutoPremium: "",
        quote1HomePremium: "",
        quote1UmbrellaPremium: "",
        quote1Notes: "",
        quote1Doc: "",
        quote2Carrier: "",
        quote2AutoPremium: "",
        quote2HomePremium: "",
        quote2UmbrellaPremium: "",
        quote2Doc: "",
        quote2Notes: "",
        quote3Carrier: "",
        quote3AutoPremium: "",
        quote3HomePremium: "",
        quote3UmbrellaPremium: "",
        quote3Doc: "",
        quote3Notes: "",
        proposalDoc: "", 
        proposalNoteS: "",       
        mcNotes: "",
        mcStatus: "",
        mainAgent: "",
        refAgentOne: "",
        refAgentTwo: "",
        refAgentThree: "",
        refAgentFour: "",
        uploadedLink: "",
        active: "",
        quoted: "",
        presented: "",
        sold: "",
        docName1: "",
        document1: "",
        docName2: "",
        document2: "",
        docName3: "",
        document3: "",
        proposalName: "",
        proposal: ""
    }

    componentDidMount = () => {
        console.log("Property Update Policy Form Component Launched with props:")
        console.log(this.props.quoteData);
        this.setState({
            quoteID: this.props.quoteData._id,
            quickQuote: this.props.quoteData.quickQuote,
            firstName: this.props.quoteData.firstName,
            lastName: this.props.quoteData.lastName,
            quickName: this.props.quoteData.quickName,
            dob: this.props.quoteData.dob,
            street: this.props.quoteData.street,
            city: this.props.quoteData.city,
            state: this.props.quoteData.state,
            zip: this.props.quoteData.zip,
            quickAddress: this.props.quoteData.quickAddress,
            phone: this.props.quoteData.phone,
            email: this.props.quoteData.email,
            vehicles: this.props.quoteData.vehicles,
            drivers: this.props.quoteData.drivers,
            notes: this.props.quoteData.notes,
            savings: this.props.quoteData.savings,
            referrer: this.props.quoteData.referrer,
            quote1Carrier: this.props.quoteData.quote1Carrier,
            quote1AutoPremium: this.props.quoteData.quote1AutoPremium,
            quote1HomePremium: this.props.quoteData.quote1HomePremium,
            quote1UmbrellaPremium: this.props.quoteData.quote1UmbrellaPremium,
            quote1Doc: this.props.quoteData.quote1Doc,
            quote2Carrier: this.props.quoteData.quote2Carrier,
            quote2AutoPremium: this.props.quoteData.quote2AutoPremium,
            quote2HomePremium: this.props.quoteData.quote2HomePremium,
            quote2UmbrellaPremium: this.props.quoteData.quote2UmbrellaPremium,
            quote2Notes: this.props.quoteData.quote2Notes,
            quote2Doc: this.props.quoteData.quote2Doc,
            quote3Carrier: this.props.quoteData.quote3Carrier,
            quote3AutoPremium: this.props.quoteData.quote3AutoPremium,
            quote3HomePremium: this.props.quoteData.quote3HomePremium,
            quote3UmbrellaPremium: this.props.quoteData.quote3UmbrellaPremium,
            quote3Doc: this.props.quoteData.quote3Doc,
            proposalDoc: this.props.quoteData.proposalDoc,        
            mcNotes: this.props.quoteData.mcNotes,
            mcStatus: this.props.quoteData.mcStatus,
            mainAgent: this.props.quoteData.mainAgent,
            refAgentOne: this.props.quoteData.refAgentOne,
            refAgentTwo: this.props.quoteData.refAgentTwo,
            refAgentThree: this.props.quoteData.refAgentThree,
            refAgentFour: this.props.quoteData.refAgentFour,
            uploadedLink: this.props.quoteData.uploadedLink,
            active: this.props.quoteData.active,
            quoted: this.props.quoteData.quotes,
            presented: this.props.quoteData.presented,
            sold: this.props.quoteData.sold,
            quote1Notes: this.props.quoteData.quote1Notes,
            quote3Notes: this.props.quoteData.quote3Notes,
            proposalNotes: this.props.quoteData.proposalNotes,
            targetPremium: this.props.quoteData.targetPremium
        })
        console.log("Quote Update Form State")
        console.log(this.state);
        setTimeout(() => {
            console.log(this.state);
        }, 3000)
        // this.state.editableData = this.props.policyData
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        console.log(name + " : " + value)
        this.setState({
            [name]: value
        });
    };


    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    proposalDoc: res
                })
            })
        }
    }


    fileSelectedHandlerP = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))
        this.setState({
            proposalName: event.target.files[0].name
        })

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    proposal: res
                })
            })
        }
    }



    fileSelectedHandler1 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))
        this.setState({
            docName1: event.target.files[0].name
        })


        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document1: res
                })
            })
        }
    }


    fileSelectedHandler2 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))
        this.setState({
            docName2: event.target.files[0].name
        })


        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document2: res
                })
            })
        }
    }


    fileSelectedHandler3 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))
        this.setState({
            docName3: event.target.files[0].name
        })


        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document3: res
                })
            })
        }
    }



    handleQuoteUpdate = (event) => {
        event.preventDefault();
        console.log("Submitting Quote Update");
        console.log(this.state);

        let quoteInformation = this.state;

        if (this.state.quoted === 'on'){
            this.setState({quoted: true})
        } else if (this.state.quoted === 'off'){
            this.setState({quoted: false})
        }

        console.log(quoteInformation)

        setTimeout(() => {
            API.updateQuote(this.props.quoteData._id, quoteInformation)
            .then(res => {
                console.log("Updated Quote!")
                console.log(res.data)
                alert("Updated Quote")
                cogoToast.success("Quote has been updated");

                if (this.state.proposal.length > 0){

                    API.createDocumentsForQuoteProposal(res.data._id, {
                        docName: this.state.proposalName,
                        document: this.state.proposal,
                        policyid: res.data._id,
                        type: "quote"
                    }).then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Proposal for Quote!")
                    }).catch( err => console.log(err))
                    
                }

                if (this.state.document1.length > 0){

                    API.createDocumentForQuote1(res.data._id, {
                        docName: this.state.docName1,
                        document: this.state.document1,
                        policyid: res.data._id,
                        type: "quote"
                    }).then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Quote 1 into Quote!")
                    }).catch( err => console.log(err))

                }

                if (this.state.document2.length > 0){

                    API.createDocumentForQuote2(res.data._id, {
                        docName: this.state.docName2,
                        document: this.state.document2,
                        policyid: res.data._id,
                        type: "quote"
                    }).then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Quote 2 into Quote!")
                    }).catch( err => console.log(err))

                }

                if (this.state.document3.length > 0){

                    API.createDocumentForQuote3(res.data._id, {
                        docName: this.state.docName3,
                        document: this.state.document3,
                        policyid: res.data._id,
                        type: "quote"
                    }).then((res) => {
                        console.log(res.data);
                        cogoToast.success("Uploaded Quote 3 into Quote!")
                    }).catch( err => console.log(err))

                }

            })
            .catch(err => console.log(err))
        }, 1000)
    }

    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
        
    }


    render() {
        return (

            < div >

                <div className="card">
                    {/* <div className="card-header"> */}
                        <h5 style={{ textAlign: 'center' }}>Update Quote</h5>
                        {/* <p>
                        </p> */}
                    {/* </div> */}
                    
                    <div className="card-body">
                        <form>
                            <Table bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td><strong>Question</strong></td> <td><strong>Input</strong></td>
                                    </tr> */}
                                     <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Short Name</td>
                                        <td><input type="text" value={this.state.quickName} onChange={this.handleInputChange} name="quickName" placeholder="Name" /></td>
                        
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Short Address</td>
                                        <td><input type="text" value={this.state.quickAddress} onChange={this.handleInputChange} name="quickAddress" placeholder="Address" /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Email</td>
                                        <td><input type="text" value={this.state.email} onChange={this.handleInputChange} name="email" /></td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Phone</td>
                                        <td><input type="text" value={this.state.phone} onChange={this.handleInputChange} name="phone" /></td>

                                    </tr>
                                   
                                    {/* <tr>
                                        <td>Effective Date</td>
                                        <td><input type="date" value={this.state.editableData.effectiveDate.toString().split("T")[0]} onChange={this.handleInputChange} name="effectiveDate" placeholder="effectiveDate" />
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>First Name</td>
                                        <td><input type="text" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="Client First Name" /></td>
                              
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Last Name</td>
                                        <td><input type="text" value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Client First Name" /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Proposal</td>
                                        <td>
                                            {/* {this.state.proposalDoc ?  */}
                                                {/* <button className="btn btn-success" onClick={(e) => this.downloadBase64File(e, this.state.proposalDoc)}>Proposal</button> */}

                                            {/* :  */}
                                            
                                                <input type="file" name="fileData" onChange={this.fileSelectedHandlerP} />

                                            {/* } */}
                                            
                                            
                                            </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Proposal Notes</td>
                                        <td>
                                            <input type="text" value={this.state.proposalNotes} onChange={this.handleInputChange} name="proposalNotes" placeholder="Proposal Notes" />
                                        </td>
                                    </tr>
                                    <tr>

                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote1</td>
                                        <td>
                                            {/* {this.state.quote1Doc ?
                                                <button className="btn btn-dark" onClick={(e) => this.downloadBase64File(e, this.state.quote1Doc)}>Quote 1</button>
                                                : */}
                                                <input type="file" name="fileData" onChange={this.fileSelectedHandler1} />
                                            {/* } */}
                                            
                                        </td>

                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote1 Carrier</td>
                                        <td>
                                            <input type="text" value={this.state.quote1Carrier} onChange={this.handleInputChange} name="quote1Carrier" placeholder="Quote 1 Carrier" />
                                        </td>
                              
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote1 Auto Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote1AutoPremium} onChange={this.handleInputChange} name="quote1AutoPremium" placeholder="Quote 1 Auto Premium" />
                                        </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote1 Home Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote1HomePremium} onChange={this.handleInputChange} name="quote1HomePremium" placeholder="Quote 1 Home Premium" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote1 Umbrella Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote1UmbrellaPremium} onChange={this.handleInputChange} name="quote1UmbrellaPremium" placeholder="Quote 1 Umbrella Premium" />
                                        </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote 1 Notes</td>
                                        <td>
                                            <input type="text" value={this.state.quote1Notes} onChange={this.handleInputChange} name="quote1Notes" placeholedr="Quote 1 Notes" />
                                        </td>
                                    </tr>
                                    <tr>

                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote2</td>
                                        <td>
                                            {/* {this.state.quote2Doc ? */}
                                                {/* <button className="btn btn-dark" onClick={(e) => this.downloadBase64File(e, this.state.quote2Doc)}>Quote 2</button> */}
                                        {/*  */}
                                            {/* :    */}
                                                <input type="file" name="fileData" onChange={this.fileSelectedHandler2} />                                            
                                            {/* }     */}
                                            

                                            
                                        </td>

                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote2 Carrier</td>
                                        <td>
                                        <input type="text" value={this.state.quote2Carrier} onChange={this.handleInputChange} name="quote2Carrier" placeholder="Quote 2 Carrier" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote2 Auto Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote2AutoPremium} onChange={this.handleInputChange} name="quote2AutoPremium" placeholder="Quote 2 Auto Premium" />
                                        </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote2 Home Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote2HomePremium} onChange={this.handleInputChange} name="quote2HomePremium" placeholder="Quote 2 Home Premium" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote2 Umbrella Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote2UmbrellaPremium} onChange={this.handleInputChange} name="quote2UmbrellaPremium" placeholder="Quote 2 Umbrella Premium" />
                                        </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote2 Notes</td>
                                        <td>
                                            <input type="text" value={this.state.quote2Notes} onChange={this.handleInputChange} name="quote2Notes" placeholder="Quote 2 Notes" />
                                        </td>
                                    </tr>

                                    <tr>


                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote 3</td>
                                        <td>
                                            {/* {this.state.quote3Doc ?
                                            
                                                <button className="btn btn-dark" onClick={(e) => this.downloadBase64File(e, this.state.quote3Doc)}>Quote 3</button>
                                        
                                            : */}
                                                <input type="file" name="fileData" onChange={this.fileSelectedHandler3} />
                                            {/* } */}
                                        </td>

                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote3 Carrier</td>
                                        <td>
                                        <input type="text" value={this.state.quote3Carrier} onChange={this.handleInputChange} name="quote3Carrier" placeholder="Quote 3 Carrier" />
                                        </td>
                                       
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote3 Auto Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote3AutoPremium} onChange={this.handleInputChange} name="quote3AutoPremium" placeholder="Quote 3 Auto Premium" />
                                        </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote 3 Home Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote3HomePremium} onChange={this.handleInputChange} name="quote3HomePremium" placeholder="Quote 3 Home Premium" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote3 Umbrella Premium</td>
                                        <td>
                                        <input type="text" value={this.state.quote3UmbrellaPremium} onChange={this.handleInputChange} name="quote3UmbrellaPremium" placeholder="Quote 3 Umbrella Premium" />
                                        </td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quote3 Notes</td>
                                        <td>
                                        <input type="text" value={this.state.quote3Notes} onChange={this.handleInputChange} name="quote3Notes" placeholder="Quote 3 Notes" />
                                        </td>
                                    </tr>




                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Quoted</td>
                                        <td><input type="checkbox" checked={this.state.quoted} onChange={this.handleInputChange} name="quoted"/>
                                        </td>
                                    
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Presented</td>
                                        <td><input type="checkbox" checked={this.state.presented} value={this.state.presented} onChange={this.handleInputChange} name="presented" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Drivers</td>
                                        <td><input type="textarea" value={this.state.drivers} onChange={this.handleInputChange} name="drivers" placeholder="Drivers" />
                                    </td>
                                   
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Vehicles</td>
                                        <td><input type="textarea" value={this.state.vehicles} onChange={this.handleInputChange} name="vehicles" placeholder="Vehicles" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Notes</td>
                                        <td><input type="textarea" value={this.state.notes} onChange={this.handleInputChange} name="notes" placeholder="Notes" /></td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>DOB</td>
                                        <td><input type="text" value={this.state.dob} onChange={this.handleInputChange} name="dob" placeholder="Carrier" /></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Active</td>
                                        <td><input type="checkbox" checked={this.state.active} value={this.state.active} onChange={this.handleInputChange} name="active" /></td>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Target Premium</td>
                                        <td><input type="number" value={this.state.targetPremium} onChange={this.handleInputChange} name="targetPremium" placeholder="Target Premium" /></td>
                                    </tr>

                                </tbody>
                            </Table>
                            <Table>

                                <tbody>
                                    <tr>
                                        <td>Item Name</td>
                                        <td>Agent</td>
                                        <td>Split</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Main Agent</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.mainAgent} onChange={this.handleInputChange} name="mainAgent">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.mainAgent ?
                                                <input type="number" value={this.state.mainAgentSplit} name="mainAgentSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #1</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentOne} onChange={this.handleInputChange} name="refAgentOne">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.refAgentOne ?
                                                <input type="number" value={this.state.refAgentOneSplit} name="refAgentOneSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #2</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentTwo} onChange={this.handleInputChange} name="refAgentTwo">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td >
                                            {this.state.refAgentTwo ?
                                                <input type="number" value={this.state.refAgentTwoSplit} name="refAgentTwoSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #3</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentThree} onChange={this.handleInputChange} name="refAgentThree">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.refAgentThree ?
                                                <input type="number" value={this.state.refAgentThreeSplit} name="refAgentThreeSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #4</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentFour} onChange={this.handleInputChange} name="refAgentFour">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {/* {this.state.refAgentFour ?
                                                <input type="number" value={this.state.refAgentSplitFour} name="refAgentSplitFour" onChange={this.handleInputChange} />

                                                : null} */}
                                        </td>

                                    </tr>


                                </tbody>
                            </Table>
                            <div style={{ textAlign: 'center' }}>
                                <button className="btn-success btn-sm btn" onClick={(e) => this.handleQuoteUpdate(e)}>Update Quote</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div >



        )
    }
}


export default QuoteUpdateForm;
