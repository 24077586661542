import React, { Component } from "react";
import "./style.css";
// import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';
// import RequestItem from '../../components/RequestItem'
import './style.css'
import API from "../../utils/API";
import Modal from "react-modal";
import PropertyPolicyUpdateForm from "../PropertyPolicyUpdateForm";
import AutoUpdatePolicyForm from "../AutoPolicyUpdateForm";
import UmbrellaPolicyUpdateForm from "../UmbrellaPolicyUpdateForm";
import CreateUmbrellaRow from "../CreateUmbrellaRow";
import CreatePropertyRow from "../CreatePropertyRow";
import CreateAutoRow from "../CreateAutoRow";
import CreateServiceRequest from "../CreateServiceRequest";
import cogoToast from "cogo-toast";
import DocVault from "../DocVault";



const customStylesModal = {
    content: {
        padding: '5px 5px',
        // backgroundImage: 'linear-gradient(to top, #0ba360 0%, #3cba92 100%)',
        // height: '100%',
        border: 'thick solid black',
        overflowX: 'auto'
    }
}

// const customStylesModalQuoteCard = {
//     content: {
//         padding: '5px 5px', 
//         background: 'transparent',
//         border: '0px',
//         height: '100%',
//         border: '0px'
//     }
// }

const docVaultModal = {
    content: {
        margin: '10%',
        padding: '5px 5px',
        backgroundImage: 'rgba(255,255,255,0.5)',
        // height: '100%',
        border: 'thick solid black',
        inset: '40px',
        overflowX: 'auto'
    } 
}

class PolicyDatabase extends Component {

    state = {
        requests: [],
        policies: [],
        openEditModal: false,
        editablePolicy: '',
        searchTerm: '',
        searchedPolicies: [],
        sortedPolicies: [],
        refreshPolicies: [],
        showCreateAutoRow: false,
        showCreatePropertyRow: false,
        showCreateUmbrellaRow: false,
        docIDArray: [],
        policyType: ""
    }

    componentDidMount = () => {
        console.log("Service Request Tracker Card Mounted")
        // setTimeout(() => { this.calculateTotals() }, 3000)
        // API.getServicerequests().then(res => {
        //     console.log("Retrieved Service Requests");
        //     console.log(res.data);
        //     this.setState({
        //         requests: res.data
        //     })
        // })
        let policies = []
        API.getProperties().then(res0 => {
            console.log("Retrieved Properties");
            console.log(res0.data);
            
            for (var i = 0; i < res0.data.length; i++){
                // policies.push(res0.data[i])
                res0.data[i].type = 'Property'
                policies.push(res0.data[i])
                if (i === res0.data.length - 1){
                    API.getAutos().then(res1 => {
                        console.log("Retrieved Autos");
                        console.log(res1.data);


                        for (var j = 0; j < res1.data.length; j++){
                            
                            res1.data[j].type = 'Auto'
                            policies.push(res1.data[j])
                            if (j === res1.data.length - 1){
                                API.getUmbrellas().then(res2 => {
                                    console.log("Retreived Umbrellas")
                                    console.log(res2.data);

                                    for (var k = 0; k < res2.data.length; k++){
                                        res2.data[k].type = 'Umbrella'
                                        policies.push(res2.data[k])
                                        if (k === res2.data.length - 1){
                                            console.log("All policies retrieved");
                                            this.setState({
                                                policies: policies,
                                                refreshPolicies: policies
                                            })
                                            console.log(this.state);
                                        }
                                    }
                                })
                            }
                        }
                    })
                    // console.log(this.state.policies)
                }
            }


        })
    }

    openEditModal = (data) => {
        console.log("Opening up Edit Modal for Data ID: " + data._id)
        console.log(data)
        if(this.state.openEditModal === false){
            this.setState({
                openEditModal: true,
                editablePolicy: data
            })
        } else {
            this.setState({
                openEditModal: false
            })
        }
    }


    closeEditModal = (e) => {
        e.preventDefault();
        if (this.state.openEditModal === true){
            this.setState({
                openEditModal: false,
                editablePolicy: ''
            })
        } else {
            this.setState({
                openEditModal: true
            })
        }
        
    }
  

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    completeAndRemove(){
        // API.getServicerequests().then(res => {
        //     console.log("Retrieved Service Requests");
        //     console.log(res.data);
        //     this.setState({
        //         requests: res.data
        //     })
        // })

    }

    sortTable = (e, type) => {

        if (type === 'effective'){
            console.log("Sorting Effective Dates");

        }

        e.preventDefault();
        console.log(e);
        console.log("Sorting table of type: " + type);
        // let sortedPolicies = this.state.policies.sort((a, b) => {

        //     if (a.clientLastName )

        // })
        // console.log(sortedPolicies)
    }

    searchTable = (e) => {
        e.preventDefault();
        console.log("Search Table for: " + this.state.searchTerm)
        let rowsWithTerm = [];
        for (var i = 0; i < this.state.refreshPolicies.length; i++){

            let object = this.state.refreshPolicies[i]

            for (const property in object){
                console.log(`${property}: ${object[property]}`)
                console.log(typeof(property))
                console.log(typeof(object[property]))
                if (typeof(object[property]) === 'string'){

                    if (object[property].toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1){

                        if (rowsWithTerm.length === 0){
                            rowsWithTerm.push(object);
                        } else {
                            let rowFoundAlready = false;
                            for (var j = 0; j < rowsWithTerm.length; j++){
                                if (rowsWithTerm[j]._id === object._id){
                                    rowFoundAlready = true;
                                }
                                if (j === rowsWithTerm.length - 1){
                                    if (rowFoundAlready === false){
                                        rowsWithTerm.push(object);
                                    }
                                }
                            }
                            this.setState({
                                policies: rowsWithTerm
                            })
                        }
                       
                    }

                }

            }

            if (i === this.state.refreshPolicies.length - 1){
                console.log("Completed search");
                console.log("Rows with Term: " + this.state.searchTerm);
                console.log(rowsWithTerm)
                console.table(rowsWithTerm);
            }

        }
        // this.setState({

        // })
    }

    resetTable = (e) => {
        e.preventDefault();
        console.log("Resetting table");
        console.log("Transforming policies table: ")
        console.table(this.state.policies)
        console.log("Into original table: ");
        console.table(this.state.refreshPolicies)
        this.setState({
            policies: this.state.refreshPolicies
        })
    }

    addNewRowToPolicies = (rowEntry) => {
        console.log("Adding New Row to Policies");
        console.log(rowEntry);
        this.state.policies.push(rowEntry);
        this.state.refreshPolicies.push(rowEntry);

        this.setState({
            showCreateAutoRow: false,
            showCreatePropertyRow: false,
            showCreateUmbrellaRow: false
        })
    }

    readNewRowData = (rowEntry) => {
      
        console.log("Adding New Row to Policies");
        console.log(rowEntry);
        this.state.policies.push(rowEntry);
        this.state.refreshPolicies.push(rowEntry);

        this.setState({
            showCreateAutoRow: false,
            showCreatePropertyRow: false,
            showCreateUmbrellaRow: false
        })
    }


    showCreateAutoRow = (e) => {
        console.log("Showing Create Auto Row")
        e.preventDefault();

        if (this.state.showCreateAutoRow === true){
            this.setState({
                showCreateAutoRow: false
            })
        } else {
            this.setState({
                showCreateAutoRow: true
            })
        }
    }

    showCreatePropertyRow = (e) => {
        e.preventDefault();

        if (this.state.showCreatePropertyRow === true){
            this.setState({
                showCreatePropertyRow: false
            })
        }  else {
            this.setState({
                showCreatePropertyRow: true
            })
        }
    }

    showCreateUmbrellaRow = (e) => {
        e.preventDefault();

        if (this.state.showCreateUmbrellaRow === true){
            this.setState({
                showCreateUmbrellaRow: false
            })
        } else {
            this.setState({
                showCreateUmbrellaRow: true
            })
        }
    }

    uploadDocument = (event, id) => {
        event.preventDefault();
        console.log("Uploading document for ID: " + id);
    }

    openServiceRequestModal = (event, id) => {
        event.preventDefault();
        if (this.state.openServiceRequestModal !== true){
            this.setState({
                openServiceRequestModal: true,
                serviceID: id
            })
        } else {
            this.setState({
                openServiceRequestModal: false,
                serviceID: id
            })
        }
    }

    downloadBase64File(e, base64Data){
        e.preventDefault()
        let linkSource = base64Data;
        let downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = 'PolicyDoc';
        downloadLink.click();
        cogoToast.info("Document downloading...")
        
    }

    gatherVaultDocs(e, docIDArray, policyID, policyType){
        e.preventDefault();
        console.log("Gathering Documents from Vault for: ");
        console.log(docIDArray)
        this.setState({
            docIDArray: docIDArray,
            openDocVaultModal: true,
            policyID: policyID,
            policyType: policyType
        })

    }

    openDocVaultModal = (e) => {
        e.preventDefault();
        if (this.state.openDocVaultModal === false){
            this.setState({
                openDocVaultModal: true
            }) 
        } else {
            this.setState({
                openDocVaultModal: false,
                docIDArray: [],
                policyID: ""
            })    
        }
    }


    render() {
        return (
            <div>
                <Modal  
                    isOpen={this.state.openServiceRequestModal}
                    onRequestClose={(e) => this.openServiceRequestModal(e)}
                    style={customStylesModal}
                >

                    {/* <p>Create Service Request</p> */}
                    <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'gray', color: 'black'}} onClick={(e) => this.openServiceRequestModal(e)}>X</button>
                                                
                    <CreateServiceRequest 
                        uid={this.state.serviceID}
                    />


                </Modal>

                <Modal
                    isOpen={this.state.openDocVaultModal}
                    onRequestClose={(e) => this.openDocVaultModal(e)}
                
                    style={docVaultModal}
                >
                    {/* <p>Document Vaults here</p> */}
                    <DocVault
                        docIDArray={this.state.docIDArray}
                        rootPolicyID={this.state.policyID}
                        rootPolicyType={this.state.policyType}
                        closeModal={(e) => this.openDocVaultModal(e)}
                    />

                </Modal>

                 <Modal
                    isOpen={this.state.openEditModal}
                    onRequestClose={(e) => this.closeEditModal(e)}
                    style={customStylesModal}
                 >  
                    <button style={{width: '10%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={(e) => this.closeEditModal(e)}>X</button>                            
                    {/* <p>Edit Row</p> */}
                    {/* <p>{this.state.editablePolicy._id}</p> */}

                    {this.state.editablePolicy.type === 'Property' ?
                        // <p>Property Form</p>
                        <PropertyPolicyUpdateForm
                            policyData={this.state.editablePolicy}
                            agents={this.props.agents}
                        />
                        
                    : null }


{                   this.state.editablePolicy.type === 'Auto' ?
                        // <p>Auto Form</p>
                        <AutoUpdatePolicyForm
                            policyData={this.state.editablePolicy}
                            agents={this.props.agents}
                        />
                    : null }


                    {this.state.editablePolicy.type === 'Umbrella' ?
                        <UmbrellaPolicyUpdateForm
                            policyData={this.state.editablePolicy}
                            agents={this.props.agents}
                        />
                    : null }
                

                    
                 </Modal>
                 <div className="card" style={{overflowX: 'auto'}}>
                    <button style={{width: '20%', marginLeft: '80%', textAlign: 'center', backgroundColor: 'white', color: 'black'}} onClick={() => this.props.closeComponent()}>X</button>
                                                
                                                {/* <div className="card-header">Service Request Controller</div>
                                                <div className="card-body"> */}
                    <h3 style={{textAlign: 'center'}}>Policy Database</h3>
                    <div className="row">
                        <div className="col-sm-2"><button style={{width: '100%', height: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.resetTable(e)}>Refresh</button></div>
                        <div className="col-sm-2"><button style={{width: '100%', height: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateAutoRow(e)}>New Auto</button></div>
                        <div className="col-sm-2"><button style={{width: '100%', height: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreatePropertyRow(e)}>New Property</button></div>
                        <div className="col-sm-2"><button style={{width: '100%', height: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.showCreateUmbrellaRow(e)}>New Umbrella</button></div>
                        <div className="col-sm-2"><button disabled style={{width: '100%', height: '100%', float: 'left', display: 'inline-block', backgroundColor: 'navy', color: 'white'}} onClick={(e) => this.sortTable(e, 'effective')}>Sort Effective</button></div>
                        <div className="col-sm-2">
                            <form>
                                <input style={{width: '70%'}} value={this.state.searchTerm} onChange={this.handleInputChange} type="text" name="searchTerm" />
                                <button style={{width: '30%', backgroundColor: 'green', color: 'white'}} type="submit" onClick={(e) => this.searchTable(e)}> Go </button>
                            </form>
                        </div>
                    </div>
                    
                    

                    <table
                        id="database"
                    >
                                                    <thead>
                                                        <tr>
                                                            <th style={{minWidth: '200px'}}>Client Name</th>
                                                            {/* <th>First Name</th> */}
                                                            <th style={{minWidth: '150px'}}>Effective</th>
                                                            <th>Edit</th>
                                                            <th>Service</th>
                                                            <th>Documents</th>
                                                            <th>Carrier</th>
                                                            <th>Policy #</th>
                                                            <th>Active?</th>               
                                                            <th>Assigned?</th>
                                                            <th>Premium</th>
                                                            <th>Type</th>               
                                                            <th>Split</th>

                                                            {/* <th>Column</th>                                                             */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                    {this.state.showCreateAutoRow ? 
                                                    
                                                    <CreateAutoRow 
                                                    
                                                        agents={this.props.agents}
                                                        sendRowToDatabase={(data) => this.addNewRowToPolicies(data)}
                                                        generateNewRow={(data) => this.readNewRowData(data)}

                                                    />
                                                    
                                                    : null}
                                                    
                                                    {this.state.showCreatePropertyRow ? 
                                                    
                                                    <CreatePropertyRow 
                                                    
                                                        agents={this.props.agents}
                                                        sendRowToDatabase={(data) => this.addNewRowToPolicies(data)}
                                                        generateNewRow={(data) => this.readNewRowData(data)}
                                                    />
                                                    
                                                    : null}


                                                    {this.state.showCreateUmbrellaRow ?
                                                    
                                                    <CreateUmbrellaRow 
                                                        
                                                        agents={this.props.agents}
                                                        sendRowToDatabase={(data) => this.addNewRowToPolicies(data)}
                                                        generateNewRow={(data) => this.readNewRowData(data)}
                                                    />
                                                
                                                    
                                                    : null }


                                                    
                                                                {this.state.policies.map(policy => (
                                                                    <tr key={policy._id}>
                                                                        <td><strong>{policy.clientLastName}, {policy.clientFirstName}</strong></td>
                                                                        {/* <td>{policy.clientFirstName}</td> */}

                                                                        <td>{policy.effectiveDate.split("T")[0]}</td>
                                                                        <td><button className="btn btn-secondary" onClick={(e) => this.openEditModal(policy)}>Edit</button></td>
                                                                        <td>
                                                                            <button className="btn-primary btn" onClick={(e) => this.openServiceRequestModal(e, policy._id)}>Request</button>
                                                                        </td>
                                                                        <td>  
                                                                        {policy.documents.length > 0 ? 
                                                                           
                                                                           <i onClick={(e) => this.gatherVaultDocs(e, policy.documents, policy._id, policy.type)} class="fa fa-folder-open fa-2x" aria-hidden="true"></i>
                                                                    

                                                                         : null}   
                                                                        </td>
                                                                        <td>{policy.carrier}</td>
                                                                        <td>{policy.policyNumber}</td>
                                                                        <td>{policy.policyActive ? 'True' : 'False'}</td>
                                                                        <td>{policy.clientID === -1 ? 'True' : 'False'}</td>
                                                                        <td>{policy.premium}</td>
                                                                        <td>{policy.type}</td>
                                                                        <td>{policy.mainAgentSplit < 100 ? 'Split' : 'Not split'}</td>
                                                                        
                                                                    </tr>
                                                                ))}


                                                                

                                                    </tbody>
                        </table>
                                                    {/* <ServiceRequestController
                                                        requests={this.state.serviceRequests}
                                                    />
                                                    <button style={{width: '33%', marginLeft: '33%', textAlign: 'center'}}onClick={() => this.showServiceRequestController()}>Close</button> */}
                                                {/* </div> */}
                    </div>
            </div >
        )

    }

}

export default PolicyDatabase;