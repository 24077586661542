import React, { Component } from "react";
import "./style.css";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';


// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
import QuoteItem from "../QuoteItem";


class QuoteController extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        totalPremiums: 0
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        setTimeout(() => { this.calculateTotals() }, 3000)
    }

    calculateTotals = () => {
    //     var autoPrems = 0;
    //     var homePrems = 0;
    //     var umbrellaPrems = 0;

    //     for (var i = 0; i < this.props.agentAutos.length; i++) {
    //         autoPrems += this.props.agentAutos[i].premium
    //     }

    //     for (var j = 0; j < this.props.agentProperties.length; j++) {
    //         homePrems += this.props.agentProperties[j].premium
    //     }

    //     for (var k = 0; k < this.props.agentUmbrellas.length; k++) {
    //         umbrellaPrems += this.props.agentUmbrellas[k].premium
    //     }

    //     setTimeout(() => {
    //         console.log("Auto premiums: " + autoPrems)
    //         console.log("Home premiums: " + homePrems)
    //         console.log("Umbrella premiums: " + umbrellaPrems)
    //     }, 1000)

    //     this.setState({
    //         totalPremiums: autoPrems + homePrems + umbrellaPrems
    //     })

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            DOB: this.state.DOB,
            streetAddress: this.state.streetAddress,
            cityAddress: this.state.cityAddress,
            zipAddress: this.state.zipAddress,
            stateAddress: this.state.stateAddress,
            email: this.state.email,
            quoteNotes: this.state.quoteNotes
        }

        console.log(quoteData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }





    render() {
        return (
            <div>
                <Card bg="light" style={{}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <Card.Header style={{ textAlign: 'center' }}>
                        <h2><strong>Quote Controller</strong></h2>
                    </Card.Header>
                    <Card.Body style={{ height: '900px', padding: '5%', backgroundImage: 'url(https://picsum.photos/800)',  backgroundSize: 'cover'}}>
                        <Table striped bordered hover size="sm" responsive>
                            <thead>
                                <tr style={{backgroundColor: 'white'}}>
                                    <th>Client Name</th>
                                    <th>Active</th>
                                    <th>Status</th>
                                    <th>Quotes</th>
                                    <th>Savings</th>
                                    <th>Referred By</th>
                                    <th>Last Updated</th>
                                    <th>Created On</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.quotes ?
                                    <>
                                        {this.props.quotes.map(quote => (
                                            <QuoteItem  
                                                key={quote._id}
                                                quote={quote}
                                                rerender={this.props.rerender}
                                                agents={this.props.agents}

                                            />
                                            // <tr>
                                            //     <td>

                                            //     </td>
                                            //     <td>
                                            //         {/* Client Name */}
                                            //         {quote.firstName + " " + quote.lastName}

                                            //     </td>
                                            //     <td>
                                            //         {/* Active? */}
                                            //         {quote.active ? "Active" : "Inactive"}
                                            //     </td>
                                            //     <td>
                                            //         {/* Status */}
                                            //         {quote.quoted ? 
                                            //             <> 
                                            //             { quote.presented ? 
                                            //                 <>
                                            //                     {quote.sold ? "Sold" : "Need to Sell"}
                                            //                 </>
                                            //             : "Need to Present " } 
                                            //             </> 
                                            //         : "Need to Quote" }
                                            //     </td>
                                            //     <td>
                                            //         {/* Quotes */}
                                            //         {quote.quoteAutoPremium ? <>Auto: {quote.quoteAutoPremium} </> : null}
                                            //         {quote.quoteHomePremium ? <>Home: {quote.quoteHomePremium} </> : null}
                                            //         {quote.quoteUmbrellaPremium ? <>Umbrella: {quote.quotedUmbrellaPremium}</> : null}
                                            //     </td>
                                            //     <td>
                                            //         {/* Savings */}
                                            //         {quote.savings ? <>{quote.savings}</> : null}
                                            //     </td>
                                            //     <td>
                                            //         {/* Referred By */}
                                            //         {quote.referrer ? <>Quote referred by: {quote.referrer}</>: null}
                                            //     </td>
                                            
                                            //     <td>
                                            //         {/* Last Updated */}
                                            //         <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                            //             {quote.updatedAt}
                                            //         </Moment>
                                            //     </td>
                                            //     <td>
                                            //         {/* Created On */}
                                            //         <Moment format="MM/DD/YYYY" add={{ hours: 8 }}>
                                            //             {quote.created_at}
                                            //         </Moment>
                                            //     </td>
                                            //     <td><button className="btn btn-info" onClick={() => showEdit(quote._id)}>Edit</button></td>
                                            // </tr>


                                        ))}

                                    </>
                                    : null}

                                
                               
                            </tbody>


                        </Table>


                    </Card.Body>



                </Card>
            </div >
        )

    }

}

export default QuoteController;