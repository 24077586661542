import React, { Component } from "react";
import "./style.css";
import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
// import QuoteModule from "../../components/QuoteModule";



class ProductCard extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: "",
        showCarriers: false,
        showQuotes: false
    }

    showQuoteTabs = () => {
        if (this.state.showQuotes === false){
            this.setState({
                showQuotes: true,
                showCarriers: false
            })
        }
    }

    showCarrierTabs = () => {
        if (this.state.showCarriers === false){
            this.setState({
                showQuotes: false,
                showCarriers: true
            })
        }
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }



    render() {
        return (
            <div>
                <div style={{height: '100%', overflow: 'hidden' }}>
                    <div className="row" style={{textAlign: 'center'}}>

                        <div className="col-5 offset-1"><button style={{backgroundImage: 'linear-gradient(147deg, white 90%, black 0%)', width: '100%', borderRadius: '5px'}} onClick={() => this.showQuoteTabs()}>Quotes</button></div>
           
                        <div className="col-5"><button style={{backgroundImage: 'linear-gradient(147deg, white 90%, black 0%)', width: '100%', borderRadius: '5px'}} onClick={() => this.showCarrierTabs()}>Carriers</button></div>
            
                    </div>
           
                    <ListGroup className="list-group-flush" style={{borderRadius: '10px'}}>
                        {this.state.showCarriers ?
                            <ListGroup.Item>
                            <h1 style={{textAlign: 'center'}}>Our Carriers</h1>
                            <div className="row">
                                <div className="col-md-4">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'  }} src="/images/farmers-logo.svg" />
                           
                                </div>
                                <br />
                                <div className="col-md-4">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'   }} src="/images/nationwide-logo.png" />
                               
                                </div>
                                <br />
                                <div className="col-md-4">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'   }} src="/images/logos/progressive-logo.jpeg" />
                           
                                </div>
                                <br />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'   }} src="/images/travelers-logo.png" />
                           
                                </div>
                                <br />
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'  }} src="/images/plymouth-logo.png" />
                              
                                </div>
                                <br />
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{height: '100%', width: '100%', padding: '10%'   }} src="/images/encompass-logo.png" />
                         
                                </div>     
                                <br />
                            </div>
                            <div className="row">    
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'   }} src="/images/carriers/pure/pure-logo.jpeg" />
                       
                                </div>
                                <br />
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'   }} src="/images/greatbay-logo.png" />
                    
                                </div>
                                <br />
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'   }} src="/images/stillwater-logo.png" />
                  
                                </div> 
                                <br />

                            </div>
                
                            <div className="row">
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%'  }} src="/images/clearcover-word-purple.svg" />
                                </div>
                                <div className="col">
                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', padding: '10%' }} src="/images/openly-logo.png" />                                  
                                </div>
                                <div className="col">
                                    <h2 style={{padding: '25%'}}>..and more!</h2>
                                </div>
                            </div>
                               
                            </ListGroup.Item>
                            : 
                            null
                           
                    
                        }
                        


                    </ListGroup>
                    <div className="card-body">
                        <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button>
                    </div>
                </div>
            </div>
        )

    }

}

export default ProductCard;