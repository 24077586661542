import React, { Component } from "react";
import "./style.css";
// import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class TextToQuoteCard extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: ""
    }

    componentDidMount = () => {
        console.log("Text Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }

    redirect = (url) => {
        console.log("redirecting to: " + url);
        window.open(url, "", "width=800, height=800, top=+200, left=+400");
        // var left = (screen.width/2)
        // var top = (screen.height/2)
        // 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left
    }



    render() {
        return (
            <div className="row">
                <div className="card col-md-6 offset-md-6 col-sm-8 offset-sm-2 col-xs-10 offset-xs-1" style={{margin: '10px', boxShadow: '0px 0px 1px 1px black', borderRadius: '10px', padding: '10px', color: 'black', backgroundColor: 'white' }}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div className="card-header" style={{ textAlign: 'center', color: 'black', backgroundColor: 'orange' }}>
                        <div className="row">
                            <div className="col-md-3">
                                <img alt="plymouth" style={{height: '100px', width: '200px'}} src="/images/plymouth-logo.png"></img>
                            </div>
                            <div className="col-md-9">
                                <h2 className="" style={{ color: 'black', marginTop: '25px' }}><strong>Quote in seconds</strong></h2>
                            </div>
                        </div>

                    </div>
                    {/* <div className="card-body" style={{backgroundColor: 'orange'}}>
                        <div className="card-title">
                            <h4 className="" style={{ color: 'black', textAlign: 'center' }}><strong>You can get a super fast quote for your Homeowners!</strong></h4>
                        </div>
                    </div> */}

                    <div className="card-body" style={{textAlign: 'center', padding: '25px', backgroundColor: 'darkorange'}}>
                        {/* <img alt="" id="headshot-img" style={{ height: '100%', width: '100%', backgroundColor: 'white' }} src="/images/plymouth-logo.png" /> */}
                        <h3><strong>Just text 'Rock' <br /> to 267-609-4721</strong></h3>
                        <h5>Follow the instructions to get a quote in seconds!</h5>

                        <br />
                        <p> If you do not want to text for a quote you can get an online quote by going here: </p>
                        <button class="box-shadow-button" style={{boxShadow: '0px 0px 2px 2px black', borderRadius: '10px', height: '50px', width: '50%', backgroundColor: 'black', color: 'white'}}
                                    // onClick={() => this.showCoverPath()} 
                                    onClick={() => this.redirect('https://nwexpress.com/fawncircle')}
                                    >
                                        {/* <a href="" target="_blank">Commercial Insurance</a> */}
                                        <div className="row">
                                            <div className="col-xl-12">
                                                {/* <img style={{height: '50px', width: '75px'}} src="/images/nationwide-logo.png"></img> */}
                                            </div>
                                            <div className="col-xl-12">
                                                <h5 style={{color: 'white'}}><strong>Express Quote</strong></h5>
                                                {/* <p style={{color: 'white'}}><strong>(Auto + Home) </strong></p> */}
                                            </div>
                                        </div>

                                    </button>

                        
                        {/* <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button> */}
                    </div>
                </div>
            </div>
        )

    }

}

export default TextToQuoteCard;