import React, { Component } from 'react';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import Button from 'react-bootstrap/Button';
import FawnNav from "./components/Nav"
import LandingPage from "./pages/LandingPage"
import AgentPage from "./pages/AgentPage"
import ClientPage from "./pages/ClientPage"
import AdminPage from "./pages/AdminPage"
import EnrollPage from './pages/EnrollPage'
import BaseballPage from './pages/BaseballPage'
import AuthPage from './pages/AuthPage'
import fire from './config/Fire'
import API from './utils/API';
// import { Modal } from 'react-bootstrap';
import Modal from 'react-modal'
import QuotePage from "./pages/QuotePage";
import ClaimPage from './pages/ClaimPage';

Modal.setAppElement("#root")

class App extends Component {

  constructor() {
    super()
    this.state = {
      user: "",
      agents: []
    }
  }

  componentDidMount() {
    // console.log("Component Mounted Home");
    console.log(this.props);
    this.authListener();
    this.gatherAgents();
    this.testAPI();
  }

  authListener() {
    fire.auth().onAuthStateChanged((user) => {
      // console.log(user);
      if (user) {
        this.setState({
          user: user
        })
      } else {
      }
    });
  }

  testAPI = () => {
    console.log("Running Test API")
    API.testAPI()
      .then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
  }

  gatherAgents = () => {
    API.getAgents()
      .then(res => {
        this.setState({
          agents: res.data
        })
      }).catch(err => {
        console.log(err)
      })
  }


  render() {
    return (
      <Router>
        <div className="main">

          <FawnNav />
          <Switch>

            <Route exact path="/">
              <LandingPage />
            </Route>

            <Route exact path="/baseball">
              <BaseballPage />
            </Route>

            <Route exact path="/login">
              <AuthPage />
            </Route>

            <Route exact path="/signup">
              <AuthPage />
            </Route>

            <Route exact path="/claim">
               <ClaimPage />
            </Route>

            <Route exact path="/enroll">
              <EnrollPage
                user={this.state.user}
                agents={this.state.agents}
              />
            </Route>

            <Route exact path="/quote/:id">
                <QuotePage
                />
            </Route>

            <Route exact path="/mindshift">
              <EnrollPage
                user={this.state.user}
                agents={this.state.agents}
                source='mindshift'
              />
            </Route>

            <Route exact path="/agent">
              <AgentPage
                user={this.state.user}
                agents={this.state.agents}
              />
            </Route>

            <Route exact path="/client">
              <ClientPage user={this.state.user}/>
            </Route>

            <Route exact path="/admin">
              <AdminPage
                user={this.state.user}
                agents={this.state.agents}
              />
            </Route>
            
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App;
