import React, { Component } from 'react';
// import ListGroup from 'react-bootstrap/ListGroup'
import './style.css'
// import API from '../../utils/API';
// import Modal from 'react-modal';
import cogoToast from 'cogo-toast';
import Table from 'react-bootstrap/Table'


// const customStylesModal = {
//     content: {
//         padding: '5px 5px',
//         backgroundImage: 'linear-gradient(to top, lightblue 0%, whitesmoke 100%)',
//         // height: '100%',
//         border: '0px',
//         height: '50%',
//         textAlign: 'center'
//     }
// }

// const customStylesModalTransparent = {
//     content: {
//         padding: '5px 5px',
//         background: 'transparent',
//         border: '0px',
//         height: '100%',
//         border: '0px'
//     }
// }

class UpcomingRenewals extends Component {
    state = {
        serviceReqType: "",
        serviceReqName: "",
        serviceReqEmail: "",
        serviceReqBody: "",
        showCallModal: false,
        showEmailModal: false
    }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };



    showCallModal = (e) =>{
        e.preventDefault()
        console.log("Showing Call Modal")
        if (this.state.showCallModal === false){
            this.setState({
                showCallModal: true,
                showEmailModal: false
            })
        } else {
            this.setState({
                showCallModal: false
            })
        }
    }

    showEmailModal = (e) => {
        e.preventDefault()
        console.log("Showing Email Modal")
        if (this.state.showEmailModal === false){
            this.setState({
                showCallModal: false,
                showEmailModal: true
            })
        } else {
            this.setState({
                showEmailModal: false
            })
        }
    }

    copyText = (event, data) => {
        event.preventDefault();

        var copyText = data;
        navigator.clipboard.writeText(copyText);
        console.log(navigator.clipboard);
        cogoToast.success("Copied: " + data)
    }

    render() {
        return (
            <div>
                <h3>Upcoming Renewals</h3>
                <div>
                    <Table striped bordered hover variant="dark">
                        <tbody style={{width: '100%'}}>
                            <tr style={{backgroundColor: 'white'}}>
                                <th style={{width: '25%'}}><strong>Client</strong></th>
                                <th style={{width: '25%'}}><strong>Date</strong></th>
                                <th style={{width: '25%'}}><strong>Premium</strong></th>
                                <th style={{width: '25%'}}><strong>Est. Commission</strong></th>
                            </tr>   
                            <tr style={{backgroundColor: 'white'}}>
                                <td>Evan Tester</td>
                                <td>07/30/2022</td>
                                <td>$3,400</td>
                                <td>$85 (2.5%)</td>
                            </tr>
                            <tr style={{backgroundColor: 'white'}}>
                                <td>Ellie Sats</td>
                                <td>08/10/2022</td>
                                <td>$2,210</td>
                                <td>$55.25</td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div >
        )
    }
}


export default UpcomingRenewals;
