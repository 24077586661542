import React, { Component } from "react";
import "./style.css";
import API from '../../utils/API'

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class QuickQuote extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        chosenAgent: ""

    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.dob,
            streetAddress: this.state.streetAddress,
            cityAddress: this.state.cityAddress,
            zipAddress: this.state.zipAddress,
            stateAddress: this.state.stateAddress,
            email: this.state.email,
            quoteNotes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.props.agentData._id,
            refAgentOneName: this.props.agentData.firstName + " " + this.props.agentData.lastName,
            refAgentTwo: this.state.refAgentTwo
        }

        console.log(quoteData)



        API.saveAgentQuoteRequest(this.props.agentData._id, {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.dob,
            quickAddress: this.state.quickAddress,
            notes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.props.agentData._id,
            refAgentOneName: this.props.agentData.firstName + " " + this.props.agentData.lastName,
            refAgentTwo: this.state.refAgentTwo
       }).then(res => {
            // cogoToast.info("Saved Note!")
            // this.props.rerender();
            alert("Quote Saved!")
            this.props.rerender()
        }
        ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }





    render() {
        return (
            <div>
                <div className="card" style={{}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    {/* <div className="card-header" style={{ textAlign: 'center' }}> */}
                        {/* Quick Quote */}
                    {/* </div> */}

                    <form style={{padding: '10px'}}>
                        <div className="row">
                            <div className="col">
                                <label >First Name</label>
                                <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="First Name" />
                            </div>
                            <div className="col">
                                <label >Last Name</label>
                                <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder='Last Name' />
                            </div>
                            <div className="col">
                                <label>Date of Birth</label>
                                <input type="date" className="form-control" value={this.state.dob} onChange={this.handleInputChange} name="dob" />
                            </div>
                        </div>
                        <hr />
                        <div className="form-row row">
                            <div className="col-md-2"></div>
                            <div className="col-md-3">
                            <label><strong>Select Specialist</strong></label>
                                {this.props.agents ? <div>
                                    <select id="protegeDropMenu" value={this.state.chosenAgent} onChange={this.handleInputChange} name="chosenAgent">
                                        <option value="">--Select an Agent--</option>
                                        {this.props.agents.map(agent => (
                                            <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                        ))}
                                    </select>

                                </div> : <div>No agents yet</div>}
                            </div>
                            <div className="col-md-2"></div>
                            <div className="col-md-3">
                            <label><strong>Partnering Agent</strong></label>
                                {this.props.agents ? <div>
                                    <select id="protegeDropMenu" value={this.state.chosenRefAgentTwo} onChange={this.handleInputChange} name="refAgentTwo">
                                        <option value="">--Select an Agent--</option>
                                        {this.props.agents.map(agent => (
                                            <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option> 
                                        ))}
                                    </select>
                                 </div>   
                                 : <div>No agents yet</div>}
                            </div>
                            <div className="col-md-2"></div>
                        </div>

                        <div className="form-row">
                            <div className="col-lg-12">
                                <label>Address</label>
                                <input type="text" className="form-control" value={this.state.quickAddress} onChange={this.handleInputChange} name="quickAddress" id="inputAddress" placeholder="1234 Main St" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-12">
                                <label>Additional Notes</label>
                                <textarea value={this.state.quoteNotes} onChange={this.handleInputChange} name="quoteNotes" className="form-control" rows="2" cols="60" />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-12">
                                <label>Upload Files</label>
                                <input type="file" id="dec-1" name="declarations" accept="image/png, image/jpeg" />
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button className="btn btn-sm btn-success" onClick={this.handleQuoteSubmit}>Submit for Quote</button>
                        </div>
                    </form>

                </div>
            </div>
        )

    }

}

export default QuickQuote;