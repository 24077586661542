import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'


class EditPolicy extends Component {
    render() {
        return (

            < div >

                <div class="card-body">
                    <p>Edit Policy Stuff</p>



                </div>


            </div >



        )
    }
}


export default EditPolicy;
