import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import Table from 'react-bootstrap/Table';
import API from "../../utils/API";


class CreateCarrier extends Component {
    state = {
        carrierName: "",
        carrierLogo: "",
        carrierServiceNumber: "",
        carrierClaimsNumber: "",
        customerPortalLink: "",
        carrierData: "",
        carrierSelected: "",
        carrierSelectedID: "",
        selectedCarrierName: "",
        selectedCarrierLogo: "",
        selectedCarrierServiceNumber: "",
        selectedCarrierClaimsNumber: "",
        selectedCarrierCustomerPortalLink: ""
    }

    componentDidMount = () =>{
        console.log("Create Carrier Modal Component Launched")

        API.getCarriers()
            .then((res) => {
                console.log("Carreirs Retrieved from API")
                console.log(res);
                this.setState({
                    carrierData: res.data
                })
                console.log(this.state);
            })
            
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleCarrierSubmit = event => {
        event.preventDefault()
        console.log("Submitting Carrier ....")

        var carrierData = {
            name: this.state.carrierName,
            logo: this.state.carrierLogo,
            servicePhone: this.state.carrierServiceNumber,
            claimsPhone: this.state.carrierClaimsNumber,
            serviceURL: this.state.customerPortalLink
        }

        console.log(carrierData)

        API.saveCarrier(carrierData)
            .then(res => {
                console.log("Saved Carrier")
                alert("Carrier Saved!")
                console.log(res.data)
            })
            .catch(err => console.log(err))

    }

    handleCarrierEditSubmit = event => {
        event.preventDefault()
        console.log("Submitting Carrier Edits....")

        let carrierID = this.state.carrierSelectedID;

        var carrierEditData = {
            name: this.state.selectedCarrierName,
            logo: this.state.selectedCarrierLogo,
            servicePhone: this.state.selectedCarrierServiceNumber,
            claimsPhone: this.state.selectedCarrierClaimsNumber,
            serviceURL: this.state.selectedCarrierCustomerPortalLink
        }

        console.log(carrierEditData)

        API.updateCarrier(carrierID, carrierEditData)
            .then(res => {
                console.log("Saved Edits to Carrier")
                alert("Carrier Edits Saved!")
                console.log(res.data)

                API.getCarriers()
                    .then((res) => {
                        console.log("Carreirs Retrieved from API")
                        console.log(res);
                        this.setState({
                            carrierData: res.data
                        })
                        console.log(this.state);
                    })
            })
            .catch(err => console.log(err))

    }

    populateCarrierDataInForm = (event) => {
        event.preventDefault();
        console.log("Populating Carrier Edit Form");
        console.log(this.state);

        for (var i = 0; i < this.state.carrierData.length; i++){

            if (this.state.carrierSelected === this.state.carrierData[i]._id){
                console.log("Found Client Data to Edit:");
                console.log(this.state.carrierData[i]);
                this.setState({
                    carrierSelectedID: this.state.carrierData[i]._id,
                    selectedCarrierName: this.state.carrierData[i].name,
                    selectedCarrierServiceNumber: this.state.carrierData[i].servicePhone,
                    selectedCarrierClaimsNumber: this.state.carrierData[i].claimsPhone,
                    selectedCarrierCustomerPortalLink: this.state.carrierData[i].serviceURL,
                    selectedCarrierLogo: this.state.carrierData[i].logo
                })
            }

        }

    }

    handleSelectChange = () => {
        console.log("Edit Carrier selection changed");
        console.log(this.state.carrierSelected);
        console.log(this.state);
    }

    render() {
        return (

            < div >

                <div className="card" style={{borderRadius: '10px', color: 'black', overflow: 'auto', padding: '0'}}>
                    <div className="card-header" style={{textAlign: 'center'}}>
                        <h3 style={{ textAlign: 'center' }}>New Carrier</h3>
                        <p>Create Carrier below
                        </p>
                    </div>
                    <div className="card-body" style={{padding: '0'}}>
                        <form>
                            <Table bordered hover>
                          
                                <tbody>
                                    <tr>
                                    <td><strong>Question</strong></td>
                                    <td><strong>Input</strong></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Carrier Name</td>
                                        <td><input type="text" value={this.state.carrierName} onChange={this.handleInputChange} name="carrierName"  />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Carrier Logo</td>
                                        <td> <input type="text" value={this.state.carrierLogo} onChange={this.handleInputChange} name="carrierLogo" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Service Telephone #</td>
                                        <td><input type="text" value={this.state.carrierServiceNumber} onChange={this.handleInputChange} name="carrierServiceNumber" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Claims Telephone #</td>
                                        <td><input type="text" value={this.state.carrierClaimsNumber} onChange={this.handleInputChange} name="carrierClaimsNumber" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Customer Portal Link</td>
                                        <td><input type="text" value={this.state.customerPortalLink} onChange={this.handleInputChange} name="customerPortalLink" /> </td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                            <div style={{ textAlign: 'center' }}>
                                {/* <button className="btn-dark btn" onClick={this.handleReferralSubmit}>Refer!</button> */}
                                <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}}  
                                                        onClick={(e) => this.handleCarrierSubmit(e)}>Submit
                                </button>
                            </div>
                            
                        </form>
                    </div>
                </div>
                <br />

                <div className="card" style={{borderRadius: '10px', color: 'black', overflow: 'auto', padding: '0'}}>
                    <div className="card-header" style={{textAlign: 'center'}}>
                        <h3 style={{ textAlign: 'center' }}>Edit Carrier</h3>
                        <p>Select a Carrier to Edit Below
                        </p>
                    </div>
                    <div className="card-body" style={{padding: '0'}}>
                        <form>
                            <Table bordered hover>
                          
                                <tbody>
                                    <tr>
                                    <td><strong>Select Carrier</strong></td>
                                    <td>
                                        
                                        {/* Input showing Carriers */}

                                        <form>
                                            {/* <input list="carriers" name="carrierSelected" onChange={this.handleInputChange} /> */}

                                            {this.state.carrierData ? 
                                            
                                                <select id="carrierDropMenu" value={this.state.carrierSelected} onChange={this.handleInputChange} name="carrierSelected">
                                                    {/* <option value="">--Select a Carrier--</option> */}
                                                    {this.state.carrierData.map(carrier => (
                                                        <option key={carrier._id} value={carrier._id}>{carrier.name}</option>
                                                    ))}
                                                </select>
                                            : 
                                            <div>No Carriers yet</div>}

                                            <button onClick={this.populateCarrierDataInForm}>Edit</button>
                                        </form>

              
                                        
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Carrier Name</td>
                                        <td><input type="text" value={this.state.selectedCarrierName} onChange={this.handleInputChange} name="selectedCarrierName"  />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Carrier Logo</td>
                                        <td> <input type="text" value={this.state.selectedCarrierLogo} onChange={this.handleInputChange} name="selectedCarrierLogo" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Service Telephone #</td>
                                        <td><input type="text" value={this.state.selectedCarrierServiceNumber} onChange={this.handleInputChange} name="selectedCarrierServiceNumber" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Claims Telephone #</td>
                                        <td><input type="text" value={this.state.selectedCarrierClaimsNumber} onChange={this.handleInputChange} name="selectedCarrierClaimsNumber" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Customer Portal Link</td>
                                        <td><input type="text" value={this.state.selectedCarrierCustomerPortalLink} onChange={this.handleInputChange} name="selectedCarrierCustomerPortalLink" /> </td>
                                    </tr>
                                    
                                </tbody>
                            </Table>
                            <div style={{ textAlign: 'center' }}>
                                {/* <button className="btn-dark btn" onClick={this.handleReferralSubmit}>Refer!</button> */}
                                <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}}  
                                                        onClick={(e) => this.handleCarrierEditSubmit(e)}>Save Edit
                                </button>
                            </div>
                            
                        </form>
                    </div>
                </div>


            </div >



        )
    }
}


export default CreateCarrier;
