import firebase from 'firebase/app';
// import firebase from 'firebase/compat/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
// console

// console.log(process.env);

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
}

// console.log(config);
const fire = firebase.initializeApp(config);
const storage = firebase.storage();

// firebase.auth().onAuthStateChanged((user) => (this.currentUser = user));
// console.log(storage);
// console.log(fire);

// service firebase.storage {
//     match /b/{bucket}/o {
//       match /{allPaths=**} {
//         allow read, write: if request.auth != null;
//       }
//     }
//   }


export  {
    storage,
    fire as default
}