import React, { Component } from "react";
import "./style.css";
import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import CreateBaseballPlayer from '../../components/CreateBaseballPlayer'
import './style.css'



class Baseball extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: "",
        baseRunnerCode: '0',
        showGame: false,
        showSignup: true,
        showLeaderboard: false
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }

    showGameCard = () => {
        if (this.state.showGame === false){
            this.setState({
                showGame: true,
                showSignup: false,
                showLeaderboard: false
            })
        }
    }

    showSignupCard = () => {
        if(this.state.showSignup === false){
            this.setState({
                showGame: false,
                showSignup: true,
                showLeaderboard: false
            })
        }
    }

    showLeaderCard = () => {
        if (this.state.showLeaderboard === false){
            this.setState({
                showGame: false,
                showSignup: false,
                showLeaderboard: true
            })
        }
    }



    render() {
        return (
            <div>
                <div className="" style={{background: 'transparent', padding: '10px', fontFamily: 'Brush Script MT', fontSize: '48px'  }}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div className="" style={{ height: '50px', textAlign: 'center' }}>
                        {/* <p className="" style={{ color: 'black', fontFamily: 'Brush Script MT' }}>Baseball</p> */}
                    </div>
                    {/* <div className="card-body">
                        <div className="card-title">
                            <h4 className="" style={{ color: 'black' }}>Here's a little bit about how we got started and what our goals are!</h4>
                        </div>
                    </div> */}
                    <ListGroup className="list-group-flush" style={{boxShadow: '0px 0px 4px 4px black', borderRadius: '10px', backgroundColor: 'white'}}>

                        <ListGroup.Item>
                            <p className="baseball" style={{ textAlign: 'center', color: 'black', fontFamily: 'Brush Script MT' }}>Baseball</p>                           
                        </ListGroup.Item>

                        <ListGroup.Item>
                        {/* Toggle Buttons */}
                        <div className="row" style={{textAlign: 'center', padding: '10px'}}>
                            <div className="col"><button style={{color: 'white', fontSize: '30px', boxShadow: '0px 0px 4px 4px black', backgroundImage: 'linear-gradient(147deg, #640e27 90%, white 0%)', width: '100%', padding: '5px 20px 5px 20px', borderRadius: '5px'}} onClick={() => this.showSignupCard()}>Join</button></div>
                            <div className="col"><button style={{color: 'white', fontSize: '30px', boxShadow: '0px 0px 4px 4px black', backgroundImage: 'linear-gradient(147deg, #640e27 90%, white 0%)', width: '100%', padding: '5px 20px 5px 20px', borderRadius: '5px'}} onClick={() => this.showGameCard()}>Play</button></div>
                            <div className="col"><button style={{color: 'white', fontSize: '30px', boxShadow: '0px 0px 4px 4px black', backgroundImage: 'linear-gradient(147deg, #640e27 90%, white 0%)', width: '100%', padding: '5px 20px 5px 20px', borderRadius: '5px'}} onClick={() => this.showLeaderCard()}>Leader</button></div>
                        </div>
                        </ListGroup.Item>

                        {this.state.showSignup ? 
                            <ListGroup.Item>
                                <div style={{height: '100%', width: '100%', backgroundColor: '#640e27', padding: '25px', fontFamily: 'Arial, Helvetica, sans-serif', boxShadow: '0px 0px 4px 4px black', borderRadius: '10px'}}>
                                    <CreateBaseballPlayer />

                                </div>
                            </ListGroup.Item>

                        : null}

                        {this.state.showLeaderboard ? 
                            <ListGroup.Item>
                                <div style={{height: '100%', width: '100%', backgroundColor: '#640e27', padding: '25px', fontFamily: 'Arial, Helvetica, sans-serif', boxShadow: '0px 0px 4px 4px black', borderRadius: '10px'}}>
                                    <p className="baseball">Leaderboard</p>
                                </div>
                            </ListGroup.Item>
                        : null}

                        {this.state.showGame ? 
                            <ListGroup.Item>

                            {/* Baseball */}
                            <div style={{height: '100%', width: '100%', backgroundColor: '#640e27', padding: '25px', fontFamily: 'Brush Script MT', boxShadow: '0px 0px 4px 4px black', borderRadius: '10px' }}>
                                
                                {/* Scoreboard */}
                                <div style={{height: '300px', width: '100%', backgroundColor: 'transparent', padding: '10px'}}>
                                <div style={{height: '50px', width: '1%', backgroundColor: 'transparent', float: 'left', padding: '0px'}}></div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>1</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>2</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>3</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>4</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%',  float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>5</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>6</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%',  float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>7</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>8</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>9</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}>|</div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center',fontSize: '24px', color: 'white', fontWeight: 'bolder'}}>|</div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>H</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>R</u></div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white', fontWeight: 'bolder'}}><u>E</u></div>
                                    </div>

                                    {/* <br /> */}
                                    <div style={{height: '50px', width: '1%', backgroundColor: 'transparent', float: 'left', padding: '0px'}}></div>
                                    <div style={{height: '50px', width: '1%', backgroundColor: 'transparent', float: 'left', padding: '0px'}}></div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        {/* First Inning */}
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Second Inning */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Third Inning */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Fourth Inning */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Fifth Inning */}
                                    <div style={{height: '50px', width: '7%',  float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Sixth Inning */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Seventh Inning */}
                                    <div style={{height: '50px', width: '7%',  float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Eigth Inning */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Ninth Inning */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>|</div>                                    </div>
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center',fontSize: '24px', color: 'white'}}>|</div>                                    </div>
                                        {/* Runs Scored */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Hits Scored */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                        {/* Errors Scored */}
                                    <div style={{height: '50px', width: '7%', float: 'left', padding: '0px', border: '1px solid black'}}>
                                        <div style={{height: '100%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '24px', color: 'white'}}>
                                            0
                                        </div>
                                    </div>
                                    {/* <br /> */}
                                    <div style={{height: '150px', width: '5%', backgroundColor: 'transparent', float: 'left', padding: '00px'}}>
                                    </div>
                                    <div style={{height: '150px', width: '30%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '36px', color: 'white'}}><u>Hits</u></div>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '28px', color: 'white'}}>0</div>
                                    </div>
                                    <div style={{height: '150px', width: '30%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '36px', color: 'white'}}><u>Runs</u></div>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '28px', color: 'white'}}>0</div>
                                    </div>
                                    <div style={{height: '150px', width: '30%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '36px', color: 'white'}}><u>Bank</u></div>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '28px', color: 'white'}}>$0</div>
                                    </div>
                                </div>
                                {/* Divider */}
                                <div style={{height: '20px', width: '100%', backgroundColor: 'transparent', padding: '10px'}}></div>
                                {/* Game Screen */}
                                <div style={{height: '100%', width: '100%', backgroundColor: 'transparent', padding: '0px', textAlign: 'center', fontFamily: 'Brush Script MT', color: 'white'}}>
                                    <p className="baseball" style={{fontFamily: 'Arial, Helvetica, sans-serif'}}>Player:</p>
                                    <div className="row">
                                        <div className="col">
                                        {this.state.baseRunnerCode === '0' ? 
                                        <span>
                                            <p className="baseball" style={{fontFamily: 'Brush Script MT', fontSize: '48px', fontColor: 'black'}}>No Runners on Base yet!</p>
                                            <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-0.png" />
                                            </div>                                    
                                        </span>
                                        : null}
                                        {this.state.baseRunnerCode === '112030' ? 
                                        <span>
                                            <p className="baseball" style={{fontFamily: 'Brush Script MT'}}> "Man on First Base!"</p>
                                            <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-11-20-30.png" />
                                            </div>                                    
                                        </span>
                                        : null}
                                        {this.state.baseRunnerCode === '112130' ? 
                                        <span>
                                            <p className="baseball" style={{fontFamily: 'Brush Script MT'}}> "Runners on First and Second!"</p>
                                            <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-11-21-30.png" />
                                            </div>                                    
                                        </span>
                                        : null}
                                        {this.state.baseRunnerCode === '112131' ? 
                                        <span>
                                            <p className="baseball" style={{fontFamily: 'Brush Script MT'}}> "Bases are Loaded!!"</p>
                                            <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-11-21-31.png" />
                                            </div>                                    
                                        </span>
                                        : null}
                                        {this.state.baseRunnerCode === '102131' ? 
                                            <span>
                                                <p className="baseball" style={{fontFamily: 'Brush Script MT'}}> "Runners on Second and Third!"</p>
                                                <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-10-21-31.png" />
                                                </div>                                    
                                        </span>
                                        : null}
                                        {this.state.baseRunnerCode === '102130' ? 
                                            <span>
                                                <p style={{fontFamily: 'Brush Script MT'}}> "Man on Second Base!"</p>
                                                <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                    <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-10-21-30.png" />
                                                </div>                                    
                                            </span>
                                        : null}
                                        
                                        {this.state.baseRunnerCode === '102031' ? 
                                        <span>
                                            <p className="baseball" style={{fontFamily: 'Brush Script MT'}}> "Man on Third Base!"</p>
                                            <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-10-20-31.png" />
                                            </div>                                    
                                        </span>
                                        : null}

                                        {this.state.baseRunnerCode === '112031' ? 
                                        <span>
                                            <p className="baseball" style={{fontFamily: 'Brush Script MT'}}> "Runners on First and Third!"</p>
                                            <div style={{height: '75%', width: '75%', marginLeft: '12.5%', float: 'left', padding: '0px', border: '3px solid black'}}>
                                                <img alt="" id="headshot-img" style={{ height: '100%', width: '100%' }} src="/images/sandlot-11-20-31.png" />
                                            </div>                                    
                                        </span>
                                        : null}
                                        </div>

                                        {/* <div className="col-lg-3"></div> */}
                                    </div>
                                </div>

                                {/* Divider 2 */}
                                <div style={{height: '40px', width: '100%', backgroundColor: 'transparent', padding: '10px'}}></div>

                                {/* Batter's Box */}
                                <div style={{height: '150px', width: '100%', backgroundColor: 'transparent', padding: '10px'}}>
                                    <div style={{height: '120px', width: '50%', marginLeft: '25%', float: 'left', padding: '0px', border: '3px solid brown'}}>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '36px', color: 'white'}}><u>Swings: </u> 4</div>
                                        <div style={{height: '50%', width: '100%', backgroundColor: '#485464', textAlign: 'center', fontSize: '28px', color: 'white'}}><button class="btn btn-dark btn" style={{fontSize: '28px'}}>Swing!</button></div>
                                </div>
                                </div>
                        
                            </div>

                            </ListGroup.Item>                        
                        : null}
                        <br />
                        


                    </ListGroup>
                    {/* <div className="card-body">
                        <button className="btn btn-light" onClick={this.props.return} style={{float: 'right' }}>Return</button>
                    </div> */}
                </div>
            </div>
        )

    }

}

export default Baseball;