import React, { Component } from 'react';
import fire from '../../config/Fire';
import API from "../../utils/API";
import Modal from "react-modal";
import cogoToast from 'cogo-toast';


const customStylesModal = {
    content: {
        padding: '5px 5px',
        backgroundImage: 'linear-gradient(to top, lightblue 0%, whitesmoke 100%)',
        // height: '100%',
        border: '0px',
        height: '100%',
        textAlign: 'center'
    }
}

class LoginCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            userData: ""
        }
    }

    login = e => {
        e.preventDefault();
        fire
            .auth()
            .signInWithEmailAndPassword(this.state.email, this.state.password).then((res) => {
            // cogoToast.success("Successful login with " + res);
            console.log(res.user.uid)
            console.log(res);
            this.checkCredentials(res.user.uid);
            document.cookie = "fawnuid=" + res.user.uid;
            // let cookieArr = document.cookie.split(";").map(cookie => cookie.split("="));
            // console.log(cookieArr);
            // // Turns a multi dimensional array into a json object
            // let cookieObj = cookieArr
            //                     .reduce((accumulator, [key, value]) => ({
            //                         ...accumulator, [key.trim()]: decodeURIComponent(value)
            //                     }),
            //                     {});

            // console.log(cookieObj);
            // console.log(cookieObj.fawnuid);
                

        }).catch((error) => {
            // alert("Problem with logging in. Error: " + error.message);
            cogoToast.error("Error: " + error.message);
            console.log(error)
        })
    }

    forgotPassword = e => {
        e.preventDefault();
        console.log("Forgot Password...")
        console.log("no code here yet")
        this.setState({
            forgotPassword: true
        })
    }

    createAccount = e => {
        e.preventDefault();
        console.log("Creating Account...")
        console.log("no code here yet")
    }

    checkCredentials = (uid) => {

        console.log(uid + " has logged in")

        let client = false;
        let agent = false;
        let admin = false;
        console.log("Looking for Client Login first")
        API.findClientLogin(uid)
            .then(res => {
                console.log("Found client login")
                console.log(res)
                if (res.data.length > 0) {
                    console.log("This is a client login")
                    client = true
                    document.location.href = '/client'
                } else {
                    console.log("Did not find a client login")
                    console.log("This is not a client login")
                    console.log("Looking for an agent login")
                    API.findAgentLogin(uid)
                        .then(res => {
                            if (res.data.length > 0) {
                                console.log("This is an agent login")
                                agent = true
                                document.location.href = '/agent'
                            } else {
                                console.log("This is not an agent login")
                                API.findAdminLogin(uid)
                                    .then(res => {
                                        console.log("Found an admin login")
                                        console.log(res);
                                        if (res.data.length > 0) {
                                            console.log("This is an admin login")
                                            admin = true
                                            setTimeout(() => {
                                                document.location.href = '/admin'
                                            }, 5000)

                                            // Assign UID to session or cookies
                                        } else {
                                            console.log("This is not an admin login");
                                        }
                                    }).catch(err =>{
                                        console.log(err)
                                        console.log("This is not a proper login")
                                    })
                            }
                        }).catch(err =>
                            console.log("Type of user not found"))
                }
            }).catch(err =>
                console.log("This is not a proper login"))

    };


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    clientLogin = (e) => {
        e.preventDefault()

        console.log("Client logging in using")

        var loginInfo = {
            username: this.state.username,
            password: this.state.password
        }
        console.log(loginInfo)

    }

    agentLogin = (e) => {
        e.preventDefault()
        console.log("Agent logging in")
        var loginInfo = {
            username: this.state.username,
            password: this.state.password
        }
        console.log(loginInfo)
    }

    adminLogin = (e) => {
        e.preventDefault()
        console.log("Admin logging in")
        var loginInfo = {
            username: this.state.username,
            password: this.state.password
        }
        console.log(loginInfo)
    }

    closeForgotPassword = (event) => {
        event.preventDefault();
        this.setState({
            forgotPassword: false
        })
    }

    sendResetPasswordEmail = (event, email) => {
        event.preventDefault()
        console.log("Sending Password Reset Email for Email: ");
        console.log(email);
        fire.auth().sendPasswordResetEmail(email)
            .then(() => {
                cogoToast.success("Password reset email sent!")
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
            })
    }

    exitModal = (e) => {
        e.preventDefault();
        this.setState({
            forgotPassword: false
        })
    }


    render() {
        return (
            <div className="opacity-60" style={{backgroundColor: 'rgba(0,0,0,0.75)', marginLeft: 'auto', marginRight: 'auto', width: '100%', height: '400px', color: 'black', borderRadius: '5px' }}>
            
                <Modal
                    isOpen={this.state.forgotPassword}
                    onRequestClose={(e) => this.closeForgotPassword(e)}
                    style={customStylesModal}
                >
                    <div style={{height: '500px', width: '100%', textAlign: 'center'}}>
                        
                        <form style={{marginTop: '200px'}}>
                        <h3>Password Reset</h3>
                            <label className="form-group" style={{color: 'black'}}><strong>Email your email to reset your password</strong></label>
                            <br />
                            <br />
                            <input type="text" className="form-group" value={this.state.email} name="email" onChange={this.handleInputChange} />
                            <br />
                            <br />
                            <button style={{textAlign: 'center', margin: '10px', width: '150px'}} onClick={(e) => {this.sendResetPasswordEmail(e, this.state.email)}} className="btn btn btn-info"><strong>Reset Password</strong></button>
                            <br />
                            <button style={{textAlign: 'center', margin: '10px', width: '150px'}} onClick={(e) => {this.exitModal(e)}} className="btn btn btn-warning"><strong>Exit</strong></button>
                        </form>    
                    
                    </div>
     
                </Modal>

                <div>
                    <div >
                        {/* <div className="col-lg-3 col-12"></div> */}
                        <div style={{textAlign: 'center'}}>
                            <form style={{padding: '5%'}}>
                                <label className="form-group" style={{color: 'white'}}><strong>Email</strong></label><br />
                                <input  type="text" className="form-group" value={this.state.email} name="email" onChange={this.handleInputChange} />
                                <br />
                                <label style={{color: 'white'}}><strong>Password</strong></label>
                                <br />
                                <input  type="password" value={this.state.password} name="password" onChange={this.handleInputChange} />
                            </form>
                            <br />
                            <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.login} className="btn btn-sm btn-light"><strong>Login</strong></button>
                            <br />
                            <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.forgotPassword} className="btn btn-sm btn-light"><strong>Forgot Password</strong></button>
                            <br />
                            <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={() => this.props.switchToSignup()} className="btn btn-sm btn-light"><strong>Create Account</strong></button>
                        </div>
                        {/* <div className="col-lg-3 col-12"></div> */}
                    </div>
                </div>
            </div>
        )
    }



}

export default LoginCard;