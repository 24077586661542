import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
// import CreateReferringAgent from '../../components/CreateReferringAgent';
// import ResponsiveParralaxCarousel from '../../components/ResponsiveParralaxCarousel'
// import ResponsiveParralaxLogin from '../../components/ResponsiveParralaxLogin'



class ClaimPage extends Component {

    state = {
        showForm: true,
        chosenCarrier: "",
        carrierData: []
    }

    //   handleSubmit(e) {
    //     e.preventDefault();
    //     console.log('You clicked submit.');
    //   }

    alternatingColor = ['white', 'white'];
    alternatingText = ['black', 'black'];

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    // handleInputChange = event => {
    //     // const { name, value } = event.target;
    //     const target = event.target;
    //     const name = target.name;
    //     const value = target.type === 'checkbox' ? target.checked : target.value;
    //     this.setState({
    //         [name]: value
    //     });
    // };

    componentDidMount = () => {
        console.log("Claims Page");

        API.getCarriers()
        .then((res) => {
            console.log("Carreirs Retrieved from API")
            console.log(res);
            this.setState({
                carrierData: res.data
            })
            console.log(this.state);
        })

    }


  





  handleClick = () => {
    //   console.log("Button Clicked")
    console.log("Carrier Selected:")
    console.log(this.state.chosenCarrier);
  }



    render() {
        return (
            <div className="container-fluid" style={{height: '1500px'}}>
         
                {/* <CreateReferringAgent
                 source={this.props.source}
                 /> */}
                 {/* <select>
                    <option value="farmers">Farmers</option>
                    <option value="travelers">Travelers</option>
                    <option value="encompass">Encompass</option>
                 </select> */}
                 <div className="row">
                    <div className="col">
                        <div className="card-header">Claim Support</div>
                        <div className="card-body">
                            <h3>We're so sorry you've had a claim. We'll help you get to the right place.</h3>
                            <h4>Please select your carrier from the list below</h4>
                            {/* </option> */}


{/* Dynamic dropdown, edit with proper parameters for this state carriers from get route */}
                            <select id="carrierDropMenu" value={this.state.chosenCarrier} onChange={this.handleInputChange} name="chosenCarrier">
                                        <option value="">--Select a Carrier--</option>
                                        {this.state.carrierData.map(carrier => (
                                            <option key={carrier._id} value={carrier.name}>{carrier.name}</option>
                                        ))}
                                        <option value="other">Carrier not shown</option>
                            </select>
                            <button onClick={() => this.handleClick()}>Choose Carrier</button>


                            {/* Write Code below, if carrier == other put my contact info */}

                            {/* Otherwise, show pertinent carrier data (logo, url, phone, etc.) */}

                        </div>
                    </div>
                 </div>
            </div >

        )

    }

}

export default ClaimPage;