import React, { Component } from "react";
import "./style.css";
import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class AboutCard extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)



        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }



    render() {
        return (
            <div>
                <div className="row" style={{background: 'transparent', padding: '10px' }}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <div className="card" style={{ textAlign: 'center' }}>
                        <div className="card-header">
                            <h2 className="" style={{ color: 'black' }}>About Us</h2>
                        </div>

                        <div className="card-body">

                        <ListGroup className="offset-lg-3 col-lg-6 list-group-flush">

                            <ListGroup.Item>
                                <h6 style={{textIndent: '50px'}}>Fawn Circle is a Property and Casualty Insurance brokerage based out of Newtown Square, PA. We offer insurance to everyone! We have tailored our offerings to provide solutions to people throughout all walks of life. </h6>
                                <h6 style={{textIndent: '50px'}}>We officially opened our doors in 2016. Fawn Circle, LLC is as an independent brokerage for primarily Property and Casualty insurance.</h6>
                                <h6 style={{textIndent: '50px'}}>We work with both consumers and professionals. Our mission is to provide fair and honest feedback on everyone's policies that come to us for assistance. We're happy to earn business through savings and/or better coverages, but we understand that by being honest and upfront we can save your time, our time and earn the valued trust of our community. </h6>
                                <h6 style={{textIndent: '50px'}}>Given the nature of how we started our business, we have a culture of valuing every client immensely. We work to give 24-hour turnarounds in terms of service requests and we aim to use creative solutions to go the extra mile and help fill our client's needs.</h6>
                                <h6 style={{float: 'right', textIndent: ''}}>Sincerely yours,
                                <br /> <strong>Evan J. Cleary</strong>
                                <br /> Founder / CEO</h6>
                            
                            </ListGroup.Item>
                            <button className="btn btn-dark" onClick={this.props.return} style={{float: 'right' }}>Return</button>


                        </ListGroup>
                        </div>
                    </div>
            
                </div>
            </div>
        )

    }

}

export default AboutCard;