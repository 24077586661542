import React, { Component } from "react";
import "./style.css";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import MasterControlProperty from '../MasterControlProperty';
import MasterControlAuto from '../MasterControlAuto';
import MasterControlUmbrella from '../MasterControlUmbrella';


// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class MasterControl extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        totalPremiums: 0
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
        setTimeout(() => { this.calculateTotals() }, 3000)
    }

    calculateTotals = () => {
        var autoPrems = 0;
        var homePrems = 0;
        var umbrellaPrems = 0;

        for (var i = 0; i < this.props.agentAutos.length; i++) {
            autoPrems += this.props.agentAutos[i].premium
        }

        for (var j = 0; j < this.props.agentProperties.length; j++) {
            homePrems += this.props.agentProperties[j].premium
        }

        for (var k = 0; k < this.props.agentUmbrellas.length; k++) {
            umbrellaPrems += this.props.agentUmbrellas[k].premium
        }

        setTimeout(() => {
            console.log("Auto premiums: " + autoPrems)
            console.log("Home premiums: " + homePrems)
            console.log("Umbrella premiums: " + umbrellaPrems)
        }, 1000)

        this.setState({
            totalPremiums: autoPrems + homePrems + umbrellaPrems
        })

    }



    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            DOB: this.state.DOB,
            streetAddress: this.state.streetAddress,
            cityAddress: this.state.cityAddress,
            zipAddress: this.state.zipAddress,
            stateAddress: this.state.stateAddress,
            email: this.state.email,
            quoteNotes: this.state.quoteNotes
        }

        console.log(quoteData)
    }





    render() {
        return (
            <div>
                <Card bg="light" style={{}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <Card.Header style={{ textAlign: 'center' }}>
                        <h2><strong>Raw Master Control</strong></h2>
                    </Card.Header>
                    <Card.Body style={{ height: '1000px', overflow: 'auto', backgroundImage: 'url(https://picsum.photos/800)',  backgroundSize: 'cover' }}>
                        <Table bordered hover size="sm" responsive>
                            <thead>
                                <tr style={{backgroundColor: 'white'}}>
                                    <th>Type</th>
                                    <th>Client Name</th>
                                    <th>Status</th>
                                    <th>Premium</th>
                                    <th>Split</th>
                                    <th>Est. Comm</th>
                                    <th>Renewal Date</th>
                                    <th>Date Updated</th>
                                    <th>Date Added</th>
                                    <th>View Client</th>
                                </tr>
                            </thead>
                            <tbody>
                           
                                
                                {/* <tr style={{ backgroundColor: 'black', height: '50px' }}></tr> */}
                                {this.props.agentProperties ?
                                    <>
                                        {this.props.agentProperties.map(property => (
                                            <MasterControlProperty
                                                key={property._id}
                                                property={property}
                                                agentID={this.props.agentID._id}
                                            />
                                           

                                        ))}

                                    </>
                                    : null}

                                {/* <tr style={{ backgroundColor: 'black', height: '50px' }}></tr> */}

                                {this.props.agentAutos ?
                                    <>

                                        {this.props.agentAutos.map(auto => (
                                            <MasterControlAuto
                                                key={auto._id}
                                                auto={auto}
                                                agentID={this.props.agentID._id}
                                            />

                                         
                                        ))}

                                    </>
                                    : null}

                                {/* <tr style={{ backgroundColor: 'black', height: '50px' }}></tr> */}

                                {this.props.agentUmbrellas.map(umbrella => (
                                    <MasterControlUmbrella
                                        key={umbrella._id}
                                        umbrella={umbrella}
                                        agentID={this.props.agentID._id}
                                    />
                                    
                                ))}
                                <tr></tr>

                                

                            </tbody>


                        </Table>


                    </Card.Body>



                </Card>
            </div >
        )

    }

}

export default MasterControl;