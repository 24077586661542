import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
import { storage } from '../../config/Fire';
import cogoToast from 'cogo-toast';


class CreateStandaloneProperty extends Component {
    state = {
        policyNumber: "",
        carrier: "",
        effectiveDate: "",
        clientFirstName: "",
        clientLastName: "",
        address: "",
        dwelling: "",
        structures: "",
        persprop: "",
        lossofuse: "",
        clientID: "",
        liability: "",
        declaration: "",
        medical: "",
        deductible: "",
        premium: "",
        mainAgent: "",
        mainAgentSplit: "",
        refAgentOne: "",
        refAgentOneSplit: "",
        refAgentTwo: "",
        refAgentTwoSplit: "",
        refAgentThree: "",
        refAgentThreeSplit: "",
        refAgentFour: "",
        refAgentFourSplit: ""
    }

    componentDidMount = () => {

        API.getClients()
        .then(res => {
            this.setState({ clients: res.data });
            console.log(res);
            console.log(this.state.clients)
        
        })
        .catch(err => console.log(err));

        API.getCarriers()
        .then((res) => {
            console.log("Carreirs Retrieved from API")
            console.log(res);
            this.setState({
                carrierData: res.data
            })
            console.log(this.state);
        })
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };


    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    declaration: res
                })
            })
        }
    }

    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        url: urlB,
                        uploaded: true
                    })
                })
            }
        )
    }


    handlePolicyCreate = (event) => {
        event.preventDefault()

        var propertyInformation = {
            policyNumber: this.state.policyNumber,
            carrier: this.state.carrier,
            address: this.state.address,
            effectiveDate: this.state.effectiveDate,
            clientFirstName: this.state.clientFirstName,
            clientLastName: this.state.clientLastName,
            clientID: -1,
            dwelling: this.state.dwelling,
            structures: this.state.structures,
            persprop: this.state.persprop,
            lossofuse: this.state.lossofuse,
            liability: this.state.liability,
            medical: this.state.medical,
            deductible: this.state.deductible,
            premium: this.state.premium,
            mainAgent: this.state.mainAgent,
            mainAgentSplit: this.state.mainAgentSplit,
            refAgentOne: this.state.refAgentOne,
            refAgentOneSplit: this.state.refAgentOneSplit,
            refAgentTwo: this.state.refAgentTwo,
            refAgentTwoSplit: this.state.refAgentTwoSplit,
            refAgentThree: this.state.refAgentThree,
            refAgentThreeSplit: this.state.refAgentThreeSplit,
            refAgentFour: this.state.refAgentFour,
            refAgentFourSplit: this.state.refAgentFourSplit,
            declaration: this.state.declaration
        }
        
        API.savePropertyPolicy(this.state.clientID, {
            policyNumber: this.state.policyNumber,
            carrier: this.state.carrier,
            address: this.state.address,
            effectiveDate: this.state.effectiveDate,
            clientFirstName: this.state.clientFirstName,
            clientLastName: this.state.clientLastName,
            dwelling: this.state.dwelling,
            structures: this.state.structures,
            persprop: this.state.persprop,
            lossofuse: this.state.lossofuse,
            liability: this.state.liability,
            clientID: this.state.clientID,
            medical: this.state.medical,
            deductible: this.state.deductible,
            premium: this.state.premium,
            mainAgent: this.state.mainAgent,
            mainAgentSplit: this.state.mainAgentSplit,
            refAgentOne: this.state.refAgentOne,
            refAgentOneSplit: this.state.refAgentOneSplit,
            refAgentTwo: this.state.refAgentTwo,
            refAgentTwoSplit: this.state.refAgentTwoSplit,
            refAgentThree: this.state.refAgentThree,
            refAgentThreeSplit: this.state.refAgentThreeSplit,
            refAgentFour: this.state.refAgentFour,
            refAgentFourSplit: this.state.refAgentFourSplit,
            declaration: this.state.declaration
        }).then(res => {
            console.log("Saved Property Policy")
            cogoToast.success("Policy Saved!");
            
            console.log(res.data)
            alert("Created Property Policy!")
        }
        ).catch(err => {
            cogoToast.error("Policy Not Submitted");
            alert("Error, missing data...please submit again")
            
            console.log(err)})


        // API.savePropertyStandalonePolicy({
        //     policyNumber: this.state.policyNumber,
        //     carrier: this.state.carrier,
        //     address: this.state.address,
        //     effectiveDate: this.state.effectiveDate,
        //     clientFirstName: this.state.clientFirstName,
        //     clientLastName: this.state.clientLastName,
        //     clientID: -1,
        //     dwelling: this.state.dwelling,
        //     structures: this.state.structures,
        //     persprop: this.state.persprop,
        //     lossofuse: this.state.lossofuse,
        //     liability: this.state.liability,
        //     medical: this.state.medical,
        //     deductible: this.state.deductible,
        //     premium: this.state.premium,
        //     mainAgent: this.state.mainAgent,
        //     mainAgentSplit: this.state.mainAgentSplit,
        //     refAgentOne: this.state.refAgentOne,
        //     refAgentOneSplit: this.state.refAgentOneSplit,
        //     refAgentTwo: this.state.refAgentTwo,
        //     refAgentTwoSplit: this.state.refAgentTwoSplit,
        //     refAgentThree: this.state.refAgentThree,
        //     refAgentThreeSplit: this.state.refAgentThreeSplit,
        //     refAgentFour: this.state.refAgentFour,
        //     refAgentFourSplit: this.state.refAgentFourSplit,
        //     declaration: this.state.declaration
        // }).then(res => {
        //     console.log("Saved Property Policy")
        //     cogoToast.success("Policy Saved!");
            
        //     console.log(res.data)
        //     alert("Created Property Policy!")
        // }
        // ).catch(err => {
        //     cogoToast.error("Policy Not Submitted");
        //     alert("Error, missing data...please submit again")
            
        //     console.log(err)})

        console.log(propertyInformation)

    }

    render() {
        return (

            < div >

                <div className="card">
                    {/* <div className="card-header"> */}
                        <h5 style={{ textAlign: 'center' }}>Create a Property Policy</h5>
                        {/* <p>
                        </p> */}
                    {/* </div> */}
                    <div className="card-body">
                        <form>
                            <Table bordered hover>
                                <tbody style={{ textAlign: 'center' }}>
                                    <tr>
                                        <td><strong>Question</strong></td>
                                        <td><strong>Input</strong></td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Policy #</td>
                                        <td><input type="text" value={this.state.policyNumber} onChange={this.handleInputChange} name="policyNumber" placeholder="Policy Number" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Carrier</td>
                                        <td>
                                            
                        
                                            {/* <input type="text" value={this.state.carrier} onChange={this.handleInputChange} name="carrier" placeholder="Carrier" /> */}

                                            {this.state.carrierData ? 
                                            
                                            <select id="carrierDropMenu" value={this.state.carrier} onChange={this.handleInputChange} name="carrier">
                                                <option value="">--Select a Carrier--</option>
                                                {this.state.carrierData.map(carrier => (
                                                    <option key={carrier._id} value={carrier.name}>{carrier.name}</option>
                                                ))}
                                            </select>
                                        : 
                                        <div>No Carriers yet, *Go to Create Carrier option in Admin Tools</div>}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Premium</td>
                                        <td><input type="number" value={this.state.premium} onChange={this.handleInputChange} name="premium" placeholder="Premium" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Address</td>
                                        <td><input type="text" value={this.state.address} onChange={this.handleInputChange} name="address" placeholder="Address" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Effective</td>
                                        <td><input type="date" value={this.state.effectiveDate} onChange={this.handleInputChange} name="effectiveDate" placeholder="effectiveDate" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Client</td>
                                        <td>
                                        <form>
                                        <input list="clients" value={this.state.clientID} name="clientID" onChange={this.handleInputChange} />

                                        {this.state.clients ?
                                            <datalist id="clients">
                                                {this.state.clients.map(client => (
                                                    <option key={client._id} value={client._id}>{client.firstName} {client.lastName}</option>
                                                ))}
                                            </datalist>

                                        : null}
                                        </form>
                                    </td>
                                    </tr>
                                    
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>First Name</td>
                                        <td><input type="text" value={this.state.clientFirstName} onChange={this.handleInputChange} name="clientFirstName" placeholder="Client First Name" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Last Name</td>
                                        <td><input type="text" value={this.state.clientLastName} onChange={this.handleInputChange} name="clientLastName" placeholder="Client First Name" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Policy</td>
                                        <td>
                                        <input name="fileData" type="file" onChange={this.fileSelectedHandler} />   
                                            {/* <button onClick={this.fileUploadHandlerB}>Upload
                                                <span>
                                                    {this.state.uploaded === false ? <img alt="loading" src="/loading.gif" /> : null}
                                                    {this.state.uploaded === true ? <img alt="checkmark" src="/checkmark.png" style={{ height: '20px', width: '20px' }} /> : null}
                                                </span>
                                            </button> */}
                                        </td>
                                    </tr>
            

                                </tbody>
                            </Table>
                            <Table>

                                <tbody>
                                    <tr>
                                        <td>Item Name</td>
                                        <td>Agent</td>
                                        <td>Split</td>
                                    </tr>

                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Main Agent</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.mainAgent} onChange={this.handleInputChange} name="mainAgent">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.mainAgent ?
                                                <input type="number" value={this.state.mainAgentSplit} name="mainAgentSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #1</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentOne} onChange={this.handleInputChange} name="refAgentOne">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.refAgentOne ?
                                                <input type="number" value={this.state.refAgentOneSplit} name="refAgentOneSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #2</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentTwo} onChange={this.handleInputChange} name="refAgentTwo">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.refAgentTwo ?
                                                <input type="number" value={this.state.refAgentTwoSplit} name="refAgentTwoSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #3</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentThree} onChange={this.handleInputChange} name="refAgentThree">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.refAgentThree ?
                                                <input type="number" value={this.state.refAgentThreeSplit} name="refAgentThreeSplit" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Referral Agent #4</td>
                                        <td>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentFour} onChange={this.handleInputChange} name="refAgentFour">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}

                                        </td>
                                        <td>
                                            {this.state.refAgentFour ?
                                                <input type="number" value={this.state.refAgentSplitFour} name="refAgentSplitFour" onChange={this.handleInputChange} />

                                                : null}
                                        </td>

                                    </tr>


                                </tbody>
                            </Table>
                            <div style={{ textAlign: 'center' }}>
                                <button className="btn-success btn-sm btn" onClick={this.handlePolicyCreate}>Create Policy</button>
                            </div>
                        </form>
                    </div>
                </div>


            </div >



        )
    }
}


export default CreateStandaloneProperty;
