import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup'
import './style.css'
import API from '../../utils/API';
import cogoToast from 'cogo-toast';
import { storage } from '../../config/Fire';


class SourcedCreateServiceRequest extends Component {
    state = {
        serviceReqType: "",
        serviceReqName: "",
        serviceReqEmail: "",
        serviceReqBody: "",
        url: "",
        declaration: "",
        showServiceRequestForm: true
    }

    componentDidMount = () => {
        console.log(storage);
        console.log(this.props);
        if (this.props.sourceType){
            console.log("Source type is: " + this.props.sourceType);
            console.log("Source is: " + this.props.source);
            console.log("Source Name: " + this.props.sourceName)
            console.log("Source Email: " + this.props.sourceEmail);
            console.log("Source Phone: " + this.props.sourcePhone);
        }
    }

    // getBase64(file) {
    //     var reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = function () {
    //       console.log(reader.result);
    //     };
    //     reader.onerror = function (error) {
    //       console.log('Error: ', error);
    //     };
    //  }

    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleServiceReqSubmit = event => {
        event.preventDefault()
        console.log("Submitting Service Request....")
        console.log("File; ");
        console.log(this.state);
        console.log(this.state.declaration)

        var serviceReqData = {
            type: this.state.serviceReqType,
            name: this.props.sourceName,
            requester: this.props.source,
            email: this.props.sourceEmail,
            phone: this.props.sourcePhone,
            message: this.state.serviceReqBody,
            fileData: this.state.declaration,
            client: this.props.source
        }

            console.log(serviceReqData)

        if (this.props.sourceType === 'client'){

            API.saveServiceRequestForClient(serviceReqData, this.props.source).then(res => {
                console.log("Saved Service Request")
                alert("Service Request Saved!");
                cogoToast.success("Service Request Saved!")
                this.setState({
                    showServiceRequestForm: false
                })
            }
    
                ).catch(err => console.log(err))

        } else {
            alert("Source type not recognized, please refresh and try again!")
        }

    

    }
    

    fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    declaration: res
                })
            })
        }


            // this.setState({
            //     declaration: this.getBase64(event.target.files[0])
            // })
       
    }

    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.declaration.name}`).put(this.state.declaration);
        console.log(uploadTask);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.declaration.name).getDownloadURL().then(urlB => {
                    this.setState({
                        url: urlB,
                        uploaded: true
                    })
                    console.log("Upload Successful!");
                    console.log(this.state);
                    console.log(this.state.url);
                    // Run code to create a retrieval map in the database, add prikey to clients
                })
            }
        )
    }

    showServiceRequestForm = () => {
        this.setState({
            showServiceRequestForm: true
        })
    }

    render() {
        return (

            < div className="card" style={{backgroundColor: 'transparent'}}>
                <div className="card-header" style={{textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.8)'}}>
                    <h2>Service Request Form</h2>
                    <p><strong>NOTE: Changes in coverage can not be made via this tool, please call us for those</strong></p>
                </div>
                <div className="card-body" style={{color: 'black', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.7)'}}>
                <div className="row">

                    <div className="col-md-10 offset-md-1">

                        {this.state.showServiceRequestForm ? 
                        
                        <ListGroup className="list-group-flush">
                            <form>
                                <ListGroup.Item>
                                    <label>Category</label><br />
                                    <select style={{width: '100%'}} value={this.state.serviceReqType} onChange={this.handleInputChange} name="serviceReqType">
                                        <option value=""></option>
                                        <option value="add-driver">Add Driver</option>
                                        <option value="add-jewelry">Add Jewelry</option>
                                        <option value="add-vehicle">Add Vehicle</option>
                                        <option value="billing">Billing</option>
                                        <option value="claim">Claim Assistance</option>
                                        <option value="general">General</option>
                                        <option value="new-rates">Get New Insurance Rates</option>
                                        <option value="policy-review">Request a Policy Review Meeting</option>
                                        <option value="req-mortgage-doc">Proof of Insurance for Mortgage or Auto Lender</option>
                                        <option value="req-other">Reason not listed</option> 
                                        <option value="renewal-quote">Quote for New Insurance</option>
                                    </select>
                                </ListGroup.Item>
                                {/* <ListGroup.Item>
                                    <label>Name</label>
                                    <input style={{textAlign: 'center'}} value={this.state.serviceReqName} onChange={this.handleInputChange} type="text" name="serviceReqName" className="form-control" />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>E-mail</label>
                                    <input style={{textAlign: 'center'}} value={this.state.serviceReqEmail} onChange={this.handleInputChange} type="email" name="serviceReqEmail" className="form-control" />
                                </ListGroup.Item> */}
                                <ListGroup.Item>
                                    <label>How may we help you?</label>
                                    <textarea value={this.state.serviceReqBody} onChange={this.handleInputChange} name="serviceReqBody" className="form-control" rows="5" cols="30" />
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Please upload any related documentation (optional)</label>
                                    <input name="fileData" type="file" onChange={this.fileSelectedHandler} />
                                        {/* <button onClick={this.fileUploadHandlerB}>Upload
                                            <span>
                                                {this.state.uploaded === false ? <img alt="loading" src="/loading.gif" /> : null}
                                                {this.state.uploaded === true ? <img alt="checkmark" src="/checkmark.png" style={{ height: '20px', width: '20px' }} /> : null}
                                            </span>
                                    </button> */}
                                </ListGroup.Item>
                                <ListGroup.Item style={{textAlign: 'center'}}>
                                    {/* <button onClick={this.handleServiceReqSubmit} className="btn btn-info">Send Message</button> */}
                                    <button type="button" style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                onClick={(e) => this.handleServiceReqSubmit(e)}>Submit
                                            </button>
                                </ListGroup.Item>
                            </form>
                        </ListGroup>

                        :

                        <div className="row">
                            <div className="col">

                                <h3>Thank you for submitting your request, would you'd like to enter another one?</h3>
                                <br />
                                <button onClick={() => this.props.sourceReturn()}>No thank you.</button>
                                <br />
                                <br />
                                <button onClick={() => this.showServiceRequestForm()}>Yes please!</button> 
                        
                            </div>
                        </div>
                        
                        }


                        

                    </div>
                </div>
                    
                </div>


            </div >



        )
    }
}


export default SourcedCreateServiceRequest;
