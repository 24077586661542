import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
// import FawnButton from "../FawnButton";


// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'
import FawnButtonMini from "../FawnButtonMini";
// import InputAnimation from "../InputAnimation";



class QuoteCard extends Component {

    state = {
        firstName: "",
        lastName: "",
        DOB: "",
        email: "",
        streetAddress: "",
        cityAddress: "",
        zipAddress: "",
        stateAddress: "",
        vehiclesBody: "",
        driversBody: "",
        quoteNotes: "",
        beginQuoting: false,
        formSequence: 1
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleQuoteSubmit = event => {
        event.preventDefault()

        var quoteData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.DOB,
            street: this.state.streetAddress,
            city: this.state.cityAddress,
            zip: this.state.zipAddress,
            state: this.state.stateAddress,
            email: this.state.email,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.state.refAgentOne,
            refAgentTwo: this.state.refAgentTwo
        }

        console.log(quoteData)



        API.saveQuote({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            dob: this.state.DOB,
            street: this.state.streetAddress,
            city: this.state.cityAddress,
            zip: this.state.zipAddress,
            state: this.state.stateAddress,
            email: this.state.email,
            vehicles: this.state.vehiclesBody,
            drivers: this.state.driversBody,
            notes: this.state.quoteNotes,
            mainAgent: this.state.chosenAgent,
            refAgentOne: this.state.refAgentOne,
            refAgentTwo: this.state.refAgentTwo
        }).then(res => {
            console.log("Saved Quote!")
            console.log(res.data)
        }
        ).catch(err => console.log(err))

    }

    beginQuotingToggle = () => {
        if (this.state.beginQuoting === false){
            this.setState({
                beginQuoting: true
            })
        } else {
            this.setState({
                beginQuoting: false
            })
        }
    }

    incrementForm = () => {
        this.setState({
            formSequence: this.state.formSequence += 1
        })
        console.log(this.state)
    }

    resetForm = () => {
        this.setState({
            formSequence: 1,
            firstName: "",
            lastName: "",
            DOB: "",
            email: "",
            streetAddress: "",
            cityAddress: "",
            zipAddress: "",
            stateAddress: "",
            vehiclesBody: "",
            driversBody: "",
            quoteNotes: "",
            beginQuoting: false,
        })
    }


    render() {
        return (
            <div>
                <div className="card" style={{ backgroundColor: 'transparent', textAlign: 'center'}}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    {/* <div className="card-header" style={{ textAlign: 'center' }}> */}
                        {/* <h2 className="contact-text" style={{ color: this.props.text }}>Quote Form</h2> */}
                    {/* </div> */}
                    {/* <div className="card-body" style={{backgroundColor: 'transparent'}}> */}
                        {/* <div className="card-title"> */}
                            {/* <h4 className="contact-text" style={{ color: this.props.text }}>Rember to tag correct producers</h4> */}
                        {/* </div> */}
                    {/* </div> */}

                    <form className="form-screen" style={{ color: this.props.text }}>
                        <p className="quote-screen-text" style={{textAlign: 'center'}}>Fast Quote</p>
                        {this.state.beginQuoting ? 
                        <div id="quoting-screen" >

                        <div className="row">
                            {this.state.formSequence === 1 ? 
                                <div className="offset-md-3 col-md-6">
                                      <label><h3>First Name</h3></label>
                                      <input type="text" className="form-control" value={this.state.firstName} onChange={this.handleInputChange} name="firstName" />
                                      <br />
                                      <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                      />
                                  </div>
                        
                            : null}

                            {this.state.formSequence === 2 ? 
                                <div className="offset-md-3 col-md-6">
                                <label><h3>Last Name</h3></label>
                                    <input type="text" className="form-control" value={this.state.lastName} onChange={this.handleInputChange} name="lastName"/>
                                    <br />
                                      <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                      />
                                  
                                  </div>
                        
                            : null}                            
                
                            {/* <div className="col">
                            </div> */}
                            {/* <div className="col">
                                <label>Date of Birth</label>
                                <input type="date" className="form-control" value={this.state.DOB} onChange={this.handleInputChange} name="DOB" />
                            </div> */}
                        </div>



                            {this.state.formSequence ===  3 ? 
                                <div className="form-row row">
                        
                                        <div className="col-md-4">
                                            <label><strong>Specialist</strong></label>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.chosenAgent} onChange={this.handleInputChange} name="chosenAgent">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>

                                            </div> : <div>No agents yet</div>}
                                        </div>
                                        {/* <div className="col-md-1"></div> */}
                                        <div className="col-md-4">
                                            <label><strong>Partner Agent</strong></label>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentOne} onChange={this.handleInputChange} name="refAgentOne">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                                : <div>No agents yet</div>}

                                        </div>

                                        <div className="col-md-4">
                                            <label><strong>Affiliate</strong></label>
                                            {this.props.agents ? <div>
                                                <select id="protegeDropMenu" value={this.state.refAgentTwo} onChange={this.handleInputChange} name="refAgentTwo">
                                                    <option value="">--Select an Agent--</option>
                                                    {this.props.agents.map(agent => (
                                                        <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                                : <div>No agents yet</div>}
                                        </div>
                                        
                                        {/* <br /> */}
                                        <div className="offset-md-3 col-md-6">
                                            <br />
                                            <FawnButtonMini 
                                                buttonText="Next"
                                                pf1={this.incrementForm}
                                            />
                                        </div>

                      
                                    
                                
                                </div>
                            : null}    
                            



                        <div className="form-row row">

                            
                            {this.state.formSequence === 4 ? 
                            
                            <div className="offset-md-3 col-md-6">
                                <label><h3>Street Address</h3></label>
                                <input type="text" className="form-control" value={this.state.streetAddress} onChange={this.handleInputChange} name="streetAddress" id="inputAddress" />
                                <br />
                                <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                    />
                            </div>
                                
                            : null}


                            {this.state.formSequence === 5 ? 
                            
                                <div className="offset-md-3 col-md-6">
                                    <label><h3>City</h3></label>
                                    <input type="text" className="form-control" value={this.state.cityAddress} onChange={this.handleInputChange} name="cityAddress" id="inputCity" />
                                    <br />
                                    <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                    />
                                </div>
                                
                            : null}


                            
                            {this.state.formSequence === 6 ? 
                            
                                <div className="offset-md-3 col-md-6">
                                <label><h3>State</h3></label>
                                <select id="inputState" className="form-control" value={this.state.stateAddress} onChange={this.handleInputChange} name="stateAddress">
                                    <option value="">Choose...</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                                </select>
                                <br />
                                <FawnButtonMini 
                                        buttonText="Next"
                                        pf1={this.incrementForm}
                                    />
                                </div>
                                
                            : null}

      

           
                        </div>


                            {this.state.formSequence === 7 ? 
                            
                                    <div className="offset-md-3 col-md-6">
                                        <label><h3>Email</h3></label>
                                        <input type="email" className="form-control" value={this.state.email} onChange={this.handleInputChange} name="email" id="inputEmail4" />
                                        <br />
                                        <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                        />
                                    </div>
                                

                                

                            : null}


                            {/* <div className="form-group col-md-2">
                                <label>Zip</label>
                                <input type="text" className="form-control" value={this.state.zipAddress} onChange={this.handleInputChange} name="zipAddress" id="inputZip" />
                            </div> */}

            
                      
                        <div className="form-row row">

                                {this.state.formSequence === 8 ?
                                
                                    <div className="offset-md-3 col-md-6">
                                        <label><h3>Year/Make/Model of Vehicles</h3></label>
                                        <textarea value={this.state.vehiclesBody} onChange={this.handleInputChange} name="vehiclesBody" className="form-control" rows="5" cols="60" />
                                        <br />
                                        <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                        />
                                    </div>

                                : null }
    
    
                                {this.state.formSequence === 9 ? 
                                    
                                    <div className="offset-md-3 col-md-6">
                                        <label><h3>Name / Age of Drivers</h3></label>
                                        <textarea value={this.state.driversBody} onChange={this.handleInputChange} name="driversBody" className="form-control" rows="5" cols="60" />
                                        <br />
                                        <FawnButtonMini 
                                            buttonText="Next"
                                            pf1={this.incrementForm}
                                        />
                                    </div>

                                : null}
    
    
                                {this.state.formSequence === 10 ?
                                

                                        <div className="offset-md-3 col-md-6">
                                            <label><h3>Comments</h3></label>
                                            <textarea value={this.state.quoteNotes} onChange={this.handleInputChange} name="quoteNotes" className="form-control" rows="5" cols="60" />
                                            <br />
                                            <FawnButtonMini 
                                                buttonText="Next"
                                                pf1={this.incrementForm}
                                            />
                                        </div>
     
    
                                : null}

                        </div>

                                {this.state.formSequence === 11 ? 

                                    <div style={{ textAlign: 'center' }}>
                                        <br />
                                        {/* <button className="btn btn-lg btn-success" onClick={this.handleQuoteSubmit}>Submit Quote</button> */}
                                        {/* <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, #640e27 74%, white 0%)', width: '100%', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                        onClick={() => this.handleQuoteSubmit()}>Submit
                                        </button> */}

                                        <div className="form-row row">

                                            <div className="col">

                                                <div className="card">
                                                    <h3>Review Info:</h3>
                                                    <ol>
                                                        <li><strong>Name:</strong> {this.state.firstName} {this.state.lastName}</li>
                                                        <li><strong>Address:</strong> {this.state.streetAddress}</li>
                                                        <li><strong>City:</strong> {this.state.cityAddress}</li>
                                                        <li><strong>State:</strong> {this.state.stateAddress}</li>
                                                        <li><strong>Email:</strong> {this.state.email}</li>
                                                        <li><strong>Vehicles:</strong> {this.state.vehiclesBody}</li>
                                                        <li><strong>Drivers:</strong> {this.state.driversBody}</li>
                                                        <li><strong>Notes:</strong>{this.state.quoteNotes}</li>
                                                    </ol>
                                                </div>
                                                <br />
                                                <FawnButtonMini 
                                                    buttonText="Submit"
                                                    pf1={(e) => this.handleQuoteSubmit(e)}
                                                />                                        
                                                <br />
                                                <FawnButtonMini 
                                                    buttonText="Restart"
                                                    pf1={this.resetForm}
                                                />
                                            </div>
                                    
                                        </div>

                                          </div>

                                : null }
                                
                            

                        
                                

                            </div>    
                         : 

                         <div>

                             <div className="row">
                                 <div className="offset-md-3 col-md-6">
                                    <FawnButtonMini 
                                        pf1={this.beginQuotingToggle}
                                        buttonText="Go!"
                                    />
                                 </div>
                             </div>
  
                         </div>
                        }
               

                       
               <p style={{ fontSize: '12px', position: 'absolute', bottom: 0, textAlign: 'center', padding: '0' }}>*Please note our carriers use credit-based insurance scores when rating policies.</p>
                    </form>
              
                                  



                    {/* <div className="card">
                        <button className="btn btn-light" onClick={this.props.return} style={{ float: 'right' }}>Return</button>
                    </div> */}
                                   {/* <p style={{ fontSize: '12px', bottom: 0, position: 'absolute' }}>*Please note our carriers use credit-based insurance scores when rating policies.</p> */}
                </div>
            </div>
        )

    }

}

export default QuoteCard;