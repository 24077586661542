import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
import Table from 'react-bootstrap/Table';
import API from '../../utils/API';
import fire from '../../config/Fire';


class CreateAgent extends Component {
    state = {
        agentFirstName: "",
        agentLastName: "",
        agentEmail: "",
        agentTelephone: "",
        agentPassword: "",
        agentAuthority: "agent"
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    signup = e => {
        e.preventDefault()
        fire.auth().createUserWithEmailAndPassword(this.state.agentEmail, this.state.agentPassword).then(data => {
            console.log("Successfully created user")
            console.log(data)

            API.saveAgent({
                uid: data.user.uid,
                firstName: this.state.agentFirstName,
                lastName: this.state.agentLastName,
                email: data.user.email,
                telephone: this.state.agentTelephone,
                servicer: this.state.clientServicer
            })
        }).catch((error) => {
            console.log(error)
        })
    }


    render() {
        return (

            < div >

                <div className="card" style={{boxShadow: '0px 0px 4px 4px black', borderRadius: '10px', color: 'black', overflow: 'auto', padding: '0'}}>
                    {/* <div className="card-header"> */}
                        {/* <h5 style={{ textAlign: 'center' }}>Create an Agent</h5> */}
                        {/* <p> */}
                        {/* </p> */}
                    {/* </div> */}

                    <div className="row">
                        {/* <div className="col-lg-3"></div> */}
                        <div className="col">
                            <div className="card-body">
                                <h3 style={{textAlign: 'center'}}> Create Agent </h3>
                                <form>
                                    <Table bordered hover style={{backgroundColor: 'white'}}>
                                        <tbody>
                                            <tr>
                                                <th>Question</th>
                                                <th>Input</th>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>First name</td>
                                                <td><input type="text" value={this.state.agentFirstName} onChange={this.handleInputChange} name="agentFirstName" placeholder="First Name" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Last name</td>
                                                <td><input type="text" value={this.state.agentLastName} onChange={this.handleInputChange} name="agentLastName" placeholder="Last Name" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Email</td>
                                                <td> <input type="text" value={this.state.agentEmail} onChange={this.handleInputChange} name="agentEmail" placeholder="E-mail address" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Password</td>
                                                <td><input type="text" value={this.state.agentPassword} onChange={this.handleInputChange} name="agentPassword" placeholder="Password" /></td>
                                            </tr>
                                            <tr>
                                                <td style={{textAlign: 'center', backgroundColor: 'black', color: 'white', fontWeight: 'bold'}}>Telephone</td>
                                                <td><input type="text" value={this.state.agentTelephone} onChange={this.handleInputChange} name="agentTelephone" placeholder="Telephone number" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <button style={{color: 'white', fontSize: '20px', boxShadow: '0px 0px 1px 1px black', backgroundImage: 'linear-gradient(147deg, black 90%, white 0%)', padding: '5px 20px 5px 20px', borderRadius: '5px'}} 
                                                        onClick={(e) => this.signup(e)}>Submit
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div style={{ textAlign: 'center' }}>
                                        {/* <button className="btn-success btn-sm btn" onClick={this.signup}>Create!</button> */}
                                                

                                    </div>
                                </form>
                            </div>                            
                        </div>
                        {/* <div className="col-lg-3"></div> */}
                    </div>

                </div>


            </div >



        )
    }
}


export default CreateAgent;
