import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'
// import Table from 'react-bootstrap/Table';
import API from "../../utils/API";
// import { storage } from '../../config/Fire';
import cogoToast from "cogo-toast";


class CreateQuoteRow extends Component {
    state = {
        policyNumber: "",
        carrier: "",
        effectiveDate: "",
        clientFirstName: "",
        clientLastName: "",
        address: " ",
        dwelling: 0,
        structures: 0,
        persprop: 0,
        lossofuse: 0,
        liability: 0,
        medical: 0,
        deductible: 0,
        premium: "",
        declaration: "",
        mainAgent: "",
        mainAgentSplit: "",
        refAgentOne: "",
        refAgentOneSplit: "",
        refAgentTwo: "",
        refAgentTwoSplit: "",
        refAgentThree: "",
        refAgentThreeSplit: "",
        refAgentFour: "",
        refAgentFourSplit: "",
        firstName: "",
        lastName: "",
        dob: "",
        quickAddress: "",
        phone: "",
        vehicles: "",
        drivers: "",
        email: "",
        declaration1: "",
        declaration2: "",
        declaration3: "",
        targetPremium: "",
        docName: "",
        document: "",
        docName1: "",
        document1: "",
        docName2: "",
        document2: "",
        docName3: "",
        document3: ""
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    componentDidMount = () => {
        // API.getClientIDs()
        // .then(res => {
        //     this.setState({ clients: res.data });
        //     console.log(res);
        //     console.log(this.state.clients)
        
        // })
        // .catch(err => console.log(err));


        // API.getAgents()
        // .then(res => {
        //     this.setState({ agents: res.data });
        //     console.log(res);
        //     console.log(this.state.agents)

        // })
        // .catch(err => console.log(err));

        // setTimeout( () => {
        //     console.log(this.state)
        // }, 5000)

    }


    getBase64 = (file, callBack) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          callBack(reader.result)
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
          return error
        };
     }

     fileSelectedHandler = event => {
        event.preventDefault();
        console.log(event.target.files)
        this.setState({
            docName: event.target.files[0].name
        })
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document: res
                })
            })
        }
    }

    fileSelectedHandler1 = event => {
        event.preventDefault();
        console.log(event.target.files)
        this.setState({
            docName1: event.target.files[0].name
        })
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document1: res
                })
            })
        }
    }

    fileSelectedHandler2 = event => {
        event.preventDefault();
        console.log(event.target.files)
        this.setState({
            docName2: event.target.files[0].name
        })
        // console.log(this.getBase64(event.target.files[0]))

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document2: res
                })
            })
        }
    }

    fileSelectedHandler3 = event => {
        event.preventDefault();
        console.log(event.target.files)
        // console.log(this.getBase64(event.target.files[0]))
        this.setState({
            docName3: event.target.files[0].name
        })

        if (event.target.files[0]) {
            this.getBase64(event.target.files[0], (res) => {
                console.log(res);
                this.setState({
                    document3: res
                })
            })
        }
    }

    handleQuoteCreate = (event) => {
        event.preventDefault()

        var quoteInformation = {
            quickAddress: this.state.quickAddress,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            clientID: -1,
            phone: this.state.phone,
            vehicles: this.state.vehicles,
            drivers: this.state.drivers,
            email: this.state.email,
            mainAgent: this.state.mainAgent,
            mainAgentSplit: this.state.mainAgentSplit,
            refAgentOne: this.state.refAgentOne,
            refAgentOneSplit: this.state.mainAgentSplit,
            declaration1: this.state.declaration1,
            declaration2: this.state.declaration2,
            declaration3: this.state.declaration3,
            targetPremium: this.state.targetPremium
        }

        console.log(quoteInformation);

        API.saveQuote(
            quoteInformation
        ).then(res => {
            console.log("Saved Quote!");
            alert("Saved Quote!");
            cogoToast.success("Saved New Quote!");
            
            console.log(res.data)
            this.props.generateNewRow(res.data)

            if (this.state.document.length > 0){
                API.createDocumentForQuote(res.data._id, {
                    docName: this.state.docName,
                    document: this.state.document,
                    policyid: res.data._id,
                    type: "quote"
                })
            }

            if (this.state.document1.length > 0){

                API.createDocumentForQuote1(res.data._id, {
                    docName: this.state.docName1,
                    document: this.state.document1,
                    policyid: res.data._id,
                    type: "quote"
                })

            }

            if (this.state.document2.length > 0){

                API.createDocumentForQuote2(res.data._id, {
                    docName: this.state.docName2,
                    document: this.state.document2,
                    policyid: res.data._id,
                    type: "quote"
                })

            }

            if (this.state.document3.length > 0){

                API.createDocumentForQuote3(res.data._id, {
                    docName: this.state.docName3,
                    document: this.state.document3,
                    policyid: res.data._id,
                    type: "quote"
                })

            }

        }).catch(err => console.log(err))



    }


    render() {
        return (

        // <span>


            <tr>
                <td>
                    <input type="text" style={{width: '100%'}} value={this.state.firstName} onChange={this.handleInputChange} name="firstName" placeholder="First" />
                    <input type="text" style={{width: '100%'}} value={this.state.lastName} onChange={this.handleInputChange} name="lastName" placeholder="Last" />
                </td>
                <td>
                    <input type="number" style={{width: '200px'}} value={this.state.targetPremium} onChange={this.handleInputChange} name="targetPremium" placeholder="Target Premium" />
                </td>
                <td>
                    <input type="text"  style={{width: '300px'}} value={this.state.quickAddress} onChange={this.handleInputChange} name="quickAddress" placeholder="Address" />
                </td>
                <td>
                    <input type="text" style={{width: '150px'}} value={this.state.phone} onChange={this.handleInputChange} name="phone" placeholder="Phone #" />
                </td>
                <td>
                    <input style={{width: '250px'}} type="text" value={this.state.vehicles} onChange={this.handleInputChange} name="vehicles" placeholder="Vehicles" />
                </td>

                <td>
                    <input style={{width: '250px'}} type="text" value={this.state.drivers} onChange={this.handleInputChange} name="drivers" placeholder="Drivers" />
                </td>
           
                <td>
                    {this.props.agents ? 
                    <div>
                        <select style={{width: '150px'}} id="protegeDropMenu" value={this.state.mainAgent} onChange={this.handleInputChange} name="mainAgent">
                            <option value="">--Select Main Agent--</option>
                                {this.props.agents.map(agent => (
                                    <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                ))}
                        </select>
                    </div> : <div>No agents yet</div>}
                </td>
                <td>

                    {this.props.agents ? 
                    <div>
                        <select style={{width: '150px'}} id="protegeDropMenu" value={this.state.refAgentOne} onChange={this.handleInputChange} name="refAgentOne">
                            <option value="">--Select Referral Agent--</option>
                                {this.props.agents.map(agent => (
                                    <option key={agent._id} value={agent._id}>{agent.firstName} {agent.lastName}</option>
                                ))}
                        </select>

                    </div> : <div>No agents yet</div>}

                </td>
                <td>

                    <input style={{width: '100px'}} type="number" value={this.state.mainAgentSplit} name="mainAgentSplit" placeholder="Split" onChange={this.handleInputChange} />                                

                </td>
               
              
                <td>

                    <input style={{width: '100px'}} type="text" value={this.state.email} onChange={this.handleInputChange} name="email" placeholder="e-mail" />

                </td>
        
        
               
                
                <td>

                    <button className="btn-success btn" onClick={this.handleQuoteCreate}>Create</button>

                </td>
                <td>
                        <p>Declarations</p>
                    {/* : */}
                    
                        <input name="document" type="file" onChange={this.fileSelectedHandler} />
                    {/* {this.state.declaration1.length > 0 ?  */}
                        {/* <p>Quote 1</p>
                    {/* : */}
                    
                        {/* <input name="document1" type="file" onChange={this.fileSelectedHandler1} /> */}
                    {/* } */}

                    {/* {this.state.declaration2.length > 0 ?  */}
                        {/* <br /> */}
                        {/* <p>Quote 2</p> */}
                    {/* : */}
                    
                        {/* <input name="document2" type="file" onChange={this.fileSelectedHandler2} /> */}
                    {/* } */}

                    {/* {this.state.declaration3.length > 0 ?  */}
                        {/* <br /> */}
                        {/* <p>Quote 3</p> */}
                    {/* : */}
                    
                        {/* <input name="document3" type="file" onChange={this.fileSelectedHandler3} /> */}
                    {/* } */}
           
                </td>
            </tr>

        )
    }
}


export default CreateQuoteRow;
