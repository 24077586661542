import React, { Component } from "react";
import "./style.css";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class ContactCard extends Component {

    state = {
        contactBody: "",
        contactEmail: "",
        contactName: ""
    }

    componentDidMount = () => {
        console.log("Contact Card Mounted")
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    handleContactSubmit = event => {
        event.preventDefault()

        var contactData = {
            contactBody: this.state.contactBody,
            contactEmail: this.state.contactEmail,
            contactName: this.state.contactName,
        }

        console.log(contactData)

        // API.saveNote(this.props.userID, {
        //     noteText: this.state.noteText,
        //     noteAuthor: this.props.userID,
        //     noteAuthorName: this.props.userData.firstName + " " + this.props.userData.lastName,
        //     noteTagged: this.state.noteTagged,
        //     completed: false
        // }).then(res => {
        //     cogoToast.info("Saved Note!")
        //     this.props.rerender();
        // }
        // ).catch(err => console.log(err))

        // setTimeout(() => {
        //     // cogoToast.loading("Re-loading appointments")
        //     this.props.rerender()
        // }, 1000)

    }



    render() {
        return (
            <div>
                <Card bg={this.props.theme} style={{ padding: '10px' }}>
                    {/* <Card.Img variant="top" src="holder.js/100px180?text=Image cap" /> */}
                    <Card.Header style={{ textAlign: 'center' }}>
                        <h2 className="contact-text" style={{ color: 'white' }}>Contact Us</h2>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>
                            <h4 className="contact-text" style={{ color: 'white' }}>Please submit questions, comments or inquiries below!</h4>
                        </Card.Title>
                        <Card.Text>
                        </Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <form>
                            <ListGroup.Item>
                                <label>Your Name</label>
                                <input value={this.state.contactName} onChange={this.handleInputChange} type="text" name="contactName" className="form-control" />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <label>Your E-mail</label>
                                <input value={this.state.contactEmail} onChange={this.handleInputChange} type="email" name="contactEmail" className="form-control" />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <label>Message:</label>
                                <textarea value={this.state.contactBody} onChange={this.handleInputChange} name="contactBody" className="form-control" rows="10" cols="60" />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <button onClick={this.handleContactSubmit} className="btn btn-info">Send Message</button>
                            </ListGroup.Item>
                        </form>
                    </ListGroup>
                    <Card.Body>
                        <button className="btn btn-light" onClick={this.props.return} style={{float: 'right'}}>Return</button>
                    </Card.Body>
                </Card>
            </div>
        )

    }

}

export default ContactCard;