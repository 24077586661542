import React, { Component } from 'react';
import fire from '../../config/Fire';
import API from "../../utils/API";

class SignupCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            userData: "",
            showOptionsForSignup: true,
            showAgentSignup: false,
            showClientSignup: false,
            firstName: "",
            lastName: "",
            telephone: "",
            residentState: "",
            pcLicensed: false
        }
    }

    login = e => {
        e.preventDefault();
        fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((res) => {
            // cogoToast.success("Successful login with " + res);
            console.log(res.user.uid)
            this.checkCredentials(res.user.uid)

        }).catch((error) => {
            console.log(error)
        })
    }

    forgotPassword = e => {
        e.preventDefault();
        console.log("Forgot Password...")
        console.log("no code here yet")
    }

    createAccount = (e) => {
        e.preventDefault();
        console.log("Creating Account...")
        console.log(this.state);
        console.log("no code here yet")
    }

    createAgentAccount = (e) => {
        e.preventDefault();
        if (this.state.password === this.state.confirmPassword){
            alert("Creating Account...")
            console.log(this.state);
            console.log("no code here yet")
            fire.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(data => {
                console.log("Successfully created user");
                console.log(data);
                API.saveAgent({
                    uid: data.user.uid,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: data.user.email,
                    telephone: this.state.telephone,
                    servicer: this.state.clientServicer,
                    residentState: this.state.residentState
                })


                fire
                    .auth()
                    .signInWithEmailAndPassword(this.state.email, this.state.password).then((res) => {
                    // cogoToast.success("Successful login with " + res);
                    console.log(res.user.uid)
                    console.log(res);
                    this.checkCredentials(res.user.uid);
                    document.cookie = "fawnuid=" + res.user.uid;
                    // let cookieArr = document.cookie.split(";").map(cookie => cookie.split("="));
                    // console.log(cookieArr);
                    // // Turns a multi dimensional array into a json object
                    // let cookieObj = cookieArr
                    //                     .reduce((accumulator, [key, value]) => ({
                    //                         ...accumulator, [key.trim()]: decodeURIComponent(value)
                    //                     }),
                    //                     {});
        
                    // console.log(cookieObj);
                    // console.log(cookieObj.fawnuid);
                    document.location.href = '/agent'
        
                }).catch((error) => {
                    console.log(error)
                })

            })
        } else {
            alert("Passwords don't match!");
        }

    }

    createClientAccount = (e) => {
        e.preventDefault();
        if (this.state.password === this.state.confirmPassword){
            alert("Creating Account...");
            console.log(this.state);
            // console.log("")
            fire.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(data => {
                console.log("Successfully created user")
                console.log(data)
                alert("User: " + this.state.firstName + " " + this.state.lastName + " was created!")
    
                API.saveClient({
                    uid: data.user.uid,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: data.user.email,
                    telephone: this.state.telephone,
                    servicer: this.state.servicer
                })
    
                fire
                    .auth()
                    .signInWithEmailAndPassword(this.state.email, this.state.password).then((res) => {
                    // cogoToast.success("Successful login with " + res);
                    console.log(res.user.uid)
                    console.log(res);
                    this.checkCredentials(res.user.uid);
                    document.cookie = "fawnuid=" + res.user.uid;
                    // let cookieArr = document.cookie.split(";").map(cookie => cookie.split("="));
                    // console.log(cookieArr);
                    // // Turns a multi dimensional array into a json object
                    // let cookieObj = cookieArr
                    //                     .reduce((accumulator, [key, value]) => ({
                    //                         ...accumulator, [key.trim()]: decodeURIComponent(value)
                    //                     }),
                    //                     {});
        
                    // console.log(cookieObj);
                    // console.log(cookieObj.fawnuid);
                    document.location.href = '/client'
        
                }).catch((error) => {
                    console.log(error)
                })
                    
            }).catch((error) =>  {
                console.log(error)
                alert("Error: User not created!")
            })
        }
    }




    checkCredentials = (uid) => {

        console.log(uid + " has logged in")

        var client = false;
        var agent = false;
        var admin = false;
        console.log(client, agent, admin)
        // var manager = false;
        API.findClientLogin(uid)
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    console.log("This is a client login")
                    client = true
                    document.location.href = '/client'
                } else {
                    API.findAgentLogin(uid)
                        .then(res => {
                            if (res.data.length > 0) {
                                console.log("This is an agent login")
                                agent = true
                                document.location.href = '/agent'
                            } else {
                                API.findAdminLogin(uid)
                                    .then(res => {
                                        if (res.data.length > 0) {
                                            console.log("This is an admin login")
                                            admin = true
                                            document.location.href = '/admin'
                                        }
                                    }).catch(err =>
                                        console.log("This is not a proper login"))
                            }
                        }).catch(err =>
                            console.log("Type of user not found"))
                }
            }).catch(err =>
                console.log("This is not a proper login"))

    };


    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    clientLogin = (e) => {
        e.preventDefault()

        console.log("Client logging in using")

        var loginInfo = {
            username: this.state.username,
            password: this.state.password
        }
        console.log(loginInfo)

    }

    agentLogin = (e) => {
        e.preventDefault()
        console.log("Agent logging in")
        var loginInfo = {
            username: this.state.username,
            password: this.state.password
        }
        console.log(loginInfo)
    }

    adminLogin = (e) => {
        e.preventDefault()
        console.log("Admin logging in")
        var loginInfo = {
            username: this.state.username,
            password: this.state.password
        }
        console.log(loginInfo)
    }

    showOptionsForSignup = (e) => {
        if (this.state.showOptionsForSignup === true){
            this.setState({
                showAgentSignup: false,
                showClientSignup: false,
                showOptionsForSignup: false
            })
        } else {
            this.setState({
                showAgentSignup: false,
                showClientSignup: false,
                showOptionsForSignup: true
            })
        }
    }

    showAgentSignup = (e) => {
        if (this.state.showAgentSignup === true){
            this.setState({
                showAgentSignup: false,
                showOptionsForSignup: true
            })
        } else {
            this.setState({
                showAgentSignup: true,
                showClientSignup: false,
                showOptionsForSignup: false
            })
        }
    }

    showClientSignup = (e) => {
        if (this.state.showClientSignup === true){
            this.setState({
                showClientSignup: false,
                showOptionsForSignup: true
            })
        } else {
            this.setState({
                showAgentSignup: false,
                showClientSignup: true,
                showOptionsForSignup: false
            })
        }
    }

    // handleChange = () => {
    //     setChecked(!checked);
    // }


    render() {
        return (
            <div className="opacity-60" style={{backgroundColor: 'rgba(0,0,0,0.75)', width: '100%', color: 'black', borderRadius: '5px' }}>
                {/* <div className="">
                    <h4 style={{ textAlign: 'center' }}><strong>Sign Up</strong></h4> 
                    </div> */}
                <div className="card-body" style={{ backgroundColor: 'transparent'}}>
                    <div className="row">
                        {this.state.showOptionsForSignup ? 
                            <div className="col-12" style={{textAlign: 'center'}}>
                                <h3 style={{color: 'white'}}>Are you a Client or Agent?</h3>
                                <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.showClientSignup} disabled={false} className="btn btn-lg btn-light"><strong>Client</strong></button>
                                <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.showAgentSignup} className="btn btn-lg btn-light"><strong>Agent </strong></button>
                                {/* <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.showClientSignup} disabled={false} className="btn btn-lg btn-light"><strong>Client</strong></button> */}
                            </div>
                        : null}
                        
                        {/* <div className="col-lg-3 col-12"></div> */}
                        {this.state.showAgentSignup ? 
                            <div className="col-12" style={{textAlign: 'center'}}>
                                <h3 style={{color: 'white'}}>Agent Sign Up</h3>
                                <form style={{color: 'white', padding: '5%'}}>
                                    <label className="form-group"><strong>Email</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.email} name="email" onChange={this.handleInputChange} />
                                    <br />

                                    <label><strong>Password</strong></label>
                                    <br />
                                    <input type="password" value={this.state.password} name="password" onChange={this.handleInputChange} />
                                    <br />

                                    <label><strong>Confirm Password</strong></label>
                                    <br />
                                    <input type="password" value={this.state.confirmPassword} name="confirmPassword" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>First Name</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.firstName} name="firstName" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>Last Name</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.lastName} name="lastName" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>Telephone</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.telephone} name="telephone" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>Resident State</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.residentState} name="residentState" onChange={this.handleInputChange} />
                                    <br />

                                </form>
                                <br />
                                <br />
                                <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.createAgentAccount} className="btn btn-lg btn-light"><strong>Submit</strong></button>
                                <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.showOptionsForSignup} className="btn btn-lg btn-light"><strong>Quit</strong></button>
                            </div>                        
                        : null}

                        {this.state.showClientSignup ? 
                            <div className="col-12" style={{textAlign: 'center', padding: '5%'}}>
                                <h3 style={{color: 'white'}}>Client Sign Up</h3>
                                {/* <form>
                                    <label className="form-group"><strong>Email</strong></label><br />
                                    <input type="text" className="form-group" value={this.state.email} name="email" onChange={this.handleInputChange} />
                                    <br />
                                    <label><strong>Password</strong></label>
                                    <br />
                                    <input type="password" value={this.state.password} name="password" onChange={this.handleInputChange} />
                                </form> */}
                                  <form style={{color: 'white'}}>
                                    <label className="form-group"><strong>Email</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.email} name="email" onChange={this.handleInputChange} />
                                    <br />

                                    <label><strong>Password</strong></label>
                                    <br />
                                    <input type="password" value={this.state.password} name="password" onChange={this.handleInputChange} />
                                    <br />

                                    <label><strong>Confirm Password</strong></label>
                                    <br />
                                    <input type="password" value={this.state.confirmPassword} name="confirmPassword" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>First Name</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.firstName} name="firstName" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>Last Name</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.lastName} name="lastName" onChange={this.handleInputChange} />
                                    <br />

                                    <label className="form-group"><strong>Telephone</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.telephone} name="telephone" onChange={this.handleInputChange} />
                                    <br />

                                    {/* <label className="form-group"><strong>Resident State</strong></label>
                                    <br />
                                    <input type="text" className="form-group" value={this.state.residentState} name="residentState" onChange={this.handleInputChange} />
                                    <br /> */}

                                </form>
                                <br />
                                <br />
                                <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.createClientAccount} className="btn btn-lg btn-light"><strong>Submit</strong></button>
                                <button style={{ textAlign: 'center', margin: '10px', width: '150px' }} onClick={this.showOptionsForSignup} className="btn btn-lg btn-light"><strong>Quit</strong></button>
                            </div>                        
                        : null}
  
                        {/* <div className="col-lg-3 col-12"></div> */}
                    </div>
                </div>
            </div>
        )
    }



}

export default SignupCard;