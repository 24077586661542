import React, { Component } from 'react';
// import Carousel from 'react-bootstrap/Carousel';
import './style.css'

// width: 100%;
//   padding: 10px 15px;
//   background: linear-gradient(to right, #667eea 0%, #000080 100%);
//   color: white;
//   border-radius: 5px;
//   outline: none;
//   border: none;
//   font-size: 1em;
//   transition: background 0.6s;
//   cursor: pointer;
  
//   &:hover {
//     background: linear-gradient(to right, #000080 0%, #667eea 100%);
//   }

class FawnButtonMini extends Component {

    handleButtonClick = (event) => {
        event.preventDefault();
        this.props.pf1(event)
    }

    render() {
        return (

            <div style={{textAlign: 'center'}}>

                <button style={{color: 'white', 
                                width: '100%', 
                                fontSize: '1.2em',
                                transition: 'background 0.6s',
                                cursor: 'pointer',
                                boxShadow: '0px 0px 2px 2px black', 
                                backgroundImage: 'linear-gradient(147deg, navy 90%, white 0%)', 
                                padding: '10px 5px 10px 5px', 
                                margin: '2px',
                                borderRadius: '5px'}} 
                    onClick={(event) => this.handleButtonClick(event)}>{this.props.buttonText}
                </button>


            </div>



        )
    }
}

export default FawnButtonMini;
