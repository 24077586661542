import React, { Component } from "react";
import "./style.css";
import API from "../../utils/API";
import Table from 'react-bootstrap/Table';
import { storage } from '../../config/Fire';

// import ListGroupItem from 'react-bootstrap/ListGroupItem'
import './style.css'



class CreateVehicle extends Component {

    state = {
        createActivated: false,
        VIN: "",
        year: "",
        make: "",
        model: "",
        compDed: "",
        collDed: "",
        glassDed: "",
        IDCard: "",
        parentPolicy: "",
        activeOnPolicy: true
    }

    componentDidMount = () => {

        setTimeout(() => { this.getPolicies(this.props.clientID) }, 1000)

    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    };

    getPolicies = (clientID) => {

        API.findClientsAutoPolicies(clientID)
            .then(res => {
                this.setState({
                    policies: res.data
                })
            })
    }


    fileSelectedHandler = event => {
        event.preventDefault();
        if (event.target.files[0]) {
            this.setState({
                IDCard: event.target.files[0]
            })
        }
    }

    fileUploadHandlerB = (event) => {
        this.setState({
            uploaded: false
        })
        event.preventDefault();
        const uploadTask = storage.ref(`*/${this.state.IDCard.name}`).put(this.state.IDCard);
        uploadTask.on('state_changed',
            (snapshot) => {
                // progress function ... demonstratesprogress
                console.log(snapshot)
            },
            (error) => {
                //error function
                console.log(error)
            },
            () => {
                storage.ref('*').child(this.state.IDCard.name).getDownloadURL().then(urlB => {
                    this.setState({
                        url: urlB,
                        uploaded: true
                    })
                })
            }
        )
    }

    activateCreate = (e) => {
        e.preventDefault();
        if (this.state.createActivated === true) {
            this.setState({
                createActivated: false
            })
        } else {
            this.setState({
                createActivated: true
            })
        }
    }

    handleCarSubmit = (e) => {
        e.preventDefault()

        API.saveVehicle(this.props.autoData[0]._id, {
            VIN: this.state.VIN,
            year: this.state.year,
            make: this.state.make,
            model: this.state.model,
            compDed: this.state.compDed,
            collDed: this.state.collDed,
            glassDed: this.state.glassDed,
            IDCard: this.state.url,
            parentPolicy: this.state.parentPolicy,
            activeOnPolicy: this.state.activeOnPolicy
        }).then(res => {
            alert("You've saved the Vehicle")
        }).catch(err => alert("WARNING, VEHICLE NOT SAVED. PLEASE TRY AGAIN, IF ERROR PERSISTS CALL 610-299-9918"))

    }


    render() {
        return (

            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        Create a Vehicle
                            <br />
                        {/* <button className="btn-warning btn" onClick={this.activateCreate}>Create</button> */}
                    </div>
                    {/* {this.state.createActivated ? */}
                    <div className="card-body">
                        <Table responsive>

                            <tbody>
                                <tr>
                                    <td><strong>Info needed</strong></td>
                                    <td><strong>Input</strong></td>
                                </tr>
                                <tr>
                                    <td>Year: </td>
                                    <td><input type="number" onChange={this.handleInputChange} value={this.state.year} name="year" /></td>
                                </tr>
                                <tr>
                                    <td>Make: </td>
                                    <td><input type="text" onChange={this.handleInputChange} value={this.state.make} name="make" /></td>
                                </tr>
                                <tr>
                                    <td>Model: </td>
                                    <td><input type="text" onChange={this.handleInputChange} value={this.state.model} name="model" /></td>
                                </tr>
                                <tr>
                                    <td>Comprehensive Deductible: </td>
                                    <td><input type="number" onChange={this.handleInputChange} value={this.state.compDed} name="compDed" /></td>
                                </tr>
                                <tr>
                                    <td>Collision Deductible: </td>
                                    <td><input type="number" onChange={this.handleInputChange} value={this.state.collDed} name="collDed" /></td>
                                </tr>

                                <tr>
                                    <td>Glass Deductible: </td>
                                    <td><input type="number" onChange={this.handleInputChange} value={this.state.glassDed} name="glassDed" /></td>
                                </tr>




                                <tr>
                                    <td>IDCard: </td>
                                    <td>
                                        <input style={{width: '80%'}} type="file" onChange={this.fileSelectedHandler} />
                                        <button onClick={this.fileUploadHandlerB}>Upload
                                            <span>
                                                {this.state.uploaded === false ? <img alt="loading" src="/loading.gif" /> : null}
                                                {this.state.uploaded === true ? <img alt="checkmark" src="/checkmark.png" style={{ height: '20px', width: '20px' }} /> : null}
                                            </span>
                                        </button>

                                    </td>
                                </tr>











                                <tr>
                                    <td>VIN #: </td>
                                    <td><input type="text" onChange={this.handleInputChange} value={this.state.VIN} name="VIN" /></td>
                                </tr>
                                <tr>
                                    <td>Policy Assignment</td>
                                    {this.state.policies ?

                                        <td><select id="protegeDropMenu" value={this.props.parentPolicy} onChange={this.handleInputChange} name="parentPolicy" type="">
                                            <option value="">--Select a Policy--</option>
                                            {this.state.policies.map(policy => (
                                                <option key={policy._id} value={policy._id}>{policy.carrier} #{policy.policyNumber}</option>
                                            ))}
                                        </select>
                                        </td>


                                        : <td>"No auto policies yet"</td>}
                                </tr>
                            </tbody>
                        </Table>
                        <button style={{ textAlign: "center" }} className="btn btn-success" onClick={this.handleCarSubmit}>Submit</button>
                    </div>

                    {/* : null} */}


                </div>
            </div>
        )

    }

}

export default CreateVehicle;